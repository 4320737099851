import React from 'react'
import defaultLogo from '../../../../images/recieptDefault.png'

const ReceiptPreview = ({
  SellerName,
  SellerLocation,
  RestaurantName,
  ReceiptLogo,
  Address,
  Ytunnus,
  Zipcode,
  City,
  CustomerMessage,
  Phone,
}) => {
  RestaurantName = RestaurantName || 'Restaurant Name'
  ReceiptLogo = ReceiptLogo || defaultLogo
  Address = Address || 'Helsinginkatu 1 B'
  Ytunnus = Ytunnus || '1234567-8'
  Zipcode = Zipcode || '00100'
  City = City || 'Helsinki'
  CustomerMessage = CustomerMessage || ''

  return (
    <div className="ReceiptPreviewWrapper">
      <div>
        Yhteensa <span className="right">21,40</span>
      </div>
      <div>
        Alv 14% <span className="right">3,00</span>
      </div>
      <hr />
      <div>{RestaurantName}</div>
      <div>{Ytunnus}</div>
      <div>{Address}</div>
      <div>{Phone}</div>
      <div>
        {Zipcode} {City}
      </div>
      {SellerName && SellerLocation ? (
        <>
          <br />
          <div>{SellerName}</div>
          <div>{SellerLocation}</div>
        </>
      ) : null}
      <hr />
      <div className="centered">
        <div className="customerMessage">{CustomerMessage}</div>
        <img src={ReceiptLogo} alt="Receipt logo" className="logo" />
      </div>
    </div>
  )
}

export default ReceiptPreview
