import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import Item, { Extra, SpecialAvailabilityDay, Tag } from '../types/Item'
import { v4 as uuidv4 } from 'uuid'
import { Translation } from '../types'

const specialAvailabilityDay = yup
  .object({
    from: yup.string(),
    to: yup.string(),
    available: yup.bool(),
  })
  .default({
    from: '',
    to: '',
    available: true,
  })
  .nullable()
  .transform(val => {
    if (val.from === '' && val.to === '') return null
    return val
  })

const options = (t: TFunction<'translation', undefined>) => {
  const schema = yup
    .array()
    .of(
      yup.object({
        optionChoiceLimit: yup.string().required(t('errors.required')),
        optionList: yup
          .array()
          .of(
            yup.object({
              price: yup
                .string()
                .transform(price => {
                  if (price === '' || price === undefined) return '0'
                  return price
                })
                .matches(/^\d+(\.\d{1,2})?$/gm, { message: t('errors.numberFixed2') })
                .required(t('errors.required')),
              translations: yup.object({
                name: yup.object({ en: yup.string().required(t('errors.required')) }),
              }),
            })
          )
          .required(t('errors.required')),
        translations: yup.object({
          optionName: yup.object({ en: yup.string().required(t('errors.required')) }),
        }),
      })
    )
    .default([])
  return schema
}

const extraItemSchema = (t: TFunction<'translation', undefined>) => {
  const schema: yup.ObjectSchema<Extra<'extraItem'>> = yup.object({
    type: yup.string().oneOf(['extraItem', undefined]).default('extraItem'),
    translations: yup.object({
      name: yup.object({ en: yup.string().required(t('errors.required')) }),
    }),
    price: yup
      .string()
      .transform(price => {
        if (price === '' || price === undefined) return '0'
        return price
      })
      .matches(/^\d+(\.\d{1,2})?$/gm, { message: t('errors.numberFixed2') })
      .required(t('errors.required')),
    isMultiple: yup.bool().default(false),
    name: yup.string(),
    nameFI: yup.string(),
    nameSE: yup.string(),
  })
  return schema
}

const otherItemSchema = (t: TFunction<'translation', undefined>) => {
  const schema: yup.ObjectSchema<Extra<'otherItem'>> = yup.object().shape({
    type: yup.string().oneOf(['otherItem']).required(),
    id: yup.string().required(t('errors.required')),
    isMultiple: yup.bool().default(false),
  })
  return schema
}

const extras = (t: TFunction<'translation', undefined>) => {
  const schema = yup
    .array()
    .of(
      yup.lazy((value: Extra<'extraItem' | 'otherItem'>) => {
        if (value && value.type === 'otherItem') {
          return otherItemSchema(t)
        } else {
          return extraItemSchema(t)
        }
      })
    )
    .default([])

  return schema
}

const tagSchema = (t: TFunction<'translation', undefined>) => {
  const schema: yup.ObjectSchema<Tag> = yup.object({
    id: yup
      .string()
      .transform(id => {
        if (!id) return uuidv4()
        return id
      })
      .default(uuidv4())
      .required(),
    translations: yup.object({
      name: yup.object({ en: yup.string().required(t('errors.required')) }),
    }),
    nameId: yup.string().required(t('errors.required')),
    color: yup.string().default('#5bd96c'),
  })
  return schema
}

const itemSchema = (t: TFunction<'translation', undefined>) => {
  // TS errors here might look scary, but easy to fix
  const objectSchema: yup.ObjectSchema<Item> = yup.object({
    id: yup
      .string()
      .transform(id => {
        console.log('ID TRANSFORMED')
        if (!id) return uuidv4()
        return id
      })
      .default(uuidv4())
      .required(),
    partnerId: yup.string(),
    image: yup.string(),
    eanCode: yup.string(),
    translations: yup.object({
      title: yup.object({ en: yup.string().required(t('errors.required')) }),
      description: yup
        .object({ en: yup.string().required(t('errors.required')) })
        .transform(value =>
          Object.keys(value).every(ln => {
            console.log(value[ln])
            return value[ln] === '' || value[ln] === undefined
          })
            ? undefined
            : value
        )
        .default(undefined),
    }),
    price: yup
      .string()
      .matches(/^\d+(\.\d{1,2})?$/gm, { message: t('errors.numberFixed2') })
      .required(t('errors.required')),
    type: yup.string().oneOf(['menuItem']).default('menuItem').required(),
    itemType: yup
      .string()
      .oneOf(['main', 'side_dish', 'drink', 'combo'], t('errors.required'))
      .required(t('errors.required')),
    isAlcohol: yup.bool(),
    isIncludeSurcharge: yup.bool(),
    shouldSuggested: yup.bool(),
    noTakeAway: yup.bool(),
    isHidden: yup.bool(),
    options: options(t),
    extras: extras(t),
    tags: yup.array().of(tagSchema(t)).default([]).required(t('errors.required')),
    specialAvailability: yup
      .object({
        hideWhenUnavailable: yup.bool().default(false),
        1: specialAvailabilityDay,
        2: specialAvailabilityDay,
        3: specialAvailabilityDay,
        4: specialAvailabilityDay,
        5: specialAvailabilityDay,
        6: specialAvailabilityDay,
        7: specialAvailabilityDay,
      })
      .nullable()
      .default(null),
    // DEPRECATED PROPERTIES
    title: yup.string().default(undefined),
    titleFI: yup.string().default(undefined),
    titleSE: yup.string().default(undefined),
    description: yup.string().default(undefined),
    descriptionFI: yup.string().default(undefined),
    descriptionSE: yup.string().default(undefined),
  })
  return objectSchema
}

export default itemSchema
