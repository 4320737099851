import { Chip, Stack } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

function Chips({ index }: { index: number }) {
  const { getValues, setValue } = useFormContext()

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        color="primary"
        variant={getValues().options?.[index]?.optionChoiceLimit === '1' ? 'filled' : 'outlined'}
        label="1 option"
        size="small"
        onClick={() => setValue(`options.${index}.optionChoiceLimit`, '1')}
      />
      <Chip
        color="primary"
        variant={getValues().options?.[index]?.optionChoiceLimit === '2' ? 'filled' : 'outlined'}
        label="2 options"
        size="small"
        onClick={() => setValue(`options.${index}.optionChoiceLimit`, '2')}
      />
      <Chip
        color="primary"
        variant={getValues().options?.[index]?.optionChoiceLimit === '3' ? 'filled' : 'outlined'}
        label="3 options"
        size="small"
        onClick={() => setValue(`options.${index}.optionChoiceLimit`, '3')}
      />
      <Chip
        color="primary"
        variant={getValues().options?.[index]?.optionChoiceLimit === '4' ? 'filled' : 'outlined'}
        label="4 options"
        size="small"
        onClick={() => setValue(`options.${index}.optionChoiceLimit`, '4')}
      />
      <Chip
        color="primary"
        variant={getValues().options?.[index]?.optionChoiceLimit === '999999' ? 'filled' : 'outlined'}
        label="Unlimited"
        size="small"
        onClick={() => setValue(`options.${index}.optionChoiceLimit`, '999999')}
      />
    </Stack>
  )
}

export default Chips
