import { useEffect, useRef, useState } from 'react'
import TranslateIcon from '@mui/icons-material/Translate'
import {
  Button,
  Stack,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Tabs,
  Typography,
  TextField,
} from '@mui/material'
import EditableText from '../../../../../../ui/EditableText/EditableText'
import { get, useFormContext } from 'react-hook-form'
import TranslationsTable from '../../../../../../ui/TranslationsTable/TranslationsTable'
import { Item } from '../../../../../../types'

type TranslationsProps = {
  restaurant: any
  item: Item
}

function Translations({ restaurant, item }: TranslationsProps) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext()

  return (
    <Box sx={{ maxWidth: '80vw' }}>
      <Typography variant="h5">Localisation</Typography>
      <Stack spacing={1} ml={1} mt={1}>
        <TranslationsTable restaurant={restaurant} formBase="translations.title" title="Title" />
        <TranslationsTable restaurant={restaurant} formBase="translations.description" title="Description" optional />
      </Stack>
    </Box>
  )
}

export default Translations
