import React, { useState, useEffect } from 'react'
import { useMenuSelectContext } from '../../contexts/MenuSelectContext'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import M from 'materialize-css'
import MassOverrideSpecialAvailabilityModal from '../massOverrideSpecialAvailabilityModal/MassOverrideSpecialAvailabilityModal'

// page uses the hook
export default function SelectedMenuItemsContoller() {
  const { selectionList, selectAll, clearList, removeElements, updateElements }: any = useMenuSelectContext()
  const [showDelete, setShowDelete] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    try {
      let elems = document.querySelectorAll('.dropdown-trigger')
      M.Dropdown.init(elems, {
        constrainWidth: false,
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <>
      {selectionList.length > 0 && <MassOverrideSpecialAvailabilityModal />}
      <div className={selectionList.length > 0 ? 'menu-selection-wrapper' : 'menu-selection-wrapper hide'}>
        <p className="count">{selectionList.length}</p>

        {!showDelete && (
          <>
            <button className="btn-flat" onClick={() => selectAll()}>
              select all
            </button>
            <button className="btn-flat" onClick={() => clearList()}>
              clear selection
            </button>
          </>
        )}
        {showDelete ? (
          <div className="delete-buttons">
            {t('general.areYouSure')}
            <button className="btn white" onClick={() => removeElements()}>
              {t('general.Yes')}
            </button>{' '}
            <button className="btn-flat" onClick={() => setShowDelete(false)}>
              {t('general.No')}
            </button>
          </div>
        ) : (
          <>
            <a className="dropdown-trigger btn-floating white" href="#" data-target="dropdown1">
              <i className="material-icons">more_horiz</i>
            </a>
            <ul id="dropdown1" className="dropdown-content">
              <li>
                <a href="#overrideSpecialAvailabilityModal" className="modal-trigger">
                  <i className="material-icons">access_time</i>⏳ Override Special Availability
                </a>
              </li>
              <li>
                <a onClick={() => setShowDelete(true)}>
                  <i className="material-icons">delete_forever</i>Delete Selected
                </a>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  )
}
