import { useEffect, useRef, useState } from 'react'
import Translations from './components/Translations'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  colors,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import Availability from './components/Availability'
import { Item } from '../../../../../types'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { Tag } from '../../../../../types/Item'
import { useRecoilValue } from 'recoil'
import selectedRestaurantPartnersState from '../../../../../selectors/selectedRestaurantPartners'
const animatedComponents = makeAnimated()

const presetItemTags: Tag[] = [
  { translations: { name: { en: 'Vegan' } }, color: colors.green[600], nameId: 'vegan' },
  { translations: { name: { en: 'Lactose free' } }, color: colors.grey[600], nameId: 'lactose_free' },
  { translations: { name: { en: 'Gluten free' } }, color: colors.blueGrey[600], nameId: 'gluten_free' },
  { translations: { name: { en: 'Spicy' } }, color: colors.red[600], nameId: 'spicy' },
  { translations: { name: { en: 'Grilled' } }, color: colors.orange[600], nameId: 'grilled' },
]

type InfoProps = {
  item: Item
  restaurant: any
  specialAvailability: Item['specialAvailability']
  setSpecialAvailability: (args: any) => any
}

function Info({ item, restaurant, specialAvailability, setSpecialAvailability }: InfoProps) {
  const { t } = useTranslation()
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const selectedRestaurantPartners = useRecoilValue(selectedRestaurantPartnersState)
  const img = useWatch({ control, name: 'image' })
  const translations = useWatch({ control, name: 'translations' })
  const tags = useWatch({ control, name: 'tags' })

  const handleImageRemove = () => {
    setValue('image', undefined)
  }

  // Syncing deprecated values
  useEffect(() => {
    setValue('title', translations?.title?.en)
    setValue('titleFI', translations?.title?.fi)
    setValue('titleSE', translations?.title?.sv)

    setValue('description', translations?.description?.en)
    setValue('descriptionFI', translations?.description?.fi)
    setValue('descriptionSE', translations?.description?.sv)

    // if no nameId, create one
    const newTags = tags?.map((tag: Tag) => {
      if (!tag?.nameId) {
        return {
          ...tag,
          nameId: tag?.translations?.name?.en,
        }
      }

      return tag
    })

    setValue('tags', newTags)
  }, [translations])

  return (
    <Stack spacing={3}>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {img ? (
            <Box
              component="img"
              src={img}
              sx={{
                maxWidth: '70%',
                width: '300px',
                height: 'auto',
                border: '5px solid',
                borderColor: 'mynt.main',
                borderRadius: '10px',
              }}
            />
          ) : (
            <Box
              sx={{
                width: '20vh',
                height: '20vh',
                maxWidth: '30vw',
                maxHeight: '30vw',
                border: '5px solid',
                borderColor: 'mynt.main',
                borderRadius: '10px',
                bgcolor: 'grey.300',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="button">No image</Typography>
            </Box>
          )}
          {errors.image && errors.image.message && (
            <Typography variant="subtitle1" color="error">
              {errors.image.message as string}
            </Typography>
          )}
          <Stack direction="row" spacing={2} mt={2}>
            <Button variant="contained" component="label">
              {t('menuView.editMenuItem.EditImageButton')}
              <Controller
                control={control}
                name={'image'}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <input
                      {...field}
                      value={value?.fileName}
                      onChange={(e: any) => {
                        onChange(URL.createObjectURL(e.target.files[0]))
                      }}
                      type="file"
                      hidden
                    />
                  )
                }}
              />
            </Button>
            <Button variant="outlined" color="error" onClick={handleImageRemove}>
              Delete image
            </Button>
          </Stack>
        </Box>
      </Box>

      {item?.eanCode ? (
        <div className="eanCode">
          <b>EAN Code:</b> {item?.eanCode}
        </div>
      ) : null}
      <Translations restaurant={restaurant} item={item} />
      {Boolean(selectedRestaurantPartners?.length) && (
        <Box>
          <Typography variant="h5">Partner ID</Typography>
          <Typography variant="subtitle2" mb={1}>
            Needed when integrated POS requires partner ID
          </Typography>
          <TextField
            placeholder="Partner ID"
            fullWidth
            inputProps={{ className: 'browser-default', step: '.01' }}
            size="small"
            {...register('partnerId')}
            error={Boolean(errors.partnerId)}
            helperText={errors.partnerId?.message as string}
          />
        </Box>
      )}
      <Box>
        <Typography variant="h5" mb={1}>
          {t('menuView.editMenuItem.EditProductPrice')}
        </Typography>
        <TextField
          placeholder="Price of an item"
          fullWidth
          inputProps={{ className: 'browser-default', step: '.01' }}
          size="small"
          {...register('price')}
          error={Boolean(errors.price)}
          helperText={errors.price?.message as string}
        />
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          {t('menuView.editMenuItem.EditProductType')}
        </Typography>
        <Controller
          name="itemType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select size="small" fullWidth value={value} onChange={onChange} error={Boolean(errors.itemType)}>
              <MenuItem value="main">Main dish</MenuItem>
              <MenuItem value="side_dish">Side dish</MenuItem>
              <MenuItem value="drink">Drink</MenuItem>
              <MenuItem value="combo">Combo</MenuItem>
            </Select>
          )}
        />
        <FormHelperText>{errors.itemType?.message?.toString?.()}</FormHelperText>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          Tags
        </Typography>
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <CreatableSelect
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              name="tags"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                multiValue: (styles, { data }) => ({
                  ...styles,
                  backgroundColor: data?.value?.color,
                }),
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: colors.grey[100],
                }),
              }}
              options={presetItemTags
                ?.filter((tag: Tag) => !field.value?.find((selectedTag: any) => selectedTag?.nameId === tag?.nameId))
                ?.map((tag: Tag) => ({
                  value: tag,
                  label: tag?.translations?.name?.en,
                }))}
              onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
              value={field?.value?.map((tag: Tag) => ({
                value: tag,
                label: tag?.translations?.name?.en,
                color: colors.grey[100],
              }))}
              onCreateOption={inputValue => {
                const newTag: Tag = {
                  translations: { name: { en: inputValue } },
                  color: colors.lightBlue[600],
                  nameId: inputValue,
                }
                field.onChange([...field.value, newTag])
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          )}
        />
      </Box>
      <Box>
        <Typography variant="h5">Preferences</Typography>
        <Stack ml={1}>
          <Controller
            name="isAlcohol"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={t('menuView.editMenuItem.EditProductIsAlcoholTitle')}
              />
            )}
          />
          <Controller
            name="isIncludeSurcharge"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={t('menuView.editMenuItem.EditProductIsIncludeSurchargeTitle')}
              />
            )}
          />
          <Controller
            name="shouldSuggested"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={t('menuView.editMenuItem.EditProductShouldSuggestedTitle')}
              />
            )}
          />
          <Controller
            name="noTakeAway"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={t('menuView.editMenuItem.EditProductNoTakeAway')}
              />
            )}
          />
          <Controller
            name="isHidden"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={t('menuView.editMenuItem.EditProductIsHiddenItem')}
              />
            )}
          />
        </Stack>
      </Box>
      <Availability specialAvailability={specialAvailability} setSpecialAvailability={setSpecialAvailability} />
    </Stack>
  )
}

export default Info
