import React, { useState, useEffect } from 'react'
import Toggelable from '../../components/Toggleable/Toggleable'
import { createDevice } from '../../api/deviceApi'
import { useMenuContext } from '../../contexts/MenuContext'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'

function AddMonitorForm({ handleCancel, refresh }) {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [deviceName, setDeviceName] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [subMenu, setSubMenu] = useState('')
  const [ordersFrom, setOrdersFrom] = useState([])
  const [type, setType] = useState('customer_monitor')

  const [isAdding, setIsAdding] = useState(false)
  const [error, setError] = useState('')
  const { allSubMenus } = useMenuContext()

  const handleAddClick = () => {
    setError('')

    if (!isFilledOut()) {
      setError('Please fill in all the details')
      return
    }

    setIsAdding(true)
    createDevice({
      serialNumber,
      name: deviceName,
      restaurantId: selectedRestaurant.id,
      type,
      menuIds: ordersFrom, // todo, using real sub menu ids here
    })
      .then(() => {
        refresh()
      })
      .catch(error => {
        setError(error.message)
        setIsAdding(false)
      })
      .finally(() => {
        setDeviceName('')
        setSerialNumber('')
        // setRestaurant("");
        setIsAdding(false)
      })
  }

  /**
   * Adds or removes menus to the monitor
   * @param {*} e
   * @returns
   */
  const handleSetOrdersFrom = e => {
    if (e.target.name == 'no-submenu') {
      setOrdersFrom([])
      return
    }
    let newOrdersFrom = [...ordersFrom]
    let index = newOrdersFrom.findIndex(elem => elem == e.target.value)
    if (index !== -1) {
      newOrdersFrom.splice(index)
    } else {
      newOrdersFrom.push(e.target.value)
      e.target.checked = true
    }
    setOrdersFrom(newOrdersFrom)
  }

  let isFilledOut = () => {
    if (deviceName?.length > 3) {
      if (serialNumber?.length > 3) {
        return true
      }
    }

    return false
  }

  return (
    <form className="col s12">
      <label htmlFor="deviceName">Monitor Name:</label>
      <input type="text" id="deviceName" value={deviceName} onChange={e => setDeviceName(e.target.value)} />
      <br />
      <label htmlFor="serialNumber">Serial Number:</label>
      <input type="text" id="serialNumber" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} />
      <br />
      <label htmlFor="type">Monitor type:</label>
      <select id="type" value={type} onChange={e => setType(e.target.value)} className="browser-default">
        <option value="customer_monitor">Customer Monitor</option>
        <option value="kitchen_monitor">Kitchen Monitor</option>
      </select>
      <br />

      <label htmlFor="restaurant">Show orders from submenu:</label>
      <p>
        <label key={subMenu.id}>
          <input
            type="checkbox"
            className="filled-in checkbox-mynt"
            name="no-submenu"
            checked={ordersFrom.length == 0}
            value={subMenu.id}
            onChange={handleSetOrdersFrom}
          />
          <span>Entire restaurant</span>
        </label>
      </p>

      {allSubMenus.map(subMenu => (
        <p key={subMenu.id}>
          <label>
            <input
              type="checkbox"
              className="filled-in checkbox-mynt"
              name="submenu"
              value={subMenu.id}
              checked={ordersFrom.includes(subMenu.id)}
              onChange={handleSetOrdersFrom}
            />
            <span> {subMenu.name}</span>
          </label>
        </p>
      ))}
      <br />
      <div className="btn-wrapper">
        <button
          type="button"
          onClick={handleAddClick}
          disabled={isAdding}
          className={`btn ${isAdding ? 'disabled' : ''}`}
        >
          Add Monitor
        </button>
        <button
          type="button"
          onClick={handleCancel}
          disabled={isAdding}
          className={`btn-flat ${isAdding ? 'disabled' : ''}`}
        >
          cancel
        </button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </form>
  )
}

export default AddMonitorForm
