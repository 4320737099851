import { selector } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'

const selectedRestaurantSubMenusState = selector<any>({
  key: 'selectedRestaurantSubnmenus',
  get: ({ get }) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const subMenus = selectedRestaurant?.subMenus
    return subMenus
  },
  set: ({ get, set }, newData) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const newValue = {
      ...selectedRestaurant,
      subMenus: [...newData],
    }
    set(selectedRestaurantState, newValue)
  },
})

export default selectedRestaurantSubMenusState
