import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Printer } from '../types'
import { v4 as uuidv4 } from 'uuid'
import { PrintingMethods } from '../types/Printer'

const printerSchema = (t: TFunction<'translation', undefined>) => {
  // @ts-ignore
  const objectSchema: yup.ObjectSchema<Printer> = yup.object({
    id: yup
      .string()
      .transform(id => {
        if (!id) return uuidv4()
        return id
      })
      .default(uuidv4())
      .required(),
    restaurantId: yup.string().required(t('errors.required')),
    model: yup.string().test('printer-model-autocomplete', t('errors.required'), (value, context) => {
      if (!context.parent?.printingMethod) {
        return false
      }
      return true
    }),
    serialNumber: yup.string().required(t('errors.required')),
    name: yup.string().required(t('errors.required')),
    type: yup.string().oneOf(['printer']).default('printer').required(),
    printerPaperWidth: yup.string().oneOf(['58mm', '80mm']).default('80mm').required(),

    menuIds: yup.array().of(yup.string().defined().strict(true)).default([]).required(t('errors.required')),
    showOtherSubmenuItems: yup.boolean().default(false),

    receiptsLanguage: yup.string().required(t('errors.required')),
    externalId: yup.string(),
    staffPinCode: yup
      .string()
      .length(4, t('errors.length', { length: 4 }))
      .matches(/^\d+$/, 'The field should have digits only')
      .required(t('errors.required')),
    shouldAutoPrintOrders: yup.boolean(),
    autoAcceptOrderInSeconds: yup.number(),
    printingMethod: yup.string().oneOf(Object.values(PrintingMethods)).default(PrintingMethods.SUNMI_V2),
    useManualCut: yup.boolean().default(false),
    isAggressiveNotiEnabled: yup.boolean(),
    skipDeliveredWhenMarkedReady: yup.boolean(),
    disableCustomerReceipt: yup.boolean(),
    appVersion: yup.string(),
    osVersion: yup.string(),
    buildNumber: yup.string(),
    vendorSerialNumber: yup.string(),
    lastActivityAt: yup.string().nullable(),
    ip: yup.string(),
    port: yup.string(),
    isCashierPartnerOnly: yup.boolean(),
  })

  return objectSchema
}

export default printerSchema
