import { useEffect } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Category } from '../types'

export default function useDeprecatedItemValues(
  getValues: UseFormGetValues<Category>,
  setValue: UseFormSetValue<Category>
) {
  // Picking deprecated values from item and setting them to translations
  const trigger = () => {
    const values = getValues()
    // titles
    if (!values?.translations?.title?.en && values?.title) {
      setValue('translations.title.en', values.title)
    }
    if (!values?.translations?.title?.fi && values?.titleFI) {
      setValue('translations.title.fi', values.titleFI)
    }
    if (!values?.translations?.title?.sv && values?.titleSE) {
      setValue('translations.title.sv', values.titleSE)
    }

    // descriptions
    if (!values?.translations?.description?.en && values?.description) {
      setValue('translations.description.en', values.description)
    }
    if (!values?.translations?.description?.fi && values?.descriptionFI) {
      setValue('translations.description.fi', values.descriptionFI)
    }
    if (!values?.translations?.description?.sv && values?.descriptionSE) {
      setValue('translations.description.sv', values.descriptionSE)
    }
  }

  useEffect(trigger, [])

  return trigger
}
