import { Paper, Stack, Typography, Grid, TextField, Box, Select, MenuItem } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import { SUPPORTED_COUNTRIES } from '../../../../../constants'
import { toast } from 'react-toastify'
import { GoogleMap, useLoadScript, Libraries, Marker } from '@react-google-maps/api'

const getCountryNameFromCode = (code: string) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
  return regionNames.of(code)
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

const libraries: Libraries = ['places']

function Location() {
  const { register, setValue, control, formState } = useFormContext()
  const [cords, setCords] = useState<[number, number] | null>(null)
  const watchedValues = useWatch({ control })
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
  })

  const onMapClick = async (e: any) => {
    console.log(e)
    const { lat, lng } = e.latLng
    const latitude = lat()
    const longitude = lng()
    console.log(latitude, longitude)

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=fi`
    )
    const data = await response.json()
    console.log(data)

    if (data.results && data.results.length > 0) {
      let streetNumber = ''
      let route = ''
      let city = ''
      let zipCode = ''
      let country = ''

      const components = data.results[0].address_components
      components.forEach((component: any) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name
        }
        if (component.types.includes('route')) {
          route = component.long_name
        }
        if (component.types.includes('locality')) {
          city = component.long_name
        }
        if (component.types.includes('postal_code')) {
          zipCode = component.long_name
        }
        if (component.types.includes('country')) {
          country = component.short_name
        }
      })

      const address = `${route} ${streetNumber}`

      console.log(`Address: ${address}`)
      console.log(`City: ${city}`)
      console.log(`Zip Code: ${zipCode}`)
      console.log(`Country: ${country}`)
      if (SUPPORTED_COUNTRIES?.find(({ operatingCountryCode }) => operatingCountryCode === country)) {
        setValue('address', address)
        setValue('city', city)
        setValue('zipCode', zipCode)
        setValue('config.operatingCountryCode', country)
        setValue('geopoint', {
          latitude,
          longitude,
        })
        setCords([latitude, longitude])
      } else {
        toast.error('Clicked country is not supported.')
      }
    } else {
      console.log('No data found for the provided coordinates.')
    }
  }

  useEffect(() => {
    if (watchedValues?.address && watchedValues?.city && watchedValues?.zipCode) {
      const address = `${watchedValues?.address} ${watchedValues?.city} ${watchedValues?.zipCode}`
      // @ts-ignore
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address }, (results: any, status: any) => {
        if (status === 'OK') {
          console.log(results)
          const { lat, lng } = results[0].geometry.location
          setCords([lat(), lng()])
        } else {
          console.log('Geocode was not successful for the following reason: ' + status)
        }
      })
    }
  }, [watchedValues?.address, watchedValues?.city, watchedValues?.zipCode])

  return (
    <Paper elevation={4}>
      <Stack spacing={2} p={2}>
        <Typography variant="h4">Location</Typography>
        <Grid container>
          <Grid item xs={12}>
            <Stack p={1}>
              <Typography variant="subtitle1">Street address*</Typography>
              <Controller
                name="address"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    inputProps={{ className: 'browser-default' }}
                    placeholder="Testikatu 123"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    // ref={addressRef}
                    value={field.value}
                    error={Boolean(formState?.errors?.address)}
                    helperText={formState.errors.address?.message?.toString?.()}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack p={1}>
              <Typography variant="subtitle1">City*</Typography>
              <TextField
                fullWidth
                {...register('city', { required: 'Required' })}
                inputProps={{ className: 'browser-default' }}
                error={Boolean(formState?.errors?.city)}
                helperText={formState.errors.city?.message?.toString?.()}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack p={1}>
              <Typography variant="subtitle1">Postal code*</Typography>
              <TextField
                fullWidth
                {...register('zipCode', { required: 'Required' })}
                inputProps={{ className: 'browser-default' }}
                placeholder="000000"
                error={Boolean(formState?.errors?.zipCode)}
                helperText={formState.errors.zipCode?.message?.toString?.()}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack p={1}>
              <Typography variant="subtitle1">Operating Country*</Typography>
              <Controller
                name="config.operatingCountryCode"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState, formState }) => (
                  <Select
                    MenuProps={{ sx: { maxHeight: '50vh' } }}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={String(field.value)}
                    error={Boolean(formState?.errors?.['config.operatingCountryCode'])}
                    inputProps={{ helperText: formState?.errors?.['config.operatingCountryCode']?.message }}
                  >
                    {SUPPORTED_COUNTRIES.map(({ operatingCountryCode }, index) => (
                      <MenuItem key={index} value={operatingCountryCode}>
                        {getCountryNameFromCode(operatingCountryCode)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box minWidth="500px" maxWidth="100%" height="400px" maxHeight="30vw">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: cords?.[0] || 60.18074735284208, lng: cords?.[1] || 24.952748898971745 }}
              zoom={11}
              onClick={onMapClick}
            >
              {cords && <Marker position={{ lat: cords[0], lng: cords[1] }} />}
            </GoogleMap>
          )}
        </Box>
      </Stack>
    </Paper>
  )
}

export default Location
