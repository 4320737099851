import { Collapse, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

type CollapsableListProps = {
  title?: string
  children?: React.ReactNode
  expanded?: boolean
  setExpanded?: any
}

function CollapsableList({ title, children, expanded = false, setExpanded }: CollapsableListProps) {
  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={() => setExpanded((prev: any) => !prev)}
        sx={{ cursor: 'pointer' }}
      >
        {expanded ? <RemoveIcon /> : <AddIcon />}
        <Typography variant="subtitle1" fontWeight={600}>
          {title}
        </Typography>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack spacing={1} ml={2}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default CollapsableList
