import { useEffect, useState } from 'react'
import Toggelable from '../../../components/Toggleable/Toggleable'
import { AVAILABLE_LANGUAGES } from '../../../constants'
import ToggleableCard from '../../../components/ToggelableCard/ToggleableCard'

const REQUIRED_LANGUAGES = ['en']

const formatDepricatedLanguages = languages => {
  // Swedish language code was wrong
  // So have to modify it manualy
  if (Object.keys(languages).includes('SE')) {
    languages['SV'] = languages['SE']
    delete languages['SE']
  }

  const langArr = Object.keys(languages).filter(key => Boolean(languages[key]))
  return langArr.map(lang => lang.toLowerCase())
}

function Localisation({ restaurantData, setRestaurantData }) {
  const supportedLanguages =
    restaurantData.config?.languagesSupported ||
    (restaurantData.languagesSupported && formatDepricatedLanguages(restaurantData.languagesSupported)) ||
    REQUIRED_LANGUAGES

  const getLanguageName = code => {
    const lang = new Intl.DisplayNames(['en'], { type: 'language' })
    return lang.of(code)
  }

  const toggleSupportedLanguage = language => {
    const newLanugages = supportedLanguages.includes(language)
      ? supportedLanguages.filter(el => el !== language)
      : [...supportedLanguages, language]

    REQUIRED_LANGUAGES.forEach(requiredLanugage => {
      if (!newLanugages.includes(requiredLanugage)) newLanugages.push(requiredLanugage)
    })
    console.log(newLanugages)
    setRestaurantData(prev => ({
      ...prev,
      config: {
        ...prev.config,
        languagesSupported: newLanugages,
      },
    }))
  }

  return (
    <ToggleableCard
      title={'Localisation settings'}
      description={'In this section you can control supported languages and other localisation settings'}
    >
      <div className="supported-languages">
        <h5>Supported languages</h5>
        <p>In here you can control what languages should appear in your Kiosks and QR code selections</p>
        <p>
          <b>English</b> is currently mandatory{' '}
        </p>

        <div className="tab">
          {AVAILABLE_LANGUAGES.map(language => (
            <div className="m-t-15" key={language}>
              <label>
                <input
                  type="checkbox"
                  disabled={language === 'en'}
                  className="materialize-checkbox  filled-in"
                  checked={supportedLanguages.includes(language)}
                  onChange={e => toggleSupportedLanguage(language)}
                />
                <span>{getLanguageName(language)}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </ToggleableCard>
  )
}

export default Localisation
