import Toggelable from '../Toggleable/Toggleable'

function ToggleableCard({ children, ...props }) {
  return (
    <div className="card">
      <div className="card-content">
        <Toggelable {...props}>
          <div className="tab">{children}</div>
        </Toggelable>
      </div>
    </div>
  )
}

export default ToggleableCard
