import React, { useEffect, useState } from 'react'
import AvailabilityTable from './EditAvailability/AvailabilityTable'
import { Box, Button, Stack, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { useFormContext, useWatch } from 'react-hook-form'
import { Item } from '../../../../../../types'

type AvailabilityProps = {
  specialAvailability: Item['specialAvailability']
  setSpecialAvailability: (args: any) => any
}

export default function Availability({ specialAvailability, setSpecialAvailability }: AvailabilityProps) {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext()
  const specialAvailabilityExists = Boolean(
    specialAvailability && specialAvailability !== null && Object.keys(specialAvailability).length
  )
  const [showAvailability, setShowAvailability] = useState(specialAvailabilityExists)

  const [showDelete, setShowDelete] = useState(false)
  const [willDelete, setWillDelete] = useState(false)

  const handleDelete = () => {
    setValue('specialAvailability', {})
    setSpecialAvailability({})
    setWillDelete(true)
  }

  return (
    <div>
      <Typography variant="h5">⏳ Special Availability</Typography>
      <Typography mt={1} mb={1}>
        This feature allows you to configure so this item is only available during specifc days and hours. Intended to
        be used to setup for example lunch offers or weekend Brunch offers.
      </Typography>
      <Button className="browser-default" variant="contained" onClick={() => setShowAvailability(prev => !prev)}>
        {!showAvailability ? 'Show Special Availability' : 'Hide Special Availability'}
      </Button>
      {showAvailability && (
        <AvailabilityTable specialAvailability={specialAvailability} setSpecialAvailability={setSpecialAvailability} />
      )}

      {showAvailability && (
        <div>
          {showDelete ? (
            <Stack spacing={1}>
              <Typography sx={{ color: red[600] }}>Are you sure?</Typography>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" color="error" className="browser-default" onClick={() => handleDelete()}>
                  Yes delete
                </Button>{' '}
                <Button
                  variant="contained"
                  color="success"
                  className="browser-default"
                  onClick={() => setShowDelete(false)}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Button color="error" variant="outlined" className="browser-default" onClick={() => setShowDelete(true)}>
              Remove Special Ordering
            </Button>
          )}

          {willDelete && <p>Special Availability settings will be deleted on save</p>}
        </div>
      )}
    </div>
  )
}
