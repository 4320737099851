import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { FormProvider, useForm } from 'react-hook-form'
import CouponForm from '../../../components/forms/CouponForm'
import { useEffect, useState } from 'react'
import { createCoupon, generateCouponCode } from '../../../api/adminApi'
import moment from 'moment'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { couponListState } from '../../../atoms/CouponAtoms'

function CreateCoupon() {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const { makeApiRequest } = useRequestStatus()
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [, setCoupons] = useRecoilState(couponListState)
  const defaultValues = {
    code: '',
    rewardAmount: '',
    description: 'Voucher',
    from: moment().format('YYYY-MM-DD'),
    to: null,
    type: 'cash_discount',
    itemIds: [],
    restaurantId: selectedRestaurant?.id,
  }
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  })
  const { handleSubmit, setValue, reset } = methods

  const onSubmit = async (data: any) => {
    console.log('coupon created', data)
    await makeApiRequest(createCoupon, [data])
    setCoupons((prev: any) => [...prev, data])
    setDialogOpen(false)
    reset(defaultValues)
  }

  useEffect(() => {
    setValue('restaurantId', selectedRestaurant?.id)
  }, [selectedRestaurant?.id])

  return (
    <Stack mt={4} pb={2}>
      <Button variant="contained" startIcon={<AddIcon />} onClick={() => setDialogOpen(true)}>
        Create new coupon
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Create new coupon
          <IconButton
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <CouponForm />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit(onSubmit, console.error)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default CreateCoupon
