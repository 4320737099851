import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Tooltip } from '@mui/material'

type DeleteButtonProps = {
  onClick: (args: any) => any
  disabled?: boolean
  rest?: any[]
}

function DeleteButton({ onClick, disabled, ...rest }: DeleteButtonProps) {
  return (
    <IconButton
      size="small"
      color="error"
      sx={{ '&:focus': { bgcolor: 'inherit' } }}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <Tooltip title="Delete">
        <DeleteIcon fontSize="small" />
      </Tooltip>
    </IconButton>
  )
}

export default DeleteButton
