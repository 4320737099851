// @ts-ignore
import { diffLines, formatLines } from 'unidiff'
import { parseDiff, Diff, Hunk } from 'react-diff-view'
import 'react-diff-view/style/index.css'

const EMPTY_HUNKS: any[] = []

type DiffProps = {
  oldObj: any
  newObj: any
}

const DiffComponent = ({ oldObj, newObj }: DiffProps) => {
  const oldText = JSON.stringify(oldObj, null, 2)
  const newText = JSON.stringify(newObj, null, 2)

  const diffText = formatLines(diffLines(oldText, newText), { context: 3 })
  const [diff] = parseDiff(diffText, { nearbySequences: 'zip' })

  return (
    <Diff className="browser-default" viewType="unified" diffType="add" hunks={diff.hunks || EMPTY_HUNKS}>
      {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
    </Diff>
  )
}

export default DiffComponent
