import { PrintingMethods } from '../types/Printer'

const RECOMMENDED_KIOSK_PRINTING_METHODS = {
  K2: PrintingMethods.BITMAP_V2,
  K2_PRO: PrintingMethods.BITMAP_V2,
  K2_MINI: PrintingMethods.SUNMI_V2,
  KUORI: PrintingMethods.TEC_MOBILE_KOP3X,
}

const RECOMMENDED_PRINTER_PRINTING_METHODS = {
  V2: PrintingMethods.SUNMI_V2,
  T2: PrintingMethods.SUNMI_V2,
  TOSHIBA_LABEL_PRINTER: PrintingMethods.TPCL,
  EXTERNAL_TOSHIBA_LABEL_PRINTER: PrintingMethods.TEC_MOBILE_HSP100,
}

export { RECOMMENDED_KIOSK_PRINTING_METHODS, RECOMMENDED_PRINTER_PRINTING_METHODS }
