import QRCode from 'react-qr-code'
import M from 'materialize-css'
import { RestaurantIdToName } from './RestaurantIdToName'

export function ShowCode({ menuIds, restaurantId, serialNumber }) {
  console.log('Show code menu id ', menuIds)
  console.log('Show code menu serial ', serialNumber)

  let imageName = RestaurantIdToName(restaurantId)
  const project = String(process.env.REACT_APP_FIREBASE_ENV)
  let domain = {
    'pepi-dev': 'https://ordering.myntapp.io',
    'pepi-cms': 'https://ordering.myntapp.io',
    'pepi-test': 'https://pepi-ordering-test.web.app',
    'pepi-store-test': 'https://pepi-ordering-test.web.app',
    'pepi-internal-store-test': 'https://mynt-ordering-internal-test.web.app',
    'pepi-internal-test': 'https://mynt-ordering-internal-test.web.app',
  }?.[project]
  domain = domain ? domain : 'http://localhost:3001'

  imageName = imageName + ' QR'

  let url = `${domain}/?restaurant_id=${restaurantId}`

  // if (menuIds?.[0]?.length > 0) {
  console.log('Show code We adding serila numbert to ', menuIds?.length)
  url = url + '&sn=' + serialNumber
  // }

  url = url.replace(/ /g, '%20')

  const onClickUrl = () => {
    M.toast({ html: 'Url Copied', classes: 'rounded' })
    navigator.clipboard.writeText(url)
  }

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode')
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = imageName
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  return (
    <div className="show-code-wrapper">
      <div className="col s6">
        <h4>Code & Url</h4>

        <label htmlFor="id">URL (click to copy):</label>
        <input type="text" id="id" name="id" value={url} onClick={onClickUrl} />

        <div className="qr-code">
          <QRCode value={url} id="QRCode" />
        </div>
        <button className="btn m-t-15 m-b-15" onClick={onImageDownload}>
          download QR code
        </button>
      </div>
    </div>
  )
}
