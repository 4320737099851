import { TableRow, TableCell, Collapse, Stack, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import PartialBackdrop from '../../ui/PatrialBackdrop/PartialBackdrop'
import TeamMemberForm from '../forms/TeamMemberForm'

type CollapsibleTableRowProps = {
  children?: React.ReactNode
  expanded?: boolean
  isLoading?: boolean
}

function CollapsibleTableRow({ children, expanded, isLoading = false }: CollapsibleTableRowProps) {
  return (
    <TableRow
      sx={{ borderBottom: 'unset', 'tr:has(+ &)': { borderBottom: 'unset', '& > *': { borderBottom: 'unset' } } }}
    >
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
        <PartialBackdrop show={isLoading}>
          <Collapse in={expanded} unmountOnExit>
            {children}
          </Collapse>
        </PartialBackdrop>
      </TableCell>
    </TableRow>
  )
}

export default CollapsibleTableRow
