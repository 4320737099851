import { TableCell, TableRow } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// page uses the hook
export default function EditTimes({ ...props }) {
  const { t, i18n } = useTranslation()
  const timeList = [1, 2, 3, 4, 5, 6, 7]

  let openingTimes = timeList.map(
    key => props.openingTimes[Object.keys(props.openingTimes).find(k => String(k) === String(key))]
  )
  const currentDayOfWeek = new Date().getDay()

  // useEffect(() => {
  //   openingTimes = timeList.map(
  //     key => props.openingTimes[Object.keys(props.openingTimes).find(k => String(k) === String(key))]
  //   )
  // })

  return (
    <>
      {timeList.map((dow, dowIndex) => {
        const openTime = openingTimes[dowIndex]
        return (
          <TableRow key={dow}>
            <TableCell>{NumberToDay(dow)}</TableCell>
            <TableCell>
              <input
                type="time"
                value={openTime[0]}
                onChange={e => props.update(e, dow, 0)}
                className={openTime[2] ? 'closed' : ''}
              />
            </TableCell>
            <TableCell>
              <input
                type="time"
                value={openTime[1]}
                onChange={e => props.update(e, dow, 1)}
                className={openTime[2] ? 'closed' : ''}
              />
            </TableCell>
            <TableCell>
              <label>
                <input
                  type="checkbox"
                  className="filled-in checkbox-mynt"
                  checked={openTime[2] ? openTime[2] : false}
                  onChange={e => props.update(e, dow, 2)}
                />
                <span>{t('menuView.restaurantInfoBox.closed')}</span>
              </label>
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

function NumberToDay(number) {
  const { t, i18n } = useTranslation()

  switch (number) {
    case 1:
      return t('general.Monday')
    case 2:
      return t('general.Tuesday')
    case 3:
      return t('general.Wednesday')
    case 4:
      return t('general.Thursday')
    case 5:
      return t('general.Friday')
    case 6:
      return t('general.Saturday')
    case 7:
      return t('general.Sunday')
    default:
      break
  }
}
