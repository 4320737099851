import {
  Alert,
  Box,
  ButtonBase,
  Card,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import AddKioskForm from '../../devices/AddKioskForm'
import { useEffect, useState } from 'react'
import AddPrinterForm from '../../devices/AddPrinterForm'
import DeleteIcon from '@mui/icons-material/Delete'
import { getDevices } from '../../../api/deviceApi'
import { deleteDevice } from '../../../api/deviceApi'
import { useOutletContext } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'

function Step2() {
  const { restaurantData } = useOutletContext<any>()
  const [kioskDialogOpen, setKioskDialogOpen] = useState(false)
  const [printerDialogOpen, setPrinterDialogOpen] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [existingDevices, setExistingDevices] = useState([])

  const updateDevices = () => {
    return getDevices().then(({ data }) => {
      console.log('devices', data)
      setExistingDevices(data?.filter((device: any) => device.restaurantId === restaurantData.id))
    })
  }

  const handlePrinterSetup = () => {
    setSnackBarOpen(true)
    setPrinterDialogOpen(false)
    return updateDevices()
  }

  const handleKioskSetup = () => {
    setSnackBarOpen(true)
    setKioskDialogOpen(false)
    return updateDevices()
  }

  const handleDeviceDelete = (id: string) => {
    console.log('delete', id)
    deleteDevice(id).then(() => {
      updateDevices()
    })
  }

  useEffect(() => {
    updateDevices()
  }, [])

  return (
    <>
      <Stack spacing={2} p={4} alignItems="center">
        <Typography variant="h6">Setup Devices for your restaurant</Typography>
        <Stack direction="row" spacing={4}>
          <Card sx={{ overflow: 'hidden' }}>
            <ButtonBase
              className="browser-default"
              sx={{
                '&:focus': {
                  'background-color': 'inherit',
                },
              }}
              onClick={() => setPrinterDialogOpen(true)}
            >
              <Stack alignItems="center">
                <img src="/img/printer.png" alt="printer" />
                <Box p={1}>
                  <Typography variant="subtitle1">Connect a printer</Typography>
                </Box>
              </Stack>
            </ButtonBase>
          </Card>
          <Card sx={{ overflow: 'hidden', cursor: 'pointer' }}>
            <ButtonBase
              className="browser-default"
              sx={{
                '&:focus': {
                  'background-color': 'inherit',
                },
              }}
              onClick={() => setKioskDialogOpen(true)}
            >
              <Stack alignItems="center">
                <img src="/img/kiosk.png" alt="kiosk" />
                <Box p={1}>
                  <Typography variant="subtitle1">Connect a kiosk</Typography>
                </Box>
              </Stack>
            </ButtonBase>
          </Card>
        </Stack>
        {existingDevices.length !== 0 && (
          <Stack spacing={1}>
            <Typography variant="h5">Connected devices:</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Device Name</TableCell>
                    <TableCell>Device Type</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Delete device</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {existingDevices.map((device: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{device.name}</TableCell>
                      <TableCell>{device.type}</TableCell>
                      <TableCell>{device.serialNumber}</TableCell>
                      <TableCell>
                        <IconButton color="error" onClick={() => handleDeviceDelete(device.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
        <Alert>Note: you’ll be able to add more devices later on in the partners dashboard under “Devices”</Alert>

        <Dialog open={kioskDialogOpen} onClose={() => setKioskDialogOpen(false)} fullWidth>
          <DialogTitle>Setup a kiosk 🔧</DialogTitle>
          <Paper sx={{ p: 2, pt: 0 }}>
            <AddKioskForm
              restaurantId={restaurantData.id}
              onCancel={() => setKioskDialogOpen(false)}
              onSuccess={handleKioskSetup}
            />
          </Paper>
        </Dialog>
        <Dialog open={printerDialogOpen} onClose={() => setPrinterDialogOpen(false)} fullWidth>
          <DialogTitle>Setup a printer 🔧</DialogTitle>
          <Paper sx={{ p: 2, pt: 0 }}>
            <AddPrinterForm
              restaurantId={restaurantData.id}
              onCancel={() => setPrinterDialogOpen(false)}
              onSuccess={handlePrinterSetup}
            />
          </Paper>
        </Dialog>
      </Stack>
      <Snackbar open={snackBarOpen} onClose={() => setSnackBarOpen(false)} autoHideDuration={3000}>
        <Alert onClose={() => setSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
          The Device has beed added!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Step2
