import moment from 'moment'
import M from 'materialize-css'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import OrderTable from '../../components/orderTable/OrderTable'
import { getOrders, getRestaurantInfo } from '../../api/restaurantInfoApi'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAuthContext } from '../../contexts/AuthContext'
import LoadingOrders from '../../components/loadingComponents/LoadingOrders'
import { allOrdersAtom, ordersLoadedAtom, viewTypeAtom } from './OrderAtoms'
import DownloadCSVButton from '../../components/orderComponents/DownloadCsvButton'
import { useMenuContext } from '../../contexts/MenuContext'
import ToggleableMenu from '../../components/ToggleableMenu/ToggleableMenu'
import RestaurantIdToName from '../../utils/restaurantIdToRestaurantName'
import AnalyticalChip from '../../components/AnalyticalChip/AnalyticalChip'
import OrdersChart from './OrdersChart'
import ToggleChartView from './ToggleChartTable'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { Button, ButtonGroup } from '@mui/material'

/**
 *
 */
function Orders({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [loaded, setLoaded] = useRecoilState(ordersLoadedAtom)
  const [viewType, setViewType] = useRecoilState(viewTypeAtom)

  const [loadingError, setLoadingError] = useState(false)
  const [orders, setOrders] = useRecoilState(allOrdersAtom)
  const [upsoldItemsArray, setUpsoldItemsArray] = useState([])
  const [extraSalesSum, setExtraSalesSum] = useState(0)
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const { loggedIn } = useAuthContext()
  // const [restaurants, setRestaurants] = useState([])
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [filterParams, setFilterParams] = useState({
    limit: 15,
    restaurantId: selectedRestaurant?.id,
  })

  // const { allRestaurantData, refreshData } = useMenuContext()

  const refreshOrders = async prop => {
    setLoaded(false)
    try {
      const orderDataResp = await getOrders(filterParams)

      let orderData = orderDataResp?.data || []

      for (let i = 0; i < orderData.length; i++) {
        let mains = 0

        for (let y = 0; y < orderData[i].menu.length; y++) {
          if (orderData[i].menu[y].item.itemType == 'main') {
            mains = mains + 1
          }
        }

        if (mains == 0) {
          mains = 1
        }

        orderData[i].customerAmount = mains
      }

      setOrders(orderDataResp?.data || [])

      setLoadingError(false)
      setLoaded(true)
    } catch (error) {
      setLoadingError(true)
      setLoaded(false)
      console.error(error)
      // alert(error.message);
    }
  }

  // useEffect(() => {
  //   if (!loggedIn) return
  //   refreshOrders()

  //   if (allRestaurantData != null) {
  //     setRestaurants(allRestaurantData)

  //     setFilterParams({
  //       ...filterParams,
  //       limit: filterParams?.limit || 15,
  //       restaurantId: allRestaurantData?.[0]?.id,
  //     })
  //   }
  // }, [loggedIn, allRestaurantData])

  const setToday = () => {
    const todayStart = moment().startOf('day').unix()
    const todayEnd = moment().endOf('day').unix()
    setFilterParams({
      ...filterParams,
      from: todayStart,
      to: todayEnd,
    })
  }

  const setYesterday = () => {
    const yesterdayStart = moment().subtract(1, 'days').startOf('day').unix()
    const yesterdayEnd = moment().subtract(1, 'days').endOf('day').unix()
    setFilterParams({
      ...filterParams,
      from: yesterdayStart,
      to: yesterdayEnd,
    })
  }

  const setLastSevenDays = () => {
    const weekStart = moment().subtract(6, 'days').startOf('day').unix()
    const weekEnd = moment().endOf('day').unix()
    setFilterParams({
      ...filterParams,
      from: weekStart,
      to: weekEnd,
    })
  }

  const calculateUpsales = () => {
    let filteredOrders = orders.filter(order => {
      return order.menu.some(item => item.item.hasOwnProperty('upselling'))
    })

    console.log('calculating extra: ')

    let extraSales = orders.reduce((itemwithExtraSales, order) => {
      const extraItems = order.menu.filter(
        item => item.item.chosenExtras != undefined && item.item.chosenExtras.length > 0
      )

      return itemwithExtraSales.concat(extraItems.map(item => item.item.chosenExtras))
    }, [])

    let sum = 0
    extraSales.forEach(extraSale => {
      extraSale.forEach(e => {
        sum = sum + parseFloat(e.price)
      })
    })

    setExtraSalesSum(sum)

    let upsoldItemsArr = orders.reduce((itemsWithUpselling, order) => {
      const upsellingItems = order.menu.filter(item => item.item.hasOwnProperty('upselling'))
      return itemsWithUpselling.concat(upsellingItems.map(item => item.item))
    }, [])

    setUpsoldItemsArray(upsoldItemsArr)
  }

  useEffect(() => {
    if (!loggedIn) return
    setLoadingError(false)
    refreshOrders()
  }, [filterParams, loggedIn])

  useEffect(() => {
    setFilterParams({ ...filterParams, restaurantId: selectedRestaurant?.id })
  }, [selectedRestaurant])

  const handleChangePage = p => {
    const finalize = () => {
      setPage(p)
      window.scrollTo(0, 0)
    }
    if (page < p) {
      setFilterParams({
        ...filterParams,
        endBefore: undefined,
        startAfter: orders?.[orders.length - 1]?.createdAt,
      })
    } else if (page > p) {
      setFilterParams({
        ...filterParams,
        startAfter: undefined,
        endBefore: orders?.[0]?.createdAt,
      })
    }
    finalize()
  }

  const paginationArray = useMemo(() => {
    return [...Array(pageCount).keys()]
  }, [pageCount])

  useEffect(() => {
    let elems = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(elems, {})
    let Delems = document.querySelectorAll('select')
    M.FormSelect.init(Delems, {})
    // let DropElems = document.querySelectorAll(".dropdown-trigger");
    // M.Dropdown.init(DropElems, {});

    calculateUpsales()
  }, [loaded, orders])

  return (
    <>
      {/* <Sidebar /> */}
      <main>
        <div className="container">
          <AnalyticalChip
            label="Average Order Value"
            value={`${
              orders.length
                ? (orders.reduce((sum, order) => sum + parseFloat(order.totalAmount), 0) / orders.length).toFixed(2)
                : 0
            }€`}
          />

          {upsoldItemsArray.length > 0 && <AnalyticalChip label="Upsold items" value={upsoldItemsArray.length} />}

          {upsoldItemsArray.length > 0 && (
            <AnalyticalChip
              label="Upsales"
              value={`${upsoldItemsArray.reduce((sum, item) => sum + parseFloat(item.price), 0).toFixed(2)}€`}
            />
          )}

          {upsoldItemsArray.length > 0 && (
            <AnalyticalChip
              label="Average Upsale per order"
              value={`${(
                upsoldItemsArray.reduce((sum, item) => sum + parseFloat(item.price), 0) / orders.length
              ).toFixed(2)}€`}
            />
          )}

          {extraSalesSum > 0 && <AnalyticalChip label="Extra Sales" value={`${extraSalesSum.toFixed(2)}€`} />}
          <h4>{t('ordersView.title')}</h4>

          <div className="order-info-row">
            <ToggleableMenu
              cssClass="date-filter"
              title={
                filterParams.from || filterParams.to
                  ? (filterParams.from ? moment.unix(filterParams.from).format('YYYY-MM-DD') : 'Beginning') +
                    ' - ' +
                    (filterParams.to ? moment.unix(filterParams.to).format('YYYY-MM-DD') : ' Now')
                  : 'Date selection'
              }
              clear={() => setFilterParams({ ...filterParams, to: null, from: null })}
              hasInput={filterParams.from || filterParams.to}
            >
              <div className="input-field col s6">
                <input
                  id="from"
                  name="from"
                  type="date"
                  className="search"
                  value={filterParams.from ? moment.unix(filterParams.from).format('YYYY-MM-DD') : ''}
                  onChange={e => {
                    setFilterParams({
                      ...filterParams,
                      from: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').startOf('day').unix() : undefined,
                    })
                  }}
                />
                <label htmlFor="from">{t('ordersView.from')}</label>
              </div>

              <div className="input-field col s6">
                <input
                  id="to"
                  name="to"
                  type="date"
                  className="search"
                  value={filterParams.to ? moment.unix(filterParams.to).format('YYYY-MM-DD') : ''}
                  onChange={e =>
                    setFilterParams({
                      ...filterParams,
                      to: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').endOf('day').unix() : undefined,
                    })
                  }
                />
                <label htmlFor="to">{t('ordersView.to')}</label>
              </div>
              <div className="button-row">
                <button className="btn-small" onClick={setToday}>
                  Today
                </button>
                <button className="btn-small" onClick={setYesterday}>
                  Yesterday
                </button>
                <button className="btn-small" onClick={setLastSevenDays}>
                  7 Days
                </button>
                <button
                  className="btn-small"
                  onClick={() => setFilterParams({ ...filterParams, to: null, from: null })}
                >
                  Clear
                </button>
              </div>
            </ToggleableMenu>

            <ToggleableMenu title={t('ordersView.limitOrders') + ': ' + filterParams.limit}>
              <p>Limit orders</p>

              <ButtonGroup>
                <Button
                  variant="contained"
                  className="btn-small"
                  onClick={() =>
                    setFilterParams({
                      ...filterParams,
                      limit: 5,
                    })
                  }
                >
                  5
                </Button>
                <Button
                  variant="contained"
                  className="btn-small"
                  onClick={() =>
                    setFilterParams({
                      ...filterParams,
                      limit: 15,
                    })
                  }
                >
                  15
                </Button>
                <Button
                  variant="contained"
                  className="btn-small"
                  onClick={() =>
                    setFilterParams({
                      ...filterParams,
                      limit: 50,
                    })
                  }
                >
                  50
                </Button>
                <Button
                  variant="contained"
                  className="btn-small"
                  onClick={() =>
                    setFilterParams({
                      ...filterParams,
                      limit: 100,
                    })
                  }
                >
                  100
                </Button>

                <Button
                  variant="contained"
                  className="btn-small"
                  onClick={() =>
                    setFilterParams({
                      ...filterParams,
                      limit: 1000,
                    })
                  }
                >
                  1000
                </Button>
              </ButtonGroup>
            </ToggleableMenu>

            <DownloadCSVButton orders={orders} />
            <ToggleChartView
              view={viewType}
              onClick={() => (viewType == 'list' ? setViewType('chart') : setViewType('list'))}
            />
          </div>

          {loadingError && (
            <div className="error-wrapper">
              <div className="error-row">
                <i className="medium material-icons">error</i>
              </div>
              <div className="error-row">
                <h5>{t('general.ErrorLoading')}</h5>
              </div>
              <div className="error-row">
                <span>Try decreasing "Limit orders" setting</span>
              </div>
            </div>
          )}

          {viewType == 'list' && !loadingError && (
            <table className="orders-table">
              <thead>
                <tr>
                  <th>{t('ordersView.table.orderSum')}</th>
                  <th>{t('ordersView.table.status')}</th>
                  <th>{t('ordersView.table.date')}</th>
                  <th>{t('ordersView.table.time')}</th>
                  <th>{t('ordersView.table.avgPerCustomer')}</th>
                  <th>{t('ordersView.table.requestType')}</th>
                  <th>{t('ordersView.table.platform')}</th>
                </tr>
              </thead>
              <OrderTable ordersData={orders} loaded={loaded} />
            </table>
          )}

          {viewType == 'chart' && !loadingError && <OrdersChart orders={orders} loaded={loaded} />}

          {!loadingError && (
            <ul className="pagination">
              <li className={`${page === 0 ? 'disabled' : 'waves-effect'}`}>
                <a href="#!" onClick={() => handleChangePage(page > 0 ? page - 1 : page)}>
                  <i className="material-icons">chevron_left</i>
                </a>
              </li>
              {page + 1}
              {paginationArray.map(p => (
                <li className={page == p ? 'active' : 'waves-effect'} onClick={() => handleChangePage(p)}>
                  <a href="#!">{p}</a>
                </li>
              ))}
              <li className="waves-effect">
                <a href="#!" onClick={() => handleChangePage(page + 1)}>
                  <i className="material-icons">chevron_right</i>
                </a>
              </li>
            </ul>
          )}
        </div>
      </main>
    </>
  )
}

export default Orders
