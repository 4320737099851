import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { restaurantListState } from '../../../../atoms/RestaurantListAtom'
import Location from './components/Location'
import { createRestaurant } from '../../../../api/restaurantInfoApi'
import { useRequestStatus } from '../../../../hooks/useRequestStatus'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'

type CreateNewRestaurantModalProps = {
  open: boolean
  onClose: () => any
}

function CreateNewRestaurantModal({ open, onClose }: CreateNewRestaurantModalProps) {
  const [copyMenuFrom, setCopyMenuFrom] = useState<string>("Don't copy menu")
  const [restaurantList, setRestaurantList] = useRecoilState(restaurantListState)
  const { makeApiRequest, isFetching } = useRequestStatus()
  const methods = useForm<any>({
    defaultValues: {
      config: {
        enableAllDayReceiptPrinting: true,
        enableGenerateQRCodeByStaff: false,
        enableTableNumberInput: false,
        languagesSupported: ['en'],
        operatingCountryCode: '',
      },
      name: '',
      address: '',
      city: '',
      zipCode: '',
      image: 'https://i.imgur.com/GuDcLM1.png',
      openingTimes: {
        1: ['09:00', '20:30', false],
        2: ['09:00', '20:30', false],
        3: ['09:00', '20:30', false],
        4: ['09:00', '20:30', false],
        5: ['09:00', '20:30', false],
        6: ['09:00', '20:30', false],
        7: ['09:00', '20:30', true],
      },
      phone: '',
      menu: [],
    },
  })
  const { register, control, setValue, getValues, handleSubmit, formState } = methods

  const handleCopyMenu = (e: any) => {
    const restaurantId = e.target.value
    setValue('menu', restaurantList?.find(restaurant => restaurant.id === restaurantId)?.menu || [])
    setCopyMenuFrom(restaurantId)
  }

  const onSubmit = async (data: any) => {
    console.log('Submit success', data)
    const { resp, isSuccess } = await makeApiRequest(createRestaurant, [data], {
      pendingMessage: 'Creating restaurant',
    })
    if (isSuccess) {
      console.log('restaurant created', resp?.data)
      setRestaurantList((prev: any) => [resp?.data, ...prev])
      onClose()
    }
  }

  console.log(getValues())

  return (
    <Dialog open={open} onClose={onClose}>
      <PartialBackdrop show={isFetching}>
        <DialogTitle>
          <span>
            <Typography variant="h4">Create new restaurant</Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <Stack width="100%">
              <Typography variant="subtitle1">Restaurant name*</Typography>
              <TextField
                fullWidth
                {...register('name', { required: 'Required' })}
                inputProps={{ className: 'browser-default' }}
                error={Boolean(formState.errors.name)}
                helperText={formState.errors.name?.message?.toString?.()}
              />
            </Stack>
            <Stack width="100%">
              <Typography variant="subtitle1">Contact phone</Typography>
              <TextField
                fullWidth
                {...register('phone')}
                inputProps={{ className: 'browser-default' }}
                error={Boolean(formState.errors.phone)}
                helperText={formState.errors.phone?.message?.toString?.()}
              />
            </Stack>
            <Stack width="100%">
              <Typography variant="subtitle1">Copy menu from</Typography>
              <Select
                MenuProps={{ sx: { maxHeight: '50vh' } }}
                onChange={handleCopyMenu}
                value={String(copyMenuFrom)}
                renderValue={value => restaurantList?.find(restaurant => restaurant.id === value)?.name || value}
                fullWidth
              >
                <MenuItem value={"Don't copy menu"}>Don't copy menu</MenuItem>
                {restaurantList?.map(restaurant => (
                  <MenuItem key={restaurant?.id} value={restaurant?.id}>
                    {restaurant?.name} ({restaurant?.menu?.length} items)
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Stack width="100%">
              <Typography variant="h5">Config</Typography>
              <Stack spacing={1}>
                <Controller
                  name="config.enableAllDayReceiptPrinting"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox checked={Boolean(field.value)} onClick={field.onChange} />}
                      label={
                        <span>
                          <b>All Day Reciept </b>
                        </span>
                      }
                    />
                  )}
                />
                <Controller
                  name="config.enableGenerateQRCodeByStaff"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox checked={Boolean(field.value)} onClick={field.onChange} />}
                      label={
                        <span>
                          <b>QR Code by staff </b>(Should be disabled 99% of the time)
                        </span>
                      }
                    />
                  )}
                />
                <Controller
                  name="config.enableTableNumberInput"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox checked={Boolean(field.value)} onClick={field.onChange} />}
                      label={
                        <span>
                          <b>Table Ordering</b>
                        </span>
                      }
                    />
                  )}
                />
              </Stack>
            </Stack>

            <FormProvider {...methods}>
              <Location />
            </FormProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined">Cancel</Button>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </DialogActions>
      </PartialBackdrop>
    </Dialog>
  )
}

export default CreateNewRestaurantModal
