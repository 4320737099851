import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material'
import TranslationsTable from '../../../../ui/TranslationsTable/TranslationsTable'
import { FormProvider, set, useForm, useFormContext } from 'react-hook-form'
import { Category } from '../../../../types'
import { useEffect, useState } from 'react'
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver'
import { categorySchema } from '../../../../schemas'

type EditMenuTitleProps = {
  open: boolean
  onClose: () => void
  onSave: (data: any) => void
  data: any
  restaurant: any
  resetOnClose?: boolean
}

function EditMenuTitle({ open, onClose, data, restaurant, resetOnClose, onSave }: EditMenuTitleProps) {
  const resolver = useYupValidationResolver(categorySchema)
  const methods = useForm<Category>({
    mode: 'all',
    resolver,
    defaultValues: data,
  })
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = methods

  // Picking deprecated values
  useEffect(() => {
    const values = getValues()
    console.log('values', values)

    if (!values?.translations?.title?.en && values.title) {
      setValue('translations.title.en', values.title)
    }
    if (!values?.translations?.title?.fi && values.titleFI) {
      setValue('translations.title.fi', values.titleFI)
    }
    if (!values?.translations?.title?.sv && values.titleSE) {
      setValue('translations.title.sv', values.titleSE)
    }

    if (!values?.translations?.description?.en && values.description) {
      setValue('translations.description.en', values.description)
    }
    if (!values?.translations?.description?.fi && values.descriptionFI) {
      setValue('translations.description.fi', values.descriptionFI)
    }
    if (!values?.translations?.description?.sv && values.descriptionSE) {
      setValue('translations.description.sv', values.descriptionSE)
    }
  }, [])

  const onSubmit = async (data: any) => {
    console.log('submit valid', data)
    onSave(data)
  }

  useEffect(() => {
    if (!open && resetOnClose) {
      reset(data)
    }
  }, [open])

  useEffect(() => {
    reset(data)
  }, [data])

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Stack spacing={2}>
            <TranslationsTable restaurant={restaurant} formBase="translations.title" title="Title" />
            <TranslationsTable
              restaurant={restaurant}
              formBase="translations.description"
              title="Description"
              optional
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit, async data => console.log('submit invalid', data))}>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export default EditMenuTitle
