import { Button, ClickAwayListener, Stack, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import CollapsibleTableRow from '../../../components/CollapsibleTableRow/CollapsibleTableRow'
import useYupValidationResolver from '../../../hooks/useYupValidationResolver'
import subMenuSchema from '../../../schemas/subMenuSchema'
import { FormProvider, useForm } from 'react-hook-form'
import SubMenu from '../../../types/SubMenu'
import SubMenuForm from '../../../components/forms/SubMenuForm'
import { stringToDashFormat } from '../../../utils/stringToDashFormat'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import selectedRestaurantSubMenusState from '../../../selectors/selectedRestaurantSubmenus'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'
import DeleteConfirmButton from '../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import usePriceFormat from '../../../hooks/usePriceFormat'

const SubMenuRow = ({ subMenu }: any) => {
  const resolver = useYupValidationResolver(subMenuSchema)
  const methods = useForm<SubMenu>({
    resolver,
    defaultValues: subMenu,
  })
  const { handleSubmit } = methods

  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [selectedRestaurantSubmenus, setSelectedRestauerantSubmenus] = useRecoilState(selectedRestaurantSubMenusState)

  const { withCurrencySymbol } = usePriceFormat()

  const [showForm, setShowForm] = useState(false)
  const { makeApiRequest } = useRequestStatus()

  const onSubmit = async (data: any) => {
    console.log('submit', data)
    const parsedData = {
      ...data,
      id: stringToDashFormat(data.name),
    }
    console.log(parsedData)

    const index = selectedRestaurantSubmenus.findIndex((subMenu: any) => subMenu.id === data.id)
    const newData = [...selectedRestaurantSubmenus]
    newData[index] = parsedData
    setSelectedRestauerantSubmenus(newData)

    await makeApiRequest(saveRestaurantInfoData, [
      {
        id: selectedRestaurant.id,
        subMenus: newData,
      },
    ])
    setShowForm(false)
  }

  const handleDelete = async () => {
    const index = selectedRestaurantSubmenus.findIndex(({ id }: any) => id === subMenu.id)
    const newData = [...selectedRestaurantSubmenus]
    newData.splice(index, 1)

    await makeApiRequest(
      saveRestaurantInfoData,
      [
        {
          id: selectedRestaurant.id,
          subMenus: newData,
        },
      ],
      { pendingMessage: 'Deleting submenu...' }
    )
    setSelectedRestauerantSubmenus(newData)
  }

  return (
    <>
      <TableRow className={showForm ? 'tr-submenu-editing' : undefined}>
        <TableCell className="title">
          <b>{subMenu.name}</b>
        </TableCell>
        <TableCell className="title">
          {subMenu.surcharge && withCurrencySymbol(subMenu.surcharge) + ' surcharge, '}
          {subMenu.shouldAcceptPreOrder && 'Preorders'}
        </TableCell>
        <TableCell align="right">
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <Button variant="contained" onClick={() => setShowForm(!showForm)}>
              Edit
            </Button>
            <DeleteConfirmButton onClick={handleDelete}>Delete</DeleteConfirmButton>
          </Stack>
        </TableCell>
      </TableRow>
      <CollapsibleTableRow expanded={showForm}>
        <Stack spacing={1} px={4}>
          <FormProvider {...methods}>
            <SubMenuForm restaurant={selectedRestaurant} />
          </FormProvider>
          <Stack py={3}>
            <Button variant="contained" onClick={handleSubmit(onSubmit, console.error)}>
              Save
            </Button>
          </Stack>
        </Stack>
      </CollapsibleTableRow>
    </>
  )
}

export default SubMenuRow
