import React, { useEffect, useState } from 'react'
import moment from 'moment'
import DownloadButton from './DownloadButton'
import { Button, Stack, TableCell, TableRow } from '@mui/material'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { useRecoilValue } from 'recoil'
import { getPdfReport, sendPdfReport } from '../../api/report'
import downloadPdf from '../../utils/downloadPdf'
import { useRequestStatus } from '../../hooks/useRequestStatus'

enum ReportsType {
  SALES = 'sales',
  PAYOUTS = 'payouts',
}

type ReportTableRowProps = {
  report: any
  type: ReportsType
}

const ReportTableRow = ({ report, type }: ReportTableRowProps) => {
  const [formattedDate, setFormattedDate] = useState('')
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const { makeApiRequest } = useRequestStatus()

  useEffect(() => {
    // Convert the UNIX timestamp to a Date object and format it
    const date = new Date(report.date * 1000)
    const formatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    setFormattedDate(formatted)
  }, [report.date])

  console.log(report)

  const handlePdfDownload = async () => {
    setIsDownloadingPdf(true)
    const month = moment(report.from).format('MMMM')
    const year = moment(report.from).format('YYYY')
    const restaurantId = report.restaurantId

    const { resp, isSuccess } = await makeApiRequest(getPdfReport, [restaurantId, month, Number(year)])
    // const { data } = await getPdfReport(restaurantId, month, Number(year))

    if (isSuccess) {
      downloadPdf(resp.data, `${report.restaurant?.name}-${month}-${year}`)
    }
    setIsDownloadingPdf(false)
  }

  const handlePdfSend = async () => {
    setIsDownloadingPdf(true)
    const month = moment(report.from).format('MM')
    const year = moment(report.from).format('YYYY')
    const restaurantId = report.restaurantId

    await makeApiRequest(sendPdfReport, [restaurantId, month, year])
    // const { data } = await getPdfReport(restaurantId, month, Number(year))
    setIsDownloadingPdf(false)
  }

  return (
    <TableRow>
      <TableCell>{report.restaurant?.name}</TableCell>
      <TableCell>
        {report.type === 'monthly'
          ? `${report.from} - ${report.to}`
          : `W${moment(report.from).isoWeek()} ${report.from} - ${report.to}`}
        {'\n' + moment(report.from).format('MMMM')}
        {'\n' + moment(report.from).format('YYYY')}
      </TableCell>
      {type === ReportsType.PAYOUTS && (
        <TableCell align="left">
          {report.amount}
          {selectedRestaurant?.config?.currency?.symbol}
        </TableCell>
      )}
      <TableCell>
        {report.rows ? (
          <Stack spacing={1}>
            {type === ReportsType.PAYOUTS && (
              <DownloadButton
                title={`${report.restaurant?.name} - ${report.from} - ${report.to}`}
                rows={report.rows}
                columns={[
                  'referenceId',
                  'createdAt',
                  'payoutAt',
                  'currency',
                  'refunded',
                  'gross',
                  'paymentVendorFee',
                  'myntFee',
                  'net',
                  'description',
                  'provider',
                ]}
              />
            )}

            {type === ReportsType.SALES && (
              <>
                <Button variant="outlined" color="primary" onClick={handlePdfSend} disabled={isDownloadingPdf}>
                  Send me PDF
                </Button>
                <Button variant="outlined" color="primary" onClick={handlePdfDownload} disabled={isDownloadingPdf}>
                  Download PDF
                </Button>
              </>
            )}
          </Stack>
        ) : null}
      </TableCell>
    </TableRow>
  )
}

export default ReportTableRow
