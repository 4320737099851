import React from 'react'
import { Bar } from 'react-chartjs-2'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface OrdersChartProps {
  orders: Array<{ createdAt: string; totalAmount: string }>
  loaded: boolean
}

const OrdersChart: React.FC<OrdersChartProps> = ({ orders, loaded }) => {
  const data = React.useMemo(() => {
    const groupedOrders = orders.reduce<Record<string, number>>((acc, order) => {
      const date = new Date(order.createdAt);

      if (isNaN(date.getTime())) {
        console.error('Invalid date:', order.createdAt)
        return acc
      }

      const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

      if (!acc[formattedDate]) {
        acc[formattedDate] = 0 // Initialize with 0
      }

      acc[formattedDate] += parseFloat(order.totalAmount);  // Sum up the totalAmount for each date
      return acc;
    }, {})

    console.log('groupedOr ', groupedOrders)

    return {
      labels: Object.keys(groupedOrders),
      datasets: [
        {
          label: 'Total Amount',
          data: Object.values(groupedOrders), // Directly use the summed values
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.6)',
          hoverBorderColor: 'rgba(75,192,192,1)'
        }
      ]
    };

  }, [orders]);

  if (!loaded) {
    return null
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: { parsed: { y: any } }) {
            let value = context.parsed.y
            return value + '€' // Add the "€" symbol at the end
          },
        },
      },
    },
    scales: {
      y: {
        // This targets the y-axis of the chart
        ticks: {
          callback: function (value: number | string, index: any, values: any) {
            return value + '€' // Append "€" to each tick on the y-axis
          },
        },
      },
    },
  }

  return <Bar data={data} options={options} />
}

export default OrdersChart
