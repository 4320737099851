export default function TosHtml() {
  return (
    <>
      <h1 id="k-ytt-ehdot">Käyttöehdot</h1>
      <p>
        Käyttämällä Mynt-palvelua hyväksyt nämä yleiset ehdot (&quot;Ehdot&quot;). Ehdot muodostavat ne velvoitteet,
        oikeudet ja toimintatavat, joiden mukaan ravintola ja liike (yhdessä &quot;Yhteistyökumppanit&quot; ja kukin
        erikseen &quot;Yhteistyökumppani&quot;) myyntipisteineen liittyvät -alustaan sekä myymälä- ja
        ravintolaverkostoon ja joka solmitaan Sterly Oy:n (“Mynt”) ja Yhteistyökumppanin välillä. Yhteistyökumppaniin ja
        Myntiin viitataan yhdessä &quot;Osapuolet&quot; ja erikseen &quot;Osapuoli&quot;.
      </p>
      <h2 id="1-johdanto">1.JOHDANTO</h2>
      <p>
        1.1 Sterly Oy, y-tunnus 3117502-2 (&quot;Mynt&quot;), tarjoaa saataville alustan (&quot;Alusta&quot;) Myntin
        mobiilisovelluksessa ja mobiililaitteilla käytettävässä verkkosovelluksessa, joka tarjoaa yrityksille ja
        yksityishenkilöille (&quot;Asiakas/Asiakkaat&quot;) mahdollisuuden tilata ruokaa, juomia ja muita tuotteita
        (&quot;Tuotteet&quot;) suoraan Yhteistyökumppanin myyntipisteestä. Asiakkaille tarjotaan Alustan kautta
        ostettujen tuotteiden yhteishinnasta laskettu 10%:n alennus seuraavasta ostoksesta kolikoiden muodossa
        (“Kolikot”). Asiakas voi halutessaan kerryttää kolikoita saadakseen suuremman kerta-alennuksen myöhemmästä
        ostoksesta. Kolikoita voi käyttää ainoastaan Alustan kautta tehdyissä ostoksissa.
      </p>
      <p>
        1.2 Ehdot hyväksynyt Yhteistyökumppani liittyy Myntin Alustalle ja verkostoon ja sitoutuu myyntipisteidensä
        kautta myymään, valmistamaan ja pakkaamaan Tuotteita Asiakkaiden tilausten mukaisesti (&quot;Tilaukset&quot;)
        Alustan välityksellä, näiden Ehtojen mukaisesti (&quot;Järjestely&quot;).
      </p>
      <h2 id="2-yleist-">2.YLEISTÄ</h2>
      <p>
        2.1 Tarjotessaan Tuotteita Alustan kautta Yhteistyökumppani toimii Myntille tehtyjen Tilauksien osalta
        Tuotteiden ja Tilauksen suorana myyjänä.{' '}
      </p>
      <p>
        2.2 Yhteistyökumppani on vastuussa Tilauksessa määriteltyjen Tuotteiden tuottamisesta Ehtojen mukaisesti.
        Hyväksymällä Tilauksen, Yhteistyökumppani tekee sitovan sopimuksen Myntin kanssa Tuotteen / Tuotteiden
        ostamisesta.
      </p>
      <p>
        2.3 Yhteistyökumppanin vastuulla on varmistaa, että sen henkilökunnan jäsenet, jotka ovat osa Järjestelyä, ovat
        tietoisia Ehdoista ja että heitä koulutetaan Järjestelyyn liittyen.
      </p>
      <p>
        2.4 Alustalle liittyessä Yhteistyökumppanille annetaan pääsy Adminpaneeliin (&quot;Adminpaneeli&quot;), joka
        tarjoaa Yhteistyökumppanille Tilausten toteuttamiseksi jatkuvan pääsyn Tuotevalikoiman muokkaamiseen, ravintolan
        tietojen ja henkilökunnan hallinnointiin, tilausten tarkasteluun, tietoihin myynneistä, tilastoista jne.
      </p>
      <p>
        2.5 Myntiin kehitetään jatkuvasti uusia ominaisuuksia, joista osa saattaa olla maksullisia. Täten Alustalle
        saatetaan lisätä uusia maksullisia ominaisuuksia. Uusista ominaisuuksista ja niiden hinnoista ilmoitetaan
        Yhteistyökumppanille sitä mukaa, kun niitä julkaistaan Yhteistyökumppanin käyttöön.
      </p>
      <h2 id="3-myntin-velvollisuudet-ja-oikeudet">3.MYNTIN VELVOLLISUUDET JA OIKEUDET</h2>
      <h3 id="3-1-alusta">3.1 Alusta</h3>
      <p>
        3.1.1 Kun Yhteistyökumppani liittyy Alustalle, Mynt antaa Yhteistyökumppanille pääsyn Alustalle ja tarjoaa
        laitteiston tai ohjelmiston, jonka avulla Asiakkaan tilaus tavoittaa Yhteistyökumppanin (&quot;Laitteet ja
        ohjelmistot&quot;).
      </p>
      <p>
        3.1.2 Mynt ei takaa Alustan tai minkä tahansa tarjotun Laitteen ja ohjelmiston saatavuutta tai toimivuutta ja se
        ei ole vastuussa häiriöistä Järjestelyn aikana. Mynt ei ole velvollinen maksamaan Yhteistyökumppanille
        hyvityksiä Alustan tai Laitteiden ja ohjelmistojen mahdollisten häiriöaikojen tai puutteiden / vikojen osalta.
      </p>
      <p>
        3.1.3 Mynt on oikeutettu julkaisemaan Sisältöä, jonka Yhteistyökumppani tai Asiakas on julkaissut Alustalla
        palautteen, kommenttien tai muun julkaisun muodossa. Mynt on lisäksi oikeutettu muokkaamaan tai poistamaan
        materiaalia, jota Mynt pitää solvaavana tai loukkaavana, joka on lainvastaista, mainostaa toisen toimijan
        tilausalustaa tai jolla ei ole muuta yhteyttä Alustan käyttöalueeseen.
      </p>
      <p>3.1.4 Mynt on yksipuolisesti oikeutettu vaihtamaan Alustaan kuuluvaa domain-nimeä ja verkkosivustoja.</p>
      <p>
        3.1.5 Alustan kautta saatavat Tuotteet näkyvät Asiakkaalle Asiakkaan hakusuodattimien ja Myntin nykyisen
        yhteistyökumppaneiden ja myynti- ja tuotepisteiden mukaisesti (&quot;Luokitus&quot;). Parhaan asiakaskokemuksen
        varmistamiseksi luokitus perustuu pääosin aukioloaikoihin ja etäisyyteen. Mynt myös lähtökohtaisesti suosittelee
        Asiakkaille niitä yhteistyökumppaneita, joissa Asiakas on kerryttänyt itselleen Kolikoita.
      </p>
      <h3 id="3-2-tilaukset">3.2 Tilaukset</h3>
      <p>
        3.2.1 Mynt tekee laadunvalvontaa jokaisen Tilauksen yhteydessä annetuista asiakastiedoista, jotta vältetään
        virheellisten Tilausten lähettäminen Yhteistyökumppanille. Mynt ei kuitenkaan missään olosuhteissa ole vastuussa
        Tilauksista, olivat ne sitten virheellisiä tai epäaitoja.{' '}
      </p>
      <p>
        3.2.2 Tilauksen yhteydessä Mynt luo kuitin Asiakkaalle Tuotteiden myyjänä, eikä Yhteistyökumppanin tule tarjota
        Asiakkaalle minkäänlaista kuittia tai laskua Tilausten yhteydessä.
      </p>
      <h2 id="4-yhteisty-kumppanin-velvollisuudet">4.YHTEISTYÖKUMPPANIN VELVOLLISUUDET</h2>
      <h3 id="4-1-tuotteiden-markkinointi">4.1 Tuotteiden markkinointi</h3>
      <p>
        4.1.1 Yhteistyökumppani sitoutuu asettamaan nykyisen Valikoimansa saataville Alustalle Järjestelyn ehtojen
        mukaisesti.
      </p>
      <h3 id="4-2-yhteisty-kumppanista-annettavat-tiedot">4.2 Yhteistyökumppanista annettavat tiedot</h3>
      <p>
        4.2.1 Yhteistyökumppani on aina vastuussa sen antamien tietojen täydellisyyden ja oikeellisuuden
        varmistamisesta.
      </p>
      <h3 id="4-3-alusta-ja-tekniset-laitteet">4.3 Alusta ja tekniset laitteet</h3>
      <p>
        4.3.1 Yhteistyökumppanilla on oltava hallussaan sellainen tekninen laitteisto, joka on välttämätön Myntin
        käyttämien teknisten järjestelmien (mukaan lukien Laitteet ja ohjelmistot) hallitsemiseksi ja on varmistettava,
        että koko henkilökunta on tietoinen Myntin teknisten järjestelmien ja ohjelmistojen toiminnasta.
      </p>
      <p>
        4.3.2 Yhteistyökumppanin on käytettävä Laitteita ja ohjelmistoja ja muita teknisiä järjestelmiä, joita Mynt on
        toimittanut tai muuten ohjeistanut. Myntillä on oikeus periä maksuja Laitteiden ja ohjelmistojen väärinkäytöstä.
        Väärinkäytöksi katsotaan kaikki sellainen Laitteiden ja ohjelmistojen käyttö, johon Mynt ei ole
        Yhteistyökumppania nimenomaisesti ohjeistanut, esimerkiksi tilauspäätteen/tulostimen käyttö muuhun kuin
        tilausten vastaanottamiseen, hallinnointiin tai kuittien tulostamiseen.
      </p>
      <p>
        4.3.3 Jos Alustaan tai Laitteisiin ja ohjelmistoihin liittyy teknisiä tai muita ongelmia, jotka estävät
        Yhteistyökumppania toteuttamasta velvollisuuksiaan Ehtojen mukaisesti, Yhteistyökumppanin on ilmoitettava tästä
        välittömästi Myntille.
      </p>
      <p>
        4.3.4 Yhteistyökumppanin on varmistettava tavoitettavuutensa puhelimitse ja/tai chat-palvelun kautta
        palveluaikojen puitteissa, jotta Mynt voi aina ottaa yhteyttä Yhteistyökumppaniin, mikäli Järjestelyyn liittyviä
        ongelmia ilmenee.
      </p>
      <p>
        4.3.5 Yhteistyökumppanin on varmistettava Myntin toimittaman kuittitulostimen paperin riittävyys.
        Yhteistyökumppani voi tilata paperia suoraan Myntiltä ottamalla yhteyttä Myntiin sähköpostitse tai puhelimitse.
      </p>
      <h3 id="4-4-tilaukset">4.4 Tilaukset</h3>
      <p>
        4.4.1 Yhteistyökumppanin on vahvistettava Tilaus viiden (5) minuutin kuluessa sen vastaanottamisesta. Jos
        Tuotetta ei ole saatavilla, Yhteistyökumppanin on välittömästi hylättävä Tilaus ja merkittävä se ei saatavilla
        olevaksi myyjän sovellukseen.
      </p>
      <p>
        {' '}
        4.4.2 Yhteistyökumppanin on välitettävä viipymättä Myntille kaikki palautteet ja valitukset sekä Asiakkailta
        että Yhteistyökumppanilta.
      </p>
      <h3 id="4-5-s-nt-jen-noudattaminen-ja-asiakkaiden-informointi">
        4.5 Sääntöjen noudattaminen ja Asiakkaiden informointi
      </h3>
      <p>
        4.5.1 Yhteistyökumppani vastaa yksin kaikilta osin siitä, että se toiminnassaan noudattaa kulloinkin voimassa
        olevaa lainsäädäntöä, asetuksia ja muita toimintaan soveltuvia säännöksiä, mukaan lukien viranomaismääräyksiä-
        ja vaatimuksia, itsesääntelyn ohjeistuksia ja ohjeita (jäljempänä yhdessä &quot;Sovellettavat Määräykset&quot;).
        Yhteistyökumppani vastaa myös siitä, että sillä on kaikki liiketoimintaansa sovellettavat luvat, lisenssit ja
        rekisteröinnit (jäljempänä yhdessä &quot;Luvat&quot;), mukaan lukien Luvat, jotka ovat välttämättömiä Myntille
        ja Yhteistyökumppanille Tuotteiden tarjoamiseksi ja/tai markkinoimiseksi Asiakkaille. Lisäksi Yhteistyökumppanin
        tulee olla rekisteröitynyt kaupparekisteriin, Verohallintoon, ennakkoperintärekisteriin sekä
        arvonlisäverovelvolliseksi. Yhteistyökumppani suostuu siihen, että Mynt voi ottaa yhteyttä asianomaisiin
        viranomaisiin tarkistaakseen, että Yhteistyökumppani noudattaa Sovellettavia Määräyksiä ja että hänellä on
        kaikki tarvittavat Luvat.
      </p>
      <p>
        4.5.2 Yhteistyökumppani vastaa siitä, että sen Tuotteet ja niiden markkinointi ovat Sovellettavien Määräysten
        mukaisia. Täten Yhteistyökumppani vastaakin siitä, että (i) sen Tuotteiden markkinointi on yleisesti
        hyväksyttyjen markkinointikäytäntöjen mukaista; (ii) Tuotteet täyttävät sovellettavat
        tuoteturvallisuusstandardit ja ne on merkitty ja pakattu Sovellettavien Määräysten mukaisesti; (iii) Asiakkaalle
        asetetaan saataville kaikki tarvittavat tiedot ennen Tilauksen toteuttamista, sisältäen mutta ei rajoittuen
        tarvittaviin tietoihin voimassaolevien kuluttajansuojamääräysten mukaisesti; ja (iv) kaikki Alustalla saatavilla
        olevat tai Yhteistyökumppanin muuten Asiakkaan saataville asettamat Tuotteita koskevat tiedot ovat oikeita ja
        Sovellettavien Määräysten mukaisia, kuten vähintäänkin elintarvikkeiden myynnin osalta tiedot ravintoarvoista ja
        allergeeneistä elintarvikkeissa sekä mahdolliset käyttöohjeet, takuuehdot ja ohjeet Tuotteiden käytöstä.
      </p>
      <p>
        4.5.3 Jos Yhteistyökumppani viittaa Myntin ulkopuoliseen tietokantaan (&quot;Ulkopuolinen Tietokanta&quot;),
        Yhteistyökumppani vastaa siitä, että Myntille kyseisestä Ulkopuolisesta Tietokannasta toimitetut tiedot ovat
        tosia ja oikeita.{' '}
      </p>
      <p>
        4.5.4 Yhteistyökumppani vastaa tarpeen mukaan siitä, että Myntillä on aina ajan tasalla olevat yhteystiedot,
        mukaan lukien puhelinnumerot, jotta Asiakkaat voivat olla yhteydessä Yhteistyökumppaniin saadakseen lisätietoja
        Yhteistyökumppanin Tuotteista niinä aikoina, jolloin niitä voidaan tilata Alustan kautta.
      </p>
      <p>
        4.5.5 Yhteistyökumppani vastaa siitä, että Mynt on jatkuvasti ajan tasalla voimassa olevista laeista,
        asetuksista ja muista sovellettavista määräyksistä, jotka koskevat Yhteistyökumppanin Alustalle saataville
        asettamien Tuotteiden myyntiä ja kuljetusta siltä osin kuin ne asettavat Myntille velvoitteita Järjestelyn
        osalta.
      </p>
      <p>
        4.5.6 Jos Yhteistyökumppani ei noudata tämän kohdan 4.5 mukaisia velvoitteita, Myntillä on oikeus oman
        harkintansa mukaan poistaa asiaankuuluvat Tuotteet ja/tai sulkea Yhteistyökumppani pois Alustalta, kunnes
        virheellinen menettely on korjattu, tai jos Mynt niin päättää, poistaa Yhteistyökumppani Alustalta jäljempänä
        olevan kohdan 11.2 nojalla.
      </p>
      <h3 id="4-6-ik-rajoitetut-tuotteet">4.6 Ikärajoitetut Tuotteet</h3>
      <p>
        Yhteistyökumppani vastaa siitä, että se informoi Myntiä jatkuvasti niistä toimintaperiaatteista, jotka koskevat
        Tuotteita, joiden ostamiseen on asetettu lakisääteinen ikäraja tai joihin Yhteistyökumppani muutoin soveltaa
        ikärajoitusta.
      </p>
      <p>4.7 Tiettyjen tuotteiden Asema</p>
      <p>
        Yhteistyökumppani vastaa siitä, että se informoi Myntiä, jos tiettyjen Tuotteiden toimittamiseen Asiakkaille
        liittyy erityisiä velvoitteita voimassa olevien lakien, asetusten ja muiden sovellettavien määräysten nojalla.
      </p>
      <h3 id="4-8-valitukset-palautukset-ja-takuuvaatimukset">4.8 Valitukset, palautukset ja takuuvaatimukset</h3>
      <p>
        4.8.1 Yhteistyökumppani vastaa siitä, että Tuotteessa ei ole vikoja ja se on muutenkin sovitussa kunnossa ja,
        että Tuote toimitetaan sovitun ja hyväksytyn Tilauksen mukaisesti. Yhteistyökumppani vastaa Tuotteiden
        valitusten, ja niihin liittyvien palautusten ja hyvitysten hyväksymisestä ja hallinnasta.{' '}
      </p>
      <h2 id="5-valikoiman-p-ivitt-minen">5.VALIKOIMAN PÄIVITTÄMINEN</h2>
      <h3 id="5-1-valikoimasta-annetut-tiedot-ja-sen-p-ivitt-minen">
        5.1 Valikoimasta annetut tiedot ja sen päivittäminen
      </h3>
      <p>
        5.1.1 Yhteistyökumppanin on toimitettava Myntille oikeat ja päivitetyt tiedot Yhteistyökumppanin nykyisistä
        Tuotteista (&quot;Valikoima&quot;), mukaan lukien myyntihinnat (sisältäen lakisääteisen ALV:in)
        (&quot;Hinnasto&quot;) Adminpaneelin kautta.{' '}
      </p>
      <p>
        5.1.2 Mynt ei vastaa Alustalla näkyvillä olevien Yhteistyökumppanin Valikoimaa koskevien tietojen
        oikeellisuudesta.
      </p>
      <h3 id="5-2-valikoiman-yll-pit-minen">5.2 Valikoiman ylläpitäminen</h3>
      <p>
        Yhteistyökumppani huolehtii siitä, että Tuotteita on kunakin hetkenä tarpeeksi saatavilla Alustalla tehtäviä
        tilauksia varten, sekä siitä että Tuotteet ovat moitteettomassa myyntikunnossa, mukaan lukien, että ne eivät
        ylitä parasta ennen -päiväystä.
      </p>
      <h2 id="6-hinnat-kulut-ja-korvaus">6.HINNAT, KULUT JA KORVAUS</h2>
      <h3 id="6-1-hinnat-ja-maksut">6.1 Hinnat ja maksut</h3>
      <p>
        6.1.1 Yhteistyökumppanin on varmistettava, että Hinnastossa ilmoitetut hinnat ovat vertailukelpoisia hintoihin,
        jotka Asiakas maksaa tilatessaan suoraan Yhteistyökumppanilta.
      </p>
      <h3 id="6-2-maksut">6.2 Maksut</h3>
      <p>
        6.2.1 Myntillä on oikeus veloittaa Yhteistyökumppanilta seuraavat maksut ja palkkiot (jäljempänä yhdessä
        &quot;Maksut&quot;):{' '}
      </p>
      <p>a)Palvelumaksu kuten kohdassa 6.3.1 (&quot;Palvelumaksu&quot;); ja</p>
      <p>
        b) Muut Yhteistyökumppanin ja Myntin välillä sovitut kulloinkin sovellettavat maksut ja kustannukset (esim.
        hyödykkeiden myynnistä, mainonnasta, etuseteleistä tai muista Asiakkaille tehdyistä tarjouksista, jotka
        Yhteistyökumppani maksaa)
      </p>
      <p>
        c) Markkinointimaksu siitä markkinointiviestinnästä, joka tehdään Myntin Adminpaneelin kautta.
        Markkinointiviestintä toteutetaan SMS-viesteillä, joista Mynt perii maksun <strong>0,1025€ / viesti</strong>.
      </p>
      <p>
        6.2.2 Myntillä ei ole velvollisuutta periä Maksuja edellä olevan kohdan 6.2.1 mukaisesti, ja jos Mynt ajoittain
        epäonnistuu tai luopuu yhden tai useamman maksun perimisestä, ei se merkitse, että Mynt luopuisi lopullisesti
        oikeudesta periä maksuja tulevaisuudessa.
      </p>
      <h3 id="6-3-ostohinnan-ja-hyvitysten-laskeminen">6.3 Ostohinnan ja hyvitysten laskeminen</h3>
      <p>
        6.3.1 Myntin Yhteistyökumppanille maksama summa (Myntin &quot;Ostohinta&quot;) saadaan laskemalla yhteen
        kaikkien oikein toimitettujen Tuotteiden arvo Hinnaston mukaisesti sekä muut sovellettavat maksut, vähentäen (i)
        Myntin Palvelumaksua vastaavan alennuksen, ja (ii) muut Maksut (mukaan lukien lakisääteinen arvonlisävero).
        Palvelumaksusta sovitaan kirjallisesti erillisessä asiakirjassa Yhteistyökumppanin kanssa.
      </p>
      <p>
        6.3.2 Mynt voi vähentää (i) lakisääteisen arvonlisäveron kaikista tehdyistä Tilauksista, (ii) mahdollisista
        palautuksista tai muista Myntin Tilauksiin tekemistä tarkistuksista johtuvat summat, ja (iii) kaikki muut
        Yhteistyökumppanin velat Myntille.
      </p>
      <h3 id="6-4-itselaskutus">6.4 Itselaskutus</h3>
      <p>
        6.4.1 Yhteistyökumppani valtuuttaa Myntin laskuttamaan Yhteistyökumppanin puolesta Yhteistyökumppanin antamien
        Tuotteiden ja palveluiden ostohinnat arvonlisäverolain (1501/1993) mukaisesti. Yhteistyökumppanin katsotaan
        hyväksyneen Myntin laskun, ellei Yhteistyökumppani kiistä laskua kirjallisesti seitsemän (7) päivän kuluessa sen
        vastaanottamisesta.
      </p>
      <h2 id="7-immateriaalioikeudet-ja-markkinointi">7.IMMATERIAALIOIKEUDET JA MARKKINOINTI</h2>
      <h3 id="7-1-oikeudet">7.1 Oikeudet</h3>
      <p>
        7.1.1 Myntillä tai sen lisenssinantajilla on kaikki oikeudet, mukaan lukien immateriaalioikeudet Alustaan,
        Laitteisiin ja ohjelmistoihin. Sama koskee tietoja, jotka Mynt on kehittänyt Alustalla.{' '}
      </p>
      <p>
        7.1.2 Yhteistyökumppani ei saa käyttää, kopioida, muokata tai muuten käsitellä mitään Myntille kuuluvia
        immateriaalioikeuksia eikä siirtää, luovuttaa tai myöntää kolmansille osapuolille mitään oikeuksia tällaisiin
        immateriaalioikeuksiin, ellei Mynt ole kirjallisesti sallinut tällaista.
      </p>
      <p>
        7.1.3 Myntillä on oikeus käyttää tekstejä, logoja, kuvia ja muuta aineistoa, jonka Yhteistyökumppani on
        asettanut saataville julkaistavaksi Alustalla markkinointitarkoituksiin. Yhteistyökumppanin saataville asettaman
        materiaalin tekijänoikeus pysyy kuitenkin Yhteistyökumppanin hallussa. Yhteistyökumppani on vastuussa siitä,
        ettei sen materiaali loukkaa minkään kolmannen osapuolen oikeuksia tai ole ristiriidassa voimassa olevan
        lainsäädännön kanssa, ja sen on korvattava ja vastattava Myntin kohdistuvista kustannuksista tai vaatimuksista,
        juridiset kulut mukaan lukien, jotka aiheutuvat patenttien tai muiden immateriaalioikeuksien loukkauksista
        kolmannelle osapuolelle tai voimassa olevan lainsäädännön rikkomisen tai noudattamatta jättämisen vuoksi.
      </p>
      <h3 id="7-2-tuotteista-otetut-valokuvat">7.2 Tuotteista otetut valokuvat</h3>
      <p>
        7.2.1 Yhteistyökumppanin on tarvittaessa parhaansa mukaan pyrittävä varmistamaan, että Myntille tai Myntin
        palvelukseen ottamalle taholle annetaan mahdollisuus kuvata Tuotteita (&quot;Valokuvat&quot;). Myntillä on
        oikeus käyttää Valokuvia markkinointitarkoituksiin Alustalla tai muuten markkinointiin Ehtojen mukaisesti. Mynt
        on Valokuvien tekijänoikeuksien haltija.
      </p>
      <p>7.2.2 Yhteistyökumppanilla on oikeus käyttää Valokuvia omalla verkkosivustollaan ja myyntipisteissään.</p>
      <p>
        7.2.3 Jos Yhteistyökumppani käyttää Valokuvia tavalla, joka ei ole Ehtojen sallima, Myntillä on oikeus muiden
        lakisääteisten ja Ehtoihin perustuvien oikeuksiensa lisäksi saada vahingonkorvauksia Yhteistyökumppanilta ja
        peruuttaa Yhteistyökumppanin oikeus käyttää Valokuvia edellä mainitun kohdan 8.2.2 mukaisesti.
      </p>
      <h3 id="7-3-markkinointi">7.3 Markkinointi</h3>
      <p>
        7.3.1 Alustan markkinoinnissa Myntillä on oikeus, mutta ei velvollisuutta, käyttää Yhteistyökumppanin nimeä ja
        tavaramerkkejä eri markkinointikanavissa.
      </p>
      <p>
        7.3.2 Yhteistyökumppani sitoutuu toimittamaan Myntille Myntin pyytämiä tietoja tai mitä tahansa muita tietoja,
        jotka Yhteistyökumppani pitää sopivana esitettäväksi Alustan tai muiden markkinointikanavien kautta. Myntillä ei
        kuitenkaan ole velvollisuutta näyttää tällaisia tietoja Alustalla.{' '}
      </p>
      <p>
        7.3.3 Yhteistyökumppanin tulee jatkuvasti markkinoida Alustaa myyntipisteissään ja niiden ulkopuolella
        käyttämällä Myntin toimittamia tarroja tai muita markkinointimateriaaleja. Yhteistyökumppanin on myös
        markkinoitava Alustaa omissa markkinointimateriaaleissaan edellyttäen, että Myntin kanssa on sovittu asiasta.
      </p>
      <p>
        7.3.4 Kaikki Yhteistyökumppanin tuottamat Ehtoihin liittyvät markkinointimateriaalit ja opasteet, mukaan lukien
        mm. kuvat, tekstit, julisteet, mainokset, kyltit, sanomalehtiartikkelit, mainoselokuvat jne., joko kirjallisessa
        tai digitaalisessa muodossa, hyväksytetään Myntillä ennen materiaalin näyttämistä. Tällainen hyväksyntä ei
        vapauta Yhteistyökumppania velvollisuudestaan noudattaa kulloinkin voimassa olevia, sovellettavia lakeja ja
        määräyksiä, mukaan lukien mm. markkinointi-, mainos- ja tekijänoikeuslainsäädäntöä.{' '}
      </p>
      <p>
        7.3.5 Mynt käyttää markkinointiviestintäohjelmistossaan Twilio-tekstiviestipalvelua. Hyväksymällä nämä ehdot
        Yhteistyökumppani hyväksyy myös <a href="https://www.twilio.com/legal/tos">Twilion käyttöehdot</a>.
      </p>
      <p>
        7.3.6 Yhteistyökumppani vastaa Adminpaneelin kautta tehdyssä markkinointiviestinnässään siitä, että
        suoramarkkinointia toteutetaan vain siihen luvan antaneille Asiakkaille.
      </p>
      <h2 id="8-henkil-tiedot-ja-p-sy-tietoihin">8.HENKILÖTIEDOT JA PÄÄSY TIETOIHIN</h2>
      <p>
        8.1 Alustalla esitettyjen tietojen lisäksi Asiakkaalle voidaan ilmoittaa kuiteissa, tilausvahvistuksessa tai
        muulla tavalla Yhteistyökumppanin nimi, osoite, Y-tunnus ja muut lakisääteiset tiedot.
      </p>
      <p>
        8.2 Kun Yhteistyökumppani käyttää Alustaa, Mynt saa pääsyn Yhteistyökumppanin työntekijöiden henkilötietoihin
        sekä muihin Yhteistyökumppaniin ja sen Alustan käyttöön liittyviin tietoihin, kuten tilaustietoihin ja
        Valikoimaan. Mynt käsittelee henkilötietoja kulloinkin voimassa olevan tietosuojakäytäntönsä mukaisesti.{' '}
      </p>
      <p>
        8.3 Yhteistyökumppani saa Ehtojen täyttämisen ja Tilausten toteuttamiseksi pääsyn Myntin Asiakkaiden
        henkilötietoihin, esim. Asiakkaan nimi, puhelinnumero tai muut Asiakkaiden antamat tiedot, joita pidetään
        välttämättöminä Palvelun toimittamiseksi. Yhteistyökumppani on tietoinen siitä, että se on rekisterinpitäjä
        käsitellessään henkilötietoja Järjestelyn puitteessa ja on vastuussa toiminnastaan EU:n yleisen
        tietosuoja-asetuksen 2016/679 (General Data Protection Regulation, GDPR) sekä muun tietosuojalainsäädännön
        mukaisesti. Mynt on rekisterinpitäjä niiden tietojen osalta, joita se käsittelee Järjestelyn puitteissa.
      </p>
      <p>
        8.4 Yhteistyökumppani saa käyttää Asiakastietoja omiin markkinointitarkoituksiinsa vain silloin, kun
        markkinointi tehdään Myntin Adminpaneelin kautta Myntin kirjallisesti hyväksymin menetelmin.
      </p>
      <p>
        8.6 Alustan käytön päättymisen jälkeen Mynt käsittelee henkilötietoja ja muita tietoja, jotka Yhteistyökumppani
        on toimittanut tai jotka on tuotettu Yhteistyökumppanin käytettäessä Alustaa vain koottujen tietojen tasolla.
      </p>
      <p>
        8.7 Jos Yhteistyökumppani tai Yhteistyökumppanin työntekijät, edustajat tai alihankkijat rikkovat sovellettavaa
        tietosuojalainsäädäntöä, Yhteistyökumppani vastaa täten vahingonkorvauksista ja kaikista viranomaisten tai
        kolmansien osapuolten Myntiä kohtaan koskevista vaatimuksista, mukaan lukien asianajajapalkkiot ja tämän kohdan
        täytäntöönpano. Yhteistyökumppanin ja Myntin on noudatettava EU:n tietosuoja-asetuksen 33. artiklaa.
      </p>
      <h2 id="9-siirrot">9. SIIRROT</h2>
      <p>
        9.1 Yhteistyökumppanilla ei ole oikeutta siirtää tai luovuttaa Ehtojen mukaisia oikeuksiaan tai
        velvollisuuksiaan kolmannelle osapuolelle ilman Myntin kirjallista suostumusta. Jos siirto tai luovutus tapahtuu
        ilman Myntin suostumusta, Myntillä on oikeus poistaa Yhteistyökumppani Alustalta.
      </p>
      <p>
        9.2 Jos Yhteistyökumppanin liiketoiminta siirretään kolmannelle osapuolelle (&quot;Siirronsaaja&quot;),
        Yhteistyökumppanin tulee ilmoittaa Myntille viimeistään kaksi (2) viikkoa ennen siirtoa, jotta Mynt voi
        käynnistää sopimusneuvottelut Siirronsaajan kanssa. Myntillä ei ole velvollisuutta käynnistää neuvotteluita, ja
        siirron yhteydessä Myntillä on oikeus poistaa Yhteistyökumppani Alustalta.
      </p>
      <h2 id="10-alustan-k-yt-n-keskeytt-minen">10. ALUSTAN KÄYTÖN KESKEYTTÄMINEN</h2>
      <p>
        10.1 Yhteistyökumppani voi keskeyttää Alustan käytön milloin tahansa ilman lisämaksua. Käytön keskeyttäminen ei
        vapauta kumpaakaan Osapuolta velvollisuudestaan maksaa Alustan käytön aikana kertyneitä velkoja.
        Yhteistyökumppanin suorittamia maksuja ja kuluja ei palauteta.
      </p>
      <p>
        10.2 Alustan käyttö katsotaan keskeytyneeksi, jos a) Yhteistyökumppani poistaa käyttäjätilinsä Adminpaneelista
        tai b) Yhteistyökumppani on aiheettomasti merkinnyt toimipaikkansa suljetuksi Alustalla, ollessaan kuitenkin
        tosiasiallisesti auki kolmena (3) peräkkäisenä päivänä.
      </p>
      <p>
        10.3 Alustan käytön lopettamisen seurauksena Yhteistyökumppanin tulee ilman aiheetonta viivästystä palauttaa
        kaikki Laitteet ja ohjelmistot (mukaan lukien, mutta ei rajoittuen, tabletit, tilauskioskit, tulostimet)
        Myntille, pidättäytyä käyttämästä Valokuvia ja palauttaa kaikki Valokuvista otetut kopiot ja kuvat Myntille tai
        tuhota kaikki Valokuvista otetut kopiot ja kuvat Myntin nimenomaisen ohjeistuksen mukaisesti. Mikäli Laitteita
        ja ohjelmistoja ei ole palautettu seitsemän (7) päivän kuluessa Alustan käytön lopettamisesta, Myntillä on
        oikeus veloittaa Yhteistyökumppanilta 300 €:n maksu. Summa laskutetaan erikseen ja se voidaan kuitata jäljellä
        olevista maksuista. Veloitettavat maksut eivät vapauta Yhteistyökumppania Teknisten Laitteiden palauttamista
        koskevasta velvollisuudesta.
      </p>
      <p>
        10.3 Alustan käytön keskeytyessä Yhteistyökumppanin kolikoita käsitellään seuraavasti: \ a) Jos Asiakas on
        kerryttänyt kolikoita ostoksia tekemällä, kolikot poistetaan käytöstä arvottomina. \ b) Jos Yhteistyökumppani on
        myynyt kolikoita suoraan asiakkaalle ilman, että Asiakas on ostanut Tuotteita, täytyy Yhteistyökumppanin tarjota
        asiakkaalle lahjakortti, joka on euromäärältään sama kuin ostettujen kolikoiden yhteishinta. Kuitenkin, jos
        asiakas on ostanut kolikoita ja sen jälkeen käyttänyt niitä samalla kerryttäen uusia kolikoita ostojen
        yhteydessä, katsotaan ostetut kolikot käytetyiksi First in - First out -menetelmällä.
      </p>
      <h2 id="11-muutokset-ehtoihin">11. MUUTOKSET EHTOIHIN</h2>
      <p>
        11.1 Myntillä on oikeus tehdä muutoksia ja/tai täydennyksiä Ehtoihin ilmoittamalla muokkauksista
        Yhteistyökumppanille sähköpostitse. Muokkaukset astuvat voimaan 14 päivän kuluttua siitä, kun Mynt on antanut
        ilmoituksen Yhteistyökumppanille. Muutoksiin ja/tai täydennyksiin ei sovelleta ilmoitusaikaa silloin, kun ne
        johtuvat lainsäädännön ja asetusten muutoksista tai ovat tarpeellisia ennakoimattomaan ja välittömään vaaraan
        reagoimiseksi, joka liittyy Palvelun, kuluttajien tai Yhteistyökumppanien suojaamiseen petoksilta,
        haittaohjelmilta, ei-toivotuilta kaupallisilta viesteiltä, tietoturvaloukkauksilta tai miltä tahansa muulta
        kyberturvallisuusriskiltä.
      </p>
      <p>
        11.2 Ehtojen alaisten tilanteiden huomioimiseen tai oikeuksien käyttämiseen liittyvät Myntin puutteet eivät
        merkitse sitä, että Mynt on luopunut oikeuksistaan näiltä osin.
      </p>
      <h2 id="12-vastuu">12. VASTUU</h2>
      <p>
        12.1 Yhteistyökumppani on Tuotteiden myyjänä vastuussa niiden laadusta, ominaispiirteistä ja muista
        ominaisuuksista, kunnes Tuotteet on toimitettu Asiakkaalle. Yhteistyökumppani vastaa Alustan välityksellä
        myytyjen Tuotteiden puutteista ja niistä aiheutuvasta haitasta Myntille, Asiakkaalle ja/tai muulle kolmannelle
        osapuolelle näiden Ehtojen ja sovellettavien tuotevastuuta koskevien lakien ja asetusten mukaisesti sisältäen
        soveltuvat kuluttajansuojasäännökset.{' '}
      </p>
      <p>
        12.2 . Ruokaa, juomaa, elintarvikkeita tai muita tuoretuotteita koskevien valitusten osalta Yhteistyökumppanin
        tulee korvata Myntille aiheutuvat kustannukset puutteellisen tai virheellisen tuotteen aiheuttaman tilanteen
        korjaamisesta tai korvaamisesta Asiakkaalle.
      </p>
      <p>
        12.3 Yhteistyökumppanin tulee puolustaa kustannuksellaan Myntiä ja vastata kaikista Myntin kohdistuvista
        vaatimuksista, vahingoista, seuraamusmaksuista, kuluista ja kustannuksista (mukaan lukien kohtuulliset kulut
        juridisen neuvonnan hankkimisesta), jotka johtuvat Yhteistyökumppanin Ehtojen, voimassa olevan lain tai
        määräysten rikkomisesta.
      </p>
      <p>
        12.4 Kummankin Osapuolen vastuu on rajattu välittömiin vahinkoihin, lukuun ottamatta kohdan 8 (Henkilötiedot ja
        muut tiedot) rikkomuksia ja kohdan 13.3 mukaista Osapuolen korvausvelvollisuutta. Osapuolet eivät siten vastaa
        välillisistä vahingoista, kuten liikevoiton menetyksestä, liikevaihdon/myynnin vähentymisestä tai liikearvon
        vähentymisestä. Myntin yhteenlaskettu enimmäisvastuu ei voi missään tapauksessa ylittää määrää 5 000 €. Tässä
        kohdassa määriteltyjä vastuunrajoituksia ei sovelleta tapauksissa, joissa on kyse tahallisuudesta tai törkeästä
        tuottamuksesta.
      </p>
      <p>
        12.5 Mahdolliset Yhteistyökumppanin Myntille esittämät vaatimukset on tehtävä kahden (2) kuukauden kuluessa
        vahingon aiheuttamasta tapahtumasta.
      </p>
      <h2 id="13-ylivoimainen-este">13. YLIVOIMAINEN ESTE</h2>
      <p>Kummallakaan Osapuolella ei ole velvoitetta Ehtojen mukaisten </p>
      <p>
        velvollisuuksiensa suorittamiseen silloin, jos suorituksen estymisen syynä on ylivoimainen este, mukaan lukien,
        muttei rajoittuen, pandemia, työtaistelu, lakko, työsulku, mellakka, kapina, terroristinen toiminta, tulipalo,
        tulva, saarto tai julkisen tahon tai lain määräys taikka muut Osapuolten vaikutusmahdollisuuksien ulkopuolella
        olevat olosuhteet.
      </p>
      <h2 id="14-kilpailu">14. KILPAILU</h2>
      <p>
        Jos Yhteistyökumppani päättää tehdä yhteistyötä toisen online-tilausalustan toimittajan kanssa,
        Yhteistyökumppani takaa, että se suorittaa edelleen sitoumuksensa näiden ehtojen mukaisesti. Mynt pidättää
        oikeuden poistaa Yhteistyökumppani Alustalta, jos Yhteistyökumppani suoraan tai epäsuoraan kolmansien osapuolten
        kautta harjoittaa epäoikeudenmukaista tai sopimatonta markkinointia saadakseen asiakkaat käyttämään muita kuin
        Myntin tarjoamia tilaustapoja.
      </p>
      <h2 id="15-yhteyshenkil-">15. YHTEYSHENKILÖ</h2>
      <p>
        15.1 Jos Yhteistyökumppanilla on Järjestelyä koskevia kysymyksiä, Yhteistyökumppanin tulee ottaa yhteyttä Myntin
        tukitoimintoon sähköpostitse <a href="mailto:hello@sterly.io">hello@sterly.io</a> tai muulla Myntin
        ohjeistamalla tavalla.
      </p>
      <h2 id="16-sovellettava-laki-ja-erimielisyydet">16. SOVELLETTAVA LAKI JA ERIMIELISYYDET</h2>
      <p>16.1 Ehtoja on tulkittava Suomen lakien mukaisesti.</p>
      <p>
        16.2 Näihin Ehtoihin liittyvät ristiriidat tai väitteet taikka rikkomus tai pätevyys ratkaistaan lopullisesti
        välimiesmenettelyllä Suomen Keskuskauppakamarin nopeutetun välimiesmenettelyn sääntöjen mukaisesti.
      </p>
      <p>
        16.3 Osapuolen pyynnöstä Suomen Keskuskauppakamarin välimieslautakunta voi kuitenkin päättää, että Suomen
        Keskuskauppakamarin välimiesmenettelysääntöjä sovelletaan nopeutetun välimiesmenettelyn sääntöjen sijasta, jos
        välimieslautakunta katsoo tämän asianmukaiseksi ottaen huomioon riidanalainen summa, tapauksen monimutkaisuus ja
        muut asiaan liittyvät olosuhteet.{' '}
      </p>
      <p>16.4 Välimiesmenettelyn paikka on Helsinki ja välimiesmenettelyn kieli on suomi.</p>
      <p>
        16.5 Tämän lausekkeen mukainen välimiesmenettely on luottamuksellinen. Luottamuksellisuus ulottuu kaikkiin
        menettelyn aikana esiin tuotuihin tietoihin sekä kaikkiin menettelyn yhteydessä annettuihin päätöksiin tai
        tuomioihin.
      </p>
      <p>
        16.6 Myntillä on kaikissa tilanteissa oikeus vaatia maksamattomia saamisia Helsingin käräjäoikeudessa
        välimiesmenettelyn sijasta.
      </p>
    </>
  )
}
