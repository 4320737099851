import React from 'react'
import { Skeleton } from '@mui/material'

const IdentitySkeleton: React.FC = () => {
  return (
    <div>
      {new Array(10).fill(1).map((_, index) => (
        <Skeleton variant="text" width={50 + Math.random() * 50 + '%'} height={60} key={index} />
      ))}
    </div>
  )
}

export default IdentitySkeleton
