import React, { useState, useEffect, useCallback } from 'react'
import Toggelable from '../../../components/Toggleable/Toggleable'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../contexts/AuthContext'
import { SUPPORTED_COUNTRIES, TABLE_FORMATS } from '../../../constants'
import ToggleableCard from '../../../components/ToggelableCard/ToggleableCard'
import { Stack, Button } from '@mui/material'
import { useFormContext, Controller, useWatch, useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'
import { v4 as uuidv4 } from 'uuid'
import { uploadFileJPEG } from '../../../utils'
import DeleteConfirmDialog from './deleteConfirmDialog/DeleteConfirmDialog'
import { toast } from 'react-toastify'

function General() {
  const { isAdmin, fetchRestaurants } = useAuthContext()
  const { t } = useTranslation()
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const { register, control, setValue, reset, handleSubmit, getValues } = useForm({ defaultValues: selectedRestaurant })
  const watchedValues = useWatch<any>({ control })
  const [isSaving, setSaving] = useState(false)
  const [restaurantDeleteConfirmDialogOpen, setRestaurantDeleteConfirmDialogOpen] = useState(false)

  const onSubmit = async (data: any) => {
    console.log(data)
    setSaving(true)
    const imageChanged = watchedValues.image !== selectedRestaurant?.image
    console.log('imagew changed', imageChanged)
    try {
      const filePath = `/restaurants/${selectedRestaurant?.id}/images/${uuidv4()}.jpeg`
      const imageUrl = imageChanged ? await uploadFileJPEG(watchedValues.image, filePath) : null
      if (imageChanged) setValue('image', imageUrl)
      const newValues = getValues()
      await saveRestaurantInfoData(newValues)
      setSelectedRestaurant(newValues)
      if (newValues.deleted) {
        await fetchRestaurants()
      }
    } catch (error: any) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  const handleOperatingCountryChange = ({ operatingCountryCode, vat, alcoholVat, currency }: any) => {
    setValue('config.operatingCountryCode', operatingCountryCode)
    setValue('config.vat', vat)
    setValue('config.alcoholVat', alcoholVat)
    setValue('config.currency', currency)
  }

  const handleRestaurantDelete = () => {
    setValue('deleted', Date.now())
    setRestaurantDeleteConfirmDialogOpen(false)
    toast.info('Restaurant will be deleted on save')
  }

  const getCountryNameFromCode = (code: string) => {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
    return regionNames.of(code)
  }

  useEffect(() => {
    reset(selectedRestaurant)
  }, [selectedRestaurant])

  console.log(watchedValues)

  return (
    <Stack spacing={2}>
      <div className="tab">
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.name')}</b>
          <input type="text" {...register('name')} />
        </div>

        <div className="input-field">
          {watchedValues.image ? (
            <>
              <img src={watchedValues.image} className="image-preview" />
            </>
          ) : null}
          <div className="file-field input-field">
            <p>Add an image to be displayed on the background of your QR Code menu</p>
            <div className="btn">
              <span>{t('menuView.restaurantInfoBox.EditImageButton')}</span>
              <Controller
                control={control}
                name={'image'}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <input
                      {...field}
                      value={value?.fileName}
                      onChange={e => {
                        if (e.target.files?.[0]) onChange(URL.createObjectURL(e.target.files?.[0]))
                      }}
                      type="file"
                    />
                  )
                }}
              />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
          </div>
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.operatingCountry')}</b>
          <div className="input-field col s12 pepi-select tab">
            <Controller
              name="_operatingCountry"
              control={control}
              render={({ field }) => (
                <select
                  onChange={e => handleOperatingCountryChange(SUPPORTED_COUNTRIES[+e.target.value])}
                  value={SUPPORTED_COUNTRIES.findIndex(
                    ({ operatingCountryCode }) => operatingCountryCode === watchedValues?.config?.operatingCountryCode
                  )}
                  className="browser-default"
                >
                  {SUPPORTED_COUNTRIES.map(({ operatingCountryCode }, index) => (
                    <option value={index} key={index}>
                      {getCountryNameFromCode(operatingCountryCode)}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.city')}</b>
          <input type="text" {...register('city')} />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.zipcode')}</b>
          <input type="text" {...register('zipCode')} />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.address')}</b>
          <input type="text" {...register('address')} />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.phone')}</b>
          <input type="tel" {...register('phone')} />
        </div>
      </div>

      {isAdmin && (
        <div className="m-t-30">
          <div className="divider"></div>
          <h5 className="red-text text-darken-2">Admin Settings</h5>
          <div className="tab">
            <div className="m-b-10">
              <label>
                <input type="checkbox" className="filled-in" {...register('config.enableProcessingTimeCalculation')} />
                <span>Use AI to predict order completion time</span>
              </label>
            </div>
            <div className="m-b-10">
              <label>
                <input type="checkbox" className="filled-in" {...register('config.enableAllDayReceiptPrinting')} />
                <span>All day printing</span>
              </label>
            </div>
            <div className="m-b-10">
              <label>
                <input type="checkbox" className="filled-in" {...register('config.enableGenerateQRCodeByStaff')} />
                <span>Staff QR Code Generation (Only HHR)</span>
              </label>
            </div>
            <div className="m-b-10">
              <label>
                <input type="checkbox" className="filled-in" {...register('config.enableTableNumberInput')} />
                <span>Table input (This is moved to Device management? //Emil)</span>
              </label>
              {watchedValues?.config?.enableTableNumberInput && (
                <div className="tab">
                  <div className="input-field col s12 pepi-select">
                    <Controller
                      control={control}
                      name="config.tableFormat"
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={({ target }) => onChange(TABLE_FORMATS[Number(target.value)])}
                          value={TABLE_FORMATS.findIndex(({ regex }) => regex === value?.regex)}
                          className="browser-default"
                        >
                          <option value={-1} disabled>
                            Select format
                          </option>
                          {TABLE_FORMATS.map((format, index) => {
                            return (
                              <option key={index} value={index}>
                                {t(format.name)}
                              </option>
                            )
                          })}
                        </select>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="m-b-10">
              <label>
                <span>Smartum Venue ID: </span>
                <input type="text" className="filled-in" {...register('config.smartumVenueId')} />
              </label>
            </div>
          </div>
          <Button variant="outlined" color="error" onClick={() => setRestaurantDeleteConfirmDialogOpen(true)}>
            Delete this restaurant
          </Button>
          <DeleteConfirmDialog
            open={restaurantDeleteConfirmDialogOpen}
            onClose={() => setRestaurantDeleteConfirmDialogOpen(false)}
            title={
              <span>
                Are you sure you want to delete <b>{watchedValues.name}</b>
              </span>
            }
            body={'Keep in mind this should only happen when a restaurant stops using Mynt.'}
            dialogActions={
              <>
                <Button variant="outlined" onClick={() => setRestaurantDeleteConfirmDialogOpen(false)}>
                  Close
                </Button>
                <Button variant="contained" color="error" onClick={handleRestaurantDelete}>
                  Delete
                </Button>
              </>
            }
          />
        </div>
      )}

      <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)} disabled={isSaving}>
        Save
      </Button>
    </Stack>
  )
}

export default General
