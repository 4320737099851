import { useEffect, useState } from 'react'
import AvailabilityTableRow from './AvailabilityTableRow'
import {
  Box,
  TableBody,
  TableContainer,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Item } from '../../../../../../../types'

const timeList = [1, 2, 3, 4, 5, 6, 7]

type AvailabilityTableProps = {
  specialAvailability: Item['specialAvailability']
  setSpecialAvailability: (args: any) => any
}

function AvailabilityTable({ specialAvailability, setSpecialAvailability }: AvailabilityTableProps) {
  const onHideWhenUnavailableChange = (e: any) => {
    setSpecialAvailability((prev: any) => {
      return {
        ...prev,
        hideWhenUnavailable: Boolean(e.target.checked),
      }
    })
  }

  console.log('specialAvailability', specialAvailability)

  return (
    <Box sx={{ maxWidth: '80vw' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeList.map(dayNumber => {
              return (
                <AvailabilityTableRow
                  dayNumber={dayNumber}
                  key={dayNumber}
                  specialAvailability={specialAvailability}
                  setSpecialAvailability={setSpecialAvailability}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <FormControlLabel
        control={<Checkbox onChange={onHideWhenUnavailableChange} checked={specialAvailability?.hideWhenUnavailable} />}
        label="Hide when unavailable"
      />
    </Box>
  )
}

export default AvailabilityTable
