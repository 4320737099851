import { Box, Stack } from '@mui/material'
import { NavLink, Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar_v2/Sidebar'
import theme from '../../theme'
import { useRef } from 'react'

function Layout() {
  const parentRef = useRef(null)

  return (
    <Stack direction="row" height="100vh" width="100vw">
      <Sidebar />
      <Stack
        width="100%"
        // height="100%"
        overflow="scroll"
        px={2}
        pt={1}
        sx={{ backgroundColor: theme.palette.background.default, position: 'relative' }}
        ref={parentRef}
      >
        <Outlet context={{ parentRef }} />
      </Stack>
    </Stack>
  )
}

export default Layout
