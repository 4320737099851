type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg'
}

function Spinner({ size = 'md' }: SpinnerProps) {
  let matSize
  switch (size) {
    case 'sm':
      matSize = 'small'
      break
    case 'md':
      matSize = 'medium'
      break
    case 'lg':
      matSize = 'large'
      break
  }

  return (
    <div className={`preloader-wrapper ${matSize} active`}>
      <div className="spinner-layer spinner-blue-only">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div>
        <div className="gap-patch">
          <div className="circle"></div>
        </div>
        <div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
