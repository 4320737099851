import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  ClickAwayListener,
  Collapse,
  Drawer,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Skeleton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LockIcon from '@mui/icons-material/Lock'
import React, { useEffect, useRef, useState } from 'react'
import ClickableLink from './components/ClickableLink/ClickableLink'
import CollapsableList from './components/CollapsableList/CollapsableList'
import theme from '../../theme'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { restaurantListState } from '../../atoms/RestaurantListAtom'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { useAuthContext } from '../../contexts/AuthContext'
import CreateNewRestaurant from './components/CreateNewRestaurantModal/CreateNewRestaurantModal'
import MenuIcon from '@mui/icons-material/Menu'

import InventoryIcon from '@mui/icons-material/Inventory'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import AssessmentIcon from '@mui/icons-material/Assessment'
import SellIcon from '@mui/icons-material/Sell'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const MOBILE_SIDEBAR_WIDTH = '70vw'
const DESKTOP_SIDEBAR_WIDTH = '18vw'

type iconColors = 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

const MORE_LINKS = [
  { title: 'Teams', path: '/team', icon: (color: iconColors) => <PeopleAltIcon fontSize="small" color={color} /> },
  {
    title: 'Submenus',
    path: '/multimenu',
    icon: (color: iconColors) => <CallSplitIcon fontSize="small" color={color} />,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: (color: iconColors) => <AssessmentIcon fontSize="small" color={color} />,
  },
  { title: 'Promotions', path: '/promos', icon: (color: iconColors) => <SellIcon fontSize="small" color={color} /> },
  {
    title: 'Edit Opening times',
    path: '/times',
    icon: (color: iconColors) => <AccessTimeIcon fontSize="small" color={color} />,
  },
  { title: 'Logs', path: '/logs', icon: (color: iconColors) => <InventoryIcon fontSize="small" color={color} /> },
]

const ADMIN_LINKS = [
  { title: 'Stripe', path: '/stripe' },
  { title: 'Coupon', path: '/adminCoupon' },
  { title: 'Orders', path: '/adminOrders' },
  { title: 'Restaurant configurations', path: '/adminConfigurations' },
]

function Sidebar() {
  const { isStaffAccount, logOut, isAdmin, isStaffAt } = useAuthContext()
  const { pathname } = useLocation()

  const [moreCollapseExpanded, setMoreCollapseExpanded] = useState(false)
  const [adminCollapseExpanded, setAdminCollapseExpanded] = useState(false)
  const [filterDeleted, setFilterDeleted] = useState(true)

  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const [AnchorEl, setAnchorEl] = useState<any>(null)
  const isRestaurantSelectionOpen = Boolean(AnchorEl)

  let restauratList = useRecoilValue(restaurantListState)
  restauratList =
    restauratList && filterDeleted ? restauratList.filter(restaurant => !restaurant.deleted) : restauratList

  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)

  const [isCreateRestaurantOpen, setCreateRestaurantOpen] = useState(false)

  const handleRestaurantSelectionToggle = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleRestaurantSelectionClose = () => {
    setAnchorEl(null)
  }

  const handleRestaurantSelection = (id: string) => {
    setSelectedRestaurant(restauratList?.find(restaurant => restaurant.id === id))
    handleRestaurantSelectionClose()
  }

  const handleFilterDeletedToggle = (e: React.MouseEvent | React.ChangeEvent) => {
    e.stopPropagation()
    setFilterDeleted(prev => !prev)
  }

  useEffect(() => {
    setMoreCollapseExpanded(Boolean(MORE_LINKS.find(link => link.path === pathname)))
    setAdminCollapseExpanded(Boolean(ADMIN_LINKS.find(link => link.path === pathname)))
  }, [pathname])

  console.log('selectedRestaurant', selectedRestaurant)

  const drawer = (
    <Stack px={5} py={3} height="100%">
      <Stack justifyContent="space-between" height="100%">
        <Stack spacing={2}>
          <Box>
            <Stack
              onClick={handleRestaurantSelectionToggle}
              direction="row"
              sx={{ cursor: 'pointer' }}
              alignItems="center"
              position="relative"
            >
              {selectedRestaurant ? (
                <>
                  <Avatar
                    src={selectedRestaurant?.image}
                    sx={{ width: 22, height: 22, position: 'absolute', left: '-28px' }}
                  />
                  <Typography variant="h5">{selectedRestaurant?.name}</Typography>
                </>
              ) : (
                <>
                  <Skeleton variant="circular" sx={{ width: 22, height: 22, position: 'absolute', left: '-28px' }} />
                  <Skeleton sx={{ width: '100%', fontSize: '1.2rem' }} />
                </>
              )}
              <KeyboardArrowDownIcon />
            </Stack>

            <Popper
              open={isRestaurantSelectionOpen}
              anchorEl={AnchorEl}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              sx={{ zIndex: 10 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <Menu
                      open={isRestaurantSelectionOpen}
                      onClose={handleRestaurantSelectionClose}
                      anchorEl={AnchorEl}
                      sx={{ maxHeight: '60vh', overflow: 'scroll' }}
                      variant="menu"
                    >
                      {isAdmin && (
                        <MenuItem onClick={handleFilterDeletedToggle}>
                          <FormControlLabel
                            label="Filter deleted out"
                            control={<Checkbox checked={filterDeleted} onChange={handleFilterDeletedToggle} />}
                          />
                        </MenuItem>
                      )}
                      {restauratList?.map(({ id, name, image, deleted }) => (
                        <MenuItem
                          key={id}
                          onClick={() => handleRestaurantSelection(id)}
                          selected={selectedRestaurant?.id === id}
                          autoFocus={selectedRestaurant?.id === id}
                          sx={{ position: 'relative' }}
                        >
                          <Avatar src={image} sx={{ width: 30, height: 30, mr: 1 }} />
                          {name}
                          {deleted && (
                            <Typography
                              variant="caption"
                              sx={{
                                position: 'absolute',
                                left: '0%',
                                top: '20%',
                                color: 'error.main',
                                fontWeight: 'bold',
                                transform: 'translateY(-50%) rotate(-30deg)',
                              }}
                            >
                              Deleted
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                      {isAdmin && (
                        <MenuItem>
                          <Button variant="outlined" onClick={() => setCreateRestaurantOpen(true)} fullWidth>
                            Create new restaurant
                          </Button>
                        </MenuItem>
                      )}
                    </Menu>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {isAdmin && (
              <ButtonBase
                style={{ textAlign: 'left' }}
                className="browser-default"
                onClick={() => navigator.clipboard.writeText(selectedRestaurant?.id)}
              >
                <Typography variant="caption" color="textSecondary">
                  {selectedRestaurant?.id}
                </Typography>
              </ButtonBase>
            )}
          </Box>
          <Stack spacing={1}>
            {/* <ClickableLink to="/dashboard" autohighlight correlatedPaths={['/']}>
              Dashboard
            </ClickableLink> */}
            <ClickableLink to="/menu" autohighlight correlatedPaths={['/']}>
              Menu
            </ClickableLink>
            <ClickableLink to="/orders">Orders</ClickableLink>
            <ClickableLink to="/devices">Devices & QR Codes</ClickableLink>
            <ClickableLink to="/identity">Branding & Identity</ClickableLink>
          </Stack>

          <CollapsableList title="More" expanded={moreCollapseExpanded} setExpanded={setMoreCollapseExpanded}>
            {MORE_LINKS.map((link, index) => (
              <ClickableLink key={index} to={link.path} icon={link.icon}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <span>{link.title}</span>
                </Stack>
              </ClickableLink>
            ))}
          </CollapsableList>

          {isAdmin && (
            <CollapsableList title="Admin only" expanded={adminCollapseExpanded} setExpanded={setAdminCollapseExpanded}>
              {ADMIN_LINKS.map((link, index) => (
                <ClickableLink key={index} to={link.path}>
                  {link.title}
                </ClickableLink>
              ))}
            </CollapsableList>
          )}
        </Stack>

        <Button startIcon={<LockIcon color="error" />} color="error" onClick={logOut}>
          Logout
        </Button>
      </Stack>
    </Stack>
  )

  return (
    <>
      <Paper
        sx={{
          backgroundColor: `${theme.palette.sidebar.main}`,
          position: { xs: 'fixed', sm: 'static' },
          width: { xs: MOBILE_SIDEBAR_WIDTH, sm: DESKTOP_SIDEBAR_WIDTH },
          zIndex: '10',
          left: 0,
          top: 0,
          borderRadius: 0,
          display: isStaffAccount ? 'none' : 'block',
        }}
      >
        <SwipeableDrawer
          variant="temporary"
          open={isMobileSidebarOpen}
          onOpen={() => setMobileSidebarOpen(true)}
          onClose={() => setMobileSidebarOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          anchor="left"
          PaperProps={{ sx: { overflow: 'visible' } }}
        >
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              height: '100vh',
              width: MOBILE_SIDEBAR_WIDTH,
            }}
          >
            {drawer}
          </Box>
        </SwipeableDrawer>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: DESKTOP_SIDEBAR_WIDTH,
            height: '100vh',
          }}
        >
          {drawer}
        </Box>
      </Paper>
      <CreateNewRestaurant open={isCreateRestaurantOpen} onClose={() => setCreateRestaurantOpen(false)} />
      <IconButton
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 10,
          display: {
            xs: isStaffAccount ? 'none' : 'block',
            sm: 'none',
          },
        }}
        onClick={() => setMobileSidebarOpen(true)}
      >
        <MenuIcon />
      </IconButton>
    </>
  )
}

export default Sidebar
