import React, { useState } from 'react'
import { deleteDevice, updateDevice } from '../../../../api/deviceApi'
import { useMenuContext } from '../../../../contexts/MenuContext'
import { timeAgo } from '../../../../utils/timeAgo'
import { RestaurantIdToName } from '../../RestaurantIdToName'
import { SubMenuIdToName } from '../../SubMenuIdToName'
import theme from '../../../../theme'

export default function DeviceMonitorTableRow(props) {
  const { allRestaurantData, selectedRestaurant, allSubMenus } = useMenuContext()
  const [showForm, setShowForm] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deviceMenus, setDeviceMenus] = useState(props.device.menuIds)
  const [deviceType, setDeviceType] = useState(props.device.type)
  const [serialNumber, setDeviceSerialNumber] = useState(props.device.serialNumber)
  const [deviceName, setDeviceName] = useState(props.device.name)
  const [deviceRestaurant, setDeviceRestaurant] = useState(props.device.restaurantId)

  const handleSave = e => {
    e.preventDefault()

    props.onUpdate(props.device.id, {
      serialNumber: serialNumber,
      name: deviceName,
      restaurantId: deviceRestaurant,
      type: deviceType,
      menuIds: deviceMenus,
    })
    // setIsSaving(true)

    // updateDevice(props.device.id, {
    //   serialNumber: serialNumber,
    //   name: deviceName,
    //   restaurantId: deviceRestaurant,
    //   type: deviceType,
    //   menuIds: deviceMenus,
    // })
    //   .then(result => {
    //     console.log('Saved ', result)
    //     props.refresh()
    //     setIsSaving(false)
    //   })
    //   .catch(error => {
    //     console.log('ERROR ', error)
    //   })
  }

  const handleDelete = () => {
    setIsDeleting(true)
    props.delete(props.device.id)
  }

  /**
   * Adds or removes menus to the printer
   * @param {*} e
   * @returns
   */
  const handleSetOrdersFrom = e => {
    if (e.target.name == 'no-submenu') {
      setDeviceMenus([])
      return
    }
    let newOrdersFrom = [...deviceMenus]
    let index = newOrdersFrom.findIndex(elem => elem == e.target.value)
    if (index !== -1) {
      newOrdersFrom.splice(index)
    } else {
      newOrdersFrom.push(e.target.value)
      e.target.checked = true
    }
    setDeviceMenus(newOrdersFrom)
  }

  return (
    <>
      <tr style={props?.highlighted ? { backgroundColor: theme.palette.success.light } : {}}>
        <td>{deviceName}</td>
        <td>{deviceType}</td>
        <td>{timeAgo(props.device.lastActivityAt || props.device.updatedAt)}</td>
        <td>{props.device.menuIds.length == 0 ? 'All orders' : props.device.menuIds.map(e => SubMenuIdToName(e))}</td>
        <td>
          <button className="btn" onClick={() => setShowForm(!showForm)}>
            Edit
          </button>
        </td>
      </tr>
      {showForm && (
        <tr className="edit-row row">
          <td colSpan={5}>
            <form className="edit-form">
              <div className="col s12">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={deviceName} />
              </div>
              <div className="col s12">
                <label htmlFor="id">Serial Number:</label>
                <input type="text" id="id" name="id" value={serialNumber} />
              </div>
              <div className="col s12">
                <label htmlFor="menus">Menu:</label>
                <p>
                  <label key="no-submenu">
                    <input
                      type="checkbox"
                      className="filled-in checkbox-mynt"
                      name="no-submenu"
                      checked={deviceMenus.length == 0}
                      value={[]}
                      onChange={handleSetOrdersFrom}
                    />
                    <span>Entire restaurant</span>
                  </label>
                </p>

                {allSubMenus.map(subMenu => (
                  <p key={subMenu.id}>
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in checkbox-mynt"
                        name="submenu"
                        value={subMenu.id}
                        checked={deviceMenus.includes(subMenu.id)}
                        onChange={handleSetOrdersFrom}
                      />
                      <span> {subMenu.name}</span>
                    </label>
                  </p>
                ))}
              </div>
              <div className="col s12 edit-form-buttons m-t-15">
                <button type="button" onClick={() => setShowForm(false)} className="btn">
                  Cancel
                </button>
                <button type="submit" onClick={handleSave} className="btn" disabled={isSaving || isDeleting}>
                  Save
                </button>
                <button type="button" onClick={handleDelete} className="btn red" disabled={isSaving || isDeleting}>
                  Delete
                </button>
              </div>
            </form>
          </td>
        </tr>
      )}
    </>
  )
}
