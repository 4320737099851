import moment from 'moment'
// @ts-ignore
import M from 'materialize-css'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMenuSelectContext } from '../../../contexts/MenuSelectContext'
import { useAuthContext } from '../../../contexts/AuthContext'
import { updateMenuItemVisibility } from '../../../api/restaurantInfoApi'
import ListViewMenuItem from './ListViewMenuItem'
import GridViewMenuItem from './GridViewMenuItem'
import EditMenuItem from './EditMenuItem/EditMenuItem'
import { useRecoilState } from 'recoil'
import selectedRestaurantMenuState from '../../../selectors/selectedRestaurantMenu'

type MenuItemProps = {
  data: any
  listView: boolean
  updateElment: any
  removeElement: any
  restaurantId: string
  restaurant: any
  index: number
  new?: boolean
  refreshMenuList: any
  duplicateElement: any
  onEdit?: any
}

function MenuItem(props: MenuItemProps) {
  const { t, i18n } = useTranslation()
  const { user, loggedIn, isAdmin } = useAuthContext()
  const menuId = props.data.id
  // @ts-ignore
  const { addOrRemove, itemInList } = useMenuSelectContext()
  const [selectedRestaurantMenu, setSelectedRestaurantMenu] = useRecoilState(selectedRestaurantMenuState)

  const title = props?.data?.translations?.title?.en || props?.data?.title
  const description = props?.data?.translations?.description?.en || props?.data?.description

  const isOwner = useMemo(() => {
    if (!loggedIn) return false
    return user.owningRestaurantIds.indexOf(props.restaurantId) >= 0
  }, [user, props.restaurantId, loggedIn])

  const hidden = useMemo(() => {
    const hiddenTime = props?.data?.hiddenTime ? props.data.hiddenTime : 0
    const hiddenFrom = props?.data?.hiddenFrom
    if (props.index === 1) {
      //console.log("hidden what is this", hiddenTime, hiddenFrom);
    }
    if (hiddenTime === -1) {
      return true
    } else if (hiddenTime === 0) {
      return false
    } else {
      if (hiddenFrom) {
        if (moment.unix(hiddenFrom).add(hiddenTime, 'hour').isSameOrBefore(moment())) {
          return false
        }
      }
      return true
    }
  }, [props.data?.hiddenTime, props.data?.hiddenFrom])

  useEffect(() => {
    var elems = document.querySelectorAll('.modal')
    let elem = document.getElementById(menuId)

    let dismissible = !(props.new === true)
    M.Modal.init(elem, {
      dismissible: dismissible,
    })

    elems = document.querySelectorAll('.dropdown-trigger')
    M.Dropdown.init(elems, {
      constrainWidth: false,
    })
  }, [props.data])

  /**
   * Toggles hidden
   */
  const toggleHidden = (time: any) => {
    updateMenuItemVisibility({
      restaurantId: props.restaurantId,
      id: props.data.id,
      hiddenTime: time,
      hiddenFrom: moment().unix(),
    }).finally(() => {
      props.refreshMenuList(props.restaurantId)
    })
    setSelectedRestaurantMenu(
      selectedRestaurantMenu?.map((menuItem: any) => {
        if (menuItem.id === props.data.id) {
          return {
            ...menuItem,
            hiddenTime: time,
            hiddenFrom: moment().unix(),
          }
        }
        return menuItem
      })
    )
  }

  useEffect(() => {
    if (props.new == true) {
    }
  }, [props.new])

  return (
    <div>
      {props.listView ? (
        <ListViewMenuItem
          title={title}
          description={description}
          price={props?.data?.price}
          itemInList={itemInList}
          isAlcohol={props?.data?.isAlcohol}
          specialAvailability={props?.data?.specialAvailability}
          data={props.data}
          index={props.index}
          isOwner={isOwner}
          isAdmin={isAdmin}
          duplicateElement={props.duplicateElement}
          toggleHidden={toggleHidden}
          countDownToTomorrow={countDownToTomorrow}
          menuId={menuId}
          addOrRemove={addOrRemove}
          onRemove={props.removeElement}
          hidden={hidden}
          onEdit={props.onEdit}
        />
      ) : (
        <GridViewMenuItem
          image={props?.data?.image}
          title={title}
          description={description}
          price={props?.data?.price}
          itemInList={itemInList}
          isAlcohol={props?.data?.isAlcohol}
          specialAvailability={props?.data?.specialAvailability}
          data={props.data}
          onSave={props.updateElment}
          onRemove={props.removeElement}
          restaurantId={props.restaurantId}
          restaurant={props.restaurant}
          index={props.index}
          eanCode={props?.data?.eanCode}
          isOwner={isOwner}
          isAdmin={isAdmin}
          duplicateElement={props.duplicateElement}
          toggleHidden={toggleHidden}
          countDownToTomorrow={countDownToTomorrow}
          menuId={menuId}
          addOrRemove={addOrRemove}
          hidden={hidden}
          onEdit={props.onEdit}
        />
      )}
    </div>
  )
}

export default MenuItem

/**
 * @returns Hours until tomorrow 2 AM
 */
function countDownToTomorrow() {
  var today = new Date()
  return 22 - today.getHours()
}
