import { useMenuContext } from '../../contexts/MenuContext'

export const SubMenuIdToName = submenuId => {
  if (typeof submenuId == Array) {
    submenuId = submenuId[0]
  }
  const { allSubMenus } = useMenuContext()

  if (!submenuId?.length) {
    return 'Entire product list'
  }
  let index = allSubMenus?.findIndex(submenu => submenu.id == submenuId)

  if (index == -1) {
    return 'Entire product list'
  }
  return allSubMenus[index].name
}
