import { selector } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'
import { partnersListState } from '../atoms/PartnersAtoms'

const selectedRestaurantPartnersState = selector({
  key: 'selectedRestaurantPartners',
  get: ({ get }) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const partnersList = get(partnersListState)
    const selectedRestaurantPartners = partnersList?.filter((partner: any) =>
      partner?.restaurantIds?.includes(selectedRestaurant?.id)
    )
    return selectedRestaurantPartners
  },
})

export default selectedRestaurantPartnersState
