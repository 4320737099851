import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import SubMenu from '../types/SubMenu'
import { OrderingModes } from '../types/Common'

const subMenuSchema = () => {
  // TS errors here might look scary, but easy to fix
  const objectSchema: yup.ObjectSchema<SubMenu> = yup.object({
    id: yup
      .string()
      .transform(value => {
        if (!value) return uuidv4()
        return value
      })
      .default(uuidv4())
      .required(),
    name: yup.string().min(3).required(),
    orderingMode: yup.string().oneOf(Object.values(OrderingModes)).default(OrderingModes.NO_SPECIAL_ORDERING),
    visibleItemIds: yup.array().of(yup.string().defined().strict(true)).default([]).required(),
    surcharge: yup.string().default(undefined),
    shouldAcceptPreOrder: yup.boolean().default(false),
    postfix: yup.string().default(undefined),
  })
  return objectSchema
}

export default subMenuSchema
