type TableFormat = {
  name: string
  regex: string
  example?: string
}

enum OrderingModes {
  NO_SPECIAL_ORDERING = 'no-special-ordering',
  TABLE_ORDERING = 'table-ordering',
  ROOM_ORDERING = 'room-ordering',
}

export type { TableFormat }
export { OrderingModes }
