import React, { useState, useEffect } from 'react'
import { createDevice } from '../../api/deviceApi'
import Toggelable from '../../components/Toggleable/Toggleable'
import { useMenuContext } from '../../contexts/MenuContext'
import Tooltip from '../../components/Tooltip/Tooltip'
import { useAuthContext } from '../../contexts/AuthContext'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import theme from '../../theme'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import { FormProvider, useForm } from 'react-hook-form'
import kioskSchema from '../../schemas/kioskSchema'
import { Kiosk, QRCode } from '../../types/'
import EditKiosk from '../../components/forms/KioskForm'
import PartialBackdrop from '../../ui/PatrialBackdrop/PartialBackdrop'
import qrCodeSchema from '../../schemas/qrCodeSchema'
import QRCodeForm from '../../components/forms/QRCodeForm'
import { TABLE_FORMATS } from '../../constants'

type AddQRCodeFormProps = {
  restaurantId: string
  onSuccess?: (kiosk: any) => void
  onCancel?: () => void
}

function AddQRCodeForm({ onSuccess, onCancel, restaurantId }: AddQRCodeFormProps) {
  const [isSaving, setSaving] = useState(false)

  const resolver = useYupValidationResolver(qrCodeSchema)
  const methods = useForm<QRCode>({
    resolver,
    defaultValues: {
      restaurantId: restaurantId,
      menuIds: [''],
      tableFormat: TABLE_FORMATS[0],
    },
  })

  const onSubmit = (data: any) => {
    // Removing self-generated id
    delete data.id

    console.log('Submit valid', data)
    setSaving(true)

    createDevice(data)
      .then(res => {
        if (!res.success) {
          alert(res.error)
        } else onSuccess && onSuccess(res?.data)
      })
      .catch(error => {
        console.error('ERROR ', error)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  // Updating restaurant id when restaurant changes
  useEffect(() => {
    methods.setValue('restaurantId', restaurantId)
  }, [restaurantId])

  return (
    <PartialBackdrop show={isSaving}>
      <Stack spacing={3} p={1}>
        <Typography variant="h4">Add QR Code:</Typography>
        <FormProvider {...methods}>
          <QRCodeForm />
        </FormProvider>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="contained" onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}>
            Add
          </Button>
          <Button variant="contained" color="info" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </PartialBackdrop>
  )
}

export default AddQRCodeForm
