import { Box, Stack, Typography, Autocomplete, TextField } from '@mui/material'
import { restaurantListState } from '../../../../atoms/RestaurantListAtom'
import { devicesListState } from '../../../../atoms/DevicesAtoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { DeviceType } from '../../../../types/Device'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'

const getDeviceTypeColor = (type: string) => {
  switch (type) {
    case DeviceType.KIOSK:
      return 'blue'
    case DeviceType.PRINTER:
      return 'green'
    case DeviceType.QR_CODE:
      return 'orange'
    case DeviceType.CUSTOMER_MONITOR:
      return 'red'
    case DeviceType.KITCHEN_MONITOR:
      return 'purple'
    default:
      return 'black'
  }
}

type DeviceFinderProps = {
  handleDeviceLookup: (device: any) => void
}

function DeviceFinder({ handleDeviceLookup }: DeviceFinderProps) {
  const restaurantList = useRecoilValue(restaurantListState)
  const deviceList = useRecoilValue(devicesListState)

  const handleSelect = (device: any) => {
    handleDeviceLookup(device)
  }

  return (
    <Box width="40%">
      <Stack spacing={2}>
        <Typography variant="h5" color="mynt.main">
          Admin device finder
        </Typography>
        <Autocomplete
          disablePortal
          size="small"
          options={
            deviceList
              ?.map((device: any) => ({
                label: device?.serialNumber || '',
                serialNumber: device?.serialNumber || '',
                type: device?.type || '',
                value: device?.serialNumber || '',
                restaurantId: device?.restaurantId || '',
                restaurantName: restaurantList?.find((restaurant: any) => restaurant?.id === device?.restaurantId)
                  ?.name,
              }))
              ?.sort((a: any, b: any) => -b?.restaurantName?.localeCompare(a?.restaurantName)) || []
          }
          renderOption={(props, device: any) => (
            <li key={device?.serialNumber} {...props}>
              <Typography variant="subtitle2" mr={1} color={getDeviceTypeColor(device?.type)}>
                {device?.type}
              </Typography>
              <Typography variant="subtitle2">{device?.serialNumber}</Typography>
            </li>
          )}
          groupBy={(option: any) => option?.restaurantName}
          freeSolo
          clearOnBlur
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{ ...params?.inputProps, className: 'browser-default' }}
              label="Serial number"
            />
          )}
          onChange={(event: any, newValue: string | null) => {
            handleSelect(newValue)
          }}
        />
      </Stack>
    </Box>
  )
}

export default DeviceFinder
