import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import Papa from 'papaparse'

/**
 *
 * @param {CSV} csv
 * @returns Array for Menu.
 */
export async function csvToMenuArray(csvFile) {
  const csvData = new Promise((resolve, reject) => {
    readCSVFile(csvFile, resolve)
  })

  let newMenuList = []

  return csvData.then(res => {
    res.forEach(row => {
      validateCsvRow(row) // Check that values are correct
      row = removeEmpty(row) // Clean out unused values
    })

    res.forEach(row => {
      // Start building menuList
      let id = uuidv4()

      if (row.type == 'menuItem') {
        let keys = Object.keys(row)

        let extras = keys.filter(e => e.includes('extra') || e.includes('priceExtra'))

        let itemExtraList = []

        for (let i = 0; i < extras.length; i++) {
          const element = extras[i]
          itemExtraList.push({
            name: row[extras[i]],
            price: row[extras[i + 1]],
          })
          i++
        }

        let optionsArray = []

        let optiongroup1 = keys.filter(e => e.includes('optionGroup1'))
        let optiongroup2 = keys.filter(e => e.includes('optionGroup2'))
        let optiongroup3 = keys.filter(e => e.includes('optionGroup3'))
        let optiongroup4 = keys.filter(e => e.includes('optionGroup4'))
        let optiongroup5 = keys.filter(e => e.includes('optionGroup5'))

        let AllOptions = []

        if (optiongroup1.length) {
          AllOptions.push(optiongroup1)
        }
        if (optiongroup2.length) {
          AllOptions.push(optiongroup2)
        }
        if (optiongroup3.length) {
          AllOptions.push(optiongroup3)
        }
        if (optiongroup4.length) {
          AllOptions.push(optiongroup4)
        }
        if (optiongroup5.length) {
          AllOptions.push(optiongroup5)
        }

        for (let x = 0; x < AllOptions.length; x++) {
          let newOption = {
            optionName: row[AllOptions[x][0]],
          }

          let optionList = []

          for (let y = 1; y < AllOptions[x].length; y++) {
            console.log('Looping through: ', AllOptions[x])

            console.log('New option ', row[AllOptions[x][y]])

            let addThisOption = {
              name: row[AllOptions[x][y]],
              price: row[AllOptions[x][y + 1]],
            }
            optionList.push(addThisOption)

            y++
          }

          newOption = {
            ...newOption,
            optionList,
          }

          optionsArray.push(newOption)
        }

        newMenuList.push({
          id: id,
          type: 'menuItem',
          title: row.title,
          description: row.description ? row.description : '',
          price: row.price,
          image: '',
          options: optionsArray,
          extras: itemExtraList,
          shouldSuggested: row.shouldSuggested ? row.shouldSuggested : false,
          isAlcohol: row.isAlcohol ? row.isAlcohol : false,
          new: false,
        })
      }
      if (row.type == 'editableText') {
        console.log('Text Row: ', row)

        newMenuList.push({
          id: id,
          type: 'editableText',
          title: row.title,
          description: row.description ? row.description : '',
          new: false,
        })
      }
    })

    console.log('New menu: ', newMenuList)

    return newMenuList
  })
}

async function readCSVFile(csvFile, callback) {
  const fileReader = new FileReader()

  fileReader.readAsText(csvFile)
  let data

  Papa.parse(csvFile, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      callback(results.data)
    },
  })
}

/**
 */
export function validateCsvRow(row) {
  if (row.type != 'menuItem' && row.type != 'editableText') {
    throw Error('Type needs to be either menuItem or editableText item is: ', row)
  }

  if (!row.title) {
    throw Error('Missing title ', row)
  }

  if (row.type == 'menuItem') {
    if (!row.price) {
      throw Error('Price of items needs to be specified, item is:', row)
    }
    if (isNaN(parseFloat(row.price))) {
      throw Error('Price needs to be a number, item is:', row)
    }

    if (isNaN(row.priceExtra1)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra2)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra3)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra4)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra5)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra6)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra7)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra8)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra9)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra10)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra11)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra12)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra13)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra14)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra15)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra16)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra17)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra18)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra19)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra20)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra21)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra22)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra23)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra24)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra25)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra26)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra27)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra28)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra29)) {
      throw Error('All extra prices has to be a number or empty string')
    }
    if (isNaN(row.priceExtra30)) {
      throw Error('All extra prices has to be a number or empty string')
    }
  }

  if (row.type == 'editableText') {
    console.log('text row: ', row)
  }
}

const removeEmpty = obj => {
  Object.keys(obj).forEach(k => !obj[k] && obj[k] !== undefined && delete obj[k])
  return obj
}
