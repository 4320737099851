import FormatCurrency from './FormatCurrency'

function withCurrencySymbol(price: any, restaurant: any, appendZeros = false) {
  const currencySymbol: string | undefined = restaurant?.config?.currency?.symbol

  if (price === undefined || price === null) return ''
  if (typeof price === 'number') price = price.toString()

  if (currencySymbol === undefined) return price
  if (appendZeros) price = FormatCurrency(price)

  if (currencySymbol === 'kr') return `${price} ${currencySymbol}`
  else return `${currencySymbol}${price}`
}

export default withCurrencySymbol
