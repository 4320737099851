import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Toggelable from '../../../../components/Toggleable/Toggleable'
import { useDropzone } from 'react-dropzone'
import { uploadFile } from '../../../../utils'
import { saveRestaurantInfoData } from '../../../../api/restaurantInfoApi'
import { Stack, Button, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'

const ScreensaverEdit = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const [saving, setSaving] = useState(false)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'video/mp4': ['.mp4'],
    },
  })
  const [currentFiles, setCurrentFiles] = useState(selectedRestaurant?.screensaverSetting?.assets || [])
  const [alwaysActive, setAlwaysActive] = useState(selectedRestaurant?.screensaverSetting?.alwaysActive || false)
  const [screensaverType, setScreensaverType] = useState(selectedRestaurant.screensaverSetting?.mode || 'off')

  const files = acceptedFiles.map((file, fileIndex) => (
    <li key={fileIndex}>
      {file.name.indexOf('.mp4') > -1 ? (
        <video
          src={URL.createObjectURL(file)}
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      ) : (
        <img
          src={URL.createObjectURL(file)}
          alt=""
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      )}
      <br />
      <button
        className="btn-xs btn btn-small btn-info"
        onClick={() => {
          acceptedFiles.splice(fileIndex, 1)
        }}
      >
        Remove
      </button>
    </li>
  ))

  const currentFilesAsHTML = currentFiles.map((file: any) => (
    <li key={file}>
      {file.indexOf('.mp4') > -1 ? (
        <video
          src={file}
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      ) : (
        <img
          src={file}
          alt=""
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      )}
      <br />
      <button
        className="btn-xs btn btn-small btn-info"
        onClick={() => {
          const newFiles = currentFiles.filter((f: any) => f !== file)
          setCurrentFiles(newFiles)
        }}
      >
        Remove
      </button>
    </li>
  ))

  const selectScreensaverType = (event: any) => {
    console.log(event)
    if (event.target.checked) {
      setScreensaverType(event.target.value)
    }
  }

  // const [imgUrl, setImgUrl] = useState(null);

  // const handleImageInput = (event) => {
  //   let imgUrl = URL.createObjectURL(event.target.files[0]);
  //   setImgUrl(imgUrl);
  //   console.log(imgUrl);
  // };

  const onSaveSettings = async () => {
    setSaving(true)
    const assets = await Promise.all(
      acceptedFiles.map(async file => {
        const filePath = `/restaurants/${selectedRestaurant.id}/images/${uuidv4()}.${file.name}`
        const url = await uploadFile(file, filePath)
        return url
      })
    )

    await saveRestaurantInfoData({
      id: selectedRestaurant.id,
      screensaverSetting: {
        alwaysActive: alwaysActive,
        mode: screensaverType,
        assets: [...currentFiles, ...assets],
      },
    })
    setSaving(false)
  }

  useEffect(() => {
    setCurrentFiles(selectedRestaurant?.screensaverSetting?.assets || [])
    setAlwaysActive(selectedRestaurant?.screensaverSetting?.alwaysActive || false)
    setScreensaverType(selectedRestaurant.screensaverSetting?.mode || 'off')
  }, [selectedRestaurant])

  return (
    <Stack spacing={2} minHeight="100%" justifyContent="space-between">
      <div className="row row-editing">
        <Typography variant="h5">
          Add the ability to display advertisements or some form of information on a Kiosk when the kiosk does not
          accept orders (Disabled, Restaurant closed, or something).
        </Typography>
        <ul>
          <li>
            <label>
              <input
                className="with-gap"
                type="radio"
                name="screen-saver"
                id="ss-default"
                value={'off'}
                checked={screensaverType === 'off'}
                onChange={event => selectScreensaverType(event)}
              />
              <span>Off</span>
            </label>
          </li>
          {/* <li>
                  <label htmlFor="ss-banner">
                    <input
                      className="with-gap"
                      type="radio"
                      name="screen-saver"
                      id="ss-banner"
                      value={"single"}
                      onChange={(event) => selectScreensaverType(event)}
                    />
                    <span>Single banner</span>
                  </label>
                </li> */}
          <li>
            <label htmlFor="ss-slider">
              <input
                className="with-gap"
                type="radio"
                name="screen-saver"
                id="ss-slider"
                value={'multiple'}
                checked={screensaverType === 'multiple'}
                onChange={event => selectScreensaverType(event)}
              />
              <span>Slideshow</span>
            </label>
          </li>
        </ul>

        <div className="m-t-15 m-b-15">
          <p>
            <label>
              <input
                type="checkbox"
                className="materialize-checkbox  filled-in"
                name="shouldSuggested"
                checked={alwaysActive}
                onClick={() => setAlwaysActive(!alwaysActive)}
              />
              <span>Always show screensaver when machine goes idle, </span>
            </label>
          </p>
        </div>

        {/* {screensaverType === "single" && (
                <div className="file-field input-field">
                  <div className="btn">
                    <span>File</span>
                    <input
                      type="file"
                      accept="image/*,video/mp4"
                      onChange={handleImageInput}
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                  {imgUrl && (
                    <div className="img-preview">
                      <img src={imgUrl} alt="single banner" />
                    </div>
                  )}
                </div>
              )} */}

        {screensaverType === 'multiple' && (
          <section className="dropzone-container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag & drop some files here, or click to select files</p>
              <p>Support only images and MP4 file (less than 1080p)</p>
            </div>
            <aside>
              <h4>Files</h4>
              <ul className="file-list">
                {currentFilesAsHTML}
                {files}
              </ul>
            </aside>
          </section>
        )}
      </div>
      <Button variant="contained" size="large" onClick={onSaveSettings} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}

export default ScreensaverEdit
