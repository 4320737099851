import React, { useState, useEffect } from 'react'
import M from 'materialize-css'
import { useForm } from 'react-hook-form'
import { updateCoupon } from '../../api/adminApi'
import QRCode from 'react-qr-code'

/**
 *
 * @returns Modal for restaurants
 */
export default function AdminCouponEditModal({ data, ...props }) {
  const [codeData, setCodeData] = useState({})
  const [saving, setSaving] = useState(false)
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()

  let onSave = () => {
    let modal = document.querySelector('#AdminCouponEditModal')
    var modalInstance = M.Modal.getInstance(modal)
    setSaving(true)
    modalInstance.close()
  }

  let addUses = e => {
    e.preventDefault()
    let formData = watch()
    console.log('Adding ', formData.uses)

    // @Duc, api call for adding X more uses to this code here
  }

  let toggleForceStop = async () => {
    const coupon = props.coupons[props.editIndex]
    await updateCoupon(coupon.id, {
      forceStopped: !coupon.forceStopped,
    })
    await props.refresh()
  }

  useEffect(() => {
    console.log(props.coupons[props.editIndex])
    setCodeData(props.coupons[props.editIndex])
  }, [props.editIndex, props.coupons])

  return (
    <div id="AdminCouponEditModal" className="modal">
      <div className="modal-content">
        <h4>Edit Coupon Code</h4>
        <p>
          Code: <b>{codeData?.code}</b>
        </p>
        <p>
          Used: <b>{codeData?.scanCount}</b>
        </p>
        <p>
          Current Limit: <b>{codeData?.scanLimit}</b>
        </p>
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            maxWidth: 256,
            width: '100%',
          }}
        >
          <QRCode
            size={512}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={'https://ordering.myntapp.io/redeem/' + codeData?.code}
            viewBox={`0 0 512 512`}
          />
        </div>
        {/* <div className="input-field col s12">
          <input type="number" {...register("uses")} id="add" />
          <label htmlFor="add">Add more uses to the coupon</label>
        </div>
        <button className="btn-small" onClick={addUses}>
          Add
        </button> */}
        <br />
        {codeData?.forceStopped ? (
          <>
            <p>This has code has been force stopped from use</p>
            <button className="btn-small" onClick={toggleForceStop}>
              Re-Open Code
            </button>
          </>
        ) : (
          <>
            <p>You can stop the usage of this code</p>
            <button className="btn-small red" onClick={toggleForceStop}>
              Force Stop
            </button>
          </>
        )}
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close  btn modal-close">
          close
        </a>
      </div>
    </div>
  )
}
