import { Checkbox, FormControlLabel, TableCell, TableRow, Typography } from '@mui/material'
import { useMenuContext } from '../../../contexts/MenuContext'
import Localized from '../../../ui/Localized/Localized'
import { useFormContext, useWatch } from 'react-hook-form'
import theme from '../../../theme'

const MultiMenuTableRow = ({ item, subMenus, handleSubmenuChange }: any) => {
  const { control } = useFormContext()
  const splitOrderIntoSuborders = useWatch({ control, name: 'splitOrderIntoSuborders' })

  if (item.type === 'editableText') {
    return (
      <TableRow className="tr-title">
        <TableCell colSpan={999} style={{ textAlign: 'center' }}>
          <Typography variant="h5">
            <Localized target={item} translationsKey="title" />
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  const isError =
    splitOrderIntoSuborders && !subMenus?.some((subMenu: any) => subMenu.visibleItemIds?.includes(item.id))

  return (
    <TableRow>
      <TableCell>
        <Localized target={item} translationsKey="title" />
        {isError && (
          <Typography variant="body2" color="error">
            Please assign this item to a submenu
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <p>
          <FormControlLabel
            label="Main Menu"
            control={<Checkbox className={'filled-in'} checked={true} disabled={true} />}
          />
        </p>
      </TableCell>
      {subMenus?.map((subMenu: any, index: number) => (
        <TableCell key={subMenu.id}>
          <p>
            <FormControlLabel
              label={subMenu.name}
              control={
                <Checkbox
                  className={'filled-in'}
                  onChange={() => handleSubmenuChange(item.id, subMenu.id)}
                  checked={subMenu?.visibleItemIds?.includes(item.id)}
                />
              }
            />
          </p>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default MultiMenuTableRow
