import { Backdrop, Box, CircularProgress } from '@mui/material'
import theme from '../../theme'

type PartialBackdropProps = {
  show: boolean
  children?: any
  sx?: any
}

function PartialBackdrop({ show, children, sx = {} }: PartialBackdropProps) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...sx,
      }}
    >
      {children}
      <Backdrop
        sx={{
          color: '#fff',
          // bgcolor: 'rgba(0, 0, 0, 0.3)',
          background: 'radial-gradient(circle, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.08) 100%)',
          borderRadius: '10px',
          zIndex: theme => theme.zIndex.drawer + 1,
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
        }}
        open={show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default PartialBackdrop
