// @ts-ignore
import M from 'materialize-css'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getAuth } from 'firebase/auth'
import { useAuthContext } from '../../contexts/AuthContext'
import EditTimes from '../menu/restaurantInfo/RestaurantInfoEditModal/EditTimes/EditTimes'
import { useParams } from 'react-router-dom'
import { getRestaurantInfoById, saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import CalendarOverride from '../../components/calendarOverride/CalendarOverride'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import Spinner from '../../ui/Spinner/Spinner'
import useDebounce from '../../hooks/useDebounce'
import { Box, Button, Card, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useRequestStatus } from '../../hooks/useRequestStatus'

function OpenTimes() {
  const { t, i18n } = useTranslation()
  const [restaurant, setRestaurant] = useRecoilState(selectedRestaurantState)
  const { makeApiRequest } = useRequestStatus()

  // const debouncedDataSave = useDebounce(updatedRestaurant => {
  //   console.log('sending data', updatedRestaurant)
  //   saveRestaurantInfoData(updatedRestaurant).then(() => {
  //     M.toast({ html: t('general.saved'), classes: 'rounded' })
  //   })
  // }, 500)

  const saveOpeningTimes = async (updatedRestaurant: any) => {
    await makeApiRequest(saveRestaurantInfoData, [updatedRestaurant])
  }

  const handleSetTime = (e: any, index: any, dayIndex: any) => {
    e.preventDefault()
    const input = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    let newOpeningTimes = { ...restaurant.openingTimes }
    const targetDay = [...newOpeningTimes[index]]
    targetDay[dayIndex] = input
    newOpeningTimes[index] = targetDay

    console.log('new opening times: ', newOpeningTimes)
    const updatedRestaurant = {
      ...restaurant,
      openingTimes: newOpeningTimes,
    }

    setRestaurant(updatedRestaurant)
    // debouncedDataSave(updatedRestaurant)
  }

  // const saveOverride = (overrideData: any) => {
  //   console.log('about to upload: ', overrideData)

  //   const updatedRestaurant = {
  //     ...restaurant,
  //     overrideTimes: overrideData,
  //   }
  //   setRestaurant(updatedRestaurant)
  //   console.log('resp: ', restaurant)
  //   saveRestaurantInfoData(updatedRestaurant).then(resp => {
  //     // TODO: Fix this to be accepted by backend
  //     console.log('ree ', resp)
  //     M.toast({ html: t('general.saved'), classes: 'rounded' })
  //   })
  // }

  let setTime = (e: any) => {
    console.log('set time ', e)
  }

  useEffect(() => {
    let elems = document.querySelectorAll('.timepicker')
    M.Timepicker.init(elems, {
      twelveHour: false,
      onSelect: setTime,
    })
  }, [])

  if (!restaurant) return <Spinner />

  return (
    <Stack alignItems="center" pb={8} sx={{ position: 'relative' }}>
      <Card elevation={4}>
        <Stack p={4}>
          <Typography variant="h4">{t('menuView.restaurantInfoBox.editOpeningTimesTitle')}</Typography>
          <Typography variant="subtitle1">{t('menuView.restaurantInfoBox.openingTimesDescription')}</Typography>
          <Table className="edit-time-table">
            <TableHead>
              <TableRow>
                <TableCell>{t('menuView.restaurantInfoBox.day')}</TableCell>
                <TableCell>{t('menuView.restaurantInfoBox.opening')}</TableCell>
                <TableCell>{t('menuView.restaurantInfoBox.closing')}</TableCell>
                <TableCell>{t('menuView.restaurantInfoBox.closed')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <EditTimes openingTimes={restaurant.openingTimes} update={handleSetTime} />
            </TableBody>
          </Table>
        </Stack>
      </Card>
      <Box sx={{ position: 'fixed', bottom: '10px', width: '400px' }}>
        <Button variant="contained" fullWidth onClick={() => saveOpeningTimes(restaurant)}>
          Save
        </Button>
      </Box>
    </Stack>
  )
}

export default OpenTimes
