import React, { createContext, useContext, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'
import selectedRestaurantMenuState from '../selectors/selectedRestaurantMenu'
import lodash from 'lodash'
import { useRequestStatus } from '../hooks/useRequestStatus'
import { setMenuListApi } from '../api/restaurantListApi'
import moment from 'moment'

export const MenuSelectContext = createContext({})

export function MenuSelectProvider(props: any) {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const restaurantId = selectedRestaurant?.id
  const menuList = useRecoilValue(selectedRestaurantMenuState)
  const [selectionList, setSelectionList] = useState<any[]>([])
  const { makeApiRequest } = useRequestStatus()

  /**
   * Adds or removes an item from the selection list
   * @param {string} itemId
   */
  let addOrRemove = (itemId: string, itemIndex: number) => {
    let index = findIndexJsonArray(selectionList, itemId, 'itemId')

    if (index === -1) {
      let newList = [...selectionList]
      newList.push({ itemId, itemIndex })
      setSelectionList(newList)
    } else {
      let newList = [...selectionList]
      newList.splice(index, 1)
      setSelectionList(newList)
    }
  }

  let selectAll = () => {
    let newList = [...selectionList]

    for (let i = 0; i < menuList.length; i++) {
      let index = findIndexJsonArray(selectionList, menuList[i].id, 'itemId')

      if (index === -1 && menuList[i].type == 'menuItem') {
        newList.push({ itemId: menuList[i].id, itemIndex: menuList[i].itemIndex })
      }
    }

    setSelectionList(newList)
  }

  let findIndexJsonArray = (array: any[], itemToFind: any, key: any) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] == itemToFind) {
        return i
      }
    }
    return -1
  }

  let itemInList = (itemId: any) => {
    return -1 != findIndexJsonArray(selectionList, itemId, 'itemId')
  }

  let clearList = () => {
    setSelectionList([])
  }

  let removeElements = () => {
    // props.removeElements(selectionList)
    clearList()
  }

  /**
   * @param {json} itemstoUpdate
   * @param {string} variable
   */
  let handleUpdateElements = (itemstoUpdate: any, variable: string) => {
    updateElements(itemstoUpdate, variable, selectionList)
    clearList()
  }

  const updateMenuList = async (list: any, apiRequestProps?: any) => {
    await makeApiRequest(
      setMenuListApi,
      [
        {
          id: restaurantId,
          menu: list.map((item: any) => {
            return {
              ...item,
              id: item?.id ? item.id : uuidv4(),
              createdAt: item.createdAt || moment.utc().toISOString(),
            }
          }),
        },
      ],
      apiRequestProps
    )
  }

  const removeElementsById = async (selectedList: any[]) => {
    const newList = [...menuList]

    for (let i = 0; i < selectedList.length; i++) {
      let indexToRemove = newList.findIndex(e => e.id == selectedList[i].itemId)

      newList.splice(indexToRemove, 1)
    }
    updateMenuList(newList, { pendingMessage: 'Removing item ...' })
  }

  const updateElements = (newData: any, variable: any, selectedList: any) => {
    let newList = lodash.cloneDeep(menuList)
    for (let i = 0; i < selectedList.length; i++) {
      const index = selectedList[i].itemIndex
      newList[index][variable] = newData
    }
    updateMenuList(newList)
  }

  useEffect(() => {
    // If restaurant index changes (Looking at different menu) Clear selection list
    setSelectionList([])
  }, [restaurantId])

  useEffect(() => {
    console.log('selectionList', selectionList)
  }, [selectionList])

  return (
    <MenuSelectContext.Provider
      value={{
        selectionList,
        addOrRemove,
        selectAll,
        itemInList,
        clearList,
        removeElements,
        handleUpdateElements,
        removeElementsById,
      }}
      {...props}
    />
  )
}

export function useMenuSelectContext() {
  return useContext(MenuSelectContext)
}
function uuidv4() {
  throw new Error('Function not implemented.')
}
