import RestaurantChip from '../restaurantChip/RestaurantChip'
import OrderActionsDropdown from './OrderActionsDropdown'
import OrderIdChip from './OrderIdChip'
import OrderTableRow from './OrderTableRow'
import OrderTime from './OrderTime'
import OrderTypeChip from './OrderTypeChip'
import './orderTable.scss'

export default function OrderTable({ ...props }) {
  if (!props.loaded) {
    return (
      <tbody>
        <tr>
          <td colSpan={6}>Loading...</td>
        </tr>
      </tbody>
    )
  }
  return (
    <tbody>
      {props?.ordersData?.length == 0 && (
        <tr>
          <td colSpan={6} className="center">
            No orders available with these filters or the restaurant hasn't gotten its first order
          </td>
        </tr>
      )}
      {props.ordersData.map(data => {
        return <OrderTableRow key={data.id} data={data} />
      })}
    </tbody>
  )
}
