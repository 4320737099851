import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import M from 'materialize-css'
import { removeStaff } from '../../../api/teamApi'
import EditStripeHolderModal from './EditStripeHolderModal'
import RestaurantChip from '../../../components/restaurantChip/RestaurantChip'
import PlaceholderLoading from 'react-placeholder-loading'
import { generateOnboardingLink } from '../../../api/teamApi'

/**
 *
 */
function StripeHolderList({ domElement, isAdmin, ...props }) {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    var elems = document.querySelectorAll('.modal')
    M.Modal.init(elems, {})
    elems = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(elems, {})
  }, [props.data])

  const onGenerateOnboardingLink = async email => {
    const resp = await generateOnboardingLink(email)
    prompt('Copy to clipboard: Ctrl+C, Enter', resp?.url)
  }

  if (!props.loaded) {
    return (
      <tbody>
        <tr>
          <td>{t('general.Loading')}</td>
          <td>
            <PlaceholderLoading shape="rect" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
        </tr>
        <tr>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
        </tr>
        <tr>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
        </tr>
        <tr>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="circle" width={32} height={32} />
          </td>
          <td>
            <PlaceholderLoading shape="rect" width={64} height={32} />
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <>
      {props.data.map(data => {
        return <EditStripeHolderModal key={data.id} update={props.update} data={data} restaurants={props.restaurants} />
      })}
      <tbody>
        {props.data.map(data => {
          return (
            <tr key={data.id}>
              <td className="user-details">
                <span className="name">
                  {data.businessName} - {data.businessID}
                </span>
                <span className="phone"></span>
              </td>
              <td>
                {props.restaurants
                  .filter(r => (data?.holdingRestaurantIds || []).indexOf(r.id) >= 0)
                  .map(r => RestaurantChip(r.name))}
              </td>
              <td>
                <a className="btn-small modal-trigger" href={'#edit-modal-' + data.id}>
                  {t('general.EditButton')}
                </a>
                <a className="btn-small" href="#" onClick={() => onGenerateOnboardingLink(data.email)}>
                  Get onboarding link
                </a>
              </td>
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default StripeHolderList
