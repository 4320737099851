import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'

function formatItemPrice(number?: any) {
  let floatNumber = parseFloat(number ? number : '0')
  return floatNumber.toLocaleString('sv-SE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

const usePriceFormat = (appendZeros?: boolean) => {
  const [currentRestaurant] = useRecoilState(selectedRestaurantState)
  const currencySymbol: string | undefined = currentRestaurant?.config?.currency?.symbol

  return {
    withCurrencySymbol: (price: string | number | undefined | null) => {
      if (price === undefined || price === null || price === '') price = 0
      if (typeof price === 'number') price = price.toString()

      if (currencySymbol === undefined) return price
      if (appendZeros) price = formatItemPrice(price)

      if (currencySymbol === 'kr') return `${price} ${currencySymbol}`
      else return `${currencySymbol}${price}`
    },
  }
}

export default usePriceFormat
