import React, { useRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { listRestaurantsByAdmin, listTokensByAnonymous } from '../../api/adminApi'
import { useAuthContext } from '../../contexts/AuthContext'
import M from 'materialize-css'
import AdminConfigEditModal from './AdminConfigEditModal'
import CreateConfigForm from './AdminConfigCreateForm'
import AdminConfigTable from './AdminConfigTable'

function AdminConfigurations({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [restaurants, setRestaurants] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const { loggedIn, isAdmin } = useAuthContext()
  const [editIndex, setEditIndex] = useState()
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()

  const refresh = async () => {
    const data = await listRestaurantsByAdmin()
    setRestaurants(data.data)
  }

  const updateData = async () => {
    setLoaded(false)
    await refresh()
    setLoaded(true)
  }

  useEffect(() => {
    if (loggedIn) {
      updateData()
    }
  }, [loggedIn])

  useEffect(() => {
    if (editIndex === null) {
      // setLoaded(false);
      refresh().finally(() => {
        setLoaded(true)
      })
    }
  }, [editIndex])

  if (!loaded) {
    return (
      <main>
        <div className="row">
          <div className="col s12">
            <div className="loading-wrapper">
              <div className="loading-row">
                <div className="preloader-wrapper big active">
                  <div className="spinner-layer spinner-blue-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="loading-row">
                <h5>{t('general.Loading')}</h5>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }

  const edit = id => {
    setEditIndex(id)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 m12">
          <h3>[Admin] Configurations</h3>
          <p>Admin can control configurations here</p>
          <table className="team-table">
            <thead>
              <tr>
                <th>Restaurant name</th>
                <th>Configurations</th>
                <th>Credentials</th>
                <th>Actions</th>
              </tr>
            </thead>
            <AdminConfigTable data={restaurants} setEditIndex={edit} />
          </table>
          {restaurants.find(item => item.id === editIndex) && (
            <AdminConfigEditModal
              data={restaurants.find(item => item.id === editIndex)}
              onClose={() => setEditIndex(null)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminConfigurations
