import React, { useState, useEffect, useRef } from 'react'
import './ToggleableMenu.scss'
import M from 'materialize-css'

const ToggleableMenu = ({ title, clear, hasInput, children, cssClass }) => {
  const [isToggled, setIsToggled] = useState(false)
  const toggleableMenuRef = useRef(null)

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  const handleClear = () => {
    if (clear) {
      clear()
    }
  }

  const handleClickOutside = menuRef => event => {
    const popupElement = menuRef.current.querySelector('.popup')
    if (menuRef.current && !menuRef.current?.contains(event.target) && !popupElement?.contains(event.target)) {
      setIsToggled(false)
    }
  }

  useEffect(() => {
    const handleClickOutsideWithRef = handleClickOutside(toggleableMenuRef)
    document.addEventListener('mousedown', handleClickOutsideWithRef)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideWithRef)
    }
  }, [])

  useEffect(() => {
    let elems = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(elems, {})
    let Delems = document.querySelectorAll('select')
    M.FormSelect.init(Delems, {})
  }, [])

  return (
    <div className="toggleable-menu" onClick={() => setIsToggled(true)} ref={toggleableMenuRef}>
      {hasInput ? (
        <button className="clear-btn" onClick={handleClear}>
          x
        </button>
      ) : (
        <button className="clear-btn">+</button>
      )}
      <button className="title-btn">{title}</button>
      {isToggled && <div className={'popup row ' + cssClass}>{children}</div>}
    </div>
  )
}

export default ToggleableMenu
