import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../../../theme'
import { Link, useLocation } from 'react-router-dom'

type ClickableLinkProps = {
  onClick?: () => void
  to: string
  highlight?: boolean
  autohighlight?: boolean
  correlatedPaths?: string[]
  icon?: (props: any) => JSX.Element
  children?: React.ReactNode
}

function ClickableLink({ to, onClick = () => null, children, correlatedPaths, icon }: ClickableLinkProps) {
  const [isHovered, setHovered] = useState(false)
  const [isHighlighted, setHighlighted] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (to === pathname || correlatedPaths?.includes(pathname)) {
      setHighlighted(true)
    } else {
      setHighlighted(false)
    }
  }, [pathname, to, correlatedPaths])

  return (
    <Typography variant="subtitle1">
      <Link to={to} className="browser-default" style={{ color: 'unset' }}>
        <Box
          sx={{
            cursor: 'pointer',
            display: 'inline-block',
            position: 'relative',
            '&::after': {
              content: '""',
              height: '2px',
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: '0px',
              backgroundColor: isHovered && !isHighlighted ? `${theme.palette.mynt.main}` : 'transparent',
            },
            px: isHighlighted ? 1.4 : 0,
            py: isHighlighted ? 0.5 : 0,
            borderRadius: 1,
            backgroundColor: isHighlighted ? `${theme.palette.sidebar.dark}` : 'transparent',
          }}
          onClick={onClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            {icon?.(isHighlighted ? 'primary' : 'action')}
            {children}
          </Stack>
        </Box>
      </Link>
    </Typography>
  )
}

export default ClickableLink
