import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import { Kiosk } from '../../types/'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import kioskSchema from '../../schemas/kioskSchema'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMenuContext } from '../../contexts/MenuContext'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext'
import Tooltip from '../../components/Tooltip/Tooltip'
import { TABLE_FORMATS } from '../../constants'

type QRCodeFormProps = {}

function QRCodeForm({}: QRCodeFormProps) {
  const { t } = useTranslation()
  // @ts-ignore
  const { allSubMenus } = useMenuContext()
  const { isAdmin } = useAuthContext()

  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext()

  const { menuIds } = useWatch({
    control,
  })

  console.log(getValues())

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack>
          <Typography variant="h5">QR Code name*</Typography>
          <Typography variant="subtitle2" mb={1}>
            For example: Room service, Lobby Qr, etc...
          </Typography>
        </Stack>
        <TextField
          placeholder="Name"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('name')}
          error={Boolean(errors.name)}
          helperText={errors.name?.message as string}
        />
      </Stack>

      <Stack>
        <Typography variant="h5">Serial Number</Typography>
        <TextField
          placeholder="Serial number"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('serialNumber')}
          error={Boolean(errors.serialNumber)}
          helperText={errors.serialNumber?.message as string}
        />
      </Stack>

      <Stack>
        <Typography variant="h5">Menu in use</Typography>
        <Controller
          name="menuIds"
          control={control}
          render={({ field }) => (
            <Select
              fullWidth
              size="small"
              {...field}
              native={false}
              displayEmpty={true}
              onChange={e => {
                const subMenuArray = e.target.value
                field.onChange(subMenuArray)
              }}
              renderValue={(selected: any) => {
                return selected[0]
                  ? allSubMenus.find(({ id }: { id: string }) => id === selected[0])?.name
                  : 'Entire product list'
              }}
            >
              <MenuItem value={['']}>Entire product list</MenuItem>
              {allSubMenus.map((subMenu: any) => (
                <MenuItem key={subMenu.id} value={[subMenu.id]}>
                  {subMenu.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Stack>

      <Stack>
        <Typography variant="h5">Table/Room format</Typography>
        <Controller
          name="tableFormat"
          render={({ field }) => (
            <Select
              fullWidth
              size="small"
              value={TABLE_FORMATS.findIndex(format => format?.regex === field?.value?.regex)}
              onChange={e => {
                const formatIndex = e.target.value
                field.onChange(TABLE_FORMATS[formatIndex as number])
              }}
            >
              {TABLE_FORMATS.map(({ name }, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {t(name)}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h5">Preferences:</Typography>
        <Stack spacing={1}>
          <Controller
            name="nameOrdering"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pl: 2, marginTop: '-10px !important' }}
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Enable name ordering</b>
                  </span>
                }
              />
            )}
          />
          <Controller
            name="isCashSupported"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ pl: 2, marginTop: '-10px !important' }}
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <Tooltip text="Note that mynt commission fee might be different with this payment method">
                      <b>Enable paying with cash to cashier</b>
                    </Tooltip>
                  </span>
                }
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default QRCodeForm
