import { request } from './base'

export async function getPdfReport(restaurantId: string, month: string, year: number) {
  const resp = await request(`/cms/owners/statistics/pdf`, 'get', {
    params: {
      restaurantId,
      month,
      year,
    },
  })
  return resp?.data
}

export async function sendPdfReport(restaurantId: string, month: string, year: number) {
  const resp = await request(`/cms/statistics/pdf`, 'post', {
    payload: {
      restaurantId,
      month,
      year,
    }
  })
  return resp?.data
}

export async function getReports(restaurantId?: string) {
  const resp = await request(`/cms/reports`, 'get', {
    params: {
      // from,
      restaurantId,
    },
  })
  return resp?.data
}
