import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import SelectRestaurant from '../../components/selectRestaurant/SelectRestaurant'
import Toggleable from '../../components/Toggleable/Toggleable'
import CreateCoupon from './components/CreateCoupon'
import { useMenuContext } from '../../contexts/MenuContext'
import { listCoupons, removeCoupon } from '../../api/adminApi'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { couponListState } from '../../atoms/CouponAtoms'
import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import DeleteConfirmButton from '../../ui/DeleteConfirmButton/DeleteConfirmButton'
import CouponTableRow from './components/CouponTableRow'
import TableBodySkeleton from '../../components/TableBodySkeleton/TableBodySkeleton'
import { useRequestStatus } from '../../hooks/useRequestStatus'

const CouponCodes = () => {
  const { makeApiRequest } = useRequestStatus()
  const [coupons, setCoupons] = useState<any[] | null>(null)
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const couponList = useRecoilValue(couponListState)
  const [editingCoupon, setEditingCoupon] = useState<string | null>(null)

  const handleEditClick = (id: any) => {
    if (editingCoupon === id) {
      setEditingCoupon(null)
      return
    }
    setEditingCoupon(id)
  }

  const handleDeleteCoupon = async (coupon: any) => {
    await makeApiRequest(removeCoupon, [coupon.id], {
      pendingMessage: 'Deleting coupon...',
      successMessage: 'Coupon deleted',
    })
    if (coupons?.length === 1) {
      setCoupons(null)
    } else {
      setCoupons((coupons as any[]).filter(c => c.id !== coupon.id))
    }
  }

  useEffect(() => {
    const selectedRestaurantCoupons = couponList?.filter(coupon => coupon.restaurantId === selectedRestaurant?.id)
    setCoupons(selectedRestaurantCoupons || null)
  }, [couponList, selectedRestaurant?.id])

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h3">Coupon Codes</Typography>
        {coupons && coupons.length === 0 ? (
          <Alert severity="info">No coupons created yet</Alert>
        ) : (
          <TableContainer sx={{ overflowY: 'hidden' }}>
            <Table>
              <TableHead>
                <TableCell>Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Discount Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Usage Count</TableCell>
                <TableCell>Usage Limit</TableCell>
                <TableCell>Starts</TableCell>
                <TableCell>Ends</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableHead>
              {!coupons && <TableBodySkeleton columnsNum={10} rowsNum={5} />}
              <TableBody>
                {coupons?.map((coupon, index) => (
                  <CouponTableRow
                    key={index}
                    coupon={coupon}
                    isEditing={coupon?.id === editingCoupon}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteCoupon}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <CreateCoupon />
    </Stack>
  )
}

export default CouponCodes
