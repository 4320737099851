import { useOutletContext } from 'react-router-dom'
import RestaurantConfigView from '../../restaurantConfigView'
import General from '../../restaurantConfigView/components/General'
import { Stack, Typography } from '@mui/material'

function Step1() {
  const { restaurantData, setRestaurantData } = useOutletContext<any>()

  return (
    <Stack spacing={2} alignItems="center" width="100%">
      <Typography variant="h6" maxWidth="80%" textAlign="center">
        We will guide you through the process of setting up your restaurant on Mynt. <br /> Please, fill in missing
        restaurant data
      </Typography>
      <General restaurantData={restaurantData} setRestaurantData={setRestaurantData} expanded />
    </Stack>
  )
}

export default Step1
