import { selector } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'
import { getReports } from '../api/report'

const selectedRestaurantReportsState = selector<any>({
  key: 'selectedRestaurantReports',
  get: async ({ get }) => {
    const selectedRestaurant = get(selectedRestaurantState)
    if (!selectedRestaurant?.id) return null
    const { data } = await getReports(selectedRestaurant?.id)
    return data
  },
})

export default selectedRestaurantReportsState
