import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../contexts/AuthContext'
import { useEffect } from 'react'

function AccountNotConnected() {
  const navigate = useNavigate()
  const { logOut, loggedIn, user } = useAuthContext()

  const handleLogOut = () => {
    logOut()
    navigate('/welcome', { replace: true })
  }

  return (
    <Grid container position="relative">
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            objectFit: 'cover',
            width: '100%',
            objectPosition: {
              xs: 'center top',
              sm: 'center',
            },
            height: {
              xs: '40vh',
              sm: '100vh',
            },
          }}
          component="img"
          src="/img/GirlEating.png"
        ></Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box>
          <Stack p={4} spacing={6} alignItems="center">
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h1" textAlign="center">
                Hi there!
              </Typography>
              <Typography variant="subtitle1" maxWidth="60%" textAlign="center">
                You’re in the Mynt Dashboard, however your account is not connected to any restaurant at the moment.
              </Typography>
            </Stack>
            <Stack spacing={6}>
              <Stack spacing={2}>
                <Typography variant="h3">I should have access, let me in!</Typography>
                <Typography variant="subtitle2" maxWidth="60%">
                  Ask your manager at your venue to add your email address under the “Teams” section in the mynt
                  dashboard. After that is done please refresh this page and you should have access.
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h3">I want Mynt for my venue</Typography>
                <Typography variant="subtitle2" maxWidth="60%">
                  Please contact us through our website at{' '}
                  <Link href="https://myntapp.io/enquire-about-mynt" target="_blank">
                    myntapp.io/enquire-about-mynt
                  </Link>{' '}
                  and our team will help you out
                </Typography>
              </Stack>
            </Stack>
            <Button variant="outlined" color="error" size="large" onClick={handleLogOut}>
              Log out
            </Button>
            <Box
              component="img"
              sx={{ position: { sm: 'absolute', xs: 'static' }, bottom: '2%', right: '2%' }}
              src="/img/MyntLogo.png"
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AccountNotConnected
