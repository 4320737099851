import { atom } from 'recoil'

export const allOrdersAtom = atom({
  key: 'allOrders',
  default: [],
})

export const ordersLoadedAtom = atom({
  key: 'loadedOrders',
  default: false,
})

export const viewTypeAtom = atom({
  key: 'viewType',
  default: 'list',
})
