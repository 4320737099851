// to - 2 ISO lang code
async function translate(phrase: string, to: string) {
  return await withGoogleTranslate(phrase, to)
}

async function withGoogleTranslate(phrase: string, to: string) {
  // Should be Sterly key, not Arsenii's
  const key = 'AIzaSyAyvk5lXZx1qNEtTW1JILXqw8iHrJ4Wz7A'
  const resp = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${key}`, {
    method: 'post',
    body: JSON.stringify({
      target: to,
      q: phrase,
    }),
  })
  const json = await resp.json()
  const result = json.data.translations

  return result
}

export default translate
