import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import { useMenuContext } from '../../contexts/MenuContext'

/**
 * @param display Optional variable, if you want to control if children should be displayed when loaded or waiting for state.
 * @returns children
 */
const GenericLoadingComponent = ({ children, display = true }) => {
  const { loaded, loadingError } = useMenuContext()
  const { t, i18n } = useTranslation()

  const { loggedIn } = useAuthContext()

  if (loaded && loggedIn && display) {
    return children
  } else {
    return (
      <main>
        <div className="row">
          <div className="col s12">
            <div className="loading-wrapper">
              <div className="loading-row">
                <div className="preloader-wrapper big active">
                  <div className="spinner-layer spinner-blue-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="loading-row">
                <h5>{t('general.Loading')}</h5>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default GenericLoadingComponent
