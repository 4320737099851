import React from 'react'
import Papa from 'papaparse'
import lodash from 'lodash'
import { orderMenuItemToString } from '../orderTable/OrderIdChip'

function JSONToCSVConvertor(JSONData, ReportTitle, columns, ShowLabel) {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData
  var CSV = ''
  //This condition will generate the Label/Header
  console.log('building headers')
  if (ShowLabel) {
    var row = ''

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      if (columns.includes(index)) {
        row += index + ','
      }
    }
    row = row.slice(0, -1)
    //append Label row with line break
    CSV += row + '\r\n'
  }

  //1st loop is to extract each row
  console.log('building headers')
  for (var i = 0; i < arrData.length; i++) {
    var row = ''
    //2nd loop will extract each column and convert it in string comma-seprated
    for (var index in arrData[i]) {
      if (columns.includes(index)) {
        row += '"' + arrData[i][index] + '",'
      }
    }
    row.slice(0, row.length - 1)
    //add a line break after each row
    CSV += row + '\r\n'
  }
  console.log('building rows done!')

  if (CSV == '') {
    alert('Invalid data')
    return
  }

  //this trick will generate a temp "a" tag

  console.log('construct order report blob')
  var csv = CSV
  var blob = new Blob([csv], { type: 'text/csv' })
  var csvUrl = window.webkitURL.createObjectURL(blob)
  console.log('construct order report link')
  var filename = ReportTitle
  const link = document.createElement('a')
  link.href = csvUrl
  link.setAttribute('download', filename)

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode.removeChild(link)
}

export default function DownloadCSVButton({ orders }) {
  const flattenMetadata = data => {
    if (data.scandicFriendID) {
      return data.scandicFriendID.toString()
    }
    return ''
  }

  const downloadCSV = () => {
    console.log('report downloading initiated')

    JSONToCSVConvertor(
      orders,
      'Orders.csv',
      [
        'orderedItems',
        'paidByFiat',
        'paidByToken',
        'vat',
        'createdAt',
        'fiatCurrency',
        'customerAmount',
        'metadataStr',
        'receiptId',
      ],
      true
    )
    console.log('report downloaded')
  }

  return (
    <button className="btn-small" onClick={downloadCSV}>
      <i className="material-icons left">cloud_download</i>
      CSV
    </button>
  )
}
