import React, { useState, useEffect, useCallback } from 'react'
import Toggelable from '../../../components/Toggleable/Toggleable'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../contexts/AuthContext'
import { SUPPORTED_COUNTRIES, TABLE_FORMATS } from '../../../constants'
import ToggleableCard from '../../../components/ToggelableCard/ToggleableCard'

function General({ restaurantData, setRestaurantData, expanded }) {
  const { isAdmin } = useAuthContext()
  const { t } = useTranslation()

  const changeRestaurantValue = (field, value, targetObject) => {
    if (targetObject) {
      setRestaurantData(prevState => ({
        ...prevState,
        [targetObject]: {
          ...restaurantData[targetObject],
          [field]: value,
        },
      }))
    } else {
      setRestaurantData(prevState => ({
        ...prevState,
        [field]: value,
      }))
    }
  }

  const handleOperatingCountryChange = ({ operatingCountryCode, vat, alcoholVat, currency }) => {
    setRestaurantData(prev => ({
      ...prev,
      config: {
        ...restaurantData.config,
        operatingCountryCode,
        vat,
        alcoholVat,
        currency,
      },
    }))
  }

  const onImageChange = () => {}

  const getCountryNameFromCode = code => {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
    return regionNames.of(code)
  }

  return (
    <ToggleableCard
      title={'General settings'}
      description={'You can reach restaurant general settings from here'}
      defaultHidden={!expanded}
    >
      <div className="tab">
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.name')}</b>
          <input
            value={restaurantData.name}
            type="text"
            onChange={({ target }) => changeRestaurantValue('name', target.value)}
          />
        </div>

        <div className="input-field">
          {restaurantData.image ? (
            <>
              <img src={restaurantData.image} className="image-preview" />
            </>
          ) : null}
          <div className="file-field input-field">
            <div className="btn">
              <span>{t('menuView.restaurantInfoBox.EditImageButton')}</span>
              <input type="file" onChange={onImageChange} />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
          </div>
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.operatingCountry')}</b>
          <div className="input-field col s12 pepi-select tab">
            <select
              onChange={({ target }) => handleOperatingCountryChange(SUPPORTED_COUNTRIES[target.value])}
              value={SUPPORTED_COUNTRIES.findIndex(
                ({ operatingCountryCode }) => operatingCountryCode === restaurantData.config?.operatingCountryCode
              )}
              className="browser-default"
            >
              {SUPPORTED_COUNTRIES.map(({ operatingCountryCode }, index) => (
                <option value={index} key={index}>
                  {getCountryNameFromCode(operatingCountryCode)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.city')}</b>
          <input
            value={restaurantData.city}
            type="text"
            onChange={({ target }) => changeRestaurantValue('city', target.value)}
          />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.zipcode')}</b>
          <input
            value={restaurantData.zipCode}
            type="text"
            onChange={({ target }) => changeRestaurantValue('zipCode', target.value)}
          />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.address')}</b>
          <input
            value={restaurantData.address}
            type="text"
            onChange={({ target }) => changeRestaurantValue('address', target.value)}
          />
        </div>
        <div className="input-field">
          <b>{t('menuView.restaurantInfoBox.phone')}</b>
          <input
            value={restaurantData.phone}
            type="tel"
            onChange={({ target }) => changeRestaurantValue('phone', target.value)}
          />
        </div>
      </div>

      {isAdmin && (
        <div className="m-t-30">
          <div className="divider"></div>
          <h5 className="red-text text-darken-2">Admin Settings</h5>
          <div className="tab">
            <div className="m-b-10">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={Boolean(restaurantData.config?.enableAllDayReceiptPrinting)}
                  onChange={({ target }) =>
                    changeRestaurantValue('enableAllDayReceiptPrinting', target.checked, 'config')
                  }
                />
                <span>All day printing</span>
              </label>
            </div>
            <div className="m-b-10">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={Boolean(restaurantData.config?.enableGenerateQRCodeByStaff)}
                  onChange={({ target }) =>
                    changeRestaurantValue('enableGenerateQRCodeByStaff', target.checked, 'config')
                  }
                />
                <span>Staff QR Code Generation (Only HHR)</span>
              </label>
            </div>
            <div className="m-b-10">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={Boolean(restaurantData.config?.enableTableNumberInput)}
                  onChange={({ target }) => changeRestaurantValue('enableTableNumberInput', target.checked, 'config')}
                />
                <span>Table input</span>
              </label>
              {restaurantData.config?.enableTableNumberInput && (
                <div className="tab">
                  <div className="input-field col s12 pepi-select">
                    <select
                      onChange={({ target }) =>
                        changeRestaurantValue('tableFormat', TABLE_FORMATS[target.value], 'config')
                      }
                      value={TABLE_FORMATS.findIndex(
                        ({ regex }) => regex === restaurantData.config?.tableFormat?.regex
                      )}
                      className="browser-default"
                    >
                      <option value={-1} disabled>
                        Select format
                      </option>
                      {TABLE_FORMATS.map((format, index) => {
                        return (
                          <option key={index} value={index}>
                            {t(format.name)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className="m-b-10">
              <label>
                <span>Smartum Venue ID: </span>
                <input
                  type="text"
                  className="filled-in"
                  value={String(restaurantData.config?.smartumVenueId || '')}
                  onChange={({ target }) => changeRestaurantValue('smartumVenueId', target.value, 'config')}
                />
              </label>
            </div>
          </div>
        </div>
      )}
    </ToggleableCard>
  )
}

export default General
