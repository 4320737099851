import React, { useState, useEffect } from 'react'
import NumberToDay from '../../../../../../../utils/NumberToDay'
import { Checkbox, FormControlLabel, TableCell, TableRow, TextField } from '@mui/material'

type AvailabilityTableRowProps = {
  dayNumber: number
  specialAvailability: any
  setSpecialAvailability: (args: any) => any
}

function AvailabilityTableRow({ dayNumber, specialAvailability, setSpecialAvailability }: AvailabilityTableRowProps) {
  const onFromChange = (e: any) => {
    setSpecialAvailability((prev: any) => {
      return {
        ...prev,
        [dayNumber]: {
          ...prev?.[dayNumber],
          from: e.target.value,
        },
      }
    })
  }

  const onToChange = (e: any) => {
    setSpecialAvailability((prev: any) => {
      return {
        ...prev,
        [dayNumber]: {
          ...prev?.[dayNumber],
          to: e.target.value,
        },
      }
    })
  }

  const onAvailableChange = (e: any) => {
    console.log(e.target.value, e)
    setSpecialAvailability((prev: any) => {
      return {
        ...prev,
        [dayNumber]: {
          ...prev?.[dayNumber],
          available: !prev?.[dayNumber]?.available,
        },
      }
    })
  }

  return (
    <TableRow>
      <TableCell>{NumberToDay(dayNumber)}</TableCell>
      <TableCell>
        <TextField
          type="time"
          inputProps={{ className: 'browser-default' }}
          size="small"
          value={specialAvailability?.[dayNumber]?.from ? String(specialAvailability?.[dayNumber]?.from) : ''}
          onChange={onFromChange}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="time"
          inputProps={{ className: 'browser-default' }}
          size="small"
          value={specialAvailability?.[dayNumber]?.to ? String(specialAvailability?.[dayNumber]?.to) : ''}
          onChange={onToChange}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={Boolean(specialAvailability?.[dayNumber]?.available)}
          onClick={onAvailableChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>
    </TableRow>
  )
}

export default AvailabilityTableRow
