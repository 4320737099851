export function unixTimestampToDate(unixTimestamp) {
  // Multiply the timestamp by 1000 to convert it to milliseconds
  const milliseconds = unixTimestamp * 1000
  // Create a new Date object using the milliseconds
  const date = new Date(milliseconds)
  return date
}

export function getHourMinutes(date) {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export function isoTimestampToDate(isoTimestamp) {
  const date = new Date(isoTimestamp)
  return date
}
