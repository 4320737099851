import { useTranslation } from 'react-i18next'
import PlaceholderLoading from 'react-placeholder-loading'

export default function LoadingOrders(name) {
  const { t, i18n } = useTranslation()
  return (
    <main>
      <div className="container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </th>
              <th>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </th>
              <th>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </th>
              <th>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </th>
              <th>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="customer-details">{t('general.Loading')}</td>
              <td className="order-id">
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={48} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
            </tr>
            <tr>
              <td className="customer-details">{t('general.Loading')}</td>
              <td className="order-id">
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={48} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
            </tr>
            <tr>
              <td className="customer-details">{t('general.Loading')}</td>
              <td className="order-id">
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={48} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
            </tr>
            <tr>
              <td className="customer-details">{t('general.Loading')}</td>
              <td className="order-id">
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={64} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="circle" width={32} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={48} height={32} />
              </td>
              <td>
                <PlaceholderLoading shape="rect" width={32} height={32} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  )
}
