import { useEffect } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Item } from '../types'

export default function useDeprecatedItemValues(getValues: UseFormGetValues<Item>, setValue: UseFormSetValue<Item>) {
  // Picking deprecated values from item and setting them to translations
  const trigger = () => {
    const values = getValues()
    // titles
    if (!values?.translations?.title?.en && values?.title) {
      setValue('translations.title.en', values.title)
    }
    if (!values?.translations?.title?.fi && values?.titleFI) {
      setValue('translations.title.fi', values.titleFI)
    }
    if (!values?.translations?.title?.sv && values?.titleSE) {
      setValue('translations.title.sv', values.titleSE)
    }

    // descriptions
    if (!values?.translations?.description?.en && values?.description) {
      setValue('translations.description.en', values.description)
    }
    if (!values?.translations?.description?.fi && values?.descriptionFI) {
      setValue('translations.description.fi', values.descriptionFI)
    }
    if (!values?.translations?.description?.sv && values?.descriptionSE) {
      setValue('translations.description.sv', values.descriptionSE)
    }

    // Options
    values.options?.forEach((option, index) => {
      if (!option?.translations?.optionName?.en && option?.optionName) {
        setValue(`options.${index}.translations.optionName.en`, option.optionName)
      }
      if (!option?.translations?.optionName?.fi && option?.optionNameFI) {
        setValue(`options.${index}.translations.optionName.fi`, option.optionNameFI)
      }
      if (!option?.translations?.optionName?.sv && option?.optionNameSE) {
        setValue(`options.${index}.translations.optionName.sv`, option.optionNameSE)
      }

      option?.optionList?.forEach((optionItem, optionIndex) => {
        if (!optionItem?.translations?.name?.en && optionItem?.name) {
          setValue(`options.${index}.optionList.${optionIndex}.translations.name.en`, optionItem.name)
        }
        if (!optionItem?.translations?.name?.fi && optionItem?.nameFI) {
          setValue(`options.${index}.optionList.${optionIndex}.translations.name.fi`, optionItem.nameFI)
        }
        if (!optionItem?.translations?.name?.sv && optionItem?.nameSE) {
          setValue(`options.${index}.optionList.${optionIndex}.translations.name.sv`, optionItem.nameSE)
        }
      })
    })

    // Extras
    values.extras?.forEach((extra, index) => {
      if (extra.type === 'extraItem' || extra.type === undefined) {
        if (!extra?.translations?.name?.en && extra?.name) {
          setValue(`extras.${index}.translations.name.en`, extra.name)
        }
        if (!extra?.translations?.name?.fi && extra?.nameFI) {
          setValue(`extras.${index}.translations.name.fi`, extra.nameFI)
        }
        if (!extra?.translations?.name?.sv && extra?.nameSE) {
          setValue(`extras.${index}.translations.name.sv`, extra.nameSE)
        }
      }
    })
  }

  useEffect(trigger, [])

  return trigger
}
