import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMenuContext } from '../../contexts/MenuContext'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext'
import { SubMenuIdToName } from '../../views/devices/SubMenuIdToName'
import { PrintingMethods } from '../../types/Printer'
import { OrderingModes } from '../../types/Common'

function SubMenuForm({ restaurant }: any) {
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext()

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h5">Submenu name*</Typography>
        <Typography variant="subtitle2" mb={1}>
          Room ordering, Table ordering or Burgers, Pizzas, Drinks, etc...
        </Typography>
        <TextField
          placeholder="Name"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('name')}
          error={Boolean(errors.name)}
          helperText={errors.name?.message as string}
        />
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          Ordering mode*
        </Typography>
        <FormControl fullWidth size="small" error={Boolean(errors.orderingMode?.message)}>
          <Controller
            name="orderingMode"
            control={control}
            render={({ field, fieldState, formState }) => (
              <Select onBlur={field.onBlur} onChange={field.onChange} value={String(field.value)}>
                <MenuItem value={OrderingModes.NO_SPECIAL_ORDERING}>Default</MenuItem>
                <MenuItem value={OrderingModes.ROOM_ORDERING}>Room ordering</MenuItem>
                <MenuItem value={OrderingModes.TABLE_ORDERING}>Table ordering</MenuItem>
              </Select>
            )}
          ></Controller>
          <FormHelperText>{errors.orderingMode?.message?.toString?.()}</FormHelperText>
        </FormControl>
      </Box>

      <Box>
        <Typography variant="h5">Surcharge: (incl VAT 24%)</Typography>
        <Typography variant="subtitle2" mb={1}>
          Extra fee that will be added to the total price of the order with this submenu
        </Typography>
        <FormControl fullWidth variant="standard" error={Boolean(errors.surcharge)}>
          <OutlinedInput
            size="small"
            inputProps={{ className: 'browser-default' }}
            placeholder="Amount"
            {...register('surcharge')}
            startAdornment={<InputAdornment position="start">{restaurant?.config?.currency?.symbol}</InputAdornment>}
          />
          <FormHelperText>{errors.surcharge?.message as string}</FormHelperText>
        </FormControl>
      </Box>

      <Box>
        <Typography variant="h5">Submenu postfix</Typography>
        <Typography variant="subtitle2" mb={1}>
          Orders will be named as 1A, 1B, 1C, etc. if you set the postfix to A, B, C, etc.
        </Typography>
        <FormControl fullWidth variant="standard" error={Boolean(errors.postfix)}>
          <OutlinedInput
            size="small"
            inputProps={{ className: 'browser-default' }}
            placeholder="Postfix"
            {...register('postfix')}
          />
          <FormHelperText>{errors.postfix?.message as string}</FormHelperText>
        </FormControl>
      </Box>

      <Stack spacing={1}>
        <Typography variant="h5">Preferences</Typography>
        <Stack spacing={1}>
          <Controller
            name="shouldAcceptPreOrder"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Allow Preorders for this menu </b>
                  </span>
                }
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SubMenuForm
