import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import { Kiosk } from '../../types/'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import kioskSchema from '../../schemas/kioskSchema'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMenuContext } from '../../contexts/MenuContext'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext'
import { useRecoilValue } from 'recoil'
import selectedRestaurantSubMenusState from '../../selectors/selectedRestaurantSubmenus'
import MultiSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { PrintingMethods } from '../../types/Printer'
import { RECOMMENDED_KIOSK_PRINTING_METHODS } from '../../utils/constants'
import { restaurantListState } from '../../atoms/RestaurantListAtom'

type KioskFormProps = {
  variant?: 'default' | 'onboarding'
}

const animatedComponents = makeAnimated()

function KioskForm({ variant }: KioskFormProps) {
  const { t } = useTranslation()
  // @ts-ignore
  const subMenus = useRecoilValue(selectedRestaurantSubMenusState)
  const { isAdmin } = useAuthContext()
  const restaurantList = useRecoilValue(restaurantListState)

  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext()

  const {
    nameOrdering,
    acceptBarcodes,
    printerPaperWidth,
    menuIds,
    printingMethod,
    vendorSerialNumber,
    backgroundImage,
  } = useWatch({
    control,
  })

  const [isSubMenuKiosk, setSubMenuKiosk] = useState(Boolean(menuIds?.length))

  const handleSubMenuKioskToggle = (e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    if (!checked) setValue('menuIds', [])
    setSubMenuKiosk(checked)
  }

  const handleModelChange = (e: any, nextHandler: Function) => {
    const model = (e?.target as HTMLInputElement).value
    switch (model) {
      case 'K2':
        setValue('printingMethod', RECOMMENDED_KIOSK_PRINTING_METHODS.K2)
        break
      case 'K2_PRO':
        setValue('printingMethod', RECOMMENDED_KIOSK_PRINTING_METHODS.K2_PRO)
        break
      case 'K2_MINI':
        setValue('printingMethod', RECOMMENDED_KIOSK_PRINTING_METHODS.K2_MINI)
        break
      case 'KUORI':
        setValue('printingMethod', RECOMMENDED_KIOSK_PRINTING_METHODS.KUORI)
        break
    }
    nextHandler(e)
  }

  const handleImageRemove = () => {
    setValue('backgroundImage', '')
  }

  useEffect(() => {
    if (!nameOrdering) setValue('forcedNameOrdering', false)
    if (!acceptBarcodes) setValue('forcedBarcodes', false)
  }, [nameOrdering, acceptBarcodes])

  console.log(getValues())

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h5">Kiosk name*</Typography>
          <Typography variant="subtitle2" mb={1}>
            For example: Lobby Kiosk, Kiosk 1, etc...
          </Typography>
          <TextField
            placeholder="Name"
            fullWidth
            inputProps={{ className: 'browser-default' }}
            size="small"
            {...register('name')}
            error={Boolean(errors.name)}
            helperText={errors.name?.message as string}
          />
        </Box>
        <Box>
          <Typography variant="h5">Kiosk model*</Typography>
          <Typography variant="subtitle2" mb={1}>
            We will choose the best printing method for your kiosk based on the model.
          </Typography>
          <Controller
            name="model"
            control={control}
            render={({ field }) => (
              <Select
                fullWidth
                size="small"
                {...field}
                value={field?.value || ''}
                onChange={e => handleModelChange(e, field.onChange)}
                error={Boolean(errors?.model)}
              >
                <MenuItem value="K2">K2</MenuItem>
                <MenuItem value="K2_PRO">K2 Pro</MenuItem>
                <MenuItem value="K2_MINI">K2 Mini</MenuItem>
                <MenuItem value="KUORI">Kuori kiosk</MenuItem>
              </Select>
            )}
          />
          <FormHelperText error={Boolean(errors?.model)}>{errors.model?.message as string}</FormHelperText>
        </Box>
        <Box>
          <Typography variant="h5">Serial Number*</Typography>
          <Typography variant="subtitle2" mb={1}>
            You can find the serial number on the bottom of the kiosk's home screen when you first turn it on. It is 16
            characters long.
          </Typography>
          <TextField
            placeholder="Serial Number"
            fullWidth
            inputProps={{ className: 'browser-default' }}
            size="small"
            {...register('serialNumber')}
            error={Boolean(errors.serialNumber)}
            helperText={errors.serialNumber?.message as string}
          />
          <Typography variant="subtitle2" mb={1}>
            VENDOR S/N: {vendorSerialNumber}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5">Terminal Serial Number</Typography>
          <Typography variant="subtitle2" mb={1}>
            (Optional). Needed when there is Mynt POS running together on vivawallet terminals.
          </Typography>
          <TextField
            placeholder="Terminal Serial Number"
            fullWidth
            inputProps={{ className: 'browser-default' }}
            size="small"
            {...register('terminalSerialNumber')}
            error={Boolean(errors.terminalSerialNumber)}
            helperText={errors.terminalSerialNumber?.message?.toString?.() as string}
          />
        </Box>
        <Box>
          <Typography variant="h5">External ID</Typography>
          <TextField
            placeholder="External ID"
            fullWidth
            inputProps={{ className: 'browser-default' }}
            size="small"
            {...register('externalId')}
            error={Boolean(errors.externalId)}
            helperText={errors.externalId?.message?.toString?.() as string}
          />
        </Box>
        <Box>
          <Typography variant="h5" mb={1}>
            Printer paper width:
          </Typography>
          <FormControl fullWidth error={Boolean(errors.printerPaperWidth)}>
            <Select fullWidth size="small" defaultValue={printerPaperWidth} {...register('printerPaperWidth')}>
              <MenuItem value="80mm">80mm</MenuItem>
              <MenuItem value="58mm">58mm</MenuItem>
            </Select>
            <FormHelperText>{errors.printerPaperWidth?.message as string}</FormHelperText>
          </FormControl>
        </Box>
        <Box>
          <Stack spacing={1}>
            <Typography variant="h5" mb={1}>
              Background image
            </Typography>
            <Stack direction="row" spacing={2} mt={2}>
              {backgroundImage ? (
                <Box
                  component="img"
                  src={backgroundImage}
                  sx={{
                    maxWidth: '70%',
                    width: '100px',
                    height: 'auto',
                    border: '5px solid',
                    borderColor: 'mynt.main',
                    borderRadius: '10px',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '100px',
                    height: '100px',
                    maxWidth: '30vw',
                    maxHeight: '30vw',
                    border: '5px solid',
                    borderColor: 'mynt.main',
                    borderRadius: '10px',
                    bgcolor: 'grey.300',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="button">No image</Typography>
                </Box>
              )}
              {!!!!!backgroundImage ? (
                <Button variant="contained" component="label">
                  Upload
                  <Controller
                    control={control}
                    name={'backgroundImage'}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <input
                          {...field}
                          value={value?.fileName}
                          onChange={(e: any) => {
                            onChange(URL.createObjectURL(e.target.files[0]))
                          }}
                          type="file"
                          hidden
                        />
                      )
                    }}
                  />
                </Button>
              ) : (
                <Button variant="outlined" color="error" onClick={handleImageRemove}>
                  Delete image
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
        <Box>
          <FormControlLabel
            label={
              <span>
                <b>Submenu kiosk </b>
                (used when you want to configure order splitting)
              </span>
            }
            control={<Checkbox checked={isSubMenuKiosk} onChange={handleSubMenuKioskToggle} />}
          />
          <Collapse in={isSubMenuKiosk}>
            <Box pl={2} pt={1} maxWidth="600px">
              <Typography variant="body1" mb={1}>
                Displayed <b>submenus</b>
              </Typography>
              <Controller
                name="menuIds"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    name="colors"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={subMenus?.map((subMenu: any) => ({ value: subMenu.id, label: subMenu.name }))}
                    onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
                    value={field?.value
                      ?.map((subMenuId: string) => subMenus?.find(({ id }: any) => subMenuId === id))
                      ?.filter(Boolean)
                      ?.map((subMenu: any) => ({ value: subMenu?.id, label: subMenu?.name }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />
            </Box>
          </Collapse>
        </Box>
        <Stack spacing={1}>
          <Typography variant="h5">Preferences</Typography>
          <Stack spacing={1}>
            <Controller
              name="nameOrdering"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                  label={
                    <span>
                      <b>Allow customers to input a name </b>
                      in the order review screen
                    </span>
                  }
                />
              )}
            />
            {nameOrdering && (
              <Controller
                name="forcedNameOrdering"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ pl: 2, marginTop: '-10px !important' }}
                    control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                    label={
                      <span>
                        <b>Force </b>
                        customers to input a name in the order review screen
                      </span>
                    }
                  />
                )}
              />
            )}
            <Controller
              name="isRequiredPhoneNumber"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                  label={
                    <span>
                      <b>Require cutomer to input a phone number </b>
                    </span>
                  }
                />
              )}
            />
            <Controller
              name="allowNoteInput"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                  label={
                    <span>
                      <b>Allow customers to add their wishes </b>to order
                    </span>
                  }
                />
              )}
            />
            <Stack spacing={1}>
              <Controller
                name="acceptBarcodes"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                    label={
                      <span>
                        <b>Allow customers to scan barcodes </b>
                        in this kiosk
                      </span>
                    }
                  />
                )}
              />
              {acceptBarcodes && (
                <Controller
                  name="forcedBarcodes"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ pl: 2, marginTop: '-10px !important' }}
                      control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                      label={
                        <span>
                          <b>Force </b>
                          this kiosk to accept only barcodes
                        </span>
                      }
                    />
                  )}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        {isAdmin && (
          <Stack spacing={1}>
            <Typography variant="h5" color="mynt.main">
              ADMIN SETTINGS
            </Typography>
            <Stack spacing={1}>
              <Box>
                <Typography variant="h5" mb={1}>
                  Attached Restaurant
                </Typography>
                <Controller
                  name="restaurantId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      size="small"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.restaurantId)}
                    >
                      {restaurantList?.map((restaurant: any) => (
                        <MenuItem key={restaurant?.id} value={restaurant?.id}>
                          {restaurant?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors.restaurantId?.message?.toString?.()}</FormHelperText>
              </Box>
              <Box>
                <Typography variant="h5" mb={1}>
                  Printing Method:
                </Typography>
                <Controller
                  name="printingMethod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      size="small"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.printingMethod)}
                    >
                      <MenuItem value={PrintingMethods.SUNMI_V2}>sunmi_v2</MenuItem>
                      <MenuItem value={PrintingMethods.BITMAP_V2}>bitmap_v2</MenuItem>
                      <MenuItem value={PrintingMethods.TEC_MOBILE_KOP3X}>tec_mobile_kop3x</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText>{errors.printingMethod?.message?.toString?.()}</FormHelperText>
              </Box>
              <Box>
                <Typography variant="h5">Kiosk Web Endpoint</Typography>
                <TextField
                  placeholder="https://mynt-kiosk.web.app"
                  fullWidth
                  inputProps={{ className: 'browser-default' }}
                  size="small"
                  {...register('webviewEndpoint')}
                  error={Boolean(errors.webviewEndpoint)}
                  helperText={errors.webviewEndpoint?.message?.toString?.() as string}
                />
              </Box>
              <Box>
                <Typography variant="h5">Terminal Id</Typography>

                <TextField
                  placeholder="Terminal Serial Number"
                  fullWidth
                  inputProps={{ className: 'browser-default' }}
                  size="small"
                  {...register('terminalId')}
                  error={Boolean(errors.terminalId)}
                  helperText={errors.terminalId?.message?.toString?.() as string}
                />
              </Box>
              <Box>
                <Typography variant="h5">Coupon scanner webhook</Typography>
                <TextField
                  placeholder="http://172.24.127.41:0000/isEmployee"
                  fullWidth
                  inputProps={{ className: 'browser-default' }}
                  size="small"
                  {...register('couponScannerWebhook')}
                  error={Boolean(errors.couponScannerWebhook)}
                  helperText={errors.couponScannerWebhook?.message?.toString?.() as string}
                />
              </Box>
              <Controller
                name="isBreakLine"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={Boolean(field.value)} onClick={field.onChange} />}
                    label={
                      <span>
                        <b>Should Break Line in Receipt</b>
                      </span>
                    }
                  />
                )}
              />
              <Controller
                name="useManualCut"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={Boolean(field.value)} onClick={field.onChange} />}
                    label={
                      <span>
                        <b>Use manual cut</b>
                      </span>
                    }
                  />
                )}
              />
              <Controller
                name="isCashSupported"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                    label={
                      <span>
                        <b>Enable paying with cash</b> to cashier
                      </span>
                    }
                  />
                )}
              />
              <Controller
                name="isMocking"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                    label={
                      <span>
                        <b>Mock device</b>
                      </span>
                    }
                  />
                )}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}

export default KioskForm
