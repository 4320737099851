import { Card, Stack, Typography, Alert, Button } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getFontNameFromOTF, uploadFile } from '../../../../../../utils'
import { getAuth } from 'firebase/auth'
import { v4 as uuidv4 } from 'uuid'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../../../../atoms/SelectedRestaurantAtom'
import Spinner from '../../../../../../ui/Spinner/Spinner'
import { Buffer } from 'buffer'

type FontCardProps = {
  fontRole: string
  font: string | File
  handleFontChange: (font: string | null) => void
}

function FontCard({ fontRole, handleFontChange, font }: FontCardProps) {
  const [fontDisplayName, setFontDisplayName] = useState<string>('')
  const [isLoading, setLoading] = useState(false)

  const selectedRestaurant = useRecoilValue(selectedRestaurantState)

  const handleFontUpload = async (e: any) => {
    setLoading(true)
    const auth = await getAuth()
    try {
      const file = e.target.files[0]
      let fontUrl = ''

      if (file) {
        const filePath = `/restaurants/${selectedRestaurant.id}/fonts/${uuidv4()}.otf`
        fontUrl = await uploadFile(file, filePath, 'font/otf' as any)
        handleFontChange(fontUrl)
      }
    } catch (error: any) {
      console.log('Error: ', error)
      alert(error.message)
    }
    setLoading(false)
  }
  function loadFont(name: string, url: string): Promise<FontFace> {
    return new Promise((resolve, reject) => {
      const myFont = new FontFace(name, `url(${url})`, {
        style: 'normal',
      })
      myFont
        .load()
        .then(() => {
          resolve(myFont)
        })
        .catch(e => reject(e))
    })
  }

  useEffect(() => {
    if (!font) {
      setFontDisplayName('')
      return
    }
    if (font instanceof File) {
      setFontDisplayName(font.name)
      return
    }
    ;(async () => {
      const response = await axios.get(font, { responseType: 'arraybuffer' })
      const buffer = Buffer.from(response.data, 'utf-8')
      const name = await getFontNameFromOTF(buffer)
      setFontDisplayName(name)

      loadFont(name, font).then(font => {
        font.load().then(loadedFont => {
          document.fonts.add(loadedFont)
        })
      })
    })()
  }, [font])

  return (
    <Stack
      spacing={2}
      p={2}
      direction="row"
      justifyContent="space-between"
      alignItems={'center'}
      flexWrap={'wrap'}
      useFlexGap
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="subtitle1">{fontRole}:</Typography>
        {font ? (
          <code style={{ fontFamily: fontDisplayName, fontSize: '1.2em' }}>{fontDisplayName}</code>
        ) : (
          <Alert severity="info">No font selected</Alert>
        )}
        {isLoading && <Spinner />}
      </Stack>
      <Stack spacing={2} direction="row">
        {font ? (
          <Button variant="contained" color="error" onClick={() => handleFontChange(null)} disabled={!Boolean(font)}>
            Remove font
          </Button>
        ) : (
          <Button variant="contained" component="label">
            File
            <input type="file" accept=".otf" onChange={handleFontUpload} hidden />
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default FontCard
