import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMenuContext } from '../../contexts/MenuContext'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext'
import { SubMenuIdToName } from '../../views/devices/SubMenuIdToName'
import { PrintingMethods } from '../../types/Printer'
import MultiSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { useRecoilValue } from 'recoil'
import selectedRestaurantSubMenusState from '../../selectors/selectedRestaurantSubmenus'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { AVAILABLE_LANGUAGES } from '../../constants'
import getLanguageName from '../../utils/getLanguageName'
import { RECOMMENDED_PRINTER_PRINTING_METHODS } from '../../utils/constants'
import { restaurantListState } from '../../atoms/RestaurantListAtom'

const animatedComponents = makeAnimated()

const AutoAcceptOptions = [
  {
    name: 'Never',
    value: -1,
  },
  {
    name: 'Immediately',
    value: 0,
  },
  {
    name: 'In 5 minutes',
    value: 5 * 60,
  },
  {
    name: 'In 20 minutes',
    value: 20 * 60,
  },
]

type PrinterFormProps = {
  variant?: 'default' | 'onboarding'
}

function PrinterForm({ variant }: PrinterFormProps) {
  const { t } = useTranslation()
  // @ts-ignore
  const { allSubMenus } = useMenuContext()
  const { isAdmin } = useAuthContext()
  const subMenus = useRecoilValue(selectedRestaurantSubMenusState)
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const restaurantList = useRecoilValue(restaurantListState)

  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext()

  const watchedValues = useWatch({
    control,
  })
  const { printerPaperWidth, menuIds, autoAcceptOrderInSeconds, printingMethod, receiptsLanguage } = watchedValues
  const [isSubMenuPrinter, setIsSubMenuPrinter] = useState(Boolean(menuIds?.length))

  const handleSubMenuPrinterToggle = (e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    if (!checked) {
      setValue('menuIds', [])
      setValue('showOtherSubmenuItems', false)
    } else {
      setValue('showOtherSubmenuItems', true)
    }
    setIsSubMenuPrinter(checked)
  }

  const handleModelChange = (e: any, nextHandler: Function) => {
    const model = (e?.target as HTMLInputElement).value
    switch (model) {
      case 'V2':
        setValue('printingMethod', RECOMMENDED_PRINTER_PRINTING_METHODS.V2)
        break
      case 'T2':
        setValue('printingMethod', RECOMMENDED_PRINTER_PRINTING_METHODS.T2)
        break
      case 'TOSHIBA_LABEL_PRINTER':
        setValue('printingMethod', RECOMMENDED_PRINTER_PRINTING_METHODS.TOSHIBA_LABEL_PRINTER)
        break
      case 'EXTERNAL_TOSHIBA_LABEL_PRINTER':
        setValue('printingMethod', RECOMMENDED_PRINTER_PRINTING_METHODS.EXTERNAL_TOSHIBA_LABEL_PRINTER)
        break
    }
    nextHandler(e)
  }

  useEffect(() => {
    if (!receiptsLanguage) setValue('receiptsLanguage', 'en')
  }, [selectedRestaurant])

  console.log('printer form', watchedValues)

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h5">Printer name*</Typography>
        <Typography variant="subtitle2" mb={1}>
          For example: Kitchen Printer 1, Drinks Printer, etc...
        </Typography>
        <TextField
          placeholder="Name"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('name')}
          error={Boolean(errors.name)}
          helperText={errors.name?.message as string}
        />
      </Box>
      <Box>
        <Typography variant="h5">Printer model*</Typography>
        <Typography variant="subtitle2" mb={1}>
          We will choose the best printing method for your printer based on the model.
        </Typography>
        <Controller
          name="model"
          control={control}
          render={({ field }) => (
            <Select
              fullWidth
              size="small"
              {...field}
              value={field.value || ''}
              onChange={e => handleModelChange(e, field.onChange)}
              error={Boolean(errors.model?.message)}
            >
              <MenuItem value="V2">V2</MenuItem>
              <MenuItem value="T2">T2</MenuItem>
              <MenuItem value="TOSHIBA_LABEL_PRINTER">Toshiba label printer</MenuItem>
              <MenuItem value="EXTERNAL_TOSHIBA_LABEL_PRINTER">External Toshiba label printer</MenuItem>
            </Select>
          )}
        />
        <FormHelperText error={Boolean(errors.model?.message)}>{errors.model?.message as string}</FormHelperText>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          Serial Number*
        </Typography>
        {/* <Typography variant="subtitle2" mb={1}>

        </Typography> */}
        <TextField
          placeholder="Serial Number"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('serialNumber')}
          error={Boolean(errors.serialNumber)}
          helperText={errors.serialNumber?.message as string}
        />
      </Box>
      <Box>
        <Typography variant="h5">Staff PinCode for Refund*</Typography>
        <Typography variant="subtitle2" mb={1}>
          Enter 4 digits.
        </Typography>
        <TextField
          placeholder="Your pin code"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          size="small"
          {...register('staffPinCode')}
          error={Boolean(errors.staffPinCode)}
          helperText={errors.staffPinCode?.message as string}
        />
      </Box>

      <Box>
        <Typography variant="h5" mb={1}>
          Auto accept orders in:
        </Typography>
        <FormControl fullWidth error={Boolean(errors.autoAcceptOrderInSeconds)}>
          <Select
            fullWidth
            size="small"
            defaultValue={autoAcceptOrderInSeconds}
            {...register('autoAcceptOrderInSeconds')}
          >
            {AutoAcceptOptions.map((setting, index) => (
              <MenuItem key={index} value={setting.value}>
                {setting.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{JSON.stringify(errors.autoAcceptOrderInSeconds)}</FormHelperText>
        </FormControl>
      </Box>

      <Box>
        <Typography variant="h5" mb={1}>
          Language on the receipts
        </Typography>
        <Controller
          name="receiptsLanguage"
          control={control}
          render={({ field }) => (
            <Select fullWidth size="small" {...field} error={Boolean(errors?.receiptsLanguage)}>
              {AVAILABLE_LANGUAGES.map((lng, index) => (
                <MenuItem key={index} value={lng}>
                  {getLanguageName(lng)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {/* <FormControl fullWidth error={Boolean(errors.receiptsLanguage)}>
          <Select fullWidth size="small" defaultValue={receiptsLanguage} {...register('receiptsLanguage')}>
            {AVAILABLE_LANGUAGES.map((lng, index) => (
              <MenuItem key={index} value={lng}>
                {getLanguageName(lng)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{JSON.stringify(errors.receiptsLanguage)}</FormHelperText>
        </FormControl> */}
      </Box>
      <Box>
        <FormControlLabel
          label={
            <span>
              <b>Submenu printer </b>
              (used when you want to split order to different printers)
            </span>
          }
          control={<Checkbox checked={isSubMenuPrinter} onChange={handleSubMenuPrinterToggle} />}
        />
        <Collapse in={isSubMenuPrinter}>
          <Stack pl={2} pt={1} maxWidth="600px">
            <Box>
              <Typography variant="body1" mb={1}>
                Displayed <b>submenus</b>
              </Typography>
              <Controller
                name="menuIds"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    name="colors"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={subMenus?.map((subMenu: any) => ({ value: subMenu.id, label: subMenu.name }))}
                    onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
                    value={field?.value
                      ?.map((subMenuId: string) => subMenus?.find(({ id }: any) => subMenuId === id))
                      ?.filter(Boolean)
                      ?.map((subMenu: any) => ({ value: subMenu?.id, label: subMenu?.name }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />
            </Box>
            <Controller
              name="showOtherSubmenuItems"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                  label={
                    <span>
                      <b>Show other submenu items in printer</b>
                    </span>
                  }
                />
              )}
            />
          </Stack>
        </Collapse>
      </Box>
      <Stack spacing={1}>
        <Typography variant="h5">Preferences</Typography>
        <Stack spacing={1}>
          <Controller
            name="shouldAutoPrintOrders"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Auto print orders </b>
                  </span>
                }
              />
            )}
          />
          <Controller
            name="disableCustomerReceipt"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Only Print Kitchen Receipt </b>
                  </span>
                }
              />
            )}
          />
          <Controller
            name="skipDeliveredWhenMarkedReady"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Mark order as "Delivered", when marked as "Ready"</b>
                  </span>
                }
              />
            )}
          />
          <Controller
            name="isAggressiveNotiEnabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Enable Agressive notifications </b>- Device will ring until you tap the screen
                  </span>
                }
              />
            )}
          />
          <Controller
            name="isCashierPartnerOnly"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox checked={Boolean(field.value)} onChange={field.onChange} />}
                label={
                  <span>
                    <b>Use as a printer to partner pos </b>
                  </span>
                }
              />
            )}
          />
        </Stack>
      </Stack>
      {isAdmin && (
        <Stack spacing={1}>
          <Typography variant="h5" color="mynt.main">
            ADMIN SETTINGS
          </Typography>
          <Stack spacing={1}>
            <Box>
              <Typography variant="h5" mb={1}>
                Attached Restaurant
              </Typography>
              <Controller
                name="restaurantId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select size="small" fullWidth value={value} onChange={onChange} error={Boolean(errors.restaurantId)}>
                    {restaurantList?.map((restaurant: any) => (
                      <MenuItem key={restaurant?.id} value={restaurant?.id}>
                        {restaurant?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.restaurantId?.message?.toString?.()}</FormHelperText>
            </Box>
            <Box>
              <Typography variant="h5" mb={1}>
                Printing Method:
              </Typography>
              <Controller
                name="printingMethod"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select fullWidth size="small" value={value} onChange={onChange}>
                    <MenuItem value={PrintingMethods.SUNMI_V2}>sunmi v2</MenuItem>
                    <MenuItem value={PrintingMethods.TPCL}>tpcl</MenuItem>
                    <MenuItem value={PrintingMethods.TEC_MOBILE_HSP100}>hsp100</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={Boolean(errors.printingMethod?.message)}>
                {errors.printingMethod?.message?.toString?.()}
              </FormHelperText>
            </Box>
            <Box>
              <Typography variant="h5" mb={1}>
                External ID
              </Typography>
              <TextField
                placeholder="External ID"
                fullWidth
                inputProps={{ className: 'browser-default' }}
                size="small"
                {...register('externalId')}
                error={Boolean(errors.externalId)}
                helperText={errors.externalId?.message as string}
              />
            </Box>

            {printingMethod === PrintingMethods.TPCL && (
              <>
                <Box>
                  <Typography variant="h5">IP</Typography>
                  <TextField
                    placeholder="Printer ip"
                    fullWidth
                    inputProps={{ className: 'browser-default' }}
                    size="small"
                    {...register('ip')}
                    error={Boolean(errors.ip)}
                    helperText={errors.ip?.message as string}
                  />
                </Box>
                <Box>
                  <Typography variant="h5">Port</Typography>
                  <TextField
                    placeholder="Printer port"
                    fullWidth
                    inputProps={{ className: 'browser-default' }}
                    size="small"
                    {...register('port')}
                    error={Boolean(errors.port)}
                    helperText={errors.port?.message as string}
                  />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default PrinterForm
