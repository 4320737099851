import { selector } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'
import { devicesListState } from '../atoms/DevicesAtoms'

const selectedRestaurantDevicesState = selector({
  key: 'selectedRestaurantDevices',
  get: ({ get }) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const devices = get(devicesListState)
    const restaurantDevices = devices?.filter((device: any) => device?.restaurantId === selectedRestaurant?.id)
    return restaurantDevices
  },
  set: ({ get, set }, newDevices) => {
    const devices = get(devicesListState)
    const selectedRestaurant = get(selectedRestaurantState)

    // Checking if devices were deleted
    const filteredDevices = devices.filter((device: any) => {
      if (device.restaurantId !== selectedRestaurant.id) return true
      if (newDevices.find((newDevice: any) => newDevice.id === device.id)) return true
      return false
    })

    // Checking if devices were modified
    const mappedDevices = filteredDevices.map((device: any) => {
      if (device.restaurantId !== selectedRestaurant.id) return device
      const index = newDevices.findIndex((newDevice: any) => newDevice.id === device.id)
      if (index !== -1) {
        return newDevices[index]
      }
      return device
    })

    // Checking if devices were added
    newDevices.forEach((newDevice: any) => {
      if (
        newDevice.restaurantId === selectedRestaurant.id &&
        !mappedDevices.find((device: any) => device.id === newDevice.id)
      ) {
        mappedDevices.push(newDevice)
      }
    })

    set(devicesListState, mappedDevices)
  },
})

export default selectedRestaurantDevicesState
