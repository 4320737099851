export const Fish1 = require('./001-fish.svg').default
export const Soup1 = require('./002-soup.svg').default
export const Steak1 = require('./003-steak.svg').default
export const Salad1 = require('./004-salad.svg').default
export const Noodles1 = require('./005-noodles.svg').default
export const Sushi1 = require('./006-sushi.svg').default
export const Sandwich1 = require('./007-sandwich.svg').default
export const Taco1 = require('./008-taco.svg').default
export const Chicken1 = require('./009-chicken.svg').default
export const Chicken2 = require('./010-chicken-1.svg').default
export const DonerKebab1 = require('./011-doner-kebab.svg').default
export const FrenchFries1 = require('./012-french-fries.svg').default
export const Breakfast1 = require('./013-breakfast.svg').default
export const WaterGlass1 = require('./014-water-glass.svg').default
export const Pasta1 = require('./015-pasta.svg').default
export const WineGlass1 = require('./016-wine-glass.svg').default
export const Beer1 = require('./017-beer.svg').default
export const Favorite1 = require('./018-favorite.svg').default
export const Premium1 = require('./019-premium-quality.svg').default
export const Burger1 = require('./020-cheeseburger.svg').default
export const Cupcake1 = require('./021-cupcake.svg').default
export const IceCream1 = require('./022-ice-cream-cup.svg').default
export const SodaCan1 = require('./023-can.svg').default
export const Pizza1 = require('./024-pizza.svg').default
export const Kofta1 = require('./025-malai-kofta.svg').default
export const Bowl1 = require('./026-bowl.svg').default
export const Curry1 = require('./027-curry.svg').default
export const Cake1 = require('./028-cake.svg').default
export const Cake2 = require('./029-cake-1.svg').default
export const Pie1 = require('./030-pie.svg').default
export const Dumpling1 = require('./031-dumpling.svg').default
export const Gyoza1 = require('./032-gyoza.svg').default
export const Burrito1 = require('./033-burritos.svg').default
export const Nacho1 = require('./034-nachos.svg').default
export const Coffee1 = require('./035-coffee.svg').default
export const Coffee2 = require('./036-coffee-beans.svg').default
export const PadThai1 = require('./037-pad-thai.svg').default
export const Pig1 = require('./038-pig.svg').default
export const Bacon1 = require('./039-bacon.svg').default
export const Ribs1 = require('./040-ribs.svg').default
export const Bread1 = require('./041-bread.svg').default
export const Bread2 = require('./042-bread-1.svg').default
export const Cookies1 = require('./043-cookies.svg').default

export const allIcons = [
  Fish1,
  Soup1,
  Steak1,
  Salad1,
  Noodles1,
  Sushi1,
  Sandwich1,
  Taco1,
  Chicken1,
  Chicken2,
  DonerKebab1,
  FrenchFries1,
  Breakfast1,
  WaterGlass1,
  Pasta1,
  WineGlass1,
  Beer1,
  Favorite1,
  Premium1,
  Burger1,
  Cupcake1,
  IceCream1,
  SodaCan1,
  Pizza1,
  Kofta1,
  Bowl1,
  Curry1,
  Cake1,
  Cake2,
  Pie1,
  Dumpling1,
  Gyoza1,
  Burrito1,
  Nacho1,
  Coffee1,
  Coffee2,
  PadThai1,
  Pig1,
  Bacon1,
  Ribs1,
  Bread1,
  Bread2,
  Cookies1,
]
