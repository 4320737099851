import React, { useState, useEffect } from 'react'
import MultiMenuTableRow from './MultiMenuTableRow'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import selectedRestaurantSubMenusState from '../../../selectors/selectedRestaurantSubmenus'
import selectedRestaurantMenuState from '../../../selectors/selectedRestaurantMenu'
import TableBodySkeleton from '../../../components/TableBodySkeleton/TableBodySkeleton'

function MultiMenuTable() {
  const [subMenus, setSubMenus] = useRecoilState(selectedRestaurantSubMenusState)
  const menuItems = useRecoilValue(selectedRestaurantMenuState)

  const toggleItemInSubmenu = (itemID: any, subMenuId: any) => {
    const clonedSubMenus = JSON.parse(JSON.stringify(subMenus))

    const targetSubmenu = clonedSubMenus.find((e: any) => e.id == subMenuId)

    if (targetSubmenu?.visibleItemIds?.find((id: any) => id == itemID)) {
      targetSubmenu.visibleItemIds = targetSubmenu.visibleItemIds.filter((id: any) => id != itemID)
    } else {
      targetSubmenu.visibleItemIds.push(itemID)
    }

    const newSubmenus = clonedSubMenus.map((submenu: any) => {
      if (submenu.id == subMenuId) {
        return targetSubmenu
      }
      return submenu
    })
    setSubMenus(newSubmenus)
  }

  return (
    <TableContainer>
      <Table className="multiMenuTable">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell
              className="tooltipped"
              data-position="top"
              data-tooltip="This shows the full product list, you can seperate the products into smaller menus by creating a submenu above and ticking the boxes of the product to be displayed on each submenu"
            >
              Product List (?)
            </TableCell>
            {subMenus?.map((subMenu: any) => (
              <th key={subMenu.id}>{subMenu.name}</th>
            ))}
          </TableRow>
        </TableHead>
        {!menuItems && !subMenus ? (
          <TableBodySkeleton rowsNum={10} columnsNum={5} />
        ) : (
          <TableBody>
            {menuItems &&
              menuItems.map((menuItem: any, index: number) => (
                <MultiMenuTableRow
                  key={menuItem.id}
                  item={menuItem}
                  index={index}
                  mainMenu={true}
                  subMenus={subMenus}
                  handleSubmenuChange={toggleItemInSubmenu}
                />
              ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default MultiMenuTable
