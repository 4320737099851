import { useEffect, useState } from 'react'
import Toggelable from '../../../components/Toggleable/Toggleable'
import { AVAILABLE_LANGUAGES } from '../../../constants'
import { Stack, Button } from '@mui/material'
import { Controller, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'
import getLanguageName from '../../../utils/getLanguageName'

const REQUIRED_LANGUAGES = ['en']

const formatDepricatedLanguages = (languages: any) => {
  // Swedish language code was wrong
  // So have to modify it manualy
  if (Object.keys(languages).includes('SE')) {
    languages['SV'] = languages['SE']
    delete languages['SE']
  }

  const langArr = Object.keys(languages).filter(key => Boolean(languages[key]))
  return langArr.map(lang => lang.toLowerCase())
}

function Localisation() {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const { control, setValue, getValues, reset, handleSubmit } = useForm({ defaultValues: selectedRestaurant })
  const [isSaving, setSaving] = useState(false)

  const { config } = useWatch({ control })

  const onSubmit = async (data: any) => {
    console.log(data)
    setSaving(true)
    try {
      await saveRestaurantInfoData(getValues())
      setSelectedRestaurant(getValues())
    } catch (error: any) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  useEffect(() => {
    reset(selectedRestaurant)

    const existingLanguages = getValues('config.languagesSupported')
    const depricatedExistingLanguages = getValues('languagesSupported')

    const supportedLanguages =
      existingLanguages ||
      (depricatedExistingLanguages && formatDepricatedLanguages(depricatedExistingLanguages)) ||
      REQUIRED_LANGUAGES

    setValue('config.languagesSupported', supportedLanguages)
  }, [selectedRestaurant])

  useEffect(() => {
    REQUIRED_LANGUAGES.forEach(reqLang => {
      if (!config?.languagesSupported?.includes(reqLang)) {
        if (Array.isArray(config.languagesSupported))
          setValue('config.languagesSupported', [...config.languagesSupported, reqLang])
      }
    })
  }, [selectedRestaurant, config.languagesSupported])

  return (
    <Stack spacing={4} minHeight="100%" justifyContent="space-between">
      <div className="supported-languages">
        <h5>Supported languages</h5>
        <p>In here you can control what languages should appear in your Kiosks and QR code selections</p>
        <p>
          <b>English</b> is currently mandatory{' '}
        </p>

        <div className="tab">
          <Controller
            name="config.languagesSupported"
            control={control}
            render={({ field }) => (
              <>
                {AVAILABLE_LANGUAGES.map((language, index) => (
                  <div className="m-t-15" key={language}>
                    <label>
                      <input
                        type="checkbox"
                        checked={field?.value?.includes(language)}
                        onChange={e => {
                          const updatedItems = e.target.checked
                            ? [...field.value, language]
                            : field.value.filter((item: any) => item !== language)
                          field.onChange(updatedItems)
                        }}
                        disabled={REQUIRED_LANGUAGES.includes(language) && field?.value?.includes(language)}
                      />
                      <span>{getLanguageName(language)}</span>
                    </label>
                  </div>
                ))}
              </>
            )}
          />
        </div>
      </div>
      <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)} disabled={isSaving}>
        Save
      </Button>
    </Stack>
  )
}

export default Localisation
