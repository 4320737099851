import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getRestaurantInfoById, saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import { getAuth } from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useAuthContext } from '../../contexts/AuthContext'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import GenericLoadingComponent from '../../components/loadingComponents/GenericLoadingComponent'
import { convertToJPEG, uploadFileJPEG } from '../../utils'
import Localisation from './components/Localisation'
import General from './components/General'

function RestaurantConfigView() {
  const { id } = useParams()
  const { loggedIn } = useAuthContext()
  const [isLoaded, setLoaded] = useState(false)
  const [restaurantData, setRestaurantData] = useState({})
  const { t } = useTranslation()

  const [image, setImage] = useState()

  console.log(id)

  useEffect(() => {
    if (!loggedIn) return
    getRestaurantInfoById(id)
      .then(({ data }) => {
        setRestaurantData(data)
        setLoaded(true)
      })
      .catch(err => console.log(err))
  }, [loggedIn])

  const handleChangeImage = async event => {
    const file = event.target.files[0]
    setImage(file)
  }

  const saveRestaurantData = useCallback(
    async e => {
      e.preventDefault()
      try {
        setLoaded(false)
        const auth = await getAuth()
        const uid = auth.currentUser.uid
        let imageUrl = null
        if (image) {
          const filePath = `/users/${uid}/restaurants/${id}/images/${uuidv4()}.jpeg`
          imageUrl = await uploadFileJPEG(image, filePath)
          const imageInJPEG = await convertToJPEG(image)
        }
        console.log(restaurantData)
        const response = await saveRestaurantInfoData({
          ...restaurantData,
          image: imageUrl ? imageUrl : restaurantData.image,
        })
        console.log(response)
        setRestaurantData(response.data)
      } catch (error) {
        alert(error.message)
      } finally {
        // refresh restaurant info
        setLoaded(true)
      }
    },
    [image, id, restaurantData]
  )

  console.log(restaurantData)

  return (
    <GenericLoadingComponent display={isLoaded}>
      <main>
        <div className="container restaurant-configuration">
          <h3>Restaurant Configuration</h3>
          <General restaurantData={restaurantData} setRestaurantData={setRestaurantData} />
          <Localisation restaurantData={restaurantData} setRestaurantData={setRestaurantData} />

          <div className="edit-box m-b-30">
            <a href="#!" className="modal-close btn" onClick={saveRestaurantData}>
              {t('general.SaveButton')}
            </a>
          </div>
        </div>
      </main>
    </GenericLoadingComponent>
  )
}

export default RestaurantConfigView
