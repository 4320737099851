import {
  DialogContent,
  Typography,
  Button,
  Stack,
  ButtonBase,
  Card,
  Checkbox,
  DialogActions,
  Dialog,
  Alert,
  Select,
  MenuItem,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { setMenuListApi } from '../../api/restaurantListApi'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import theme from '../../theme'
import Localized from '../../ui/Localized/Localized'
import { useRequestStatus } from '../../hooks/useRequestStatus'
import { deepIdReplace } from '../../utils/common'
import { restaurantListState } from '../../atoms/RestaurantListAtom'

type MenuImportFromRestaurantProps = {
  open?: boolean
  onClose?: () => void
}

function MenuImportFromRestaurant({ open = false, onClose }: MenuImportFromRestaurantProps) {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const restaurantList = useRecoilValue(restaurantListState)
  const [sourceRestaurantId, setSourceRestaurantId] = useState<any>('')
  const { makeApiRequest, isFetching } = useRequestStatus()

  const handleMenuCopy = async () => {
    if (!sourceRestaurantId) return
    const sourceRestaurant = restaurantList?.find((restaurant: any) => restaurant.id === sourceRestaurantId)
    const newMenu = [
      ...sourceRestaurant?.menu?.map((item: any) => {
        return {
          ...deepIdReplace(item),
        }
      }),
    ]
    console.log('sourceRestaurant menu', sourceRestaurant?.menu)
    console.log('newMenu', newMenu)
    const { isSuccess, resp } = await makeApiRequest(setMenuListApi, [{ id: selectedRestaurant?.id, menu: newMenu }])
    console.log(resp)
    if (isSuccess) {
      setSelectedRestaurant({
        ...selectedRestaurant,
        menu: newMenu,
      })
      onClose?.()
    }
  }

  const sourceRestaurant = restaurantList?.find((restaurant: any) => restaurant.id === sourceRestaurantId)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h6">Import menu</Typography>
        <Stack spacing={2}>
          <Alert severity="warning">
            The entire menu of <b>{selectedRestaurant?.name}</b> will be replaced
          </Alert>

          <Stack>
            <Typography variant="subtitle1">Please select Restaurant to import from: </Typography>
            <Select
              onChange={e => setSourceRestaurantId(e?.target?.value)}
              value={sourceRestaurantId}
              renderValue={(value: any) => restaurantList?.find((restaurant: any) => restaurant.id === value)?.name}
              fullWidth
            >
              {restaurantList?.map((restaurant: any) => (
                <MenuItem value={restaurant.id} key={restaurant?.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>

        <Stack spacing={4} mt={3}>
          {sourceRestaurant?.menu?.map((item: any, index: number) => (
            <Card
              sx={{
                p: 2,
                position: 'relative',
                width: '500px',
                maxWidth: '100%',
                backgroundColor: item?.type === 'editableText' ? theme.palette.grey[200] : null,
              }}
              key={index}
            >
              <Stack spacing={1} key={index} alignItems="flex-start">
                <Stack spacing={0}>
                  <Typography variant="h6">
                    <Localized target={item} translationsKey="title" />
                  </Typography>
                  <Typography variant="h6">
                    <Localized target={item} translationsKey="description" />
                  </Typography>
                </Stack>
                <Typography variant="subtitle1">{item?.price}</Typography>
                <Typography variant="subtitle1">{item?.itemType}</Typography>
              </Stack>
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleMenuCopy} disabled={!Boolean(sourceRestaurant) || isFetching}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MenuImportFromRestaurant
