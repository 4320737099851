import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material'
import { FormProvider, set, useForm, useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'
import TranslationsTable from '../../ui/TranslationsTable/TranslationsTable'
import { Category } from '../../types'

type CategoryFormProps = {
  restaurant: any
}

function CategoryForm({ restaurant }: CategoryFormProps) {
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext<Category>()

  // Picking deprecated values
  useEffect(() => {
    const values = getValues()
    console.log('values', values)

    if (!values?.translations?.title?.en && values.title) {
      setValue('translations.title.en', values.title)
    }
    if (!values?.translations?.title?.fi && values.titleFI) {
      setValue('translations.title.fi', values.titleFI)
    }
    if (!values?.translations?.title?.sv && values.titleSE) {
      setValue('translations.title.sv', values.titleSE)
    }

    if (!values?.translations?.description?.en && values.description) {
      setValue('translations.description.en', values.description)
    }
    if (!values?.translations?.description?.fi && values.descriptionFI) {
      setValue('translations.description.fi', values.descriptionFI)
    }
    if (!values?.translations?.description?.sv && values.descriptionSE) {
      setValue('translations.description.sv', values.descriptionSE)
    }
  }, [])

  return (
    <Stack spacing={2}>
      <TranslationsTable restaurant={restaurant} formBase="translations.title" title="Title" />
      <TranslationsTable restaurant={restaurant} formBase="translations.description" title="Description" optional />
    </Stack>
  )
}

export default CategoryForm
