import {
  Alert,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { AxisOptions, Chart } from 'react-charts'
import WarningIcon from '@mui/icons-material/Warning'
import theme from '../../theme'
import MainLineChart from './components/MainLineChart'
import Orders from './components/Orders'
import { getOrders, getRestaurantInfo } from '../../api/restaurantInfoApi'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAuthContext } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { restaurantListState } from '../../atoms/RestaurantListAtom'
import OpeningAlert from './components/OpeningAlert'

const fullSize = { height: '100%', width: '100%' }

function Dashboard() {
  const [orders, setOrders] = useState<any[]>([])
  const { user, isAdmin } = useAuthContext()
  const restaurantList = useRecoilValue(restaurantListState)

  // TODO: not sure what this is, but polling hurt
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getOrders({ limit: 20, restaurantIds: [] }).then(orders => console.log(orders))
  //     getRestaurantInfo().then(res => {
  //       console.log(res)
  //     })
  //   }, 5000)
  //   return () => clearInterval(interval)
  // }, [])

  useEffect(() => {
    if (!user) return
    ;(async () => {
      const queryRestaurantIds: string[] = []
      if (isAdmin) {
        const { data } = await getRestaurantInfo()
        data?.forEach((restaurant: any) => {
          queryRestaurantIds.push(restaurant.id)
        })
      } else {
        user?.owningRestaurantIds?.forEach((restaurantId: string) => {
          queryRestaurantIds.push(restaurantId)
        })
      }
      getOrders({ restaurantIds: queryRestaurantIds, limit: 20 }).then(({ data }) => {
        console.log(data)
        setOrders(data)
      })
    })()
  }, [user])

  useEffect(() => {
    console.log('restaurantList update', restaurantList)
  }, [restaurantList])

  console.log('orders', orders)

  return (
    <Stack sx={fullSize}>
      <OpeningAlert />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <MainLineChart />
        </Grid>
        <Grid item md={4} xs={12}>
          <Paper sx={{ height: '200px', overflow: 'scroll', p: 2 }} elevation={0}>
            <Stack sx={fullSize} spacing={2}>
              <Stack spacing={1} direction="row">
                <WarningIcon color="warning" />
                <Typography variant="h5">Check devices!</Typography>
              </Stack>
              <Table>
                <TableHead>
                  <TableCell>Device</TableCell>
                  <TableCell>Last active</TableCell>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <a href="#">Some device 1</a>
                    </TableCell>
                    <TableCell>2d 14h 45m ago</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <a href="#">Some device 2</a>
                    </TableCell>
                    <TableCell>7d 1h 17m ago</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Orders />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Dashboard
