import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { getRestaurantInfo, saveRestaurantInfoData } from '../api/restaurantInfoApi'
import { useAuthContext } from './AuthContext'
import { useRecoilState } from 'recoil'
import { restaurantListState } from '../atoms/RestaurantListAtom'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'

export const MenuContext = createContext({})

/**
 * This context will in the future handle all the menu data to simplify rendering
 * For starters it will be used in multi menu view
 * @param {*} props
 * @returns
 */
export function MenuContextProvider(props) {
  const [restaurantList, setRestaurantList] = useRecoilState(restaurantListState)
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const selectedRestaurantId = selectedRestaurant?.id
  const setSelectedRestaurantId = id => {
    const restaurant = restaurantList.find(r => r.id === id)
    setSelectedRestaurant(restaurant)
  }

  const allRestaurantData = restaurantList
  const setAllRestaurantData = setRestaurantList

  // const [allRestaurantData, setAllRestaurantData] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  // const [selectedRestaurantId, setSelectedRestaurantId] = useState(0)
  // const selectedRestaurant = useMemo(() => {
  //   return allRestaurantData?.find(r => r.id === selectedRestaurantId) || allRestaurantData?.[0]
  // }, [selectedRestaurantId, allRestaurantData])
  const [addingSubMenu, setAddingSubMenu] = useState(false)

  const [allSubMenus, setAllSubMenus] = useState([])

  const { loggedIn, user, isAdmin } = useAuthContext()

  // Refresh data
  let refreshData = () => {
    if (!loggedIn) {
      return
    }
    getRestaurantInfo().then(restaurantDataJson => {
      if (!(restaurantDataJson instanceof Error)) {
        setAllRestaurantData(restaurantDataJson?.data || [])
        setLoaded(true)
      } else {
        setLoadingError(true)
      }
    })
  }

  let returnMenu = () => {
    if (loaded && !loadingError) {
      return selectedRestaurant?.menu
    }
  }

  let addSubMenu = newSubMenu => {
    setAddingSubMenu(true)
    console.log('Adding submenu: ', newSubMenu)
    saveRestaurantInfoData({
      id: selectedRestaurant.id,
      subMenus: [...allSubMenus, newSubMenu],
    })
      .then(() => {
        setAddingSubMenu(false)
        setAllSubMenus([...allSubMenus, newSubMenu])
      })
      .catch(e => {
        //error
        setAddingSubMenu(false)
        console.log(e)
      })
  }

  const removeSubMenu = index => {
    const newSubMenus = [...allSubMenus]
    const spliced = newSubMenus.splice(index, 1)
    console.log('Removing submenu: ', spliced)
    saveRestaurantInfoData({
      id: selectedRestaurant.id,
      subMenus: newSubMenus,
    }).then(result => {
      setAllSubMenus(result.data.subMenus)
    })
  }

  const editSubMenu = (newSubmenu, index) => {
    const newSubMenus = [...allSubMenus]
    newSubMenus[index] = newSubmenu
    setSaving(true)
    saveRestaurantInfoData({
      id: selectedRestaurant.id,
      subMenus: newSubMenus,
    }).then(() => {
      setSaving(false)
      setAllSubMenus(newSubMenus)
    })
  }

  const toggleItemInSubmenu = (itemID, submenuID) => {
    let submenuIndex = allSubMenus.findIndex(e => e.id == submenuID)

    if (allSubMenus[submenuIndex].visibleItemIds == null) {
      allSubMenus[submenuIndex].visibleItemIds = []
    }

    let itemIndex = allSubMenus[submenuIndex].visibleItemIds.findIndex(e => e == itemID)
    let newSubmenu = JSON.parse(JSON.stringify(allSubMenus[submenuIndex]))
    console.log(newSubmenu)
    if (itemIndex > -1) {
      // Remove item
      newSubmenu.visibleItemIds.splice(itemIndex, 1)
    } else {
      // Add item
      newSubmenu.visibleItemIds.push(itemID)
    }
    editSubMenu(newSubmenu, submenuIndex)
  }

  // useEffect(() => {
  //   refreshData()
  // }, [loggedIn])

  useEffect(() => {
    setAllSubMenus(selectedRestaurant?.subMenus || [])
  }, [selectedRestaurant])

  return (
    <MenuContext.Provider
      value={{
        allRestaurantData,
        loaded,
        loadingError,
        refreshData,
        selectedRestaurant,
        selectedRestaurantId,
        setSelectedRestaurantId,
        returnMenu,
        allSubMenus,
        editSubMenu,
        addSubMenu,
        removeSubMenu,
        toggleItemInSubmenu,
        saving,
        addingSubMenu,
      }}
      {...props}
    />
  )
}

export function useMenuContext() {
  return useContext(MenuContext)
}
