import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'throttle-debounce'
import { useTranslation } from 'react-i18next'
import { createNotificationManager } from 'simple-vanilla-notifications'
import M from 'materialize-css'
import MenuItem from '../menuItem/MenuItem'
import EditableText from '../../../components/editables/EditableText/EditableText'
import {
  getRestaurantList,
  setMenuListApi,
  getMenuListApi,
  deleteMenuItemApi,
  updateMenuItemApi,
  swapMenuItems,
} from '../../../api/restaurantListApi'
import { useAuthContext } from '../../../contexts/AuthContext'
import { use } from 'i18next'
import ArrayMove from '../../../utils/arrayMove'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MenuSelectProvider } from '../../../contexts/MenuSelectContext'
import MenuItemStaff from '../menuItemStaff/MenuItemStaff'
import { toggleCloseRestaurant } from '../../../api/restaurantInfoApi'
import { csvToMenuArray } from '../../../utils/CSVFunctions'
import lodash from 'lodash'
import GenericLoadingComponent from '../../../components/loadingComponents/GenericLoadingComponent'
import Loading from '../../../components/loadingComponents/Loading'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import MenuTitle from '../menuTitle/MenuTitle'
// import EditMenuItem from '../menuItem/EditMenuItem/EditMenuItem'
// import EditMenuTitle from '../menuTitle/EditMenuTitle/EditMenuTitle'
import { Box, Button, Grid, Stack } from '@mui/material'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import selectedRestaurantMenuState from '../../../selectors/selectedRestaurantMenu'
import EditMenuEntry from './components/EditMenuEntry/EditMenuEntry'
import EditItemLayout from './components/EditMenuEntry/components/EditMenuEntryLayout'
import ItemForm from '../../../components/forms/ItemForm/ItemForm'
import CategoryForm from '../../../components/forms/CategoryForm'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { useMenuSelectContext } from '../../../contexts/MenuSelectContext'
import SelectedMenuItemsContoller from '../../../components/SelectedMenuItemsController/SelectedMenuItemsContoller'
import MenuControls from '../components/MenuControls'

const DEBOUNCE_TIME = 1000

function MenuList({ domElement, restaurantId, restaurant, forceClosed, ...props }) {
  const { t, i18n } = useTranslation()
  const parent = useRef(null)
  const [menuList, setMenuList] = useRecoilState(selectedRestaurantMenuState)
  const { makeApiRequest, isFetching } = useRequestStatus({
    successMessage: 'Menu updated',
    pendingMessage: 'Updating menu',
  })
  const { loggedIn, user, isAdmin, isOwnerOf, isStaffAt } = useAuthContext()
  const { createNotification } = createNotificationManager()
  const [editingItem, setEditingItem] = useState(null)
  const containerRef = useRef(null)
  const [editorPadding, setEditorPadding] = useState(0)
  const { selectionList } = useMenuSelectContext()

  const isOwner = isOwnerOf(restaurantId)
  const isStaff = isStaffAt(restaurantId)

  const refreshMenuList = async id => {}

  const updateMenuList = async (list, apiRequestProps) => {
    await makeApiRequest(
      setMenuListApi,
      [
        {
          id: restaurantId,
          menu: list.map(item => {
            return {
              ...item,
              id: item?.id ? item.id : uuidv4(),
              createdAt: item.createdAt || moment.utc().toISOString(),
            }
          }),
        },
      ],
      apiRequestProps
    )
    setMenuList(list)
  }

  const onMenuItemDragEnd = async result => {
    if (!result.destination) return

    const indexFrom = result.source.index
    const indexTo = result.destination.index

    if (indexFrom === indexTo) return

    if (selectionList.find(item => item?.itemId === menuList[indexFrom]?.id)) {
      console.log('Multi drag')
      // if the item being dragged is part of the selected items
      // we need to move all selected items
      const itemsToSwap = selectionList.map(item => item.itemId)
      const coreItemId = menuList[indexFrom]?.id
      const [removed] = itemsToSwap.splice(
        itemsToSwap.findIndex(id => id === coreItemId),
        1
      )
      itemsToSwap.unshift(removed)
      console.log({ restaurantId, items: itemsToSwap, toIndex: indexTo })

      await swapMenuItems({ restaurantId, items: itemsToSwap, toIndex: indexTo })
    } else {
      console.log('Single drag')
      const itemsToSwap = [menuList[indexFrom]?.id]
      console.log({ restaurantId, items: itemsToSwap, toIndex: indexTo })
      await swapMenuItems({ restaurantId, items: itemsToSwap, toIndex: indexTo })
    }
  }

  const updateElmentData = debounce(DEBOUNCE_TIME, async (element, id) => {
    // const index = menuList.findIndex(item => item.id === id)
    // const newList = [...menuList]

    // console.log(newList[index])
    // newList[index] = {
    //   ...element,
    //   updatedAt: moment.utc().toISOString(),
    //   createdAt: element.createdAt || moment.utc().toISOString(),
    // }
    // updateMenuList(newList)

    const newItem = {
      ...element,
      updatedAt: moment.utc().toISOString(),
      createdAt: element.createdAt || moment.utc().toISOString(),
    }
    makeApiRequest(updateMenuItemApi, [{ restaurantId, item: newItem }], { pendingMessage: 'Updating item ...' })
  })

  /**
   * @param {int} index
   */
  const removeElement = debounce(DEBOUNCE_TIME, async id => {
    // const index = menuList.findIndex(item => item.id === id)
    // const newList = [...menuList]
    // if (index > -1) {
    //   newList.splice(index, 1)
    // }
    // updateMenuList(newList, { pendingMessage: 'Removing item ...' })
    makeApiRequest(deleteMenuItemApi, [{ restaurantId, id }], { pendingMessage: 'Removing item ...' })
  })

  const duplicateElement = debounce(DEBOUNCE_TIME, async id => {
    const i = menuList.findIndex(item => item.id === id)
    const newList = [...(menuList || [])]

    const itemToDuplicate = Object.assign({
      ...newList[i],
      id: uuidv4(),
      vektoriId: '',
      eanCode: '',
      updatedAt: moment.utc().toISOString(),
      createdAt: moment.utc().toISOString(),
    })

    newList.push(itemToDuplicate)

    ArrayMove(newList, newList.length - 1, i + 1)
    updateMenuList(newList, { pendingMessage: 'Duplicating item ...' })
  })

  const handleEditClick = (e, item) => {
    const targetItemElementY =
      e?.target?.getBoundingClientRect?.()?.top - containerRef?.current?.getBoundingClientRect?.()?.top
    const correction = item?.type === 'menuItem' ? 300 : 150

    setEditorPadding(targetItemElementY - correction)
    console.log(targetItemElementY)

    if (item?.id === editingItem?.id) {
      setEditingItem(null)
    } else setEditingItem(item)
  }

  useEffect(() => {
    if (loggedIn && restaurantId) {
      refreshMenuList(restaurantId)
    }
  }, [loggedIn, restaurantId])

  useEffect(() => {
    setEditingItem(null)
    console.log('menuList', menuList)
  }, [menuList])

  const itemRender = (item, i) => {
    if (item.type === 'editableText') {
      if (isStaff) {
        return
      }
      return (
        <MenuTitle
          data={item}
          restaurant={restaurant}
          updateElment={updateElmentData}
          removeElement={removeElement}
          listView={props.listView}
          onEdit={e => handleEditClick(e, item)}
        />
      )
    }
    if (item.type === 'menuItem') {
      if (isStaff) {
        return (
          <MenuItemStaff
            restaurantId={restaurantId}
            restaurant={restaurant}
            key={item.id}
            data={item}
            index={i}
            updateElment={updateElmentData}
            refreshMenuList={refreshMenuList}
            onEdit={e => handleEditClick(e, item)}
          />
        )
      }

      return (
        <MenuItem
          restaurantId={restaurantId}
          restaurant={restaurant}
          key={item.id}
          data={item}
          index={i}
          duplicateElement={duplicateElement}
          updateElment={updateElmentData}
          refreshMenuList={refreshMenuList}
          removeElement={removeElement}
          new={item.new}
          listView={props.listView}
          onEdit={e => handleEditClick(e, item)}
        />
      )
    }
  }

  return (
    <div style={{ marginBottom: '40px' }}>
      {/* {isStaff &&
          (forceClosed ? (
            <button className="btn btn-fullwidth" onClick={onToggleCloseRestaurant}>
              {t('menuView.menuList.StaffOpenRestaurant')}
            </button>
          ) : (
            <button className="btn btn-fullwidth" onClick={onToggleCloseRestaurant}>
              {t('menuView.menuList.StaffCLoseRestaurant')}
            </button>
          ))} */}

      {isStaff ? (
        <Stack direction="column" spacing={2} height="200px !important">
          {/* height is set to 200px to fix the issue on printer webview when ui is squished */}
          {(menuList || []).map((item, i) => (
            <div className="menu-item-wrapper" key={item?.id}>
              {itemRender(item, i)}
            </div>
          ))}
        </Stack>
      ) : (
        <Grid container ref={parent}>
          <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
            <DragDropContext onDragEnd={onMenuItemDragEnd} onDragStart={() => setEditingItem(null)}>
              <Droppable droppableId="menu-list-droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {(menuList || []).map((item, i) => (
                      <Box mb={1} key={item?.id || i}>
                        <Draggable draggableId={item.id} index={i} key={item.id} isDragDisabled={!(isOwner || isAdmin)}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              className={`menu-item-wrapper ${snapshot.isDragging ? 'dragging' : ''} ${
                                item?.id == editingItem?.id ? `editing` : ``
                              }`}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {itemRender(item, i)}
                            </div>
                          )}
                        </Draggable>
                      </Box>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <MenuControls />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingTop: `${editorPadding}px` }} ref={containerRef}>
            <EditMenuEntry
              item={editingItem}
              restaurant={restaurant}
              onClose={() => setEditingItem(null)}
              onSave={updateElmentData}
              isPending={isFetching}
            />
          </Grid>
        </Grid>
      )}

      <SelectedMenuItemsContoller />
    </div>
  )
}

export default MenuList
