import React, { useState, useEffect } from 'react'
import OrderScreenDefaultView from '../../../../../../images/OrderScreenDefault.png'
import OrderScreenPreview from '../../OrderScreenPreview'
import ColorPicker from '../../../../../../components/ColorPicker/ColorPicker'
import { saveRestaurantInfoData } from '../../../../../../api/restaurantInfoApi'
import { Stack, Button } from '@mui/material'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../../../../../atoms/SelectedRestaurantAtom'

export default function OrderScreenEdit() {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const monitorSetting = selectedRestaurant?.monitorSetting
  const [theme, setTheme] = useState(monitorSetting?.theme || 'default')
  const [inProgressBackgroundColor, setInProgressBackgroundColor] = useState(
    monitorSetting?.inProgressBackgroundColor || '#000'
  )
  const [inprogressTextColor, setInProgresTextColor] = useState(monitorSetting?.inProgressTextColor || '#FFF')
  const [readyBgColor, setReadyBgColor] = useState(monitorSetting?.readyBackgroundColor || '#d1ffca')
  const [readyTextColor, setReadyTextColor] = useState(monitorSetting?.readyTextColor || '#000')

  const [saving, setSaving] = useState(false)

  const handleSave = () => {
    saveKioskSettings()
  }

  const saveKioskSettings = async () => {
    setSaving(true)
    try {
      await saveRestaurantInfoData({
        id: selectedRestaurant.id,
        monitorSetting: {
          theme,
          inProgressBackgroundColor,
          inProgressTextColor: inprogressTextColor,
          readyBackgroundColor: readyBgColor,
          readyTextColor: readyTextColor,
          uiVersion: '1',
        },
      })
    } catch (error) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  useEffect(() => {
    setTheme(monitorSetting?.theme || 'default')
    setInProgressBackgroundColor(monitorSetting?.inProgressBackgroundColor || '#000')
    setInProgresTextColor(monitorSetting?.inProgressTextColor || '#FFF')
    setReadyBgColor(monitorSetting?.readyBackgroundColor || '#d1ffca')
    setReadyTextColor(monitorSetting?.readyTextColor || '#000')
  }, [selectedRestaurant])

  return (
    <Stack spacing={2} minHeight="100%" justifyContent="space-between">
      <div className="branding-container">
        <div className="row row-editing">
          <h5>Order Screen styling</h5>
          <div className="row row-editing">
            <div className="col">
              <div
                className={`selectable-image order-screen ${theme === 'default' ? 'selected' : ''}`}
                onClick={() => setTheme('default')}
              >
                <img src={OrderScreenDefaultView} />
                <div className="title">Default</div>
              </div>
            </div>
            <div className="col">
              <div
                className={`selectable-image order-screen ${theme === 'customized' ? 'selected' : ''}`}
                onClick={() => setTheme('customized')}
              >
                <OrderScreenPreview
                  inProgressBackgroundColor={inProgressBackgroundColor}
                  inProgressTextColor={inprogressTextColor}
                  readyBackgroundColor={readyBgColor}
                  readyTextColor={readyTextColor}
                />
                <div className="title">Customized</div>
              </div>
            </div>
            {theme === 'customized' && (
              <div className="col w-50">
                <div className="col">
                  <div className="row">
                    <b>In Progress Backgorund color</b>
                    <ColorPicker color={inProgressBackgroundColor} onChange={e => setInProgressBackgroundColor(e)} />
                  </div>

                  <div className="row">
                    <b>In Progress Text color</b>
                    <ColorPicker color={inprogressTextColor} onChange={e => setInProgresTextColor(e)} />
                  </div>

                  <a
                    className="btn-small"
                    href={'https://ordering.myntapp.io/order-status/' + selectedRestaurant.id + '?consumer=true'}
                    target="_blank"
                  >
                    Preview
                  </a>
                </div>
                <div className="col">
                  <div className="row">
                    <b>Ready Background color</b>
                    <ColorPicker color={readyBgColor} onChange={e => setReadyBgColor(e)} />
                  </div>
                  <div className="row">
                    <b>Ready Text Color</b>
                    <ColorPicker color={readyTextColor} onChange={e => setReadyTextColor(e)} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Button variant="contained" size="large" onClick={handleSave} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}
