import {
  Alert,
  Avatar,
  Box,
  ButtonBase,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import theme from '../../../theme'
import { useEffect, useState } from 'react'
import DeleteButton from '../../../ui/DeleteButton/DeleteButton'
import { addTeamMemberApi, getTeamApi, removeStaff } from '../../../api/teamApi'
import { useOutletContext } from 'react-router-dom'
import { get } from 'http'
import { set } from 'react-hook-form'

function Step3() {
  const { restaurantData, setRestaurantData } = useOutletContext<any>()

  const [emailField, setEmailField] = useState('')
  const [emailFieldError, setEmailFieldError] = useState<string>('')
  const [addedMembers, setAddedMembers] = useState<string[]>([])
  const [isFetching, setFetching] = useState(false)

  const handleTeamMemberAdd = () => {
    setFetching(true)
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailField)) {
      addTeamMemberApi({
        email: emailField,
        workingRestaurantIds: [restaurantData.id],
        owningRestaurantIds: [],
      }).then(res => {
        setEmailFieldError('')
        refreshMembers()
      })
    } else {
      setEmailFieldError('Please enter a valid email address')
      setFetching(false)
    }
  }

  const handleTeamMemberRemove = (member: any) => {
    setFetching(true)
    removeStaff(member.id).then(() => refreshMembers())
  }

  const refreshMembers = () => {
    setFetching(true)
    getTeamApi().then(({ data }) => {
      console.log('team', data)
      setAddedMembers(data.filter((person: any) => person.workingRestaurantIds.includes(restaurantData.id)))
      setFetching(false)
    })
  }

  useEffect(() => {
    refreshMembers()
  }, [])

  return (
    <Stack spacing={2} p={4} alignItems="center">
      <Box maxWidth="80%" textAlign="center" pb={2}>
        <Typography variant="h6">
          Almost there! Do you have any colleagues who should also have access to mynt?
        </Typography>
      </Box>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={2}>
          {addedMembers.map((member: any) => (
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} key={member.id}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar />
                <Typography variant="subtitle1">{member.email}</Typography>
              </Stack>

              <DeleteButton onClick={() => handleTeamMemberRemove(member)} disabled={isFetching} />
            </Stack>
          ))}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} px={1}>
          <TextField
            fullWidth
            type="email"
            variant="standard"
            label="Email address"
            inputProps={{ className: 'browser-default' }}
            value={emailField}
            onChange={e => setEmailField(e.target.value)}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault()
                handleTeamMemberAdd()
              }
            }}
            error={Boolean(emailFieldError)}
            helperText={emailFieldError}
            disabled={isFetching}
          />
          <IconButton sx={{ padding: 0 }} onClick={handleTeamMemberAdd} disabled={isFetching}>
            <Avatar sx={{ bgcolor: isFetching ? theme.palette.grey[400] : theme.palette.mynt.main }}>
              <AddIcon />
            </Avatar>
          </IconButton>
        </Stack>
      </Stack>
      <Alert>Note: you’ll be able to add more colleagues later on in under “Team”</Alert>
    </Stack>
  )
}

export default Step3
