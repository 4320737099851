import { v4 as uuvidv4 } from 'uuid'

const deepIdReplace = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => deepIdReplace(item)) as unknown as T
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {}
    for (const key in obj) {
      if (key === 'id') {
        newObj[key] = uuvidv4()
      } else {
        newObj[key] = deepIdReplace((obj as any)[key])
      }
    }
    return newObj as T
  } else {
    return obj
  }
}

export { deepIdReplace }
