import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTeamApi, getHolders, createHolder } from '../../api/teamApi'
import StripeHolderList from './stripeHolderList/StripeHolderList'
import { useAuthContext } from '../../contexts/AuthContext'

/**
 *
 */
function Team({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [teamData, setTeamData] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const { loggedIn, isAdmin } = useAuthContext()
  const [restaurants, setRestaurants] = useState([])

  var scrollDown = () => {
    let element = document.getElementById('bottomOfView')
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const updateData = async () => {
    setLoaded(false)
    try {
      const teamData = await getHolders()
      setTeamData(teamData?.data || [])
      setRestaurants(teamData?.restaurants || [])
    } catch (error) {
      setLoadingError(true) // TODO: enable error handling, no data returned.
    } finally {
      setLoaded(true)
    }
  }

  const onClickAddStripeAccount = async () => {
    await createHolder()
    await updateData()
  }

  useEffect(() => {
    updateData()
  }, [loggedIn])

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 m12">
          <h3>Stripe Connections</h3>
          <p>Admin can control stripe connections here</p>
          <table className="team-table">
            <thead>
              <tr>
                <th> {t('teamView.teamList.tableMember')}</th>
                <th> {t('teamView.teamList.tableHolder')}</th>
                <th> {t('teamView.teamList.tableEdit')}</th>
              </tr>
            </thead>
            <StripeHolderList
              isAdmin={isAdmin}
              update={updateData}
              data={teamData}
              loaded={loaded}
              restaurants={restaurants}
            />
          </table>
          <div className="end-of-menu-list" id="bottomOfView">
            <h4>Add Stripe Account</h4>
            <p>Add new stripe account to prepare new onboarding link.</p>
            <p>
              After create an account, click edit to attach account to restaurant. Thus restaurant will pickup this
              account to process payments.
            </p>
            <div className="buttons-row">
              <div className="pepi-button" onClick={onClickAddStripeAccount}>
                Add Account
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
