const getActionName = (logObject: any) => {
  const endpoint = logObject.endpoint

  switch (endpoint) {
    case '/cms/setMenuList':
      return `Updated menu (${logObject?.body?.menu?.length})`
    case '/cms/updateRestaurant':
      return 'Restaurant Update'
    case '/cms/owners/toggleCloseRestaurant':
      const isActionKnown = logObject?.query?.isClose !== undefined
      let resultText
      if (isActionKnown) {
        resultText = logObject?.query?.isClose === 'true' ? 'Restaurant Closed' : 'Restaurant Opened'
      } else {
        resultText = 'Toggled open/close restaurant'
      }

      return resultText
    default:
      return endpoint
  }
}

export default getActionName
