import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import TeamMemberForm from '../../../../components/forms/TeamMemberForm'
import { FormProvider, useForm } from 'react-hook-form'
import { addTeamMemberApi } from '../../../../api/teamApi'
import { useRecoilState } from 'recoil'
import { teamListState } from '../../../../atoms/TeamsAtoms'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'
import { toast } from 'react-toastify'
import { useRequestStatus } from '../../../../hooks/useRequestStatus'

type AddDialogProps = {
  open: boolean
  setOpen?: (open: boolean) => any
}

function AddDialog({ open, setOpen }: AddDialogProps) {
  const [teamList, setTeamList] = useRecoilState(teamListState)
  const methods = useForm({ defaultValues: { owningRestaurantIds: [], email: '', phoneNumber: '', name: '' } })
  const { handleSubmit, control } = methods
  const { makeApiRequest, isFetching } = useRequestStatus()

  const onSubmit = async (data: any) => {
    console.log('user submitted ', data)
    const { isSuccess } = await makeApiRequest(addTeamMemberApi, [data])
    if (isSuccess) {
      setTeamList([...teamList, data])
      onClose()
    }
  }

  const onClose = () => {
    setOpen && setOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <PartialBackdrop show={isFetching}>
        <DialogTitle>
          <Typography variant="h4">New Team Member</Typography>
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <FormProvider {...methods}>
            <TeamMemberForm />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen && setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Add
          </Button>
        </DialogActions>
      </PartialBackdrop>
    </Dialog>
  )
}

export default AddDialog
