import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import M from 'materialize-css'
import { useForm, useFieldArray } from 'react-hook-form'
import { updateStaff, removeStaff } from '../../../api/teamApi'
import parsePhoneNumber from 'libphonenumber-js'
import _ from 'lodash'

/**
 *
 */
function EditStripeHolderModal({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState(props.data)
  const [showDelete, setShowDelete] = useState(false)
  const [showSmartum, setShowSmartum] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      holdingRestaurantIds: _.uniq(props.data.holdingRestaurantIds || []),
      vatID: props?.data?.vatID,
      businessName: props?.data?.businessName,
      businessID: props?.data?.businessID,
      contact: props?.data?.contact,
      transactionFee: props?.data?.transactionFee,
    },
  })
  const holdingRestaurantIdsWatch = watch('holdingRestaurantIds')

  let saveUser = async data => {
    if (data == undefined) {
      data = watch()
    }

    const payload = {
      ...data,
      holdingRestaurantIds: data.holdingRestaurantIds,
    }
    console.log('data', payload)
    try {
      await updateStaff(props.data.id, payload)
    } catch (error) {
      alert(error.message)
    } finally {
      var instance = M.Modal.getInstance(document.getElementById(`edit-modal-${props.data.id}`))
      instance.close()
      reset()
      await props.update()
    }
  }

  useEffect(() => {
    var elems = document.querySelectorAll(`#edit-modal-${props.data.id}`)
    M.Modal.init(elems, {})
  }, [props.data, setValue])

  const onDelete = async staffId => {
    try {
      await removeStaff(staffId)
    } catch (error) {
      alert(error.message)
    } finally {
      props.update()
    }
  }

  return (
    <>
      <div id={`edit-modal-${data.id}`} className="modal" key={data.id}>
        <div className="modal-content stripe-modal">
          <h5>{data.id}</h5>
          <form onSubmit={handleSubmit(saveUser)}>
            <div>
              <b>Name:</b>
              <div className="input-field col s12">
                <input
                  placeholder="Display Name"
                  id="displayName"
                  type="text"
                  value={data.displayName}
                  {...register(`displayName`)}
                />
              </div>
            </div>
            <div>
              <b>Business Name:</b>
              <div className="input-field col s12">
                <input placeholder="Business name" id="businessName" type="text" {...register(`businessName`)} />
              </div>
            </div>
            <div>
              <b>Business ID:</b>
              <div className="input-field col s12">
                <input placeholder="Business ID" id="businessID" type="text" {...register(`businessID`)} />
              </div>
            </div>
            <div>
              <b>Vat ID:</b>
              <div className="input-field col s12">
                <input placeholder="Vat ID" id="vatID" type="text" {...register(`vatID`)} />
              </div>
            </div>
            <div>
              <b>Contact:</b>
              <div className="input-field col s12">
                <input placeholder="Contact" id="contact" type="text" {...register(`contact`)} />
              </div>
            </div>
            <div className="dangerous">
              <b>Transaction Fee:</b>
              <p>
                Do not fuck this up
                <br />
                <b>Enter 3 for 3%</b>
              </p>
              <div className="input-field col s12">
                <input
                  placeholder="Transaction Fee (in percentage%)"
                  id="transactionFee"
                  type="number"
                  {...register(`transactionFee`, { required: true })}
                  onWheel={e => e.target.blur()} //Stupid override so we dont accidentally change value on scroll
                />
              </div>
            </div>
            <div>
              <b>Connecting to following restaurants</b>
            </div>
            {props.restaurants.map((r, rIndex) => (
              <p key={r.id}>
                <label>
                  <input
                    onChange={e => {
                      if (e.target.checked) {
                        setValue('holdingRestaurantIds', [...holdingRestaurantIdsWatch, r.id])
                      } else {
                        setValue('holdingRestaurantIds', [...holdingRestaurantIdsWatch.filter(w => w !== r.id)])
                      }
                    }}
                    checked={holdingRestaurantIdsWatch.indexOf(r.id) >= 0}
                    type="checkbox"
                    value={r.id}
                  />
                  <span>{r.name}</span>
                </label>
              </p>
            ))}

            {errors.holdingRestaurantIdsField ? (
              <p className="error-msg">{errors.holdingRestaurantIdsField.type}</p>
            ) : (
              ''
            )}
          </form>
        </div>
        <div className="modal-footer">
          <button className="btn" onClick={() => saveUser()}>
            {t('general.SaveButton')}
          </button>{' '}
        </div>
      </div>
    </>
  )
}

export default EditStripeHolderModal
