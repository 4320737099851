import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  Button,
  Card,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Extra from './components/Extra'
import { useEffect, useState } from 'react'
import CopyExtrasDialog from './components/copyExtrasDialog/CopyExtrasDialog'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Category, Item, Extra as TExtra } from '../../../../../types'

type ExtrasProps = {
  restaurant: any
  item: Item
}

function Extras({ restaurant, item }: ExtrasProps) {
  const [isTooltipOpened, setTooltipOpened] = useState(false)
  const [copyExtrasDialogOpen, setCopyExtrasDialogOpen] = useState(false)
  const [possibleItemsAsExtras, setPossibleItemsAsExtras] = useState<Item[]>([])
  const { register, formState, control, setValue, getValues } = useFormContext<Item>()
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'extras',
  })

  useEffect(() => {
    if (restaurant) {
      const newPossibleItems = (restaurant?.menu as (Item | Category)[]).filter(
        position => position.type == 'menuItem' && position.id != item.id
      )
      setPossibleItemsAsExtras(newPossibleItems as Item[])
    }
  }, [restaurant])

  const onExtraAdd = (type: TExtra<'extraItem' | 'otherItem'>['type']) => {
    if (type === 'extraItem') {
      const newExtra: TExtra<'extraItem'> = {
        type: 'extraItem',
        price: '',
        translations: {
          name: {
            en: '',
          },
        },
      }
      append(newExtra)
    } else if (type === 'otherItem') {
      const newExtra: TExtra<typeof type> = {
        type: type,
        id: '',
      }
      append(newExtra)
    }
    handleTooltipClose()
  }

  const onExtraRemove = (index: number) => {
    remove(index)
  }

  const handleTooltipOpen = () => {
    setTooltipOpened(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpened(false)
  }

  const handleCopiedExtrasReceive = (newExtras: TExtra<'extraItem' | 'otherItem'>[]) => {
    newExtras.forEach(newExtra => {
      append(newExtra)
    })
    setCopyExtrasDialogOpen(false)
  }

  const onOptionGroupDragEnd = (result: any) => {
    if (result.destination) {
      move(result.source.index, result.destination.index)
    }
  }

  return (
    <Stack spacing={3}>
      {/* <Typography variant="h3">Options Setting</Typography> */}
      <DragDropContext onDragEnd={onOptionGroupDragEnd}>
        <Droppable droppableId="extras-list-droppable">
          {provided => (
            <Stack {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <Draggable draggableId={field.id} index={index} key={field.id}>
                  {(prov, snapshot) => (
                    <div
                      ref={prov.innerRef}
                      // className={`menu-item-wrapper ${snapshot.isDragging ? 'dragging' : ''}`}
                      {...prov.draggableProps}
                    >
                      <Extra
                        restaurant={restaurant}
                        index={index}
                        onDeleteClick={() => onExtraRemove(index)}
                        possibleItemsAsExtra={possibleItemsAsExtras}
                        dragHandleProps={prov.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>

      {/* <Stack spacing={2}>
        {fields.map((field, index) => (
          <Extra
            restaurant={restaurant}
            index={index}
            key={field.id}
            onDeleteClick={() => onExtraRemove(index)}
            possibleItemsAsExtra={possibleItemsAsExtras}
          />
        ))}
      </Stack> */}
      <Stack spacing={2} direction="row">
        <Tooltip
          title={
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Stack spacing={2} direction="row" padding={1}>
                  <Button variant="contained" size="small" onClick={() => onExtraAdd('extraItem')}>
                    Extra Item (Regular)
                  </Button>
                  <Button variant="contained" size="small" onClick={() => onExtraAdd('otherItem')}>
                    Other menu item as extra
                  </Button>
                </Stack>
              </div>
            </ClickAwayListener>
          }
          open={isTooltipOpened}
          onClose={handleTooltipClose}
          arrow
          placement="top"
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Button className="browser-default" variant="contained" onClick={handleTooltipOpen} fullWidth>
            Add New Extra
          </Button>
        </Tooltip>
        <Button className="browser-default" variant="contained" onClick={() => setCopyExtrasDialogOpen(true)} fullWidth>
          Copy Extras from other item
        </Button>
      </Stack>
      <CopyExtrasDialog
        open={copyExtrasDialogOpen}
        onClose={() => setCopyExtrasDialogOpen(false)}
        onExtrasCopy={handleCopiedExtrasReceive}
      />
    </Stack>
  )
}

export default Extras
