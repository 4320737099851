import { useState } from 'react'
import { toast } from 'react-toastify'

type ApiRequestOptions = {
  successMessage?: string
  errorMessage?: string
  pendingMessage?: string
  removeOnSuccess?: boolean
  noToast?: boolean
}

export function useRequestStatus() {
  const [isFetching, setFetching] = useState(false)

  const makeApiRequest = async (
    apiCall: any,
    apiRequestArgs: any[],
    { successMessage, errorMessage, pendingMessage, removeOnSuccess, noToast }: ApiRequestOptions = {}
  ) => {
    setFetching(true)
    let resp: any = null

    if (noToast) {
      resp = await apiCall(...apiRequestArgs)
    } else {
      try {
        resp = await toast.promise(
          apiCall(...apiRequestArgs).then((resp: any) => {
            console.log(resp)
            if (resp?.success === false) {
              throw new Error(resp?.error || resp?.message || 'Request failed')
            }
            return resp
          }),
          {
            pending: pendingMessage || 'Loading',
            success: successMessage || 'Success',
            error: {
              render({ data }: any) {
                return errorMessage || data?.error || data?.message || 'Request failed'
              },
            },
          }
        )
      } catch (error) {
        console.log(error)
      }
    }

    setFetching(false)
    const isSuccess = Boolean(resp?.success)
    return { resp, isSuccess }
  }

  return { makeApiRequest, isFetching }
}
