import { request } from './base'

export async function getMe() {
  const resp = await request(`/cms/me`, 'get')
  return resp?.data
}

export async function acceptToS() {
  const resp = await request(`/cms/acceptTos`, 'post')
  return resp?.data
}

export async function requestToLogin(params) {
  const resp = await request(`/cms/requestEmailLogin`, 'post', {
    payload: params,
  })
  return resp?.data
}
