import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { useAuthContext } from '../../../../../contexts/AuthContext'
import KioskPreview from '../KioskPreview'
import Toggelable from '../../../../../components/Toggleable/Toggleable'
import { getFontNameFromOTF, uploadFile, uploadFilePNG } from '../../../../../utils'
import { saveRestaurantInfoData } from '../../../../../api/restaurantInfoApi'
import {
  Stack,
  Button,
  Typography,
  Card,
  Alert,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Box,
} from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../../../atoms/SelectedRestaurantAtom'
import { Buffer } from 'buffer'
import axios from 'axios'
import FontCard from './components/FontCard'
import { useRequestStatus } from '../../../../../hooks/useRequestStatus'
import { errors } from 'puppeteer'
import { Controller } from 'react-hook-form'
import getLanguageName from '../../../../../utils/getLanguageName'

type KioskFonts = any

export default function KioskEditCard() {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const setting = selectedRestaurant.kioskSetting
  const [logo, setLogo] = useState(setting?.logo || null)
  const [background, setBackground] = useState(setting?.background || null)
  const [splash, setSplash] = useState(setting?.splash || null)
  const [theme, setTheme] = useState(setting?.theme || 'default')
  const [colorTheme, setColorTheme] = useState(setting?.colorTheme || 'default')
  const [showCouponInput, setShowCouponInput] = useState(setting?.showCouponInput || false)
  const [removeItemImgBackground, setRemoveItemImgBackground] = useState(setting?.removeItemImgBackground || false)
  const [saving, setSaving] = useState(false)
  const { isAdmin } = useAuthContext()
  const { makeApiRequest } = useRequestStatus()

  const [fonts, setFonts] = useState<KioskFonts>(setting?.fonts || {})
  const [defaultKioskLanguage, setDefaultKioskLanguage] = useState(setting?.defaultLanguage || 'en')

  const saveKioskSettings = async () => {
    setSaving(true)
    const storage = getStorage()
    const auth = await getAuth()
    const uid = auth?.currentUser?.uid
    try {
      const _uploadFile = async (f: any) => {
        const filePath = `/restaurants/${selectedRestaurant.id}/images/${uuidv4()}.png`
        const imageUrl = await uploadFilePNG(f, filePath)
        return imageUrl
      }

      console.log('logo', logo)
      let logoUrl = ''
      let splashUrl = ''
      let backgroundUrl = ''

      if (logo && logo != 'NONE') {
        logoUrl = await _uploadFile(logo)
      }

      if (splash && splash != 'NONE') {
        splashUrl = await _uploadFile(splash)
      }

      if (background) {
        backgroundUrl = await _uploadFile(background)
      }

      await makeApiRequest(saveRestaurantInfoData, [
        {
          id: selectedRestaurant.id,
          kioskSetting: {
            theme,
            colorTheme,
            logo: logo == 'NONE' ? logo : logoUrl,
            background: backgroundUrl,
            splash: splash == 'NONE' ? logo : splashUrl,
            fonts: fonts,
            removeItemImgBackground: removeItemImgBackground,
            showCouponInput,
            defaultLanguage: defaultKioskLanguage,
          },
        },
      ])
    } catch (error: any) {
      console.log('Error: ', error)
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  const handleSave = () => {
    console.log('save')
    saveKioskSettings()
  }

  const handleLogoChange = (event: any) => {
    let input = URL.createObjectURL(event.target.files[0])
    setLogo(input)
  }

  const handleBackgroundChange = (event: any) => {
    let input = URL.createObjectURL(event.target.files[0])
    setBackground(input)
  }

  const handleSplashChange = (event: any) => {
    let input = URL.createObjectURL(event.target.files[0])
    console.log(input)
    setSplash(input)
  }

  const handleFontChange = (fontName: string, font: string | null) => {
    setFonts((prev: any) => ({ ...prev, [fontName]: font }))
  }

  useEffect(() => {
    setLogo(setting?.logo || null)
    setBackground(setting?.background || null)
    setSplash(setting?.splash || null)
    setTheme(setting?.theme || 'default')
    setColorTheme(setting?.colorTheme || 'default')
  }, [selectedRestaurant])

  const handleSetThemeDefault = () => {
    setTheme('default')
    setSplash(null)
    setLogo(null)
    setBackground(null)
  }

  console.log('fonts', fonts)

  return (
    <Stack minHeight="100%" justifyContent="space-between">
      <div className="branding-container">
        <div className="row row-editing">
          <h5>Welcome Screen</h5>
          <Stack>
            <div className="row row-editing">
              <div className="col">
                <div
                  className={`selectable-image ${theme === 'default' ? 'selected' : ''}`}
                  onClick={() => handleSetThemeDefault()}
                >
                  <img src={'/img/kioskCustomization/KioskDefault.jpg'} />
                  <div className="title">Default</div>
                </div>
              </div>
              <div className="col">
                <div
                  className={`selectable-image ${theme === 'customized' ? 'selected' : ''}`}
                  onClick={() => setTheme('customized')}
                >
                  <KioskPreview logo={logo} backgroundImage={background} splashImage={splash} />
                  <div className="title">Customized</div>
                </div>
              </div>
              {theme === 'customized' && (
                <div className="col kiosk-settings-wrapper">
                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk logo <span>(Transperent PNG, 500x200)</span>
                      </b>

                      <div className="file-field input-field">
                        <div className="btn">
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleLogoChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn-small tooltipped"
                      data-position="top"
                      onClick={() => setLogo('NONE')}
                      data-tooltip="No Logo"
                    >
                      <i className="material-icons">visibility_off</i>
                    </button>
                  </div>

                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk Splash <span>(Transperent PNG, 780x584) </span>
                      </b>
                      <div className="file-field input-field">
                        <div className="btn">
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleSplashChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn-small tooltipped"
                      data-position="top"
                      onClick={() => setSplash('NONE')}
                      data-tooltip="No Splash"
                    >
                      <i className="material-icons">visibility_off</i>
                    </button>
                  </div>

                  <div className="option-row">
                    <div className="option">
                      <b>
                        Kiosk Background <span>(JPG, 1080x1920)</span>
                      </b>
                      <div className="file-field input-field">
                        <div
                          className="btn tooltipped"
                          data-position="top"
                          data-tooltip="Corners of your background may get cut off on the smaller Kiosk"
                        >
                          <span>File</span>
                          <input type="file" accept="image/*" onChange={handleBackgroundChange} />
                        </div>
                        <div className="file-path-wrapper">
                          <input className="file-path validate" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="info-box">
                    Please keep in mind that the preview to the left might not be 100% accurate. Please verify your
                    settings on your actual kiosk.
                  </div>
                </div>
              )}
            </div>
            <Stack spacing={2} width={{ sm: '60%', xs: '100%' }} mb={4}>
              <Typography variant="h5">Custom fonts</Typography>
              <Stack spacing={2}>
                <FontCard
                  fontRole="Primary font"
                  font={fonts['primary']}
                  handleFontChange={font => handleFontChange('primary', font)}
                />
                <FontCard
                  fontRole="Eat here/takeaway screen font"
                  font={fonts['dining_option_choose_view']}
                  handleFontChange={font => handleFontChange('dining_option_choose_view', font)}
                />
                <FontCard
                  fontRole="Menu title header font"
                  font={fonts['menu_title_header']}
                  handleFontChange={font => handleFontChange('menu_title_header', font)}
                />
                <FontCard
                  fontRole="Items and prices font"
                  font={fonts['items_and_prices']}
                  handleFontChange={font => handleFontChange('items_and_prices', font)}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h5">Product view</Typography>
              <Stack spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(removeItemImgBackground)}
                      onChange={e => setRemoveItemImgBackground(e.target.checked)}
                    />
                  }
                  label={'Remove border around product image'}
                />
              </Stack>
            </Stack>
          </Stack>
        </div>
        <div className="row row-editing">
          <h5>Color Theme</h5>
          <div className="row row-editing">
            <div className="col">
              <div
                className={`selectable-image ${colorTheme === 'default' ? 'selected' : ''}`}
                onClick={() => setColorTheme('default')}
              >
                <img src={'/img/kioskCustomization/whitemode.png'} />
                <div className="title">Default</div>
              </div>
            </div>
            <div className="col">
              <div
                className={`selectable-image ${colorTheme === 'black' ? 'selected' : ''}`}
                onClick={() => setColorTheme('black')}
              >
                <img src={'/img/kioskCustomization/darkmode.jpg'} />
                <div className="title">Black & White</div>
              </div>
            </div>
            {(isAdmin || colorTheme === 'kebaboom') && (
              <>
                <div className="col">
                  <div
                    className={`selectable-image ${colorTheme === 'kebaboom' ? 'selected' : ''}`}
                    onClick={() => setColorTheme('kebaboom')}
                  >
                    <img src={'/img/kioskCustomization/KioskCustomTheme.png'} />
                    <div className="title">Kebaboom</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row row-editing">
          <Typography variant="h5">Kiosk UI Settings</Typography>

          <Stack spacing={2} mt={2} mb={4}>
            <Stack maxWidth="300px">
              <Typography variant="subtitle1">Default kiosk language</Typography>
              <Select
                fullWidth
                size="small"
                value={defaultKioskLanguage}
                onChange={e => setDefaultKioskLanguage(e.target.value)}
              >
                {selectedRestaurant?.config?.languagesSupported?.map((lang: any) => (
                  <MenuItem key={lang} value={lang}>
                    {getLanguageName(lang)}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <FormControlLabel
              control={<Checkbox checked={showCouponInput} onChange={() => setShowCouponInput(!showCouponInput)} />}
              label={'Allow Coupon Code Input'}
            />
          </Stack>
        </div>
      </div>
      <Button variant="contained" size="large" onClick={handleSave} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}
