export default function FormatCurrency(input) {
  // Check if the input is a valid number
  if (!isNaN(parseFloat(input)) && isFinite(input)) {
    // Parse the input as a floating-point number and format it with 2 decimal places
    return parseFloat(input).toFixed(2)
  } else {
    // Return an empty string if the input is not a valid number
    return ''
  }
}
