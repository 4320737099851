import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import DeviceTable from './components/DeviceTable'
import { getDevices } from '../../api/deviceApi'
import { useAuthContext } from '../../contexts/AuthContext'
import SelectDeviceForm from './SelectDeviceForm'
import { Kiosk, CustomerMonitor, KitchenMonitor, Printer, QRCode } from './DeviceConstants'
import { Autocomplete, Box, Button, Paper, Stack, TextField, Typography } from '@mui/material'
import { devicesListState } from '../../atoms/DevicesAtoms'
import { restaurantListState } from '../../atoms/RestaurantListAtom'
import DeviceFinder from './components/deviceFinder/DeviceFinder'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'

function DeviceManagementView() {
  const { t, i18n } = useTranslation()
  const { isAdmin } = useAuthContext()
  const [deviceFilter, setDeviceFilter] = useState<any>(Kiosk)
  const restaurantList = useRecoilValue(restaurantListState)
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const [highlightedDeviceSn, setHighlightedDeviceSn] = useState<any>(null)

  const [, setDeviceList] = useRecoilState(devicesListState)

  const handleDeviceLookup = (device: any) => {
    console.log('device lookup', device)
    const restaurantId = device?.restaurantId
    const serialNumber = device?.serialNumber
    const type = device?.type

    const newRestaurant = restaurantList?.find((restaurant: any) => restaurant?.id === restaurantId)
    if (newRestaurant?.id) setSelectedRestaurant(newRestaurant)

    setDeviceFilter(type)
    setHighlightedDeviceSn(serialNumber)
    setTimeout(() => setHighlightedDeviceSn(null), 2000)
  }

  const DEPRICATED_handleRefresh = async () => {
    const { data } = await getDevices()
    setDeviceList(data)
  }

  return (
    <Stack spacing={6} p={2}>
      <Stack spacing={2}>
        <h4>{t('deviceManagementView.title')}</h4>
        {isAdmin && <DeviceFinder handleDeviceLookup={handleDeviceLookup} />}
        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(Kiosk)}
            className={deviceFilter == Kiosk ? 'btn disabled' : 'btn'}
          >
            Kiosk
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(QRCode)}
            className={deviceFilter == QRCode ? 'btn disabled' : 'btn'}
          >
            QR codes
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(Printer)}
            className={deviceFilter == Printer ? 'btn disabled' : 'btn'}
          >
            Printer
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(CustomerMonitor)}
            className={deviceFilter == CustomerMonitor ? 'btn disabled' : 'btn'}
          >
            Customer Display
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(KitchenMonitor)}
            className={deviceFilter == KitchenMonitor ? 'btn disabled' : 'btn'}
          >
            Kitchen Display
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeviceFilter(null)}
            className={deviceFilter == null ? 'btn disabled' : 'btn'}
          >
            No filter
          </Button>
        </Stack>
        <table className="orders-table responsive">
          <thead>
            <tr>
              <th>{t('deviceManagementView.table.deviceName')}</th>
              <th>{t('deviceManagementView.table.deviceType')}</th>
              <th>{t('deviceManagementView.table.deiceLastActive')}</th>
              <th>Submenus</th>
              <th>#</th>
            </tr>
          </thead>
          <DeviceTable deviceFilter={deviceFilter} highlightedDeviceSn={highlightedDeviceSn} />
        </table>
      </Stack>

      <Stack spacing={1}>
        <Stack>
          <Typography variant="h5">Add a device or QR code</Typography>
          <Typography variant="subtitle1">Register a new Mynt device or generate a QR code here.</Typography>
        </Stack>
        <SelectDeviceForm refresh={DEPRICATED_handleRefresh} />
      </Stack>
    </Stack>
  )
}

export default DeviceManagementView
