import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { QRCode } from '../types'

const qrCodeSchema = (t: TFunction<'translation', undefined>) => {
  // @ts-ignore
  const objectSchema: yup.ObjectSchema<QRCode> = yup.object({
    id: yup
      .string()
      .transform(id => {
        if (!id) return uuidv4()
        return id
      })
      .default(uuidv4())
      .required(),
    restaurantId: yup.string().required(t('errors.required')),
    serialNumber: yup.string().required(t('errors.required')),
    name: yup.string().required(t('errors.required')),
    type: yup.string().oneOf(['qr_code']).default('qr_code').required(),
    isCashSupported: yup.boolean().default(false).required(),
    menuIds: yup.array().of(yup.string().defined().strict(true)).default([]).required(t('errors.required')),
    tableFormat: yup
      .object()
      .shape({
        name: yup.string().required(t('errors.required')),
        regex: yup.string().required(t('errors.required')),
        example: yup.string(),
      })
      .default(undefined),
    nameOrdering: yup.boolean(),
    forcedNameOrdering: yup.boolean().default(false),
  })

  return objectSchema
}

export default qrCodeSchema
