import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

type DeleteConfirmDialogProps = {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  body?: React.ReactNode
  dialogActions?: React.ReactNode
}

function DeleteConfirmDialog({ open, onClose, title, body, dialogActions }: DeleteConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmDialog
