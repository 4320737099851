import React, { useState, useEffect } from 'react'
import NumberToDay from '../../../../utils/NumberToDay'

const DEFAULT = {
  from: '00:00',
  to: '00:00',
  available: false,
}

function AvailabilityTableRow({ dayNumber, specialAvailability, onChange }) {
  const [from, setFrom] = useState(
    specialAvailability?.[dayNumber]?.from ? specialAvailability[dayNumber].from : DEFAULT.from
  )
  const [to, setTo] = useState(specialAvailability?.[dayNumber]?.to ? specialAvailability[dayNumber].to : DEFAULT.to)
  const [available, setAvailable] = useState(
    specialAvailability?.[dayNumber]?.available ? specialAvailability[dayNumber].available : DEFAULT.available
  )

  useEffect(() => {
    if (from === DEFAULT.from && to === DEFAULT.to && available === DEFAULT.available) {
      onChange(dayNumber, null)
      return
    }

    onChange(dayNumber, {
      from: from,
      to: to,
      available: available,
    })
  }, [from, to, available])

  return (
    <tr>
      <td>{NumberToDay(dayNumber)}</td>
      <td>
        <input type="time" name="from" value={from} onChange={e => setFrom(e.target.value)} />
      </td>
      <td>
        <input type="time" name="to" value={to} onChange={e => setTo(e.target.value)} />
      </td>
      <td>
        <label>
          <input
            type="checkbox"
            className="filled-in checkbox-mynt"
            name="available"
            checked={available}
            onChange={e => setAvailable(prev => !prev)}
          />
          <span>Available</span>
        </label>
      </td>
    </tr>
  )
}

export default AvailabilityTableRow
