import { Box, Chip, Collapse, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { AxisOptions, Chart } from 'react-charts'
import theme from '../../../theme'

const data = [
  {
    label: 'Arsenii Kebabs',
    data: [
      {
        primary: '2022-02-03',
        sum: 4500,
      },
      {
        primary: '2022-03-03',
        sum: 5500,
      },
      {
        primary: '2022-04-03',
        sum: 2900,
      },
      {
        primary: '2022-05-03',
        sum: 3400,
      },
    ],
  },
  {
    label: 'Tuukkas Burgers',
    data: [
      {
        primary: '2022-02-03',
        sum: 2500,
      },
      {
        primary: '2022-03-03',
        sum: 3500,
      },
      {
        primary: '2022-04-03',
        sum: 3100,
      },
      {
        primary: '2022-05-03',
        sum: 2200,
      },
    ],
  },
]

enum ChartTypes {
  SALES_PER_RESTAURANT = 'Sales per restaurant',
  UPSELL_RATE = 'Upsell rate',
  TRANSACTIONS_PER_RESTAURANT = 'Transactions per restaurant',
  AVG_ORDER_SUM = 'AVG order sum',
}

enum TimePeriods {
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  YTD = 'YTD',
  ONE_YEAR = 'ONE_YEAR',
}

type MainLineChartProps = {}

function MainLineChart({}: MainLineChartProps) {
  const [chartType, setChartType] = useState(ChartTypes.SALES_PER_RESTAURANT)
  const [timePeriod, setTimePeriod] = useState(TimePeriods.ONE_WEEK)

  const primaryAxis = useMemo(
    (): AxisOptions<any> => ({
      getValue: (datum: any) => datum.primary,
      elementType: 'line',
    }),
    []
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<any>[] => [
      {
        getValue: (datum: any) => datum.sum,
        elementType: 'line',
      },
    ],
    []
  )

  const handleChartTypeChange = (e: any, chartType: ChartTypes) => {
    e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    setChartType(chartType)
  }

  return (
    <Paper sx={{ height: '500px', p: 2 }} elevation={0}>
      <Stack sx={{ height: '100%', width: '100%' }} spacing={2}>
        <Typography variant="h3">{chartType}</Typography>
        <Stack spacing={1} direction="row" sx={{ overflowX: 'auto' }}>
          <Chip
            label="Sales per restaurant"
            variant={chartType === ChartTypes.SALES_PER_RESTAURANT ? 'filled' : 'outlined'}
            onClick={e => handleChartTypeChange(e, ChartTypes.SALES_PER_RESTAURANT)}
          />
          <Chip
            label="Upselling rate"
            variant={chartType === ChartTypes.UPSELL_RATE ? 'filled' : 'outlined'}
            onClick={e => handleChartTypeChange(e, ChartTypes.UPSELL_RATE)}
          />
          <Chip
            label="Transactions per restaurant"
            variant={chartType === ChartTypes.TRANSACTIONS_PER_RESTAURANT ? 'filled' : 'outlined'}
            onClick={e => handleChartTypeChange(e, ChartTypes.TRANSACTIONS_PER_RESTAURANT)}
          />
          <Chip
            label="Avg order sum"
            variant={chartType === ChartTypes.AVG_ORDER_SUM ? 'filled' : 'outlined'}
            onClick={e => handleChartTypeChange(e, ChartTypes.AVG_ORDER_SUM)}
          />
        </Stack>
        <Box flex={3}>
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
            }}
          />
        </Box>
        <Stack spacing={1} direction="row" flexWrap="wrap" alignItems="center" mt={1}>
          <Chip
            label="1W"
            variant={timePeriod === TimePeriods.ONE_WEEK ? 'filled' : 'outlined'}
            onClick={() => setTimePeriod(TimePeriods.ONE_WEEK)}
          />
          <Chip
            label="1M"
            variant={timePeriod === TimePeriods.ONE_MONTH ? 'filled' : 'outlined'}
            onClick={() => setTimePeriod(TimePeriods.ONE_MONTH)}
          />
          <Chip
            label="YTD"
            variant={timePeriod === TimePeriods.YTD ? 'filled' : 'outlined'}
            onClick={() => setTimePeriod(TimePeriods.YTD)}
          />
          <Chip
            label="1Y"
            variant={timePeriod === TimePeriods.ONE_YEAR ? 'filled' : 'outlined'}
            onClick={() => setTimePeriod(TimePeriods.ONE_YEAR)}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default MainLineChart
