import { useEffect, useState } from 'react'
import AvailabilityTableRow from './AvailabilityTableRow'

const timeList = [1, 2, 3, 4, 5, 6, 7]

function AvailabilityTable({ specialAvailability, onChange }, ...rest) {
  const handleDayUpdate = (day, newSchedule) => {
    const newData = { ...specialAvailability }

    if (newSchedule === null) {
      delete newData[day]
    } else {
      newData[day] = newSchedule
    }

    onChange(newData)
  }

  const handleHideWhenUnavailable = ({ target }) => {
    onChange({ ...specialAvailability, hideWhenUnavailable: target.checked })
  }

  return (
    <div {...rest}>
      <table>
        <thead>
          <tr>
            <th>Day</th>
            <th>From</th>
            <th>To</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
          {timeList.map(dayNumber => {
            return (
              <AvailabilityTableRow
                dayNumber={dayNumber}
                onChange={handleDayUpdate}
                specialAvailability={specialAvailability}
                key={dayNumber}
              />
            )
          })}
        </tbody>
      </table>

      <br />
      <label>
        <input
          type="checkbox"
          className="filled-in checkbox-mynt"
          name="hideWhenUnavailable"
          defaultChecked={Boolean(specialAvailability?.hideWhenUnavailable)}
          onChange={handleHideWhenUnavailable}
        />
        <span>Hide when unavailable</span>
      </label>
    </div>
  )
}

export default AvailabilityTable
