import { request } from './base'
export async function listTokensByAnonymous() {
  const resp = await request(`/listTokensByAnonymous`, 'get')
  return resp?.data
}

export async function listCoupons(id = undefined) {
  const resp = await request(`/cms/coupons/`, 'get', {
    params: {
      restaurantId: id,
    },
  })
  return resp?.data
}

export async function updateCoupon(id, payload) {
  const resp = await request(`/cms/coupons/${id}`, 'put', {
    payload,
  })
  return resp?.data
}

export async function removeCoupon(id) {
  const resp = await request(`/cms/coupons/${id}`, 'delete')
  return resp?.data
}

export async function generateCouponCode() {
  const resp = await request(`/cms/coupons/generateCode`, 'post', {})
  return resp?.data
}

export async function createCoupon(payload) {
  const resp = await request(`/cms/coupons/create`, 'post', {
    payload,
  })
  return resp?.data
}

export async function getRestaurantByAdmin(id) {
  const resp = await request(`/admin/getRestaurants?id=${id}`, 'get')
  return resp?.data
}

export async function listRestaurantsByAdmin(payload) {
  const resp = await request(`/admin/getRestaurants`, 'get', {
    payload,
  })
  return resp?.data
}
