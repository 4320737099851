import { atom } from 'recoil'

export const selectedRestaurantState = atom<null | any>({
  key: 'selectedRestaurantListAtom',
  default: null,
  effects: [
    ({ onSet }) =>
      onSet(newValue => {
        localStorage.setItem('selectedRestaurantId', newValue?.id)
      }),
  ],
})
