import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Toggelable from '../../../../components/Toggleable/Toggleable'
import { useDropzone } from 'react-dropzone'
import { uploadFile } from '../../../../utils'
import { saveRestaurantInfoData } from '../../../../api/restaurantInfoApi'
import { Stack, Button, Typography, FormControlLabel, Checkbox } from '@mui/material'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'
import { Controller, useForm } from 'react-hook-form'

const ScreensaverEdit = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const [saving, setSaving] = useState(false)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  })
  const [currentFiles, setCurrentFiles] = useState(selectedRestaurant?.preCheckOutSetting?.assets || [])
  const [screensaverType, setScreensaverType] = useState(selectedRestaurant.preCheckOutSetting?.mode || 'off')
  const { control, getValues, handleSubmit } = useForm({
    defaultValues: {
      config: {
        enableEdenredPayment: Boolean(selectedRestaurant?.config?.enableEdenredPayment),
      },
    },
  })

  const files = acceptedFiles.map((file, fileIndex) => (
    <li key={fileIndex}>
      {file.name.indexOf('.mp4') > -1 ? (
        <video
          src={URL.createObjectURL(file)}
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      ) : (
        <img
          src={URL.createObjectURL(file)}
          alt=""
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      )}
      <br />
      <button
        className="btn-xs btn btn-small btn-info"
        onClick={() => {
          acceptedFiles.splice(fileIndex, 1)
        }}
      >
        Remove
      </button>
    </li>
  ))

  const currentFilesAsHTML = currentFiles.map((file: any) => (
    <li key={file}>
      {file.indexOf('.mp4') > -1 ? (
        <video
          src={file}
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      ) : (
        <img
          src={file}
          alt=""
          style={{
            height: '100px',
            width: 'auto',
            border: '1px solid lightgray',
          }}
        />
      )}
      <br />
      <button
        className="btn-xs btn btn-small btn-info"
        onClick={() => {
          const newFiles = currentFiles.filter((f: any) => f !== file)
          setCurrentFiles(newFiles)
        }}
      >
        Remove
      </button>
    </li>
  ))

  const selectScreensaverType = (event: any) => {
    console.log(event)
    if (event.target.checked) {
      setScreensaverType(event.target.value)
    }
  }

  // const [imgUrl, setImgUrl] = useState(null);

  // const handleImageInput = (event) => {
  //   let imgUrl = URL.createObjectURL(event.target.files[0]);
  //   setImgUrl(imgUrl);
  //   console.log(imgUrl);
  // };

  const onSaveSettings = async () => {
    setSaving(true)
    handleSubmit(async (data: any) => {
      console.log('handleSubmit', data)
      const assets = await Promise.all(
        acceptedFiles.map(async file => {
          const filePath = `/restaurants/${selectedRestaurant.id}/images/${uuidv4()}.${file.name}`
          const url = await uploadFile(file, filePath)
          return url
        })
      )

      await saveRestaurantInfoData({
        id: selectedRestaurant.id,
        preCheckOutSetting: {
          mode: screensaverType,
          assets: [...currentFiles, ...assets],
        },
        config: {
          ...(selectedRestaurant?.config || {}),
          enableEdenredPayment: data?.config?.enableEdenredPayment,
        },
      })
      setSaving(false)
    }, console.error)()
  }

  useEffect(() => {
    setCurrentFiles(selectedRestaurant?.preCheckOutSetting?.assets || [])
    setScreensaverType(selectedRestaurant?.preCheckOutSetting?.mode || 'off')
  }, [selectedRestaurant])

  return (
    <Stack spacing={2} minHeight="100%" justifyContent="space-between">
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h5">Preferences</Typography>
          <Stack>
            <Controller
              name="config.enableEdenredPayment"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox checked={field.value} onChange={field.onChange} />}
                  label={'Enable Edenred Payment'}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h5">
            Add the ability to display an image during the checkout. This can for example be information about a loyalty
            app or
          </Typography>
          <ul>
            <li>
              <label>
                <input
                  className="with-gap"
                  type="radio"
                  name="screen-saver"
                  id="ss-default"
                  value={'off'}
                  checked={screensaverType === 'off'}
                  onChange={event => selectScreensaverType(event)}
                />
                <span>Off</span>
              </label>
            </li>
            <li>
              <label htmlFor="ss-slider">
                <input
                  className="with-gap"
                  type="radio"
                  name="screen-saver"
                  id="ss-slider"
                  value={'single'}
                  checked={screensaverType === 'single'}
                  onChange={event => selectScreensaverType(event)}
                />
                <span>On - Display screen before checkout</span>
              </label>
            </li>
          </ul>

          {screensaverType === 'single' && (
            <section className="dropzone-container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag & drop an image here, or click to select file</p>
                <p>Supports only images (1080x1920 resolution for best results)</p>
              </div>
              <aside>
                <h4>Files</h4>
                <ul className="file-list">
                  {currentFilesAsHTML}
                  {files}
                </ul>
              </aside>
            </section>
          )}
        </Stack>
      </Stack>
      <Button variant="contained" size="large" onClick={onSaveSettings} disabled={saving}>
        Save
      </Button>
    </Stack>
  )
}

export default ScreensaverEdit
