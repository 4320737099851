import { stringify } from 'uuid'

export default function OrderTypeChip(type) {
  if (!type) {
    return
  }

  let finalText = type.toLowerCase()

  let icon = 'directions_walk'

  if (type == 'EAT HERE') {
    icon = 'local_dining'
  }
  if (type == 'takeaway') {
    icon = 'directions_walk'
  }

  if (type == 'PRE ORDER') {
    icon = 'access_time'
  }

  return (
    <div className="chip tooltipped order-chip" data-tooltip={finalText} style={{ backgroundColor: `rgba(${'red'})` }}>
      <i className="material-icons">{icon}</i>
    </div>
  )
}
