import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import TranslationsTable from '../../../../../../../ui/TranslationsTable/TranslationsTable'
import { Controller, FieldError, useFormContext, useWatch } from 'react-hook-form'
import Item, { Extra as TExtra } from '../../../../../../../types/Item'
import DeleteButton from '../../../../../../../ui/DeleteButton/DeleteButton'
import theme from '../../../../../../../theme'
import usePriceFormat from '../../../../../../../hooks/usePriceFormat'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

type ExtraItemProps = {
  restaurant: any
  index: number
  onDeleteClick: (args: any) => any
  dragHandleProps?: any
}

function ExtraItem({ dragHandleProps, restaurant, index, onDeleteClick }: ExtraItemProps) {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<Item>()
  const [isExpanded, setExpanded] = useState<boolean>(false)
  const extras = useWatch({ control, name: 'extras' })
  const { withCurrencySymbol } = usePriceFormat()

  useEffect(() => {
    if (Object.keys(errors).some(error => error.includes(`extras[${index}]`))) {
      setExpanded(true)
    }
  }, [errors])

  return (
    <Paper elevation={4} sx={{ mt: 2 }}>
      <Stack padding={2} spacing={2}>
        <div onClick={() => setExpanded(prev => !prev)} style={{ cursor: 'pointer' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
              <Typography variant="h5">
                {(extras?.[index] as TExtra<'extraItem'>)?.translations?.name?.en ||
                  (extras?.[index] as TExtra<'extraItem'>)?.name}
              </Typography>
              <Typography variant="caption" sx={{ color: theme.palette.grey[700] }}>
                Regular extra
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="subtitle1">
                {withCurrencySymbol((extras?.[index] as TExtra<'extraItem'>)?.price)}
              </Typography>
              <IconButton
                sx={{ '&:focus': { bgcolor: 'inherit' } }}
                color="mynt"
                size="small"
                onClick={e =>
                  setExpanded(prev => {
                    e.stopPropagation()
                    return !prev
                  })
                }
              >
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <div onClick={e => e.stopPropagation()}>
                <ButtonBase {...dragHandleProps}>
                  <DragIndicatorIcon />
                </ButtonBase>
              </div>
              <DeleteButton onClick={onDeleteClick} />
            </Stack>
          </Stack>
        </div>

        {isExpanded && (
          <Stack spacing={2}>
            <Divider />
            <TranslationsTable
              restaurant={restaurant}
              formBase={`extras[${index}].translations.name`}
              title="Extra Name"
            />
            <Stack spacing={1}>
              <Typography variant="subtitle1">Price</Typography>
              <TextField
                size="small"
                inputProps={{ className: 'browser-default' }}
                {...register(`extras.${index}.price` as const)}
                error={Boolean(errors[`extras[${index}].price` as keyof typeof errors])}
                helperText={
                  (errors[`extras[${index}].price` as keyof typeof errors] as FieldError | undefined)?.message
                }
              />
            </Stack>
            <Stack spacing={1}>
              <Controller
                name={`extras.${index}.isMultiple`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} onChange={field.onChange} />}
                    label={
                      <span>
                        Allow users to <b>choose multiple</b>
                      </span>
                    }
                  />
                )}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}

export default ExtraItem
