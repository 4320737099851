import { stringify } from 'uuid'

export default function RestaurantChip(name) {
  let arr = name.split(' ')
  let finalText = name

  if (arr.length > 1) {
    finalText = arr[0].charAt(0) + arr[1].charAt(0)
  }
  if (arr.length == 1) {
    finalText = name.substring(0, 2)
  }
  let { red, green, blue } = pastel_colour(name)
  let color = red + ',' + green + ',' + blue + ',0.5'
  return (
    <div
      key={finalText}
      className="chip tooltipped restaurant-chip"
      data-tooltip={name}
      style={{ backgroundColor: `rgba(${color})` }}
    >
      {finalText}
    </div>
  )
}

function pastel_colour(input_str) {
  //TODO: adjust base colour values below based on theme
  var baseRed = 128
  var baseGreen = 128
  var baseBlue = 128

  //lazy seeded random hack to get values from 0 - 256
  //for seed just take bitwise XOR of first two chars
  var seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1)
  var rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256
  var rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256
  var rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256

  //build colour
  var red = Math.round((rand_1 + baseRed) / 2)
  var green = Math.round((rand_2 + baseGreen) / 2)
  var blue = Math.round((rand_3 + baseBlue) / 2)

  return { red: red, green: green, blue: blue }
}
