import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAuthContext } from '../../../../contexts/AuthContext'
import { useEffect, useState } from 'react'
import TeamMemberForm from '../../../../components/forms/TeamMemberForm'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { restaurantListState } from '../../../../atoms/RestaurantListAtom'
import DeleteConfirmButton from '../../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import { removeStaff, updateStaff } from '../../../../api/teamApi'
import { teamListState } from '../../../../atoms/TeamsAtoms'
import { useRequestStatus } from '../../../../hooks/useRequestStatus'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'
import CollapsibleTableRow from '../../../../components/CollapsibleTableRow/CollapsibleTableRow'

type TeamTableRowProps = {
  teamMember: any
  onExpand?: (teamMemberId: string) => any
  expanded?: boolean
}

function TeamTableRow({ teamMember, onExpand, expanded }: TeamTableRowProps) {
  const { isAdmin } = useAuthContext()
  const restaurantList = useRecoilValue(restaurantListState)
  const [teamList, setTeamList] = useRecoilState(teamListState)
  const { isFetching, makeApiRequest } = useRequestStatus()
  const methods = useForm({ defaultValues: teamMember })
  const { handleSubmit, control } = methods
  const { owningRestaurantIds } = useWatch({ control })
  const owningRestaurants = owningRestaurantIds
    ?.map((restaurantId: string) => restaurantList?.find((restaurant: any) => restaurant.id === restaurantId))
    .filter(Boolean)

  const onSubmit = async (data: any) => {
    console.log('user updated', data)
    const { isSuccess } = await makeApiRequest(updateStaff, [data.id, data])
    if (isSuccess) {
      setTeamList(teamList.map((staff: any) => (staff.id === data.id ? data : staff)))
      onExpand && onExpand(data.id)
    }
  }

  const handleDelete = async (id: string) => {
    console.log('delete', id)
    const { isSuccess } = await makeApiRequest(removeStaff, [id])
    if (isSuccess) {
      setTeamList(teamList.filter((staff: any) => staff.id !== id))
    }
  }

  useEffect(() => {
    methods.reset(teamMember)
  }, [teamMember])

  return (
    <>
      <TableRow key={teamMember.id} sx={{ borderBottom: 'unset', '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Stack>
            <Box fontWeight="bold">{teamMember.email}</Box>
            {isAdmin && <Box>{teamMember.phoneNumber}</Box>}
          </Stack>
        </TableCell>
        <TableCell>
          <AvatarGroup total={owningRestaurants?.length}>
            {owningRestaurants?.map((restaurant: any) => (
              <Tooltip title={restaurant?.name} placement="top" arrow>
                <Avatar src={restaurant?.image}>{restaurant?.name?.[0] + restaurant?.name?.[1]}</Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        </TableCell>
        <TableCell>
          <Stack direction="row-reverse" spacing={1}>
            <DeleteConfirmButton
              message="Are you sure you want to delete this team member?"
              onClick={() => handleDelete(teamMember.id)}
              disabled={isFetching}
            />
            <Button onClick={() => onExpand && onExpand(teamMember.id)} disabled={isFetching}>
              Edit
            </Button>
          </Stack>
        </TableCell>
      </TableRow>

      <CollapsibleTableRow expanded={expanded}>
        <Stack m={4} spacing={4}>
          <FormProvider {...methods}>
            <TeamMemberForm />
          </FormProvider>
          <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={isFetching}>
            Save
          </Button>
        </Stack>
      </CollapsibleTableRow>
    </>
  )
}

export default TeamTableRow
