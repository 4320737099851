import { FormProvider, useForm } from 'react-hook-form'
import useYupValidationResolver from '../../../../../../hooks/useYupValidationResolver'
import { categorySchema } from '../../../../../../schemas'
import { Category } from '../../../../../../types'
import { Button } from '@mui/material'
import CategoryForm from '../../../../../../components/forms/CategoryForm'
import { useEffect } from 'react'
import useDepricatedCategoryValues from '../../../../../../hooks/useDepricatedCategoryValues'

type EditCategoryFormProps = {
  category: Category
  restaurant: any
  onSave: (item: Category, id: string) => Promise<void>
  disabled?: boolean
}

function EditCategoryForm({ category, restaurant, onSave, disabled }: EditCategoryFormProps) {
  const resolver = useYupValidationResolver(categorySchema)
  const methods = useForm<Category>({ defaultValues: category, resolver })
  const { reset, handleSubmit, getValues, setValue } = methods
  const trigger = useDepricatedCategoryValues(getValues, setValue)

  const onSubmit = (item: any) => {
    console.log('submitting: ', item)
    onSave(item, item.id)
  }

  useEffect(() => {
    reset(category)
    trigger()
  }, [category])

  return (
    <FormProvider {...methods}>
      <CategoryForm restaurant={restaurant} />
      <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={disabled}>
        Save
      </Button>
    </FormProvider>
  )
}

export default EditCategoryForm
