import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material'

type TableBodySkeletonProps = {
  rowsNum?: number
  columnsNum?: number
}

function TableBodySkeleton({ rowsNum = 10, columnsNum = 3 }) {
  return (
    <TableBody>
      {new Array(rowsNum).fill(null).map((row, rowIndex) => {
        return (
          <TableRow key={rowIndex}>
            {new Array(columnsNum).fill(null).map((column, columnIndex) => (
              <TableCell key={columnIndex}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default TableBodySkeleton
