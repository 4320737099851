import React, { useState } from 'react'
import ChangeLang from '../../components/changeLang/ChangeLang'
import LoginForm from './loginForm/LoginForm'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Paper, Slide, Stack, Typography, Zoom } from '@mui/material'
import theme from '../../theme'

function Auth() {
  const { t, i18n } = useTranslation()

  return (
    <Stack width="100vw" height="100vh" position="relative" alignItems="center">
      <Box
        component="img"
        src="img/restaurantBg.jpg"
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />

      <Stack sx={{ position: 'relative', zIndex: 20, width: '100%', alignItems: 'center' }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Box
            component="img"
            src="img/MyntLogo.png"
            className="logo"
            sx={{
              opacity: '0.8',
            }}
          />
        </Stack>
        <Paper sx={{ maxWidth: '80vw', mt: 8, width: '400px' }}>
          <Stack spacing={2} pt={3} pb={4} px={4} alignItems="center">
            <Stack>
              <Typography variant="h3" textAlign="center">
                Get started with
              </Typography>
              <Typography variant="h4" textAlign="center" sx={{ color: theme.palette.mynt.main }}>
                Mynt Merchant
              </Typography>
            </Stack>
            <Divider className="browser-default" sx={{ width: '100%' }} />
            <LoginForm />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

export default Auth
