import { Paper, PaperOwnProps, SxProps } from '@mui/material'
import React from 'react'
import theme from '../../theme'
import { set } from 'react-hook-form'

type MenuElementBaseProps = {
  children: React.ReactNode
  onHoverStart?: () => void
  onHoverEnd?: () => void
}

const styles: SxProps = {
  p: 3,
  '&:hover': {},
}

function MenuElementBase({ children, onHoverStart, onHoverEnd }: MenuElementBaseProps) {
  const [isHovered, setHovered] = React.useState(false)

  const handleHoverStart = () => {
    setHovered(true)
    onHoverStart && onHoverStart()
  }

  const handleHoverEnd = () => {
    setHovered(false)
    onHoverEnd && onHoverEnd()
  }

  return (
    <div onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd}>
      <Paper sx={styles} elevation={isHovered ? 2 : 0}>
        {children}
      </Paper>
    </div>
  )
}

export default MenuElementBase
