import React, { useEffect, useState } from 'react'
import Toggelable from '../../components/Toggleable/Toggleable'
import { createDevice } from '../../api/deviceApi'
import { useMenuContext } from '../../contexts/MenuContext'
import Tooltip from '../../components/Tooltip/Tooltip'
import { FormProvider, useForm } from 'react-hook-form'
import PrinterForm from '../../components/forms/PrinterForm'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import { Printer } from '../../types'
import printerSchema from '../../schemas/printerSchema'
import PartialBackdrop from '../../ui/PatrialBackdrop/PartialBackdrop'
import { Button, Stack, Typography } from '@mui/material'
import { PrintingMethods } from '../../types/Printer'
import { AVAILABLE_LANGUAGES, SUPPORTED_COUNTRIES } from '../../constants'

type AddPrinterFormProps = {
  restaurantId: string
  restaurant?: any
  onSuccess?: (printer: any) => void
  onCancel?: () => void
}

function AddPrinterForm({ onSuccess, onCancel, restaurantId, restaurant }: AddPrinterFormProps) {
  // @ts-ignore
  const [isSaving, setSaving] = useState(false)

  const preferedDefaultReceiptsLanguage = SUPPORTED_COUNTRIES?.find(
    country => country.operatingCountryCode === restaurant?.config?.operatingCountryCode
  )?.language
  const defaultReceiptsLanguage = AVAILABLE_LANGUAGES.includes(
    preferedDefaultReceiptsLanguage as typeof AVAILABLE_LANGUAGES[number]
  )
    ? preferedDefaultReceiptsLanguage
    : 'en'

  const resolver = useYupValidationResolver(printerSchema)
  const methods = useForm<Printer>({
    resolver,
    defaultValues: {
      restaurantId: restaurantId,
      model: '',
      printerPaperWidth: '58mm',
      printingMethod: PrintingMethods.SUNMI_V2,
      menuIds: [],
      autoAcceptOrderInSeconds: 0,
      shouldAutoPrintOrders: true,
      staffPinCode: '0000',
      receiptsLanguage: defaultReceiptsLanguage,
    },
  })

  const onSubmit = (data: any) => {
    // Removing self-generated id
    delete data.id

    console.log('Submit valid', data)

    setSaving(true)

    createDevice(data)
      .then(res => {
        if (!res.success) {
          alert(res.error)
        } else onSuccess && onSuccess(res?.data)
      })
      .catch(error => {
        console.error('ERROR ', error)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const handleCancelClick = () => {
    console.log('Cancel')
    onCancel && onCancel()
  }

  // Updating restaurant id when restaurant changes
  useEffect(() => {
    methods.setValue('restaurantId', restaurantId)
  }, [restaurantId])

  return (
    <PartialBackdrop show={isSaving}>
      <Stack spacing={3} p={1}>
        <Typography variant="h4">Add Printer:</Typography>
        <FormProvider {...methods}>
          <PrinterForm />
        </FormProvider>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="contained" onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}>
            Add
          </Button>
          <Button variant="contained" color="info" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </PartialBackdrop>
  )
}

export default AddPrinterForm
