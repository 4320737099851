import React from 'react'
import { initializeApp } from 'firebase/app'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import 'react-tooltip/dist/react-tooltip.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import './i18n'

import 'simple-vanilla-notifications/defaults.css'
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize'
import 'material-icons/iconfont/material-icons.css'

import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en' // locale-data for en

document.documentElement
  .appendChild(
    Object.assign(document.createElement('script'), {
      textContent: 'window.globalThis = window',
    })
  )
  .remove()

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
} else {
  // production
  Sentry.init({
    dsn: 'https://346ef64dca7c6965940a32e77fda5ee9@o4507532232163328.ingest.de.sentry.io/4507532338331728',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    release: process.env.REACT_APP_COMMIT_HASH,
    environment: process.env.REACT_APP_FIREBASE_ENV || 'unknown',
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

const project = String(process.env.REACT_APP_FIREBASE_ENV)
const firebaseConfig = {
  'pepi-store-test': {
    apiKey: 'AIzaSyDAk70Yxt43fhgciElaWUmjphryab6Bd_Y',
    authDomain: 'pepi-store-test.firebaseapp.com',
    projectId: 'pepi-store-test',
    storageBucket: 'pepi-store-test.appspot.com',
    messagingSenderId: '246575562460',
    appId: '1:246575562460:web:e83e0bb946d50166c63e19',
    measurementId: 'G-Y0T95X0627',
  },
  'pepi-cms': {
    apiKey: 'AIzaSyDc7R8CPTBLb1NY1EhOVxebS7KUmwYaaKE',
    authDomain: 'pepi-cms.firebaseapp.com',
    projectId: 'pepi-cms',
    storageBucket: 'pepi-cms.appspot.com',
    messagingSenderId: '116906334856',
    appId: '1:116906334856:web:cabd167cc0229e3f27dd3d',
  },
  'pepi-internal-store-test': {
    apiKey: 'AIzaSyBW-5owiqbMjvs0124M8Z_qfMJpkIjeMDg',
    authDomain: 'pepi-internal-store-test.firebaseapp.com',
    projectId: 'pepi-internal-store-test',
    storageBucket: 'pepi-internal-store-test.appspot.com',
    messagingSenderId: '268347490576',
    appId: '1:268347490576:web:f1be7c9529ae7764442595',
  },
}[project]

initializeApp(firebaseConfig)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
