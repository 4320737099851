import { Button, Typography, Stack } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'

type DeleteConfirmButtonProps = {
  onClick: (args: any) => any
  targetText?: string
  message?: string
  disabled?: boolean
  [key: string]: any
}

function DeleteConfirmButton({ onClick, targetText, message, disabled, ...rest }: DeleteConfirmButtonProps) {
  const [isConfirming, setConfirming] = useState(false)

  return isConfirming ? (
    <ClickAwayListener onClickAway={() => setConfirming(false)}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="body1" color="error">
          {message ? message : `Sure you want to delete ${targetText || 'this'}?`}
        </Typography>
        <Button
          color="error"
          size="small"
          variant="contained"
          className="browser-default"
          onClick={onClick}
          disabled={disabled}
        >
          Yes
        </Button>
        <Button
          color="success"
          size="small"
          variant="contained"
          className="browser-default"
          onClick={() => setConfirming(false)}
          disabled={disabled}
        >
          No
        </Button>
      </Stack>
    </ClickAwayListener>
  ) : (
    <Button
      variant="outlined"
      startIcon={<DeleteIcon color="error" />}
      color="error"
      size="small"
      onClick={() => setConfirming(true)}
      disabled={disabled}
      {...rest}
    >
      Delete
    </Button>
  )
}

export default DeleteConfirmButton
