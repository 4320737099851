import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { FieldError, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import Item, { Option } from '../../../../../../types/Item'
import AddIcon from '@mui/icons-material/Add'
import TranslationsTable from '../../../../../../ui/TranslationsTable/TranslationsTable'
import DeleteButton from '../../../../../../ui/DeleteButton/DeleteButton'

type GroupOptionsProps = {
  groupIndex: number
  restaurant: any
}

function GroupOptions({ groupIndex, restaurant }: GroupOptionsProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<Item>()
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: `options.${groupIndex}.optionList`,
  })

  const handleOptionAdd = () => {
    const newOption = {
      price: '',
      translations: { name: { en: '' } },
    }
    append(newOption)
  }

  const handleOptionDelete = (index: number) => {
    remove(index)
  }
  console.log()

  return (
    <Stack spacing={2}>
      {fields.map((field, index) => (
        <Card variant="outlined" key={field.id}>
          <Stack padding={2} spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">{field?.translations?.name?.en || (field as any).name}</Typography>
              <DeleteButton onClick={() => handleOptionDelete(index)} />
            </Stack>
            <TranslationsTable
              title="Option name"
              restaurant={restaurant}
              formBase={`options[${groupIndex}].optionList[${index}].translations.name`}
            />
            <Stack spacing={1}>
              <Typography variant="subtitle1">Price</Typography>
              <TextField
                size="small"
                inputProps={{ className: 'browser-default' }}
                {...register(`options.${groupIndex}.optionList.${index}.price` as const)}
                error={Boolean(errors[`options[${groupIndex}].optionList[${index}].price` as keyof typeof errors])}
                helperText={
                  (
                    errors[`options[${groupIndex}].optionList[${index}].price` as keyof typeof errors] as
                      | FieldError
                      | undefined
                  )?.message
                }
              />
            </Stack>
          </Stack>
        </Card>
      ))}
      <Stack flexDirection="row-reverse">
        <Button variant="outlined" endIcon={<AddIcon />} onClick={handleOptionAdd}>
          Add option
        </Button>
      </Stack>
    </Stack>
  )
}

export default GroupOptions
