import React, { useEffect, useState } from 'react'
import { getOrders } from '../../api/restaurantInfoApi'

export default function AdminRestaurantCard({ restaurant, extraFilterParams, updateTotals, addChartData }) {
  const [filterParams, setFilterParams] = useState({
    limit: 50,
    restaurantId: restaurant.id,
  })

  const [totalAmount, setTotalAmount] = useState()
  const [amountToday, setAmountToday] = useState('')
  const [amountYesterday, setAmountYesterday] = useState('')
  const [amountBeforeYesterday, setAmountBeforeYesterday] = useState('')
  const [count, setCount] = useState('')
  const [countToday, setCountToday] = useState('')
  const [countYesterday, setCountYesterday] = useState('')
  const [loading, setLoading] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [haveUpdatedParent, setHaveUpatedParent] = useState(false)
  const [orders, setOrders] = useState([])

  const handleGetOrders = async () => {
    const now = new Date()
    const today = now.toISOString()
    now.setDate(now.getDate() - 1)
    const yesterday = now.toISOString()
    now.setDate(now.getDate() - 1)
    const dayBeforeYesterday = now.toISOString()
    const todayList = []
    const yesterdayList = []

    setLoading(true)

    try {
      const orderDataResp = await getOrders(filterParams)

      console.log('Requested: ', orderDataResp)

      let total = 0.0

      console.log('Today: ', today.slice(0, 10))
      let totalToday = 0.0
      let totalYesterday = 0.0
      let totalDayBeforeYesterday = 0.0

      let formattedOrders = []

      for (let i = 0; i < orderDataResp.data.length; i++) {
        total += parseFloat(orderDataResp.data[i].totalAmount)

        let formattedDate = orderDataResp.data[i].createdAt.slice(0, 10)

        formattedOrders.push({
          formattedDate,
          totalAmount: orderDataResp.data[i].totalAmount,
        })
        if (formattedDate == today.slice(0, 10)) {
          todayList.push(orderDataResp.data[i])
          totalToday += parseFloat(orderDataResp.data[i].totalAmount)
        } else if (formattedDate == yesterday.slice(0, 10)) {
          yesterdayList.push(orderDataResp.data[i])
          totalYesterday += parseFloat(orderDataResp.data[i].totalAmount)
        } else if (formattedDate == dayBeforeYesterday.slice(0, 10)) {
          yesterdayList.push(orderDataResp.data[i])
          totalDayBeforeYesterday += parseFloat(orderDataResp.data[i].totalAmount)
        }
      }

      if (!haveUpdatedParent) {
        addChartData(formattedOrders)
        setHaveUpatedParent(true)
      }

      total = Number(total.toFixed(2))
      totalToday = Number(totalToday.toFixed(2))
      totalYesterday = Number(totalYesterday.toFixed(2))
      totalDayBeforeYesterday = Number(totalDayBeforeYesterday.toFixed(2))
      console.log('TOTAL: ', total)

      setAmountToday(totalToday)
      setAmountYesterday(totalYesterday)
      setAmountBeforeYesterday(totalDayBeforeYesterday)

      setOrders(orderDataResp.data)
      setCount(orderDataResp.data.length)
      setCountToday(todayList.length)
      setCountToday(yesterdayList.length)
      setTotalAmount(total)
      setLoading(false)
    } catch {
      console.log('Something went wrong')
    }
  }

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      ...extraFilterParams,
    })
  }, [extraFilterParams])

  return (
    <div key={restaurant.id} className="card">
      <div className="card-content">
        <div className="col">
          <div className="row">
            <b>
              {restaurant.name} - {restaurant.city}
            </b>
          </div>
          <div className="row">
            <button className="btn" onClick={handleGetOrders}>
              Pull orders
            </button>
          </div>
          <div className="row">
            <b>
              {totalAmount != undefined
                ? totalAmount + '€ ' + count
                : loading && (
                    <div className="preloader-wrapper small active">
                      <div className="spinner-layer spinner-green-only">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  )}
            </b>
          </div>
          <button className="btn-small" onClick={e => setShowTable(!showTable)}>
            Table
          </button>
          {amountToday} -{amountYesterday} - {amountBeforeYesterday}
          {orders.length > 0 && showTable ? (
            <div className="row">
              <table>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(order => (
                    <tr>
                      <td>{order.fromPlatform}</td>
                      <td>{order.createdAt}</td>
                      <td>{order.status}</td>
                      <td>{order.totalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
