import {
  Button,
  Stack,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Tabs,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import TranslateIcon from '@mui/icons-material/Translate'
import EditOffIcon from '@mui/icons-material/EditOff'
import EditableText from '../EditableText/EditableText'
import { useFormContext } from 'react-hook-form'
import Item from '../../types/Item'
import { useEffect, useLayoutEffect, useState } from 'react'
import theme from '../../theme'
import translate from '../../api/translate'

type TranslationsTableProps = {
  restaurant: any
  formBase: string
  title: string
  optional?: boolean
}

function TranslationsTable({ restaurant, formBase, title, optional }: TranslationsTableProps) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()
  const [languageNames, setLanguageNames] = useState<null | Intl.DisplayNames>(null)
  const [isFetching, setFetching] = useState(false)
  const [supportedLanguages, setSupportedLanguages] = useState<string[] | null>(null)

  const handleAutoTranslate = () => {
    setFetching(true)
    const translations = getValues(formBase)
    const missingTranslations = Object.keys(translations).filter(key => !translations[key])

    const promises: any[] = []
    missingTranslations.forEach(missingTranslation => {
      promises.push(translate(translations.en, missingTranslation))
    })

    Promise.all(promises).then(translations => {
      translations.forEach((translation, index) => {
        const translatedText = translation?.[0]?.translatedText
        setValue(`${formBase}.${missingTranslations[index]}`, translatedText)
      })
      setFetching(false)
    })
  }

  const handleClear = () => {
    supportedLanguages?.forEach((languageCode: string) => {
      setValue(`${formBase}.${languageCode}`, '')
    })
    console.log(getValues())
  }

  // Layout effect to prevent error shown for half a sec
  useLayoutEffect(() => {
    setLanguageNames(
      new Intl.DisplayNames(['en'], {
        type: 'language',
      })
    )

    if (!restaurant?.config?.languagesSupported) return
    const sortedLanguages = [...restaurant?.config?.languagesSupported]
    if (sortedLanguages[0] !== 'en') {
      // Moving en to the first place in translations table
      const enIndex = sortedLanguages?.findIndex((lngCode: string) => lngCode === 'en')
      sortedLanguages.splice(enIndex, 1)
      sortedLanguages.unshift('en')
    }
    setSupportedLanguages(sortedLanguages)
  }, [restaurant?.config?.languagesSupported])

  if (!supportedLanguages || supportedLanguages?.length === 0) {
    let warningColor = theme.palette.warning.main
    if (Object.keys(errors).some(key => key.startsWith(formBase))) {
      warningColor = theme.palette.error.main
    }

    return (
      <Stack>
        <Typography variant="subtitle1" sx={{ color: warningColor }}>
          No languages supported!
        </Typography>
        <Typography variant="subtitle2" sx={{ color: warningColor }}>
          Please add languages in the restaurant config.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={{ position: 'relative' }}>
          <Tooltip title={`Auto-translate missing ${title}s`}>
            <IconButton color="mynt" size="small" onClick={handleAutoTranslate} disabled={isFetching}>
              <TranslateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Clear ${title}s`}>
            <IconButton color="warning" size="small" onClick={handleClear} disabled={isFetching}>
              <EditOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {isFetching && (
            <CircularProgress size={28} thickness={3} sx={{ position: 'absolute', left: '-1px', top: '0' }} />
          )}
        </Box>
      </Stack>
      <TableContainer sx={{ maxWidth: '70vw' }}>
        <Table>
          <TableHead>
            <TableRow>
              {supportedLanguages?.map((languageCode: string) => (
                <TableCell key={languageCode}>
                  {languageNames?.of(languageCode)}
                  {languageCode === 'en' && !optional && '*'}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {supportedLanguages?.map((languageCode: string) => (
                <TableCell key={languageCode}>
                  <EditableText
                    {...register(`${formBase}.${languageCode}` as const)}
                    error={Boolean(errors?.[`${formBase}.${languageCode}`])}
                    helperText={errors?.[`${formBase}.${languageCode}`]?.message}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default TranslationsTable
