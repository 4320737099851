import { Button, Dialog, DialogActions, DialogContent, IconButton, Stack, useMediaQuery } from '@mui/material'
import theme from '../../../../../../theme'
import { FormProvider } from 'react-hook-form'
import CategoryForm from '../../../../../../components/forms/CategoryForm'
import ItemForm from '../../../../../../components/forms/ItemForm/ItemForm'
import CloseIcon from '@mui/icons-material/Close'

type EditMenuEntryLayoutProps = {
  onClose: () => void
  children: React.ReactNode
}

function EditMenuEntryLayout({ onClose, children }: EditMenuEntryLayoutProps) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  if (isDesktop) {
    return (
      <Stack
        spacing={2}
        px={4}
        pb={2}
        sx={{ border: `4px dashed ${theme.palette.mynt.main}`, borderRadius: 2, position: 'relative' }}
      >
        <IconButton size="small" sx={{ position: 'absolute', top: 1, right: 1 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {children}
      </Stack>
    )
  } else {
    return (
      <Dialog open={true} onClose={onClose}>
        <DialogContent>
          <Stack spacing={2}>{children}</Stack>
        </DialogContent>
      </Dialog>
    )
  }
}

export default EditMenuEntryLayout
