const SUPPORTED_COUNTRIES = [
  {
    operatingCountryCode: 'FI',
    language: 'fi',
    vat: '0.14',
    alcoholVat: '0.255',
    currency: {
      code: 'EUR',
      symbol: '€',
    },
  },
  {
    operatingCountryCode: 'SE',
    language: 'sv',
    vat: '0.12',
    alcoholVat: '0.25',
    currency: {
      code: 'SEK',
      symbol: 'kr',
    },
  },
  {
    operatingCountryCode: 'EE',
    language: 'et',
    vat: '0.22',
    alcoholVat: '0.22',
    currency: {
      code: 'EUR',
      symbol: '€',
    },
  },
  {
    operatingCountryCode: 'ES',
    language: 'es',
    vat: '0.10',
    alcoholVat: '0.10',
    currency: {
      code: 'EUR',
      symbol: '€',
    },
  },
]

export default SUPPORTED_COUNTRIES
