import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import Select from 'react-select'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMenuContext } from '../../contexts/MenuContext'
import makeAnimated from 'react-select/animated'
import { useAuthContext } from '../../contexts/AuthContext'
import { restaurantListState } from '../../atoms/RestaurantListAtom'
import { useRecoilValue } from 'recoil'

const animatedComponents = makeAnimated()

function TeamMemberForm() {
  const { t } = useTranslation()
  // @ts-ignore
  const { isAdmin } = useAuthContext()
  const restaurantList = useRecoilValue(restaurantListState)

  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext()

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="h5">Name</Typography>
        <TextField
          placeholder="Name"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          {...register('displayName')}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">Email</Typography>
        <TextField placeholder="email" fullWidth inputProps={{ className: 'browser-default' }} {...register('email')} />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">Phone</Typography>
        <TextField
          placeholder="Phone"
          fullWidth
          inputProps={{ className: 'browser-default' }}
          {...register('phoneNumber')}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h5">Manages</Typography>
        <Controller
          name="owningRestaurantIds"
          control={control}
          render={({ field }) => (
            <Select
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              name="colors"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              options={restaurantList?.map(restaurant => ({ value: restaurant.id, label: restaurant.name }))}
              onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
              value={field?.value
                ?.map((restaurantId: string) => restaurantList?.find(({ id }) => restaurantId === id))
                ?.filter(Boolean)
                ?.map((restaurant: any) => ({ value: restaurant?.id, label: restaurant?.name }))}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          )}
        />
      </Stack>
      {isAdmin && (
        <Stack spacing={1}>
          <Divider />
          <Typography variant="h5" color="red">
            Admin only
          </Typography>
          <Stack spacing={1}>
            <Typography variant="h5">Staff at</Typography>
            <Controller
              name="workingRestaurantIds"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  name="workingRestaurantIds"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  options={restaurantList?.map(restaurant => ({ value: restaurant.id, label: restaurant.name }))}
                  onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
                  value={field?.value
                    ?.map((restaurantId: string) => restaurantList?.find(({ id }) => restaurantId === id))
                    ?.filter(Boolean)
                    ?.map((restaurant: any) => ({ value: restaurant?.id, label: restaurant?.name }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default TeamMemberForm
