import { useEffect } from 'react'
import M from 'materialize-css'
import { generateOnboardingLink, syncVektoriMenu } from '../../api/restaurantInfoApi'

import styles from './styles.module.scss'
import { useRequestStatus } from '../../hooks/useRequestStatus'

function AdminConfigTable(props) {
  const { makeApiRequest } = useRequestStatus()

  useEffect(() => {
    var modals = document.querySelectorAll('.modal')
    M.Modal.init(modals, {})
  })

  const onGenerateOnboardingLink = async (email, type) => {
    if (['stripe', 'vivawallet', 'sumup'].indexOf(type) === -1) {
      alert('Invalid type')
    }
    const resp = await generateOnboardingLink(email, type)
    prompt('Copy to clipboard: Ctrl+C, Enter', resp?.url)
  }

  console.log('./styles', styles)

  return (
    <>
      <tbody>
        {props?.data?.length &&
          props.data.map((item, index) => (
            <tr key={item.id}>
              <td>
                {item.name}
                <br />
                {item.id}
              </td>
              <td></td>
              <td></td>
              <td width="300" className={styles.restaurantConfigActions}>
                <a
                  className="btn-small"
                  href={`#${item.id}`}
                  onClick={() => onGenerateOnboardingLink(item.id, 'sumup')}
                >
                  Prep token for sumup
                </a>
                <a
                  className="btn-small"
                  href={`#${item.id}`}
                  onClick={() => onGenerateOnboardingLink(item.id, 'vivawallet')}
                >
                  Get VivaWallet On boardLink
                </a>
                <a
                  className="btn-small"
                  href={`#${item.id}`}
                  onClick={() => onGenerateOnboardingLink(item.id, 'stripe')}
                >
                  Get Stripe Onboard Link
                </a>
                <button
                  className="btn-small btn modal-trigger"
                  data-target="AdminConfigEditModal"
                  onClick={() => props.setEditIndex(item.id)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  )
}

export default AdminConfigTable
