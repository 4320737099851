import React, { useEffect, useState, useMemo, Suspense } from 'react'
import ReportTableRow from './ReportTableRow'
import moment from 'moment'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import selectedRestaurantReportsState from '../../selectors/selectedRestaurantReports'
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import TableBodySkeleton from '../../components/TableBodySkeleton/TableBodySkeleton'

enum ReportsType {
  SALES = 'sales',
  PAYOUTS = 'payouts',
}

const ReportsView = ({}) => {
  const [reportsType, setReportsType] = useState<ReportsType>(ReportsType.SALES)
  const reports = useRecoilValueLoadable(selectedRestaurantReportsState)
  const isLoading = reports.state === 'loading' || reports.contents === null

  console.log('reports', reports)

  const [filter, setFilter] = useState({
    dateRange: 'All time',
    name: '',
    reportType: 'monthly',
  })

  const filterReports = (reports: any, filter: any) => {
    const { dateRange, name } = filter

    let filteredReports = reports

    console.log('filteredReports', filteredReports)

    filteredReports = filteredReports
      .filter((report: any) => report.status === 'done')
      .filter((report: any) => report.type === filter.reportType)

    if (dateRange !== 'All time') {
      let startDate = moment()
      switch (dateRange) {
        case 'This week':
          startDate = moment().startOf('week')
          break
        case 'This month':
          startDate = moment().startOf('month')
          break
        case '3 months':
          startDate = moment().subtract(3, 'months').startOf('month')
          break
        case 'This year':
          startDate = moment().startOf('year')
          break
        default:
          break
      }
      filteredReports = filteredReports.filter((report: any) => moment(report.from) >= startDate)
    }

    if (name) {
      filteredReports = filteredReports.filter((report: any) => report.restaurant.name.includes(name))
    }

    return filteredReports
  }

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }))
  }

  const filteredReports = useMemo(() => {
    if (isLoading) return []
    return filterReports(reports.contents, filter)
  }, [reports.state, reports.contents?.length, filter])

  useEffect(() => {
    if (reportsType === ReportsType.SALES) {
      setFilter(prevFilter => ({ ...prevFilter, reportType: 'monthly' }))
    }
  }, [reportsType])

  return (
    <Stack spacing={2} py={2} px={{ xs: 0, sm: 4 }}>
      <Typography variant="h3">Reports</Typography>
      <Grid container>
        <Grid item xs={12} mb={2} mr={2}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={reportsType}
            onChange={(e: any, newValue: any) => newValue && setReportsType(newValue)}
          >
            <ToggleButton value={ReportsType.SALES} className="browser-default">
              Sales
            </ToggleButton>
            <ToggleButton value={ReportsType.PAYOUTS} className="browser-default">
              Payouts
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {reportsType === ReportsType.PAYOUTS && (
          <Grid item xs={6} pr={2}>
            <select
              className="browser-default"
              name="reportType"
              value={filter.reportType}
              onChange={handleFilterChange}
            >
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
            </select>
          </Grid>
        )}

        <Grid item xs={6}>
          <select className="browser-default" name="dateRange" value={filter.dateRange} onChange={handleFilterChange}>
            <option value="All time">All time</option>
            <option value="This week">This week</option>
            <option value="This month">This month</option>
            <option value="3 months">Last 3 months</option>
            <option value="This year">This year</option>
          </select>
        </Grid>
      </Grid>
      {/* <table className="orders-table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.map((report: any, index: number) => (
            <ReportTableRow key={index} report={report} />
          ))}
        </tbody>
      </table> */}
      <Table sx={{ width: '100%', overflowX: 'auto', display: { xs: 'block', sm: 'table' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Date</TableCell>
            {reportsType === ReportsType.PAYOUTS && <TableCell>Amount</TableCell>}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBodySkeleton rowsNum={10} columnsNum={3} />
        ) : (
          <TableBody>
            {filteredReports.map((report: any, index: number) => (
              <ReportTableRow type={reportsType} key={index} report={report} />
            ))}
          </TableBody>
        )}
      </Table>
    </Stack>
  )
}

export default ReportsView
