import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Category } from '../types'
import { v4 as uuidv4 } from 'uuid'

const categorySchema = (t: TFunction<'translation', undefined>) => {
  // TS errors here might look scary, but easy to fix
  const objectSchema: yup.ObjectSchema<Category> = yup.object({
    id: yup
      .string()
      .transform(value => {
        if (!value) return uuidv4()
        return value
      })
      .default(uuidv4())
      .required(),
    translations: yup.object({
      title: yup.object({ en: yup.string().required(t('errors.required')) }),
      description: yup
        .object({ en: yup.string().required(t('errors.required')) })
        .transform(value => {
          const result = Object.keys(value).every(ln => {
            console.log(value[ln])
            return value[ln] === '' || value[ln] === undefined
          })
            ? undefined
            : value

          return result
        })
        .default(undefined),
    }),
    type: yup.string().oneOf(['editableText']).default('editableText'),
    // DEPRECATED PROPERTIES
    title: yup.string().default(undefined),
    titleFI: yup.string().default(undefined),
    titleSE: yup.string().default(undefined),
    description: yup.string().default(undefined),
    descriptionFI: yup.string().default(undefined),
    descriptionSE: yup.string().default(undefined),
  })
  return objectSchema
}

export default categorySchema
