import { Button, ButtonBase, Divider, Paper, Stack, TextField, Typography } from '@mui/material'
import DeleteConfirmButton from '../../../../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import Localized from '../../../../../../ui/Localized/Localized'
import TranslationsTable from '../../../../../../ui/TranslationsTable/TranslationsTable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Chips from './Chips'
import GroupOptions from './GroupOptions'
import { useFormContext, useWatch } from 'react-hook-form'
import { Item } from '../../../../../../types'
import { useEffect, useState } from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

type OptionGroupProps = {
  index: number
  onDelete?: () => void
  dragHandleProps?: any
  restaurant: any
}

function OptionGroup({ index, dragHandleProps, restaurant, onDelete = () => null }: OptionGroupProps) {
  const { register, formState, control, setValue, getValues } = useFormContext<Item>()
  const watchedValues = useWatch({ name: 'options', control })
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Paper elevation={3} sx={{ mt: 2 }}>
      <Stack spacing={4} padding={2}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <ButtonBase
              onClick={() => setIsExpanded(!isExpanded)}
              className="browser-default no-button-bg"
              sx={{ width: '100%', justifyContent: 'flex-start' }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {isExpanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
                <Typography variant="h4">
                  <Localized target={watchedValues?.[index]} translationsKey="optionName" />
                </Typography>
              </Stack>
            </ButtonBase>
            <Stack direction="row" spacing={1} alignItems="center">
              <ButtonBase {...dragHandleProps}>
                <DragIndicatorIcon />
              </ButtonBase>
              <DeleteConfirmButton onClick={onDelete} />
            </Stack>
          </Stack>

          {isExpanded && (
            <>
              <TranslationsTable
                title="Option group name"
                restaurant={restaurant}
                formBase={`options[${index}].translations.optionName`}
              />
              <Stack spacing={1}>
                <Typography variant="subtitle1">Option Choice Limit</Typography>
                <TextField
                  size="small"
                  inputProps={{ className: 'browser-default' }}
                  {...register(`options.${index}.optionChoiceLimit`)}
                  error={Boolean(
                    formState.errors?.[`options[${index}].optionChoiceLimit` as keyof typeof formState.errors]
                  )}
                  helperText={
                    // @ts-ignore
                    formState.errors?.[`options[${index}].optionChoiceLimit` as keyof typeof formState.errors]?.message
                  }
                />
                <Chips index={index} />
              </Stack>
            </>
          )}
        </Stack>

        {isExpanded && (
          <>
            <Divider />
            <GroupOptions groupIndex={index} restaurant={restaurant} />
          </>
        )}
      </Stack>
    </Paper>
  )
}

export default OptionGroup
