import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getOrders } from '../../../api/restaurantInfoApi'

function Orders() {
  return (
    <Paper elevation={0}>
      <Stack>
        <Box p={2}>
          <Typography variant="h4">Latest orders</Typography>
        </Box>
        <Table>
          <TableHead>
            <TableCell>Order Sum</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Order Time</TableCell>
            <TableCell>Avg Per Customer</TableCell>
            <TableCell>Request Type</TableCell>
            <TableCell>Platform</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>adwad</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Paper>
  )
}

export default Orders
