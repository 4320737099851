import {
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import Localized from '../../../../../../../ui/Localized/Localized'
import getLocalizedString from '../../../../../../../utils/getLocalizedString'
import { useEffect, useMemo, useState } from 'react'
import theme from '../../../../../../../theme'
import usePriceFormat from '../../../../../../../hooks/usePriceFormat'
import { useFieldArray, useForm } from 'react-hook-form'
import { selectedRestaurantState } from '../../../../../../../atoms/SelectedRestaurantAtom'
import selectedRestaurantMenuState from '../../../../../../../selectors/selectedRestaurantMenu'

type CopyOptionsDialogProps = {
  open?: boolean
  onClose?: () => void
  onOptionsCopy: (extras: any) => void
}

function CopyOptionsDialog({ open = false, onClose = () => null, onOptionsCopy }: CopyOptionsDialogProps) {
  const [menuList] = useRecoilState(selectedRestaurantMenuState)
  const restaurant = useRecoilValue(selectedRestaurantState)
  const [selectedItemIndex, setSelectedItemIndex] = useState<any>(0)
  const { withCurrencySymbol } = usePriceFormat()

  const methods = useForm<any>({
    // shouldUnregister: true,
    mode: 'all',
    defaultValues: {},
  })
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = methods
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'options',
    keyName: 'key',
  })

  const alphabeticallySortedMenuList: any = useMemo(
    () =>
      menuList
        .slice()
        .filter((item: any) => Boolean(item.options?.length))
        .sort((a: any, b: any) => getLocalizedString(a, 'title').localeCompare(getLocalizedString(b, 'title'))),
    [menuList]
  )

  const selectedItem = alphabeticallySortedMenuList[selectedItemIndex]
  useEffect(() => {
    setValue('options', selectedItem?.options)
  }, [selectedItemIndex])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ minHeight: '40vh', width: '60vw', maxWidth: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h4">Copy Options from:</Typography>
          <Select onChange={e => setSelectedItemIndex(e.target.value)} value={selectedItemIndex} fullWidth>
            {alphabeticallySortedMenuList?.map((item: any, index: number) => (
              <MenuItem value={index} key={index}>
                <Stack>
                  <Typography variant="subtitle1">
                    <Localized target={item} translationsKey="title" />
                  </Typography>
                  <Typography variant="subtitle2">{item?.options?.length} options</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <Divider sx={{ borderColor: theme.palette.mynt.main, borderStyle: 'dashed' }} />
          {fields?.map((optionGroup: any, index: number) => {
            return (
              <Paper elevation={4} sx={{ width: '100%' }} key={index}>
                <Stack padding={2} spacing={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack>
                      <Typography variant="h5">
                        <Localized target={optionGroup} translationsKey="optionName" />
                      </Typography>
                    </Stack>
                    <Typography variant="subtitle1">
                      ({optionGroup?.optionList?.length} choices, limit: {optionGroup?.optionChoiceLimit || 1})
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!Boolean(fields?.length)} onClick={() => onOptionsCopy(fields)}>
          Copy {fields?.length} options
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CopyOptionsDialog
