import { request } from './base'

export async function getTeamApi() {
  const resp = await request(`/cms/owners/staffs/getStaffs`, 'get')
  return resp?.data
}

export async function getHolders() {
  const resp = await request(`/cms/admin/getStaffHolders`, 'get')
  return resp?.data
}

export async function createHolder() {
  const resp = await request(`/cms/admin/createStaffHolder`, 'post')
  return resp?.data
}

export async function addTeamMemberApi(data) {
  const resp = await request(`/cms/owners/staffs/create`, 'post', {
    payload: data,
  })
  return resp?.data
}

export async function removeStaff(id) {
  const resp = await request(`/cms/owners/staffs/${id}`, 'delete')
  return resp?.data
}

export async function updateStaff(id, payload) {
  const resp = await request(`/cms/owners/staffs/${id}`, 'put', {
    payload,
  })
  return resp?.data
}

export async function generateOnboardingLink(email) {
  const resp = await request(`/cms/admin/generateOnboardingUrl`, 'post', {
    payload: {
      email,
    },
  })
  return resp?.data
}
