import Papa from 'papaparse'
import lodash from 'lodash'

export default function DownloadButton({ title, rows, columns }) {
  let downloadCSV = () => {
    console.log('papa unparse')
    let csv = Papa.unparse(rows, {
      columns,
    })
    console.log('buildinb blob')

    const url = window.URL.createObjectURL(new Blob([csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${title}.csv`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  return (
    <button className="btn-small" onClick={downloadCSV}>
      Download CSV
    </button>
  )
}
