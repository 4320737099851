import React, { useEffect, useState } from 'react'
import FormatCurrency from '../../utils/FormatCurrency'
import { getHourMinutes, isoTimestampToDate, unixTimestampToDate } from '../../utils/secondsToTime'
import RestaurantChip from '../restaurantChip/RestaurantChip'
import OrderActionsDropdown from './OrderActionsDropdown'
import OrderIdChip, { orderMenuItemToString } from './OrderIdChip'
import OrderStatusChip from './OrderStatusChip'
import OrderTime from './OrderTime'
import OrderTypeChip from './OrderTypeChip'
import _ from 'lodash'
import { usePDF } from 'react-to-pdf'

import { Box, Button, Dialog, DialogActions, Stack } from '@mui/material'
import { DialogContent } from '@mui/material'
import PrintableReceipt from './PrintableReceipt'

export default function OrderTableRow({ data }: any) {
  const [expanded, setExpanded] = useState(false)
  const [hasUpsold, setHasUpsold] = useState(false)
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false)
  const { toPDF, targetRef } = usePDF({
    filename: `${data.receiptId}.pdf`,
    page: { margin: { top: 20, left: 40, right: 40, bottom: 0 } },
  })

  let createdDate = new Date(data.createdAt)

  const handleSavePdfClick = () => {
    setPdfDialogOpen(true)
  }

  const handlePdfSave = () => {
    // @ts-ignore
    toPDF(targetRef)
    setPdfDialogOpen(false)
  }

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const getOrderStatusClass = (status: any) => {
    switch (status) {
      case 'archived':
        return 'archived'
      case 'inprogress':
        return 'in-progress'
      case 'refunded':
        return 'refunded'
      case 'cancelled':
        return 'cancelled'
      default:
        return ''
    }
  }

  useEffect(() => {
    for (let i = 0; i < data?.menu?.length; i++) {
      if (data.menu[i].item.upselling) {
        setHasUpsold(true)
        return
      }
    }
  }, [])

  return (
    <>
      <tr onClick={toggleExpanded} className={'order-row ' + getOrderStatusClass(data.status)}>
        <td>
          €{FormatCurrency(data.totalAmount)} {hasUpsold && <>🤖</>}
        </td>
        <td>{OrderStatusChip(data.status)}</td>
        <td>
          <OrderTime date={data.createdAt} />
        </td>
        <td>{getHourMinutes(createdDate)}</td>
        <td>{FormatCurrency(data.totalAmount / data.customerAmount)}€</td>
        <td>{data?.requestType}</td>
        <td>{`${_.startCase(data.fromPlatform)} Order`}</td>
      </tr>

      {expanded && (
        <tr className="expanded-row">
          <td colSpan={6}>
            <div className="order-details" ref={targetRef}>
              <div className="order-summary">
                <h4>
                  #{data.orderId} {OrderTypeChip(data.requestType)}
                </h4>
              </div>
              <div className="order-details-columns">
                <div style={{ width: '60%' }}>
                  <PrintableReceipt data={data} />
                </div>

                <div className="order-platform">
                  <h4>Order Stats</h4>
                  <p>{data.fromPlatform == 'web' ? 'QR Code' : 'Kiosk'} order</p>

                  <p>Ordered: {getHourMinutes(isoTimestampToDate(data.createdAt))}</p>
                  {data?.histories?.map((history: any, index: number) => {
                    console.log('history', history)
                    return (
                      <p key={index}>
                        {history?.orderStatus || history?.status} at{' '}
                        {getHourMinutes(unixTimestampToDate(history?.createdAt?._seconds))}
                      </p>
                    )
                  })}
                  {/* {data.readyAt && <p>Ready: {getHourMinutes(unixTimestampToDate(data.readyAt?._seconds))}</p>} */}
                  {data.status == 'archived' && <p>Completed: {getHourMinutes(isoTimestampToDate(data.updatedAt))}</p>}
                </div>
              </div>
              <Stack direction="row" mt={2}>
                <Button variant="contained" onClick={handleSavePdfClick}>
                  Save this receipt as PDF
                </Button>
              </Stack>
              <Dialog open={pdfDialogOpen} onClose={() => setPdfDialogOpen(false)}>
                <DialogContent>
                  <PrintableReceipt data={data} ref={targetRef} />
                </DialogContent>
                <DialogActions>
                  <Stack width="100%">
                    <Button variant="contained" color="success" onClick={handlePdfSave}>
                      Save as pdf
                    </Button>
                  </Stack>
                </DialogActions>
              </Dialog>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
