import { useTranslation } from 'react-i18next'

/**
 * Converts number to Short day format (IE: 0 => "Mon")
 * @param {int} number
 * @returns
 */
export default function NumberToDay(number) {
  const { t, i18n } = useTranslation()

  switch (number) {
    case 1:
      return t('general.MondayShort')
    case 2:
      return t('general.TuesdayShort')
    case 3:
      return t('general.WednesdayShort')
    case 4:
      return t('general.ThursdayShort')
    case 5:
      return t('general.FridayShort')
    case 6:
      return t('general.SaturdayShort')
    case 7:
      return t('general.SundayShort')
    default:
      break
  }
}
