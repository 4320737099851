import React from 'react'

const AnalyticalChip = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        backgroundColor: '#f5f5f5',
        padding: '6px 12px',
        margin: '4px',
        fontSize: '14px',
        fontWeight: '500',
      }}
    >
      <span>
        <b>{label}:</b>{' '}
      </span>
      <span style={{ marginLeft: '4px' }}>{value}</span>
    </div>
  )
}

export default AnalyticalChip
