import { Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Category } from '../../../types'
import MenuElementBase from '../../../ui/MenuElementBase/MenuElementBase'
import { FormProvider, useForm } from 'react-hook-form'
import EditMenuTitle from './EditMenuTitle/EditMenuTitle'
import useYupValidationResolver from '../../../hooks/useYupValidationResolver'
import { categorySchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'

type MenuTitleProps = {
  data: Category
  restaurant: any
  updateElment: any
  removeElement: any
  listView: any
  onEdit?: any
}

function MenuTitle({ data, restaurant, updateElment, removeElement, listView, onEdit }: MenuTitleProps) {
  const [isHovered, setHovered] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { t } = useTranslation()

  const title = data?.translations?.title?.en || data.title

  return (
    <>
      <MenuElementBase onHoverStart={() => setHovered(true)} onHoverEnd={() => setHovered(false)}>
        <Stack spacing={1}>
          <Box>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="subtitle1">{data?.translations?.description?.en || data.description}</Typography>
          </Box>
          <Stack direction="row-reverse" spacing={2} sx={{ visibility: isHovered ? 'visible' : 'hidden' }}>
            <Button variant="outlined" color="error" onClick={() => setConfirmDialogOpen(true)}>
              Delete
            </Button>
            <Button variant="contained" color="primary" onClick={onEdit}>
              Edit
            </Button>
          </Stack>
        </Stack>
      </MenuElementBase>
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>{t('general.aboutToDeleteCategory') + title}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              removeElement(data.id)
              setConfirmDialogOpen(false)
            }}
            variant="outlined"
            color="error"
          >
            {t('general.Delete')}
          </Button>
          <Button onClick={() => setConfirmDialogOpen(false)} variant="outlined" color="primary">
            {t('general.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MenuTitle
