import { Controller, FieldError, useFormContext, useWatch } from 'react-hook-form'
import Item, { Extra as TExtra } from '../../../../../../types/Item'
import ExtraItem from './extraTypes/ExtraItem'
import OtherItem from './extraTypes/OtherItem'

type ExtraProps = {
  restaurant: any
  index: number
  onDeleteClick: (args: any) => any
  possibleItemsAsExtra: Item[]
  dragHandleProps?: any
}

function Extra({ restaurant, index, onDeleteClick, possibleItemsAsExtra, dragHandleProps }: ExtraProps) {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<Item>()
  const extras = useWatch({ control, name: 'extras' })

  if (extras?.[index]?.type === 'extraItem' || !extras?.[index]?.type) {
    return (
      <ExtraItem
        restaurant={restaurant}
        index={index}
        onDeleteClick={onDeleteClick}
        dragHandleProps={dragHandleProps}
      />
    )
  }

  if (extras?.[index]?.type === 'otherItem') {
    return (
      <OtherItem
        restaurant={restaurant}
        index={index}
        onDeleteClick={onDeleteClick}
        possibleItemsAsExtra={possibleItemsAsExtra}
        dragHandleProps={dragHandleProps}
      />
    )
  }

  return null
}

export default Extra
