import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { useAuthContext } from '../../../contexts/AuthContext'
import { Grid, Button, Box, Stack, Typography, ClickAwayListener, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import EditMenuItem from '../menuItem/EditMenuItem/EditMenuItem'
import EditMenuTitle from '../menuTitle/EditMenuTitle/EditMenuTitle'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { createMenuItemApi, setMenuListApi } from '../../../api/restaurantListApi'
import moment from 'moment'
import { csvToMenuArray } from '../../../utils/CSVFunctions'
import selectedRestaurantMenuState from '../../../selectors/selectedRestaurantMenu'
import theme from '../../../theme'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import MenuImportDialog from '../restaurantInfo/components/MenuImportDialog'
import EditItemForm from '../menuList/components/EditMenuEntry/components/EditItemForm'
import EditMenuEntryLayout from '../menuList/components/EditMenuEntry/components/EditMenuEntryLayout'
import EditMenuEntry from '../menuList/components/EditMenuEntry/EditMenuEntry'
import MenuImportFromRestaurant from '../../../components/MenuImportFromRestaurant/MenuImportFromRestaurant'
import { restaurantListState } from '../../../atoms/RestaurantListAtom'
import { toast } from 'react-toastify'

function MenuControls() {
  const { t } = useTranslation()
  const { loggedIn, user, isAdmin, isOwnerOf } = useAuthContext()
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const restaurantList = useRecoilValue(restaurantListState)
  const [selectedRestaurantMenu, setSelectedRestaurantMenu] = useRecoilState(selectedRestaurantMenuState)
  const [isCreatingItem, setCreatingItem] = useState(false)
  const [isCreatingCategory, setCreatingCategory] = useState(false)
  const { makeApiRequest } = useRequestStatus()
  const isOwner = isOwnerOf(selectedRestaurant?.id)

  const [isAddTooltipOpen, setAddTooltipOpen] = useState(false)
  const [isImportTooltipOpen, setImportTooltipOpen] = useState(false)
  const [isMenuImageImportOpen, setMenuImageImportOpen] = useState(false)
  const [isMenuRestaurantImportOpen, setMenuRestaurantImportOpen] = useState(false)

  const handleItemCreation = async (item: any) => {
    const { isSuccess } = await makeApiRequest(createMenuItemApi, [
      { restaurantId: selectedRestaurant?.id, item: item },
    ])
    if (isSuccess && selectedRestaurant?.subMenusSetting?.splitOrderIntoSuborders) {
      toast.info('Please assign new item to a submenu in "Submenus" tab', { autoClose: 30000, closeButton: true })
    }
    setCreatingItem(false)
    // if (isSuccess) {
    //   setSelectedRestaurantMenu(newList)
    // }
  }

  const handleMenuCategoryCreation = async (category: any) => {
    const newCategory = {
      ...category,
      updatedAt: moment.utc().toISOString(),
      createdAt: category.createdAt || moment.utc().toISOString(),
    }

    const { isSuccess } = await makeApiRequest(createMenuItemApi, [
      { restaurantId: selectedRestaurant?.id, item: newCategory },
    ])
    setCreatingCategory(false)
    // if (isSuccess) {
    //   setSelectedRestaurantMenu(newList)
    // }
  }

  const csvUpload = async (e: any) => {
    let csv = e.target.files[0]

    const result = await csvToMenuArray(csv)
    const { isSuccess } = await makeApiRequest(setMenuListApi, [{ id: selectedRestaurant?.id, menu: result }])
    if (isSuccess) {
      setSelectedRestaurantMenu(result)
    }
  }

  return (
    <>
      {(isOwner || isAdmin) && (
        <Stack
          sx={{
            bgcolor: theme.palette.grey[200],
            borderRadius: 2,
            p: 1,
            position: 'sticky',
            opacity: 1,
            bottom: '8px',
            width: '100%',
            // maxWidth: '400px',
            zIndex: 12,
            '&:hover': { opacity: 1 },
          }}
        >
          <Grid container>
            <Grid item sm={6} xs={12} p={1}>
              <Tooltip
                open={isAddTooltipOpen}
                onClose={() => setAddTooltipOpen(false)}
                arrow
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{ sx: { zIndex: 25 } }}
                title={
                  <ClickAwayListener onClickAway={() => setAddTooltipOpen(false)}>
                    <div>
                      <Stack spacing={1} direction="row" p={1}>
                        <Button variant="contained" onClick={() => setCreatingItem(true)}>
                          Item
                        </Button>
                        <Button variant="contained" onClick={() => setCreatingCategory(true)}>
                          Category
                        </Button>
                      </Stack>
                    </div>
                  </ClickAwayListener>
                }
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '100%' }}
                  startIcon={<AddIcon />}
                  onClick={() => setAddTooltipOpen(true)}
                >
                  Add
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sm={6} xs={12} p={1}>
              <Tooltip
                open={isImportTooltipOpen}
                onClose={() => setImportTooltipOpen(false)}
                arrow
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{ sx: { zIndex: 25 } }}
                title={
                  <ClickAwayListener onClickAway={() => setImportTooltipOpen(false)}>
                    <div>
                      <Stack spacing={1} direction="row" p={1}>
                        <Button variant="contained" onClick={() => setMenuImageImportOpen(true)}>
                          As image
                        </Button>

                        <Button variant="contained" component="label">
                          <Box textAlign="center">As CSV</Box>
                          <input type="file" hidden onChange={e => csvUpload(e)} accept=".csv" />
                        </Button>

                        <Button variant="contained" component="label" onClick={() => setMenuRestaurantImportOpen(true)}>
                          <Box textAlign="center">From other restaurant</Box>
                        </Button>
                      </Stack>
                    </div>
                  </ClickAwayListener>
                }
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: '100%' }}
                  startIcon={<DownloadIcon />}
                  onClick={() => setImportTooltipOpen(true)}
                >
                  Import menu
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Stack>
      )}
      <EditMenuItem
        open={isCreatingItem}
        onClose={() => setCreatingItem(false)}
        restaurant={selectedRestaurant}
        onSave={handleItemCreation}
        item={{ shouldSuggested: true }}
      />

      <EditMenuTitle
        open={isCreatingCategory}
        onClose={() => setCreatingCategory(false)}
        restaurant={selectedRestaurant}
        onSave={handleMenuCategoryCreation}
        resetOnClose
        data={{}}
      />
      <MenuImportDialog open={isMenuImageImportOpen} onClose={() => setMenuImageImportOpen(false)} />
      <MenuImportFromRestaurant open={isMenuRestaurantImportOpen} onClose={() => setMenuRestaurantImportOpen(false)} />
    </>
  )
}

export default MenuControls
