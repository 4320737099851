import { getHourMinutes, isoTimestampToDate, unixTimestampToDate } from '../../utils/secondsToTime'
import FormatCurrency from '../../utils/FormatCurrency'
import withCurrencySymbol from '../../utils/withCurrencySymbol'
import { Box, Stack, Typography } from '@mui/material'
import { forwardRef } from 'react'
import Big from 'big.js'
import Localized from '../../ui/Localized/Localized'

type PrintableReceiptProps = {
  data: any
}

const PrintableReceipt = forwardRef(({ data }: PrintableReceiptProps, ref) => {
  const restaurant = data.restaurant
  const receipt = restaurant.receiptSetting
  const createdDate = new Date(data.createdAt)

  const vats = data.vats
  const totalVatCost = Big(data.vat)
  const totalPrice = Big(data.totalAmount)
  const vatFreePrice = totalPrice.sub(totalVatCost)

  console.log(data)
  return (
    <Box ref={ref}>
      <Stack direction="row" alignItems="space-between">
        <Stack mr={8}>
          <Typography variant="h5">{receipt?.locationName || restaurant.name}</Typography>
          <Typography variant="subtitle1">{receipt?.address || restaurant.address}</Typography>
          <Typography variant="subtitle1">
            {receipt?.zipCode || restaurant.zipCode} {receipt?.city || restaurant.city}
          </Typography>
          <Typography variant="subtitle1">Business ID: {restaurant.receiptSetting?.businessId}</Typography>
        </Stack>
        <Stack textAlign="right">
          <Typography variant="h5">Receipt</Typography>
          <Typography variant="subtitle1">Receipt date: {createdDate?.toLocaleDateString()}</Typography>
        </Stack>
      </Stack>
      <div className="order-details">
        <div className="order-summary">
          <p>
            {createdDate?.toLocaleDateString()} {getHourMinutes(createdDate)}
          </p>
          {data.customer?.name ? (
            <p>
              {' '}
              Customer name: <b>{data.customer?.name}</b>
            </p>
          ) : null}
          {data.customer?.phoneNumber ? (
            <p>
              {' '}
              Phone Number: <b>{data.customer?.phoneNumber}</b>
            </p>
          ) : null}
          {data.customer?.displayName ? (
            <p>
              {' '}
              Ordered from: <b>{data.customer?.displayName}</b>
            </p>
          ) : null}
          <p>
            Receipt ID: <b>{data?.receiptId}</b>
          </p>
        </div>
        <div className="order-details-columns">
          <div className="order-items">
            <h4>Transaction details</h4>
            <table className="items-table">
              <tbody>
                {data.menu.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="item-title">
                      {item.quantity}x <Localized target={item.item} translationsKey="title" />
                      {(item.item.chosenExtras || []).length > 0 && (
                        <> (extra: {item.item.chosenExtras.map((extra: any) => extra.name).join(', ')})</>
                      )}
                      {(item.item.chosenOptions || []).length > 0 && (
                        <>
                          {' '}
                          (Options:{' '}
                          {item.item.chosenOptions.map((option: any) => option?.optionList?.[0]?.name).join(', ')})
                        </>
                      )}
                      {item.item.upselling ? <b> {item.item.upselling}</b> : null}
                    </td>
                    <td className="item-price">{withCurrencySymbol(item.item.price, data?.restaurant)}</td>
                  </tr>
                ))}

                <tr>
                  <td>VAT Free Price: </td>
                  <td className="item-price">{withCurrencySymbol(vatFreePrice.toFixed(2), data?.restaurant)}</td>
                </tr>
                {vats &&
                  Object.entries(vats).map((vat: any, index: number) =>  vat[1] > 0 && ( 
                   <tr key={index}>
                      <td>VAT {Big(vat[0]).mul(100).toFixed()}%:</td>
                      <td className="item-price">{withCurrencySymbol(vat[1], data?.restaurant)}</td>
                    </tr>
                  ))}

                <tr className="total-row">
                  <td>Total:</td>
                  <td className="item-price">{withCurrencySymbol(totalPrice.toFixed(2), data?.restaurant)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Box>
  )
})

export default PrintableReceipt
