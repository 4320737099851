import { Box, Button, Stack, Typography } from '@mui/material'

type SentryErrorViewProps = {
  error?: any
  componentStack?: any
}

function SentryErrorView({ error, componentStack }: SentryErrorViewProps) {
  return (
    <Stack p={4}>
      <Stack>
        <Typography variant="h3">Something went wrong</Typography>
        <Typography variant="h6">We're sorry — something's gone wrong. Our team has been notified.</Typography>
        <Box mt={1}>
          <Button size="large" variant="contained" onClick={() => (window.location.href = '/')}>
            Go to the main page
          </Button>
        </Box>
      </Stack>
      <Stack mt={4}>
        <Typography variant="h4">Debug logs below</Typography>
        <Typography variant="body1">{error?.toString()}</Typography>
        <Typography variant="body2">{componentStack}</Typography>
      </Stack>
    </Stack>
  )
}

export default SentryErrorView
