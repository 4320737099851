import { Stack, Typography } from '@mui/material'
import TeamTable from './components/TeamTable/TeamTable'
import AddTeamMember from './components/AddTeamMember/AddTeamMember'

function Team() {
  return (
    <Stack spacing={4} py={2} px={{ xs: 0, sm: 2 }}>
      <Typography variant="h3">Team management</Typography>
      <TeamTable />
      <AddTeamMember />
    </Stack>
  )
}

export default Team
