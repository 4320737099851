export function timeAgo(timestamp) {
  if (typeof timestamp == 'string') {
    timestamp = Date.parse(timestamp)
  }
  const currentTime = Date.now()
  const timeDiff = currentTime - timestamp
  const timeDiffInSeconds = timeDiff / 1000
  const timeDiffInMinutes = timeDiffInSeconds / 60
  const timeDiffInHours = timeDiffInMinutes / 60
  const timeDiffInDays = timeDiffInHours / 24

  if (timeDiffInSeconds < 60) {
    return `${Math.floor(timeDiffInSeconds)} sec ago`
  } else if (timeDiffInMinutes < 60) {
    return `${Math.floor(timeDiffInMinutes)} min ago`
  } else if (timeDiffInHours < 24) {
    return `${Math.floor(timeDiffInHours)} hours ago`
  } else {
    return `${Math.floor(timeDiffInDays)} days ago`
  }
}
