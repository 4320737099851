import { Box, Button, Dialog, DialogContent, Stack } from '@mui/material'
import { useState } from 'react'
import TeamMemberForm from '../../../../components/forms/TeamMemberForm'
import AddDialog from './AddDialog'

function AddTeamMember() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Stack py={2}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add New Team Member
        </Button>
      </Stack>
      <AddDialog open={open} setOpen={setOpen} />
    </>
  )
}

export default AddTeamMember
