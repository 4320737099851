import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { JsonEditor } from 'react-jsondata-editor'

function CreateConfigForm({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [showCreate, setShowCreate] = useState(false)
  const [tokens, setTokens] = useState(props.tokens)
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()

  const [config, setConfig] = useState({})
  const [creds, setCreds] = useState({})

  useEffect(() => {
    setTokens(props.tokens)
  }, [props.tokens])

  useEffect(() => {}, [])

  let onCreate = e => {
    e.preventDefault()
  }

  return (
    <>
      <div className="end-of-menu-list">
        <h4>Add new configuration</h4>
        {!showCreate && (
          <>
            <div className="buttons-row">
              <div className="pepi-button" onClick={() => setShowCreate(true)}>
                Add
              </div>
            </div>
          </>
        )}
        {showCreate && (
          <>
            <p>Fill this form carefully</p>
            <div className="">
              <input
                type="text"
                id="name"
                placeholder="Name"
                {...register('name', {
                  required: 'This is required.',
                  minLength: 5,
                  maxLength: 20,
                })}
              />
            </div>
            <label>Config</label>
            <JsonEditor
              jsonObject={config}
              onChange={output => {
                console.log(output)
                setConfig(output)
              }}
            />
            <label>Creds</label>
            <JsonEditor
              jsonObject={creds}
              onChange={output => {
                console.log(output)
                setCreds(output)
              }}
            />
            {/* <div className="input-field col s12">
                <input
                  type="text"
                  id="config"
                  placeholder="Config"
                  {...register("config", {
                    required: "This is required.",
                    minLength: 5,
                    maxLength: 20,
                  })}
                />
              </div>
              <div className="input-field col s12">
                <input
                  type="text"
                  id="creds"
                  placeholder="Creds"
                  {...register("creds", {
                    required: "This is required.",
                    minLength: 5,
                    maxLength: 20,
                  })}
                />
              </div> */}

            <button className="pepi-button" onClick={onCreate}>
              Create
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default CreateConfigForm
