import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { convertToJPEG, uploadFileJPEG } from '../../../../utils'
import Toggelable from '../../../../components/Toggleable/Toggleable'
import ReceiptPreview from './ReceiptPreview'
import { saveRestaurantInfoData } from '../../../../api/restaurantInfoApi'
import { useFormContext, Controller, useWatch, useForm } from 'react-hook-form'
import { Stack, Button, Grid, Alert } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'
import Resizer from 'react-image-file-resizer'

const LOGO_MAX_WIDTH = 250

const BrandingIdentityEdit = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const { getValues, register, setValue, control, reset, handleSubmit } = useForm({ defaultValues: selectedRestaurant })
  const { receiptSetting } = useWatch({ control })
  const { locationName, sellerName, sellerLocation, address, businessId, zipCode, city, receiptMessage, logo, phone } =
    receiptSetting || {}
  const [isSaving, setSaving] = useState(false)

  const saveIdentity = async () => {
    setSaving(true)
    try {
      const filePath = `/restaurants/${selectedRestaurant?.id}/images/${uuidv4()}.jpeg`
      const imageUrl = logo ? await uploadFileJPEG(logo, filePath) : null
      setValue('receiptSetting.logo', imageUrl)
      console.log('uploaded image', imageUrl)
      console.log(getValues())
      await saveRestaurantInfoData({ id: selectedRestaurant?.id, receiptSetting: getValues().receiptSetting })
      setSelectedRestaurant(getValues())
    } catch (error: any) {
      alert(error.message)
    } finally {
      // refresh restaurant info
      setSaving(false)
    }
  }

  const onSubmit = (data: any) => {
    console.log(data)
    saveIdentity()
  }

  const getResizedLogo = async (logo: any) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        logo, // the file to be resized
        LOGO_MAX_WIDTH, // max width
        // @ts-ignore
        undefined, // max height (undefined keeps aspect ratio)
        'JPEG', // output format
        100, // quality (0-100)
        0, // rotation (0 degrees)
        uri => {
          // the callback function with the resized image URI
          resolve(URL.createObjectURL(uri as Blob))
        },
        'blob' // output type
      )
    })
  }

  useEffect(() => {
    reset(selectedRestaurant)

    const values = getValues()
    if (!values?.receiptSetting?.locationName) setValue('receiptSetting.locationName', values?.name)
    if (!values?.receiptSetting?.phone) setValue('receiptSetting.phone', values?.phone)
    if (!values?.receiptSetting?.address) setValue('receiptSetting.address', values?.address)
    if (!values?.receiptSetting?.city) setValue('receiptSetting.city', values?.city)
    if (!values?.receiptSetting?.zipCode) setValue('receiptSetting.zipCode', values?.zipCode)
  }, [selectedRestaurant])

  console.log(receiptSetting)

  return (
    <Stack spacing={2}>
      <div className="branding-container">
        <div className="row row-editing">
          <div className="row m-0">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <h5>Location Name</h5>
                <p className="info-text">Appears at the end of your reciept</p>
                <input type="text" {...register('receiptSetting.locationName')} />

                <h5>Seller Name</h5>
                <input type="text" {...register('receiptSetting.sellerName')} />
                <h5>Seller Location</h5>
                <input type="text" {...register('receiptSetting.sellerLocation')} />

                <h5>Phone Number</h5>
                <input type="text" {...register('receiptSetting.phone')} />

                <h5>Address</h5>
                <input type="text" {...register('receiptSetting.address')} />

                <h5>Ytunnus</h5>
                <input type="text" {...register('receiptSetting.businessId')} />

                <h5>City</h5>
                <input type="text" {...register('receiptSetting.city')} />

                <h5>Zipcode</h5>
                <input type="text" {...register('receiptSetting.zipCode')} />

                <h5>Reciept Logo</h5>
                <p>Logo to be printed at end of reciept</p>

                <div className="file-field input-field">
                  <div
                    className="btn tooltipped"
                    data-position="top"
                    data-tooltip="60x60 Black and White image for best result"
                  >
                    <span>File</span>
                    {/* <input type="file" accept="image/*" onChange={handleLogoChange} /> */}
                    <Controller
                      control={control}
                      name={'receiptSetting.logo'}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <input
                            {...field}
                            value={value?.fileName}
                            onChange={async e => {
                              if (e.target.files?.[0]) onChange(await getResizedLogo(e.target.files?.[0]))
                            }}
                            type="file"
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <Stack direction="row" justifyContent="center">
                  <ReceiptPreview
                    RestaurantName={locationName}
                    SellerName={sellerName}
                    SellerLocation={sellerLocation}
                    ReceiptLogo={logo}
                    Address={address}
                    Ytunnus={businessId}
                    Zipcode={zipCode}
                    City={city}
                    CustomerMessage={receiptMessage}
                    Phone={phone}
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)} disabled={isSaving}>
        Save
      </Button>
    </Stack>
  )
}

export default BrandingIdentityEdit
