import {
  Button,
  ButtonBase,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useEffect, useState } from 'react'
import { fileToBase64 } from '../../../../utils/conversions'
import Spinner from '../../../../ui/Spinner/Spinner'
import { fetchMenuFromImage } from '../../../../api/openai'
import Localized from '../../../../ui/Localized/Localized'
import { categorySchema, itemSchema } from '../../../../schemas'
import useTranslatedSchema from '../../../../hooks/useTranslatedSchema'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantAtom } from '../../../../atoms/RestaurantAtoms'
import { setMenuListApi } from '../../../../api/restaurantListApi'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'
import { set, useFieldArray, useForm } from 'react-hook-form'
import theme from '../../../../theme'
import { selectedRestaurantState } from '../../../../atoms/SelectedRestaurantAtom'
import selectedRestaurantMenuState from '../../../../selectors/selectedRestaurantMenu'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type MenuImportDialogProps = {
  open?: boolean
  onClose?: () => void
}

function MenuImportDialog({ open = false, onClose = () => null }: MenuImportDialogProps) {
  const { t } = useTranslation()
  const [selectedImages, setSelectedImages] = useState<any[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isLoadingFull, setLoadingFull] = useState(false)
  const [result, setResult] = useState<any>([])
  const [selectedResult, setSelectedResult] = useState<null | any[]>([])
  const [menuList, setMenuList] = useRecoilState<any>(selectedRestaurantMenuState)
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)

  const handleItemsAdd = async () => {
    if (!selectedResult) return
    setLoadingFull(true)
    const newMenu = [
      ...menuList,
      ...result.map((item: any) => {
        return {
          ...item,
          id: item?.id ? item.id : uuidv4(),
          createdAt: item.createdAt || moment.utc().toISOString(),
        }
      }),
    ]
    await setMenuListApi({
      id: selectedRestaurant.id,
      menu: newMenu,
    })
    setMenuList(newMenu)
    setLoadingFull(false)
    onClose()
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const files = Array.from(e.target.files)

    if (files.length > 0) {
      console.log(files)
      setSelectedImages(files?.map(file => file?.name))

      setLoading(true)

      const base64Promises: any[] = []
      files.forEach((file: any) => {
        base64Promises.push(fileToBase64(file))
      })

      const convertedImages = await Promise.all(base64Promises)

      const promises: any[] = []
      convertedImages.forEach((image: any) => {
        promises.push(fetchMenuFromImage(image))
      })

      Promise.all(promises)
        .then(responses => {
          const parsedResults: any[] = []
          responses.forEach(response => {
            try {
              const parsedResult = JSON.parse(response?.choices?.[0]?.message?.content as string)
              parsedResults.push(...parsedResult)
            } catch (e) {
              console.log(e)
              alert(response?.choices?.[0]?.message?.content)
              setResult(null)
            }
          })
          console.log(parsedResults)
          const yupParsedResults: any[] = []
          parsedResults?.forEach((item: any) => {
            console.log('casting item: ', item)
            let castedItem = null
            if (item?.type === 'editableText') {
              castedItem = categorySchema(t).cast(item)
            } else {
              castedItem = itemSchema(t).cast(item)
            }
            yupParsedResults.push(castedItem)
          })
          setResult((prev: any) => {
            if (prev) {
              return [...prev, ...yupParsedResults]
            }
            return yupParsedResults
          })
          setLoading(false)
        })
        .catch(e => {
          console.error(e)
          setResult(null)
          setLoading(false)
        })
    }
  }

  const handleCheckboxClick = (item: any) => {
    if (!selectedResult) return
    const existingIndex = selectedResult?.findIndex(({ id }: any) => id === item.id)
    if (existingIndex === -1) {
      setSelectedResult([...selectedResult, item])
    } else {
      const newSelectedResult = [...selectedResult]
      newSelectedResult.splice(existingIndex, 1)
      setSelectedResult(newSelectedResult)
    }
  }

  useEffect(() => {
    setSelectedResult(result)
  }, [result])

  useEffect(() => {
    if (!open) {
      setResult(null)
      setSelectedImages([])
      setLoading(false)
      setLoadingFull(false)
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <PartialBackdrop show={isLoadingFull}>
          <Typography variant="h6">Import menu</Typography>
          <Typography variant="subtitle1">Please select menu picture: </Typography>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload files
            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" multiple onChange={handleImageUpload} />
          </Button>
          <div>{selectedImages.join(', ')}</div>
          {isLoading && (
            <Stack direction="row" width="100%" justifyContent="center" sx={{ marginTop: '50px' }}>
              <Stack alignItems="center">
                <Spinner />
                <Typography variant="subtitle1">Loading... (±1min)</Typography>
              </Stack>
            </Stack>
          )}
          <Stack spacing={4} mt={3}>
            {result?.map((item: any, index: number) => (
              <ButtonBase onClick={() => handleCheckboxClick(item)}>
                <Card
                  sx={{
                    p: 2,
                    position: 'relative',
                    width: '500px',
                    maxWidth: '100%',
                    backgroundColor: item?.type === 'editableText' ? theme.palette.grey[200] : null,
                  }}
                  key={index}
                >
                  <Checkbox
                    sx={{ position: 'absolute', right: 2, top: 2 }}
                    checked={Boolean(selectedResult?.find(({ id }) => id === item.id))}
                    onClick={() => handleCheckboxClick(item)}
                  />
                  <Stack spacing={1} key={index} alignItems="flex-start">
                    <Stack spacing={0}>
                      <Typography variant="h6">
                        <Localized target={item} translationsKey="title" />
                      </Typography>
                      <Typography variant="h6">
                        <Localized target={item} translationsKey="description" />
                      </Typography>
                    </Stack>
                    <Typography variant="subtitle1">{item?.price}</Typography>
                    <Typography variant="subtitle1">{item?.itemType}</Typography>
                  </Stack>
                </Card>
              </ButtonBase>
            ))}
          </Stack>
        </PartialBackdrop>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleItemsAdd}
          disabled={!Boolean(selectedResult?.length) || isLoadingFull}
        >
          Add items to menu
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MenuImportDialog
