import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { JsonEditor } from 'react-jsondata-editor'
import { saveCredsData, saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import { getRestaurantByAdmin } from '../../api/adminApi'
import '../../styles/jsondata-editor.scss'

const parseJSONInput = function (json) {
  return JSON.stringify(json, null, ' ')
}

function AdminConfigEditModal({ data, onClose, ...props }) {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()
  // const [restaurant, setRestaurant] = useState({})
  let closeBtn
  const [config, setConfig] = useState({})
  const [creds, setCreds] = useState({})

  const onCreate = async () => {
    try {
      await saveRestaurantInfoData({
        id: data.id,
        config,
      })
      await saveCredsData({
        id: data.id,
        creds,
      })
      onClose()
      closeBtn.click()
    } catch (e) {
      alert(e.message)
    } finally {
    }
  }

  useEffect(() => {
    console.log('data', data.config, data.creds)
    getRestaurantByAdmin(data.id)
      .then(restaurantResp => {
        if (restaurantResp?.data?.length === 0) {
          throw new Error('No restaurant found')
        }
        if (restaurantResp?.success === false) {
          throw new Error(restaurantResp?.message)
        }
        const restaurant = restaurantResp?.data?.[0]
        setConfig(restaurant?.config)
        setCreds(restaurant?.creds)
      })
      .catch(e => {
        alert(e.message)
      })
  }, [data.id])

  return (
    <div id="AdminConfigEditModal" className="modal">
      <div className="modal-content">
        <h4>Edit Config</h4>
        <div className="input-field col s12">
          <input
            type="text"
            id="name"
            placeholder="Name"
            {...register('name', {
              required: 'This is required.',
              minLength: 5,
              maxLength: 20,
            })}
            value={data?.name}
          />
          <br />
          ID: {data?.id}
        </div>
        <label>Config</label>
        <JsonEditor
          jsonObject={parseJSONInput(config)}
          onChange={output => {
            console.log(output)
            setConfig(JSON.parse(output))
          }}
        />
        <label>Creds</label>
        <JsonEditor
          jsonObject={parseJSONInput(creds)}
          onChange={output => {
            console.log(output)
            setCreds(JSON.parse(output))
          }}
        />
      </div>
      <div className="modal-footer">
        <a
          href="#!"
          ref={el => (closeBtn = el)}
          onClick={onClose}
          className="modal-close btn modal-close"
          style={{ marginRight: '1rem' }}
        >
          close
        </a>
        <button className="btn" onClick={onCreate}>
          Save
        </button>
      </div>
    </div>
  )
}

export default AdminConfigEditModal
