import MultiMenuTable from './components/MultiMenuTable'
import SubMenus from './components/SubMenus'
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useRequestStatus } from '../../hooks/useRequestStatus'
import { saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

function MultiMenu() {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const { makeApiRequest } = useRequestStatus()
  const methods = useForm({ defaultValues: selectedRestaurant?.subMenusSetting })
  const { control, trigger, getValues, reset } = methods

  const handleSave = async () => {
    console.log('submit')
    if (!(await trigger())) return
    console.log(getValues())
    const subMenusSetting = getValues()

    await makeApiRequest(saveRestaurantInfoData, [
      {
        id: selectedRestaurant.id,
        subMenus: selectedRestaurant.subMenus,
        subMenusSetting,
      },
    ])
  }

  const handleToastEmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      toast.info('Please make sure you assigned every item to a submenu', { autoClose: 30000, closeButton: true })
    }
  }

  useEffect(() => {
    reset(selectedRestaurant?.subMenusSetting)
  }, [selectedRestaurant?.subMenusSetting])

  return (
    <FormProvider {...methods}>
      <Stack p={2} direction="column" spacing={4} sx={{ position: 'relative' }}>
        <Stack spacing={1}>
          <Typography variant="h3">Multi Menu management</Typography>
          <Typography variant="body1" maxWidth="60%">
            This is ideal if you want to have a specific menu to display in a certain area of your venue, for example if
            you have a seperate menu for your restaurant area and for your hotelroom
          </Typography>
        </Stack>

        <FormControlLabel
          control={
            <Controller
              name="splitOrderIntoSuborders"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={e => {
                    handleToastEmit(e)
                    field.onChange(e)
                  }}
                />
              )}
            />
          }
          label={
            <span>
              <b>Split order into suborders </b> based on submenus
            </span>
          }
        />

        <Stack spacing={8}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h6">Manage Submenus</Typography>
              <Typography variant="body2">Add, Edit or remove submenus.</Typography>
            </Stack>
            <SubMenus />
          </Stack>

          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h6">Submenu Item Visibility</Typography>
              <Typography variant="body2">Enable or disable a specific item to appear in each submenu</Typography>
            </Stack>
            <MultiMenuTable />
          </Stack>
        </Stack>
        <Button variant="contained" onClick={handleSave} fullWidth sx={{ position: 'sticky', bottom: '10px' }}>
          Save
        </Button>
      </Stack>
    </FormProvider>
  )
}

export default MultiMenu
