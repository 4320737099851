import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { getLogsApi } from '../../api/logsApi'
import moment from 'moment'
import { useRecoilValue } from 'recoil'
import { restaurantListState } from '../../atoms/RestaurantListAtom'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { teamListState } from '../../atoms/TeamsAtoms'
import { useOutletContext } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import getActionName from './helpers/getActionName'
import Spinner from '../../ui/Spinner/Spinner'
import TableBodySkeleton from '../../components/TableBodySkeleton/TableBodySkeleton'
import CollapsibleTableRow from '../../components/CollapsibleTableRow/CollapsibleTableRow'
import { getRecentEventToCompare, groupEventsByEndpoint, sanitizeEvent } from './helpers/diffHelpers'
import DiffComponent from './components/Diff'

const LIMIT = 40

function Logs() {
  const restaurants = useRecoilValue(restaurantListState)
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const teamList = useRecoilValue(teamListState)
  const [logs, setLogs] = useState<any>([])
  const { parentRef } = useOutletContext<any>()
  const ref = useRef<any>(null)
  const [isFetching, setFetching] = useState(false)
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        setLogs([])
        setFetching(true)
        const { data } = await getLogsApi(selectedRestaurant?.id, '3082-02-16T12:11:47.404Z', LIMIT)
        console.log(data)
        setFetching(false)
        setLogs(data)
      } catch (e) {
        setFetching(false)
      }
    })()
  }, [selectedRestaurant?.id])

  const loadMoreLogs = async () => {
    try {
      console.log(isFetching)
      if (isFetching) return
      console.log('fetching more logs')
      setFetching(true)
      const resp = await getLogsApi(selectedRestaurant?.id, logs[logs.length - 1].createdAt, LIMIT)
      setFetching(false)
      setLogs([...logs, ...resp.data])
    } catch (e) {
      setFetching(false)
    }
  }

  console.log(groupEventsByEndpoint(logs))

  return (
    <Stack spacing={2} py={2} px={{ xs: 0, sm: 4 }} ref={ref} pb={5}>
      <Stack spacing={2}>
        <Typography variant="h3">Activity logs</Typography>
        <Table sx={{ width: '100%', overflowX: 'auto', display: { xs: 'block', sm: 'table' } }}>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell align="right">User type</TableCell>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">IP</TableCell>
              <TableCell align="right">Restaurant</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {logs?.length === 0 ? (
            <TableBodySkeleton rowsNum={10} columnsNum={6} />
          ) : (
            <TableBody>
              {logs?.map((row: any, index: number) => (
                <React.Fragment key={row.id}>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {getActionName(row)}
                    </TableCell>
                    <TableCell align="right">{row.actorType}</TableCell>
                    <TableCell align="right">{teamList?.find((user: any) => user.id === row.actorId)?.email}</TableCell>
                    <TableCell align="right">{row.ip}</TableCell>
                    <TableCell align="right">
                      {restaurants?.find(restaurant => restaurant?.id === row.restaurantId)?.name}
                    </TableCell>
                    <TableCell align="right">{moment(row.createdAt).format('lll')}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          setExpandedRow(expandedRow === index ? null : index)
                        }}
                        className="browser-default"
                      >
                        {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <CollapsibleTableRow expanded={expandedRow === index}>
                    <Stack m={4} spacing={4}>
                      <Typography variant="h5">Raw:</Typography>
                      <DiffComponent
                        oldObj={sanitizeEvent(getRecentEventToCompare(row, logs))}
                        newObj={sanitizeEvent(row)}
                      />
                    </Stack>
                  </CollapsibleTableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </Stack>
      {logs?.length !== 0 && isFetching && (
        <Stack direction="row" justifyContent="center">
          <Spinner size="sm" />
        </Stack>
      )}
      <Button onClick={loadMoreLogs} disabled={isFetching} variant="outlined">
        Load more
      </Button>
    </Stack>
  )
}

export default Logs
