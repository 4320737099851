import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { listCoupons, listTokensByAnonymous } from '../../api/adminApi'
import GenericLoadingComponent from '../../components/loadingComponents/GenericLoadingComponent'
import { useAuthContext } from '../../contexts/AuthContext'
import AdminCouponEditModal from './AdminCouponEditModal'
import AdminCouponTable from './AdminCouponTable'
import CreateCouponForm from './CreateCouponForm'

/**
 *
 */
function AdminCoupon({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [loaded, setLoaded] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const { loggedIn, isAdmin } = useAuthContext()
  const [coupons, setCoupons] = useState([])
  const [editIndex, setEditIndex] = useState()
  const [tokens, setTokens] = useState([])

  const refresh = async () => {
    try {
      const tokenResp = await listTokensByAnonymous()
      setTokens(tokenResp.data)
      const couponResp = await listCoupons()
      setCoupons(
        couponResp.data.map(coupon => ({
          ...coupon,
          coin: tokenResp.data.find(token => token.id === coupon.tokenId),
        }))
      )
    } catch (error) {
      setLoadingError(true) // TODO: enable error handling, no data returned.
    }
  }

  const updateData = async () => {
    setLoaded(false)
    await refresh()
    setLoaded(true)
  }

  useEffect(() => {
    updateData()
  }, [loggedIn])

  if (!loaded) {
    return <GenericLoadingComponent />
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col s12 m12">
          <h3>[Admin] Coupon</h3>
          <p>Admin can control coupons here</p>
          <table className="team-table">
            <thead>
              <tr>
                <th>Code</th>
                <th>Coin</th>
                <th>Usage (used | limit)</th>
                <th>URL</th>
                <th>Edit</th>
              </tr>
            </thead>
            <AdminCouponTable coupons={coupons} setEditIndex={setEditIndex} />
          </table>
          <AdminCouponEditModal coupons={coupons} editIndex={editIndex} refresh={refresh} />
          <CreateCouponForm tokens={tokens} refresh={refresh} />
        </div>
      </div>
    </div>
  )
}

export default AdminCoupon
