import React, { useEffect, useState } from 'react'
import { useMenuContext } from '../../../contexts/MenuContext'
import { stringToDashFormat } from '../../../utils/stringToDashFormat'
import SubMenuRow from './SubMenuRow'
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SubMenuForm from '../../../components/forms/SubMenuForm'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { OrderingModes } from '../../../types/Common'
import SubMenu from '../../../types/SubMenu'
import useYupValidationResolver from '../../../hooks/useYupValidationResolver'
import subMenuSchema from '../../../schemas/subMenuSchema'
import TableBodySkeleton from '../../../components/TableBodySkeleton/TableBodySkeleton'
import selectedRestaurantSubMenusState from '../../../selectors/selectedRestaurantSubmenus'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { saveRestaurantInfoData } from '../../../api/restaurantInfoApi'

function slugify(str: any) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

const DEFAULT_VALUES = {
  orderingMode: OrderingModes.NO_SPECIAL_ORDERING,
  visibleItemIds: [],
}

const SubMenus = () => {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [subMenus, setSubMenus] = useRecoilState(selectedRestaurantSubMenusState)
  console.log(subMenus)

  const [showForm, setShowForm] = useState(false)

  const resolver = useYupValidationResolver(subMenuSchema)
  const methods = useForm<SubMenu>({
    resolver,
    defaultValues: DEFAULT_VALUES,
  })
  const { handleSubmit, reset } = methods
  const { makeApiRequest } = useRequestStatus()

  const onSubmit = async (data: any) => {
    console.log('submit', data)
    const parsedData = {
      ...data,
      id: stringToDashFormat(data.name),
    }
    console.log(parsedData)
    const safeSubmenus = subMenus || []
    const newData = [...safeSubmenus, parsedData]

    await makeApiRequest(saveRestaurantInfoData, [
      {
        id: selectedRestaurant.id,
        subMenus: newData,
      },
    ])
    setSubMenus(newData)
    setShowForm(false)
    reset(undefined, { keepDirtyValues: true })
  }

  console.log('subMenus', subMenus)

  return (
    <Stack spacing={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Settings</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {!selectedRestaurant ? (
          <TableBodySkeleton rowsNum={3} columnsNum={3} />
        ) : (
          <TableBody>
            {!subMenus?.length && (
              <TableRow>
                <TableCell rowSpan={3}>Currently no submenu's setup</TableCell>
              </TableRow>
            )}
            {subMenus?.map((subMenu: any, index: any) => (
              <SubMenuRow key={subMenu.id} subMenu={subMenu} />
            ))}
          </TableBody>
        )}
      </Table>
      <Collapse in={!showForm}>
        <Button variant="outlined" fullWidth onClick={() => setShowForm(true)} endIcon={<AddIcon />}>
          Add submenu
        </Button>
      </Collapse>
      <Collapse in={showForm}>
        <Stack spacing={2} alignItems="center">
          <IconButton onClick={() => setShowForm(false)}>
            <ExpandLessIcon />
          </IconButton>
          <Typography variant="h4">New submenu</Typography>
          <FormProvider {...methods}>
            <SubMenuForm restaurant={selectedRestaurant} />
          </FormProvider>
          <Stack direction="row" spacing={2}>
            <Button fullWidth variant="outlined" onClick={() => setShowForm(false)}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" onClick={handleSubmit(onSubmit, console.error)}>
              Submit this submenu
            </Button>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default SubMenus
