import React, { useEffect, useState } from 'react'
import GenericLoadingComponent from '../../components/loadingComponents/GenericLoadingComponent'
import { useMenuContext } from '../../contexts/MenuContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { getDevices, updateDevice } from '../../api/deviceApi'
import { Button, Stack, Typography } from '@mui/material'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import { FormProvider, useForm } from 'react-hook-form'
import Printer from '../../types/Printer'
import printerSchema from '../../schemas/printerSchema'
import PrinterForm from '../../components/forms/PrinterForm'
import PartialBackdrop from '../../ui/PatrialBackdrop/PartialBackdrop'

function CurrentDevice() {
  // @ts-ignore
  const { user } = useAuthContext()

  console.log(user)

  const [isSaving, setSaving] = useState(false)

  const resolver = useYupValidationResolver(printerSchema)
  const methods = useForm<Printer>({
    resolver,
    defaultValues: user,
  })

  const onSubmit = (data: any) => {
    console.log('Submit valid', data)
    setSaving(true)

    updateDevice(user.id, data)
      .then(result => {
        console.log('Saved ', result)
      })
      .catch(error => {
        console.log('ERROR ', error)
      })
      .finally(() => setSaving(false))
  }

  useEffect(() => {
    if (user) {
      methods.reset(user)
    }
  }, [user])

  return (
    <>
      <PartialBackdrop show={isSaving}>
        <Stack p={4} spacing={6}>
          <Stack spacing={2}>
            <Typography variant="h4">Current Device:</Typography>
            <FormProvider {...methods}>
              <PrinterForm />
            </FormProvider>
          </Stack>
          <Stack spacing={2}>
            <Button
              variant="contained"
              onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </PartialBackdrop>
    </>
  )
}

export default CurrentDevice
