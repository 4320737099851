const OrderScreenPreview = ({
  inProgressBackgroundColor = '#000',
  inProgressTextColor = '#fff',

  readyBackgroundColor = '#d1ffca',
  readyTextColor = '#000',
  orderBackground = '#ffffff',
}) => {
  return (
    <div style={{ backgroundColor: orderBackground }} className="order-screen-preview-wrapper">
      <div>Kiosk Orders</div>
      <div style={{ backgroundColor: inProgressBackgroundColor }} className="col s6">
        <p style={{ color: inProgressTextColor }}>In Progress</p>
      </div>
      <div style={{ backgroundColor: readyBackgroundColor }} className="col s6">
        <p style={{ color: readyTextColor }}>Ready</p>
      </div>
    </div>
  )
}

export default OrderScreenPreview
