import { Box, Button, Stack, TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import DeleteConfirmButton from '../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import { useEffect, useState } from 'react'
import CouponForm from '../../../components/forms/CouponForm'
import CollapsibleTableRow from '../../../components/CollapsibleTableRow/CollapsibleTableRow'
import { FormProvider, useForm } from 'react-hook-form'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { updateCoupon } from '../../../api/adminApi'

type CouponTableRowProps = {
  coupon: any
  isEditing?: boolean
  onEdit?: (couponId: any) => any
  onDelete?: (coupon: any) => any
}

function CouponTableRow({ coupon, onEdit = () => null, isEditing, onDelete = () => null }: CouponTableRowProps) {
  const methods = useForm({ defaultValues: coupon })
  const { handleSubmit } = methods
  const { makeApiRequest } = useRequestStatus()

  const onSubmit = async (data: any) => {
    console.log('coupon update request', data)
    await makeApiRequest(updateCoupon, [data?.id, data])
  }

  useEffect(() => {
    methods.reset(coupon)
  }, [coupon])

  return (
    <>
      <TableRow key={coupon?.id} sx={{ borderBottom: 'unset', '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{coupon.code}</TableCell>
        <TableCell>{coupon.description}</TableCell>
        <TableCell>{coupon.type}</TableCell>
        <TableCell>{coupon.rewardAmount}</TableCell>
        <TableCell>{coupon.usageCount}</TableCell>
        <TableCell>{coupon.usageLimit > 0 ? coupon.usageLimit : 'Unlimited'}</TableCell>
        <TableCell>{moment(coupon.from).format('DD.MM.YYYY')}</TableCell>
        <TableCell>{moment(coupon.to).format('DD.MM.YYYY')}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => onEdit(coupon?.id)}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <DeleteConfirmButton onClick={() => onDelete(coupon)} />
        </TableCell>
      </TableRow>

      <CollapsibleTableRow expanded={isEditing}>
        <Stack spacing={1} px={4}>
          <FormProvider {...methods}>
            <CouponForm />
          </FormProvider>
          <Stack py={3}>
            <Button variant="contained" onClick={handleSubmit(onSubmit, console.error)}>
              Save
            </Button>
          </Stack>
        </Stack>
      </CollapsibleTableRow>
    </>
  )
}

export default CouponTableRow
