import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Info from './Tabs/Info/Info'
import { useFormContext, useWatch } from 'react-hook-form'
import Options from './Tabs/Options/Options'
import Extras from './Tabs/Extras/Extras'
import useDeprecatedItemValues from '../../../hooks/useDeprecatedItemValues'
import { Item } from '../../../types'

type ItemFormProps = {
  restaurant: any
  // specialAvailability: Item['specialAvailability']
  // setSpecialAvailability: (args: any) => any
}

function ItemForm({ restaurant }: ItemFormProps) {
  const { t } = useTranslation()
  const [tab, setTab] = useState(1)
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useFormContext<Item>()
  useDeprecatedItemValues(getValues as any, setValue as any)

  const specialAvailability = useWatch({ control, name: 'specialAvailability' })
  const setSpecialAvailability = (newSpecialAvailability: any) => {
    if (typeof newSpecialAvailability === 'function') {
      setValue('specialAvailability', newSpecialAvailability(specialAvailability))
    } else {
      setValue('specialAvailability', newSpecialAvailability)
    }
  }

  useEffect(() => {
    // Find tabs, where errors occured
    // There might be some better way of doing this
    let infoErrors = false
    let optionsErrors = false
    let extrasErrors = false

    console.log(errors)

    Object.keys(errors).forEach(error => {
      if (error.includes('options')) optionsErrors = true
      else if (error.includes('extras')) extrasErrors = true
      else infoErrors = true
    })

    if (infoErrors) setTab(1)
    if (optionsErrors) setTab(2)
    if (extrasErrors) setTab(3)
  }, [errors])

  return (
    <Stack spacing={2} sx={{ minHeight: '400px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="col s12">
        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} variant="fullWidth">
          <Tab
            value={1}
            label={t('menuView.editMenuItem.EditProductTabProductInfo')}
            sx={{ backgroundColor: 'transparent !important' }}
          />
          <Tab
            value={2}
            label={t('menuView.editMenuItem.EditProductTabProductOptions')}
            sx={{ backgroundColor: 'transparent !important' }}
          />
          <Tab
            value={3}
            label={t('menuView.editMenuItem.EditProductTabProductExtras')}
            sx={{ backgroundColor: 'transparent !important' }}
          />
        </Tabs>
      </Box>
      {tab === 1 && (
        <Info
          restaurant={restaurant}
          item={getValues()}
          specialAvailability={specialAvailability}
          setSpecialAvailability={setSpecialAvailability}
        />
      )}
      {tab === 2 && <Options restaurant={restaurant} item={getValues()} />}
      {tab === 3 && <Extras restaurant={restaurant} item={getValues()} />}
    </Stack>
  )
}

export default ItemForm
