import React from 'react'

const Toggelable = ({ title, description, children, defaultHidden = true }) => {
  const [hidden, setHidden] = React.useState(defaultHidden)

  return (
    <div className="toggleable-wrapper">
      <div className="toggleable-row" onClick={() => setHidden(!hidden)}>
        <h4>{title}</h4>
        <button onClick={() => setHidden(!hidden)} className="btn">
          {hidden ? 'Show' : 'Hide'}
        </button>
      </div>
      {description && <p className="description">{description}</p>}
      {!hidden && children}
    </div>
  )
}

export default Toggelable
