import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import { useForm } from 'react-hook-form'
import { createCoupon, generateCouponCode } from '../../api/adminApi'

/**
 *
 */
function CreateCouponForm({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [showCreate, setShowCreate] = useState(false)
  const [tokens, setTokens] = useState(props.tokens)
  const [code, setCode] = useState()
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()

  useEffect(() => {
    setTokens(props.tokens)
  }, [props.tokens])

  useEffect(() => {
    generateCouponCode().then(resp => {
      setCode(resp.data.code)
    })
  }, [])

  let onCreate = e => {
    e.preventDefault()

    let data = watch()
    createCoupon({
      ...data,
      code,
      name: code,
    }).finally(() => {
      props.refresh()
      setShowCreate(false)
    })
  }

  return (
    <>
      <div className="end-of-menu-list">
        <h4>Add Coupon Code</h4>
        {!showCreate && (
          <>
            <p>Add new coupon code.</p>
            <p>
              After create an account, click edit to attach account to restaurant. Thus restaurant will pickup this
              account to process payments.
            </p>
            <div className="buttons-row">
              <div className="pepi-button" onClick={() => setShowCreate(true)}>
                Add Code
              </div>
            </div>
          </>
        )}
        {showCreate && (
          <>
            <p>Fill this form carefully</p>
            <form onSubmit={onCreate}>
              <div className="input-field col s12">
                <input
                  type="text"
                  value={code}
                  disabled={true}
                  {...register('code', {
                    required: 'This is required.',
                    minLength: 5,
                    maxLength: 20,
                  })}
                  id="code"
                />
              </div>

              <label>Select coin</label>
              <select className="browser-default" {...register('tokenId')}>
                <option value="" disabled selected>
                  Select coin
                </option>
                {tokens.map((token, index) => (
                  <option key={index} value={token.id}>
                    {token.name} ({token.symbol})
                  </option>
                ))}
              </select>

              <div className="input-field col s12">
                <input
                  type="number"
                  {...register('rewardAmount', {
                    required: 'This is required.',
                  })}
                  id="rewardAmount"
                />
                <label htmlFor="reward">Reward Amount (Coins given per use)</label>
              </div>
              <div className="input-field col s12">
                <input type="number" {...register('scanLimit', { required: 'This is required.' })} id="scanLimit" />
                <label htmlFor="uses">Limit of uses (Amount of times it can be scanned)</label>
              </div>
              <p className="highlight">
                Will give out a total of: <b>{(watch('scanLimit') * watch('rewardAmount')).toLocaleString()} </b>{' '}
                {watch('tokenId') && tokens.find(t => t.id === watch('tokenId'))?.symbol} Coin
              </p>
              <button className="pepi-button" onClick={onCreate}>
                Create Code
              </button>
            </form>
          </>
        )}
      </div>
    </>
  )
}

export default CreateCouponForm
