import { Button } from '@mui/material'
import { useRequestStatus } from '../../../hooks/useRequestStatus'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { syncVektoriMenu } from '../../../api/restaurantInfoApi'

function VektorySyncButton() {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const config = selectedRestaurant?.config
  const { makeApiRequest } = useRequestStatus()

  const onSyncVektoriMenu = async () => {
    const { resp } = await makeApiRequest(syncVektoriMenu, [selectedRestaurant.id], {
      pendingMessage: 'Syncing menu with Vektori...',
    })
    console.log(resp)
  }

  let buttonText = ''
  if (config?.syncMenuFromVektoriToMynt && config?.syncMenuFromMyntToVektori) {
    buttonText = 'Sync menu with Vektori'
  } else if (config?.syncMenuFromVektoriToMynt) {
    buttonText = 'Sync menu from Vektori'
  } else if (config?.syncMenuFromMyntToVektori) {
    buttonText = 'Sync menu to Vektori'
  }

  return (
    buttonText && (
      <Button variant="contained" onClick={onSyncVektoriMenu}>
        {buttonText}
      </Button>
    )
  )
}

export default VektorySyncButton
