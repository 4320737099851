import _ from 'lodash'
import axios from 'axios'
import { getAuth, connectAuthEmulator, AuthErrorCodes } from 'firebase/auth'
import { request } from './base'

export async function getRestaurantInfo() {
  const resp = await request(`/cms/getRestaurantsByCMS`, 'get')
  return resp?.data
}

export async function getRestaurantInfoById(id) {
  const resp = await request(`/cms/getRestaurantById`, 'get', {
    params: {
      id: id,
    },
  })
  return resp?.data
}

/**
 *  This is to update the restaurant info data
 *  For example:
 *  - Name
 *  - Address
 *  - Opening Times
 *  - Restaurant image
 */
export async function saveRestaurantInfoData(data) {
  const resp = await request(`/cms/updateRestaurant`, 'put', {
    params: {
      id: data.id,
    },
    payload: data,
  })
  return resp?.data
}

export async function saveCredsData(data) {
  const resp = await request(`/cms/updateCredsOfRestaurant`, 'put', {
    params: {
      id: data.id,
    },
    payload: data,
  })
  return resp?.data
}

export async function updateMenuItemVisibility(data) {
  const resp = await request(`/cms/updateMenuItemVisibility`, 'put', {
    params: {
      id: data.id,
    },
    payload: data,
  })
  return resp?.data
}

function orderMenuItemToString(menu) {
  let tooltip = ''

  for (let i = 0; i < menu.length; i++) {
    const element = menu[i]

    tooltip += element.quantity + 'x ' + element.item.title

    if ((element.item?.chosenExtras || []).length > 0) {
      tooltip += '(extra: '
      for (let y = 0; y < element.item.chosenExtras.length; y++) {
        //const element = element.item.chosenExtras[y];
        tooltip += element.item.chosenExtras[y].name
        let nexty = y + 1
        if (nexty != element.item.chosenExtras.length) {
          tooltip += ','
        }
      }
      tooltip += ')'
    }

    if ((element.item?.chosenOptions || []).length > 0) {
      tooltip += '(Options: '
      for (let y = 0; y < element.item.chosenOptions.length; y++) {
        //const element = element.item.chosenExtras[y];
        tooltip += element.item.chosenOptions[y]?.optionList?.[0]?.name
        let nexty = y + 1
        if (nexty != element.item.chosenOptions.length) {
          tooltip += ','
        }
      }
      tooltip += ')'
    }
    tooltip += '   '
  }

  return tooltip
}

const flattenMetadata = data => {
  if (data?.scandicFriendID) {
    return data.scandicFriendID.toString()
  }
  return ''
}

export async function getOrders(filterParams) {
  const { restaurantIds, restaurantId, startAfter, endBefore, limit, from, to } = filterParams
  if (limit <= 50) {
    const resp = await request(`/cms/owners/transactions`, 'get', {
      params: {
        startAfter,
        restaurantId,
        restaurantIds,
        endBefore,
        limit,
        from,
        to,
      },
    })
    return {
      success: resp?.data.success,
      data: (resp?.data?.data || []).map(order => ({
        ...order,
        orderedItems: orderMenuItemToString(order.menu),
        metadataStr: flattenMetadata(order.metadata),
      })),
    }
  } else {
    const requestTimes = Math.ceil(limit / 50)
    let data = []
    let internalStartAfter = startAfter
    let internalEndBefore = endBefore
    console.log('requestTimes', requestTimes)
    for (let i = 0; i < requestTimes; i++) {
      const resp = await request(`/cms/owners/transactions`, 'get', {
        params: {
          startAfter: internalStartAfter,
          restaurantId,
          endBefore: internalEndBefore,
          limit: 50,
          from,
          to,
        },
      })
      const oneTimeOrders = (resp?.data?.data || []).map(order => ({
        ...order,
        orderedItems: orderMenuItemToString(order.menu),
        metadataStr: flattenMetadata(order.metadata),
      }))
      // console.log('oneTimeOrders', oneTimeOrders)
      if ((!internalEndBefore || internalStartAfter) && oneTimeOrders.length) {
        internalStartAfter = oneTimeOrders?.[oneTimeOrders.length - 1]?.createdAt
      } else if (internalEndBefore && oneTimeOrders.length) {
        internalEndBefore = oneTimeOrders?.[0]?.createdAt
      }
      data = data.concat(oneTimeOrders)
    }
    data = _.unionBy(data, 'id')
    // console.log('data', data)
    return {
      success: true,
      data,
    }
  }
}

export async function getOrdersWithReceipt(restaurantId, startAfter = undefined) {
  const resp = await request(`/cms/owners/transactions`, 'get', {
    params: {
      startAfter,
      restaurantId,
      paymentStatus: 'paid',
    },
  })
  return resp?.data
}

export async function toggleCloseRestaurant(restaurantId, isClose) {
  const resp = await request(`/cms/owners/toggleCloseRestaurant`, 'post', {
    params: {
      id: restaurantId,
      isClose,
    },
  }).catch(error => {
    console.clear()
    console.log('Error: ', error.response.data.error)
    alert('Error: ' + error?.response?.data?.error)
  })
  return resp?.data
}

export async function createRestaurant(payload) {
  const resp = await request(`/cms/admin/createRestaurant`, 'post', {
    params: {},
    payload,
  })
  return resp?.data
}

export async function generateOnboardingLink(id, type) {
  const resp = await request(`/cms/restaurants/${id}/${type}/getOnboardingUrl`, 'post', {
    payload: {
      id,
    },
  })
  return resp?.data
}

export async function syncVektoriMenu(id, type) {
  const resp = await request(`/cms/restaurants/${id}/synvVektoriMenu`, 'post', {
    payload: {
      id,
    },
  })
  return resp?.success
}

export async function getRestaurantTranslations(id) {
  const resp = await request(`/cms/restaurants/${id}/translations`)
  return resp?.data
}

export async function setRestaurantTranslations(id, translations) {
  const resp = await request(`/cms/restaurants/${id}/translations`, 'post', {
    payload: {
      translations,
    },
  })
  return resp?.data
}
