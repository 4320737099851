import { useTranslation } from 'react-i18next'
import getLocalizedString from '../../utils/getLocalizedString'

type LocalizedProps = {
  target: any
  translationsKey: string
  fallbackKey?: string
}

// For more semantic usage inside JSX
function Localized({ target, translationsKey, fallbackKey }: LocalizedProps) {
  return getLocalizedString(target, translationsKey, fallbackKey)
}

export default Localized
