import moment from 'moment'
import M from 'materialize-css'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import OrderTable from '../../components/orderTable/OrderTable'
import { getOrders, getRestaurantInfo } from '../../api/restaurantInfoApi'
import { useRecoilState } from 'recoil'
import { useAuthContext } from '../../contexts/AuthContext'
import LoadingOrders from '../../components/loadingComponents/LoadingOrders'
import DownloadCSVButton from '../../components/orderComponents/DownloadCsvButton'
import { useMenuContext } from '../../contexts/MenuContext'
import AdminRestaurantCard from './AdminRestaurantCard'

/**
 *
 */
function AdminOrdersFlow({ domElement, ...props }) {
  const { t, i18n } = useTranslation()
  const [loaded, setLoaded] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [orders, setOrders] = useState([])
  const [resultPerDay, setResultPerDay] = useState({})
  const [page, setPage] = useState(0)
  const [amountToday, setAmountToday] = useState(0.0)
  const [amountYesterday, setAmountYesterday] = useState(0.0)
  const [amountBeforeYesterday, setAmountBeforeYesterday] = useState(0.0)
  const [pageCount, setPageCount] = useState(0)
  const { loggedIn } = useAuthContext()
  const [restaurants, setRestaurants] = useState([])
  const [filterParams, setFilterParams] = useState({
    limit: 50,
  })

  const { allRestaurantData, refreshData } = useMenuContext()

  useEffect(() => {
    if (!loggedIn) return

    if (allRestaurantData != null) {
      setRestaurants(allRestaurantData)

      setFilterParams({
        ...filterParams,
        limit: filterParams?.limit || 15,
      })
    }
  }, [loggedIn, allRestaurantData])

  useEffect(() => {
    let elems = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(elems, {})
    let Delems = document.querySelectorAll('select')
    M.FormSelect.init(Delems, {})
    // let DropElems = document.querySelectorAll(".dropdown-trigger");
    // M.Dropdown.init(DropElems, {});
  }, [loaded, orders])

  useEffect(() => {
    console.log('Res per day: ', resultPerDay)
  }, [resultPerDay])

  if (!loaded) {
    return <LoadingOrders />
  }

  if (loadingError) {
    return (
      <main>
        <div className="error-wrapper">
          <div className="error-row">
            <i className="medium material-icons">error</i>
          </div>
          <div className="error-row">
            <h5>{t('general.ErrorLoading')}</h5>
          </div>
        </div>
      </main>
    )
  }

  let updateTotals = (today, yesterday, dayBeforeYesterday) => {
    today += amountToday
    yesterday += amountYesterday
    dayBeforeYesterday += amountBeforeYesterday

    today = Number(today.toFixed(2))
    yesterday = Number(yesterday.toFixed(2))
    dayBeforeYesterday = Number(dayBeforeYesterday.toFixed(2))

    setAmountToday(today)
    setAmountYesterday(yesterday)
    setAmountBeforeYesterday(dayBeforeYesterday)
  }

  let addChartData = newOrders => {
    console.log('orders got: ', newOrders)
    updateData()
    setOrders([...orders, ...newOrders])
  }

  const now = new Date()
  let today = now.toISOString()
  now.setDate(now.getDate() - 1)
  let yesterday = now.toISOString()
  now.setDate(now.getDate() - 1)
  let dayBeforeYesterday = now.toISOString()

  today = today.slice(0, 10)
  yesterday = yesterday.slice(0, 10)
  dayBeforeYesterday = dayBeforeYesterday.slice(0, 10)

  let updateData = () => {
    console.log('Updating, ')
    console.log(today)
    console.log(yesterday)
    console.log(dayBeforeYesterday)
    setOrdersByDate(today)
    console.log('Res today', resultPerDay)
    setOrdersByDate(yesterday)
    console.log('Res yester', resultPerDay)
    setOrdersByDate(dayBeforeYesterday)
    console.log('Res befor', resultPerDay)
  }

  let setOrdersByDate = date => {
    console.log('Trying to get orders ', orders)
    console.log('Trying to get result current ', resultPerDay)

    let newResult = resultPerDay[date] || 0

    let sum = orders.reduce(
      (accumulator, currentValue) =>
        currentValue.formattedDate == date ? accumulator + parseFloat(currentValue.totalAmount) : null,
      0
    )

    newResult = newResult + sum

    console.log(date, 'Result: ', newResult)

    let newData = { [date]: newResult }

    console.log(resultPerDay)

    setResultPerDay({ ...resultPerDay, ...newData })
  }

  return (
    <div className="container">
      <h4>[Admin Only] Orders</h4>
      <div className="row order-info-row">
        <div className="col s8">
          <div className="input-field col s3">
            <input
              id="from"
              name="from"
              type="date"
              className="search"
              onChange={e => {
                console.log('from', e.target.value)
                setFilterParams({
                  ...filterParams,
                  from: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').startOf('day').unix() : undefined,
                })
              }}
            />
            <label htmlFor="search">{t('ordersView.from')}</label>
          </div>
          <div className="input-field col s3 ">
            <input
              id="to"
              name="to"
              type="date"
              className="search"
              onChange={e =>
                setFilterParams({
                  ...filterParams,
                  to: e.target.value ? moment(e.target.value, 'YYYY-MM-DD').endOf('day').unix() : undefined,
                })
              }
            />
            <label htmlFor="to">{t('ordersView.to')}</label>
          </div>
          <div className="input-field col s3 ">
            <select
              value={filterParams.limit}
              onChange={e => {
                setFilterParams({
                  ...filterParams,
                  limit: e.target.value,
                })
              }}
            >
              <option value={15}>15</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={1000}>1000</option>
            </select>
            <label htmlFor="to">{t('ordersView.limit')}</label>
          </div>
          <div className="input-field col s3 ">
            <DownloadCSVButton orders={orders} />
          </div>
        </div>
        <div className="col s4">
          <div className="input-field col s12">
            <select
              onChange={e => {
                setFilterParams({
                  ...filterParams,
                  restaurantId: restaurants.find(r => r.id === e.target.value)?.id,
                })
              }}
            >
              {/* <option
                    value=""
                    selected={typeof filterParams?.restaurantId === "undefined"}
                  >
                    All Restaurants
                  </option> */}
              {(restaurants || []).map((r, rIndex) => (
                <option key={r.id} value={r.id} selected={r.id === filterParams?.restaurantId}>
                  {r.name}
                </option>
              ))}
            </select>
            <label>{t('ordersView.filterTitle')}</label>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-content">
          <h4>Results</h4>
          <table>
            <thead>
              <tr>
                <th>Today</th>
                <th>Yesterday</th>
                <th>Day Before</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{resultPerDay[today]}</td>
                <td>{resultPerDay[yesterday]}</td>
                <td>{resultPerDay[dayBeforeYesterday]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {allRestaurantData.map(restaurant => (
        <AdminRestaurantCard
          key={restaurant.id}
          restaurant={restaurant}
          extraFilterParams={filterParams}
          updateTotals={updateTotals}
          addChartData={addChartData}
        />
      ))}
    </div>
  )
}

export default AdminOrdersFlow
