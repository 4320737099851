import { Box, Button, Card, Divider, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { ReactNode, useEffect, useState } from 'react'
import Chips from './components/Chips'
import GroupOptions from './components/GroupOptions'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import OptionGroup from './components/OptionGroup'
import { Item, Option } from '../../../../../types'
import CopyOptionsDialog from './components/copyOptionsDialog/CopyOptionsDialog'
import { v4 as uuidv4 } from 'uuid'

type OptionsProps = {
  item: Item
  restaurant: any
}

function Options({ item, restaurant }: OptionsProps) {
  const { register, formState, control, setValue, getValues } = useFormContext<Item>()
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'options',
  })
  const watchedValues = useWatch({ name: 'options', control })
  const [copyOptionsDialogOpen, setCopyOptionsDialogOpen] = useState(false)

  const handleOptionsGroupAdd = () => {
    const newOptionsGroup: Option = {
      optionChoiceLimit: '1',
      translations: {
        optionName: {
          en: '',
        },
      },
      optionList: [],
    }
    append(newOptionsGroup)
  }

  const handleCopiedOptionsReceive = (newOptions: Option[]) => {
    newOptions.forEach(optionGroup => {
      if ((optionGroup as any)?.id) {
        ;(optionGroup as any).id = uuidv4()
      }
      optionGroup.optionList = optionGroup.optionList.map(option => {
        if ((option as any)?.id) {
          ;(option as any).id = uuidv4()
        }
        return option
      })
      append(optionGroup)
    })

    setCopyOptionsDialogOpen(false)
  }

  const handleOptionsGroupRemove = (index: number) => {
    console.log(index)
    remove(index)
  }

  const onOptionGroupDragEnd = (result: any) => {
    if (result.destination) {
      move(result.source.index, result.destination.index)
    }
  }

  return (
    <Stack spacing={3}>
      <DragDropContext onDragEnd={onOptionGroupDragEnd}>
        <Droppable droppableId="options-list-droppable">
          {provided => (
            <Stack {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <Draggable draggableId={field.id} index={index} key={field.id}>
                  {(prov, snapshot) => (
                    <div
                      ref={prov.innerRef}
                      // className={`menu-item-wrapper ${snapshot.isDragging ? 'dragging' : ''}`}
                      {...prov.draggableProps}
                    >
                      <OptionGroup
                        index={index}
                        restaurant={restaurant}
                        onDelete={() => handleOptionsGroupRemove(index)}
                        dragHandleProps={prov.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>

      <Stack direction="row" spacing={2}>
        <Button className="browser-default" variant="contained" onClick={handleOptionsGroupAdd} fullWidth>
          Add Options Group
        </Button>
        <Button
          className="browser-default"
          variant="contained"
          onClick={() => setCopyOptionsDialogOpen(true)}
          fullWidth
        >
          Copy Options from other item
        </Button>
      </Stack>

      <CopyOptionsDialog
        open={copyOptionsDialogOpen}
        onClose={() => setCopyOptionsDialogOpen(false)}
        onOptionsCopy={handleCopiedOptionsReceive}
      />
    </Stack>
  )
}

export default Options
