import React, { useState, useEffect } from 'react'
import { useMenuSelectContext } from '../../contexts/MenuSelectContext'
import M from 'materialize-css'
import AvailabilityTable from '../../views/menu/menuItem/EditAvailability/AvailabilityTable'

/**
 *
 * @returns Modal for mass overriding special availability of selected restaurants
 */
export default function MassOverrideSpecialAvailabilityModal() {
  const { selectionList, updateElements } = useMenuSelectContext()
  const [specialAvailability, setSpecialAvailability] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    var elems = document.querySelectorAll('.modal')
    M.Modal.init(elems, {})
  }, [])

  const submitOverride = () => {
    const specialAvailabilityJson = { ...specialAvailability }

    setSpecialAvailability({})
    updateElements(specialAvailabilityJson, 'specialAvailability')
    setShowConfirm(false)

    var instance = M.Modal.getInstance(document.getElementById(`overrideSpecialAvailabilityModal`))
    instance.close()
  }

  return (
    <div id="overrideSpecialAvailabilityModal" className="modal">
      <div className="modal-content">
        <h4>Overriding Special Availability</h4>
        <p>You have selected {selectionList.length} items</p>

        <AvailabilityTable specialAvailability={specialAvailability} onChange={setSpecialAvailability} />

        <div className="row">
          <p>Leave fields above untouched and save to remove special availability from selected items</p>
        </div>
        <br></br>

        {!showConfirm && (
          <div className="row">
            <button className="btn" onClick={() => setShowConfirm(true)}>
              Override all
            </button>
          </div>
        )}
        {showConfirm && (
          <div className="row">
            <p>You are about to override the special availability of {selectionList.length} items, are you sure?</p>
            <button className="btn-flat" onClick={() => setShowConfirm(false)}>
              Cancel
            </button>
            <button className="btn" onClick={() => submitOverride()}>
              Yes override all
            </button>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close  btn-flat modal-close">
          close
        </a>
      </div>
    </div>
  )
}
