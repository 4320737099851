import { TextField, Typography } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

function EditableText({ ...rest }, ref) {
  const classes = {}

  return (
    <TextField
      inputProps={{ className: 'browser-default', ...classes }}
      variant="standard"
      sx={{ minWidth: '100px' }}
      // InputProps={{
      //   sx: {
      //     '&:before': { display: 'none' },
      //     '&:after': { display: 'none' },
      //   },
      // }}
      ref={ref}
      {...rest}
    />
  )
}

export default forwardRef(EditableText)
