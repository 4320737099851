import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material'
import theme from '../../../../../theme'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import ItemForm from '../../../../../components/forms/ItemForm/ItemForm'
import CloseIcon from '@mui/icons-material/Close'
import CategoryForm from '../../../../../components/forms/CategoryForm'
import { Item } from '../../../../../types'
import { getAuth } from 'firebase/auth'
import { v4 as uuidv4 } from 'uuid'
import { uploadFileJPEG } from '../../../../../utils'
import useYupValidationResolver from '../../../../../hooks/useYupValidationResolver'
import { itemSchema } from '../../../../../schemas'
import EditMenuEntryLayout from './components/EditMenuEntryLayout'
import EditCategoryForm from './components/EditCategoryForm'
import EditItemForm from './components/EditItemForm'

type EditMenuEntryProps = {
  open: boolean
  onClose: () => void
  restaurant: any
  onSave: (item: any, id: string) => Promise<void>
  item: any
  resetOnClose?: boolean
  isPending?: boolean
}

function EditMenuEntry({ item, restaurant, onClose, onSave, open, isPending }: EditMenuEntryProps) {
  if (!item) return null
  console.log(item)
  return (
    <EditMenuEntryLayout onClose={onClose}>
      {item?.type === 'editableText' ? (
        <EditCategoryForm restaurant={restaurant} category={item} onSave={onSave} disabled={isPending} />
      ) : (
        <EditItemForm restaurant={restaurant} item={item} onSave={onSave} disabled={isPending} />
      )}
    </EditMenuEntryLayout>
  )
}

export default EditMenuEntry
