import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { teamListState } from '../../../../atoms/TeamsAtoms'
import { Box, Button, Collapse, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TeamTableBodySkeleton from '../../../../components/TableBodySkeleton/TableBodySkeleton'
import { useAuthContext } from '../../../../contexts/AuthContext'
import TeamTableRow from './TeamTableRow'
import TableBodySkeleton from '../../../../components/TableBodySkeleton/TableBodySkeleton'

function TeamTable() {
  const [expandedTeamMemberId, setExpandedTeamMemberId] = useState<null | string>(null)
  const teamList = useRecoilValue(teamListState)
  const isLoading = Boolean(teamList.length === 0)

  console.log(teamList)
  console.log(expandedTeamMemberId)
  return (
    <Box>
      <Table sx={{ width: '100%', overflowX: 'auto', display: { xs: 'block', sm: 'table' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Manages</TableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBodySkeleton rowsNum={10} columnsNum={4} />
        ) : (
          <TableBody>
            {teamList.map((teamMember: any) => (
              <TeamTableRow
                teamMember={teamMember}
                expanded={teamMember.id === expandedTeamMemberId}
                onExpand={newId =>
                  newId === expandedTeamMemberId ? setExpandedTeamMemberId(null) : setExpandedTeamMemberId(newId)
                }
              />
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  )
}

export default TeamTable
