import { FormProvider, useForm } from 'react-hook-form'
import useYupValidationResolver from '../../../../../../hooks/useYupValidationResolver'
import { itemSchema } from '../../../../../../schemas'
import { Item } from '../../../../../../types'
import ItemForm from '../../../../../../components/forms/ItemForm/ItemForm'
import { Button } from '@mui/material'
import { useEffect } from 'react'
import useDeprecatedItemValues from '../../../../../../hooks/useDeprecatedItemValues'
import { getAuth } from 'firebase/auth'
import { uploadFileJPEG, uploadFilePNG } from '../../../../../../utils'
import { v4 as uuidv4 } from 'uuid'

type EditItemFormProps = {
  item: Item
  restaurant: any
  onSave: (item: Item, id: string) => Promise<void>
  disabled?: boolean
}

function EditItemForm({ item, restaurant, onSave, disabled }: EditItemFormProps) {
  const resolver = useYupValidationResolver(itemSchema)
  const methods = useForm<Item>({ defaultValues: item, resolver })
  const { reset, handleSubmit, getValues, setValue } = methods
  const trigger = useDeprecatedItemValues(getValues, setValue)

  const onSubmit = async (item: Item) => {
    console.log('submitting: ', item)
    const { specialAvailability } = getValues()

    let formatedSpecialAvailability
    if (specialAvailability) {
      formatedSpecialAvailability = Object.keys(specialAvailability).length === 0 ? null : specialAvailability
    } else {
      formatedSpecialAvailability = null
    }

    if (item.image) {
      const auth = await getAuth()
      const imagePath = `/users/${auth?.currentUser?.uid}/restaurants/${restaurant.id}/images/${uuidv4()}.jpeg`
      const image = await uploadFilePNG(item.image, imagePath)
      item = { ...item, specialAvailability: formatedSpecialAvailability, image }
    } else {
      item = { ...item, specialAvailability: formatedSpecialAvailability }
    }

    console.log('submit valid: ', item)

    await onSave(item, item.id)
  }

  useEffect(() => {
    reset(item)
    trigger()
  }, [item])

  return (
    <FormProvider {...methods}>
      <ItemForm restaurant={restaurant} />
      <Button
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        disabled={disabled}
        sx={{ position: 'sticky', bottom: '24px' }}
      >
        Save
      </Button>
    </FormProvider>
  )
}

export default EditItemForm
