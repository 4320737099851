import { initializeApp } from 'firebase/app'

const configs: any = {
  'pepi-store-test': {
    apiKey: 'AIzaSyDAqHVQR9mkcU0KWw24WO1lJaOMgnSafs0',
    authDomain: 'pepi-test.firebaseapp.com',
    databaseURL: 'https://pepi-test-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'pepi-test',
    storageBucket: 'pepi-test.appspot.com',
    messagingSenderId: '270070237692',
    appId: '1:270070237692:web:d25416923bdfb2ebdc0a79',
    measurementId: 'G-DEG5KY094E',
  },
  'pepi-cms': {
    apiKey: 'AIzaSyAyvk5lXZx1qNEtTW1JILXqw8iHrJ4Wz7A',
    authDomain: 'pepi-dev.firebaseapp.com',
    databaseURL: 'https://pepi-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'pepi-dev',
    storageBucket: 'pepi-dev.appspot.com',
    messagingSenderId: '198003752440',
    appId: '1:198003752440:web:ee7ceab249864a50e86a0d',
    measurementId: 'G-TRE2YV76E3',
  },
  'pepi-internal-store-test': {
    apiKey: 'AIzaSyAwL05TFsOqb53NG-E8s_T5-c71guRiuBU',
    authDomain: 'pepi-internal-test.firebaseapp.com',
    databaseURL: 'https://pepi-internal-test-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'pepi-internal-test',
    storageBucket: 'pepi-internal-test.appspot.com',
    messagingSenderId: '693836800819',
    appId: '1:693836800819:web:e6af8ed3b53008ba5f1446',
  },
}

// second firebase app for realtime DB
const project: any = String(process.env.REACT_APP_FIREBASE_ENV)
const secondFirebaseConfig = configs[project as keyof typeof configs]

const secondaryApp = initializeApp(secondFirebaseConfig, 'secondary')

export { secondaryApp }
