import { TableFormat } from '../types/Common'

// Should be acquired from the backend
const TABLE_FORMATS: TableFormat[] = [
  {
    name: 'Any',
    regex: '.*',
  },
  {
    name: 'Not empty',
    regex: '^(?=\\s*\\S).*$',
  },
  {
    name: '1-4 digits',
    regex: '^[0-9]{1,4}$',
    example: '320, 341, 4, 1234',
  },
  {
    name: 'One letter and numbers',
    regex: '^[a-zA-Z]{1}[0-9]+$',
    example: 'A12, B42, C1',
  },
  {
    name: 'Two letter and numbers',
    regex: '^[a-zA-Z]{2}[0-9]+$',
    example: 'AB5, HK182, NK21',
  },
]

export default TABLE_FORMATS
