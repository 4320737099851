import React, { useState } from 'react'
import { useMenuContext } from '../../../../contexts/MenuContext'
import { updateDevice } from '../../../../api/deviceApi'
import { timeAgo } from '../../../../utils/timeAgo'
import { RestaurantIdToName } from '../../RestaurantIdToName'
import { SubMenuIdToName } from '../../SubMenuIdToName'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import EditKiosk from '../../../../components/forms/KioskForm'
import { Backdrop, Button, CircularProgress, Stack, Typography } from '@mui/material'
import DeleteConfirmButton from '../../../../ui/DeleteConfirmButton/DeleteConfirmButton'
import { Kiosk } from '../../../../types'
import { FormProvider, useForm } from 'react-hook-form'
import useYupValidationResolver from '../../../../hooks/useYupValidationResolver'
import kioskSchema from '../../../../schemas/kioskSchema'
import PartialBackdrop from '../../../../ui/PatrialBackdrop/PartialBackdrop'
import { useRequestStatus } from '../../../../hooks/useRequestStatus'
import { RECOMMENDED_KIOSK_PRINTING_METHODS } from '../../../../utils/constants'
import theme from '../../../../theme'
import { getAuth } from 'firebase/auth'
import { uploadFileJPEG } from '../../../../utils'
import { v4 as uuidv4 } from 'uuid'

type DeviceKioskTableRowProps = {
  device: Kiosk
  refresh: () => void
  delete: (id: string) => void
  onUpdate: (id: string, data: any) => void
  highlighted?: boolean
}

function DeviceKioskTableRow({ device, refresh, onUpdate, highlighted, ...rest }: DeviceKioskTableRowProps) {
  const [showForm, setShowForm] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const { makeApiRequest, isFetching } = useRequestStatus()

  const resolver = useYupValidationResolver(kioskSchema)
  const methods = useForm<Kiosk>({
    resolver,
    defaultValues: device,
  })

  const onSubmit = async (data: any) => {
    // Setting model if only printing method provided
    const { model, printingMethod, backgroundImage } = data
    if (printingMethod && !model) {
      const model = Object.keys(RECOMMENDED_KIOSK_PRINTING_METHODS).find(
        key => (RECOMMENDED_KIOSK_PRINTING_METHODS as any)?.[key] === printingMethod
      )
      console.log('model', model)

      methods.setValue('model', model)
      data.model = model
    }
    console.log('backgroundImage', backgroundImage)
    if (backgroundImage) {
      const auth = await getAuth()
      const imagePath = `/users/${auth?.currentUser?.uid}/restaurants/${device?.restaurantId}/images/${uuidv4()}.jpeg`
      const image = await uploadFileJPEG(backgroundImage, imagePath)
      console.log(image)
      methods.setValue('backgroundImage', image)
      data.backgroundImage = image
    } else {
      methods.setValue('backgroundImage', backgroundImage)
      data.backgroundImage = backgroundImage
      console.log('backgroundImage', data.backgroundImage)
    }
    console.log('data', data)

    onUpdate(device.id, data)
    // console.log('Submit valid', data)
    // setSaving(true)

    // const {isSuccess} = await makeApiRequest(updateDevice, [device.id, data])
    // if (isSuccess) {

    // }
    // updateDevice(device.id, data)
    //   .then(result => {
    //     console.log('Saved ', result)
    //     refresh()
    //   })
    //   .catch(error => {
    //     console.log('ERROR ', error)
    //   })
    //   .finally(() => setSaving(false))
  }

  const handleCancelClick = () => {
    console.log('Cancel')
    setShowForm(false)
  }

  return (
    <>
      <tr style={highlighted ? { backgroundColor: theme.palette.success.light } : {}}>
        <td>
          <Stack>
            <Typography variant="h5">
              {device.name} {device.isMocking ? <span style={{ color: 'red' }}>mock</span> : null}
            </Typography>
            <Typography variant="subtitle2">{device.serialNumber}</Typography>
          </Stack>
        </td>
        <td>{device.type}</td>
        <td>{timeAgo(device.lastActivityAt || device.updatedAt)}</td>
        <td>
          {Array.isArray(device?.menuIds)
            ? device?.menuIds?.map((e: any) => SubMenuIdToName(e))?.join(', ')
            : device?.menuIds}
        </td>
        <td>
          <button className="btn" onClick={() => setShowForm(!showForm)}>
            Edit
          </button>
        </td>
      </tr>
      {showForm && (
        <tr>
          <td colSpan={6}>
            <PartialBackdrop show={isSaving}>
              <Stack p={2} spacing={3}>
                <FormProvider {...methods}>
                  <EditKiosk />
                </FormProvider>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit, e => console.log('Submit failed: ', e))}
                  >
                    Save
                  </Button>
                  <Button variant="contained" color="info" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <DeleteConfirmButton targetText="this Kiosk" onClick={() => rest.delete(device.id)}>
                    Delete kiosk
                  </DeleteConfirmButton>
                </Stack>
              </Stack>
            </PartialBackdrop>
          </td>
        </tr>
      )}
    </>
  )
}

export default DeviceKioskTableRow
