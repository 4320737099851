import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import theme from '../../../theme'

type AddDeviceButtonProps = {
  text: string
  img: string
  onClick?: (args: any) => void
}

function AddDeviceButton({ text, img, onClick }: AddDeviceButtonProps) {
  return (
    <ButtonBase sx={{ backgroundColor: 'inherit !important' }} onClick={e => onClick && onClick(e)}>
      <Stack
        spacing={2}
        sx={{
          border: '2px solid #ccc',
          borderRadius: '10px',
          p: 2,
          backgroundColor: theme.palette.mynt.main,
          color: '#fff',
          width: '160px',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box>
          <img src={img} alt="add device" style={{ objectFit: 'contain' }} />
        </Box>
        <Typography variant="subtitle1" textAlign="center">
          {text}
        </Typography>
      </Stack>
    </ButtonBase>
  )
}

export default AddDeviceButton
