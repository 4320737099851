import { Alert, Box, Collapse } from '@mui/material'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../atoms/SelectedRestaurantAtom'
import { useEffect, useState } from 'react'

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const isTimeWithinRange = (start: string, end: string) => {
  const currentTime = new Date().toLocaleTimeString('en-Gb', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  return currentTime >= start && currentTime <= end
}

const getNextOpenDay = (openingTimes: any, startingDay = 1) => {
  let earliestOpening = null
  let thisWeekUpcomingOpening = null
  for (const dayIndex in openingTimes) {
    const [timeStart, timeEnd, isClosed] = openingTimes[dayIndex]
    if (!isClosed) {
      if (+dayIndex < startingDay && !earliestOpening) {
        earliestOpening = dayIndex
      } else if (!thisWeekUpcomingOpening) {
        thisWeekUpcomingOpening = dayIndex
      }
    }
  }

  return thisWeekUpcomingOpening || earliestOpening
}

const willOpenToday = (day: any[]) => {
  const [timeStart, timeEnd, isClosed] = day
  const currentTime = new Date().toLocaleTimeString('en-Gb', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  if (isClosed) return false
  return currentTime <= timeStart
}

function OpeningAlert() {
  const { openingTimes } = useRecoilValue<any>(selectedRestaurantState) || {}
  const [isAlertOpen, setIsAlertOpen] = useState(false)

  const today = new Date().getDay()
  const [timeStart, timeEnd, isClosed] = openingTimes?.[today] || []

  useEffect(() => {
    if (!openingTimes) return
    setIsAlertOpen(true)
  }, [openingTimes])

  if (!openingTimes) return null

  const isOpenNow = isTimeWithinRange(timeStart, timeEnd) && !isClosed

  let displayText = ''
  if (isOpenNow) {
    displayText = `Your restaurant is currently open and will close at ${timeEnd}. `
  } else {
    if (willOpenToday(openingTimes[today])) {
      displayText = `Your restaurant is currently closed and will open at ${timeStart}. `
    } else {
      const nextOpenDayIndex = getNextOpenDay(openingTimes, today)
      if (nextOpenDayIndex === null) displayText = `Your restaurant is permanently closed, please `
      else {
        displayText = `Your restaurant is closed today and will open on ${DAYS[+nextOpenDayIndex]} ${
          openingTimes?.[nextOpenDayIndex][0]
        }. `
      }
    }
  }

  return (
    <Box>
      <Collapse in={isAlertOpen}>
        <Alert severity="info" onClose={() => setIsAlertOpen(false)}>
          {displayText}
          <Link to="/times">Change opening times</Link>
        </Alert>
      </Collapse>
    </Box>
  )
}

export default OpeningAlert
