const groupEventsByEndpoint = (events: any[]): any[] => {
  const groupedEvents = events.reduce((acc, event) => {
    const endpoint = event.endpoint
    if (!acc[endpoint]) {
      acc[endpoint] = []
    }
    acc[endpoint].push(event)
    return acc
  }, {})
  return groupedEvents
}

const getRecentEventToCompare = (event: any, allEvents: any): any => {
  const groupedEvents = groupEventsByEndpoint(allEvents)
  const relevantEvents = groupedEvents[event.endpoint]

  const eventIndex = relevantEvents.findIndex((e: any) => e.id === event.id)
  return relevantEvents[eventIndex + 1] || null
}

const sanitizeEvent = (event: any): any => {
  if (!event) return null
  let newEvent = JSON.parse(JSON.stringify(event))

  delete newEvent.id
  delete newEvent.createdAt
  delete newEvent.updatedAt
  newEvent = (Object.keys(newEvent?.body).length && newEvent.body) || newEvent?.query || newEvent
  return newEvent
}

export { groupEventsByEndpoint, getRecentEventToCompare, sanitizeEvent }
