export default function OrderStatusChip(status) {
  let orderText = ''

  switch (status) {
    case 'archived':
    case 'ready':
      orderText = 'Completed ✓'
      break
    case 'inprogress':
      orderText = 'In Progress'
      break
    case 'cancelled':
      orderText = 'Cancelled'
      break
    default:
      orderText = 'Undefined'
      break
  }

  return <div className={'order-status-chip ' + status}>{orderText}</div>
}
