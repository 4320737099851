import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { request } from './base'

export async function getDevices(opts) {
  const resp = await request(`/cms/devices/`, 'get', opts)
  return resp?.data
}

export async function updateDevice(deviceId, payload) {
  const resp = await request(`/cms/devices/${deviceId}`, 'put', {
    payload,
  })
  return resp?.data
}

export async function deleteDevice(deviceId) {
  const resp = await request(`/cms/devices/${deviceId}`, 'delete')
  return resp?.data
}

export async function createDevice(payload) {
  const resp = await request(`/cms/devices/`, 'post', {
    payload,
  })
  return resp?.data
}
