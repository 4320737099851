import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditMenuItem from './EditMenuItem/EditMenuItem'
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'

type ListViewMenuItemProps = {
  title: string
  description: string
  price: number
  itemInList: any
  isAlcohol: boolean
  specialAvailability: any
  data: any
  index: number
  onRemove: any
  isOwner: boolean
  isAdmin: boolean
  duplicateElement: any
  toggleHidden: any
  menuId: string
  addOrRemove: any
  hidden: boolean
  countDownToTomorrow: any
  onEdit?: any
}

function ListViewMenuItem({
  title,
  description,
  price,
  itemInList,
  isAlcohol,
  specialAvailability,
  data,
  index,
  isOwner,
  isAdmin,
  duplicateElement,
  toggleHidden,
  menuId,
  addOrRemove,
  hidden,
  countDownToTomorrow,
  onEdit,
  onRemove
}: ListViewMenuItemProps) {
  const { t, i18n } = useTranslation()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)


  return (
    <div className={'menu-item should-animate' + (itemInList(menuId) ? ' selected' : '') + ' list-view'}>
      <div className="item-row">
        <div className="item-info">
          <h5>
            {title} {isAlcohol && <span className="alcohol">(a)</span>}{' '}
            {specialAvailability && Object.keys(specialAvailability).length !== 0 && (
              <span className="special-override">(s)</span>
            )}
          </h5>
          <p className="description"> {description}</p>
          <p className="price">{price}€</p>
        </div>
        <div className="item-row-buttons">
          {(isOwner || isAdmin) && (
            <label>
              <input
                type="checkbox"
                className="filled-in checkbox-mynt"
                // style={{ display: "none" }}
                checked={itemInList(menuId)}
                onChange={() => addOrRemove(menuId, index)}
              />
              <span />
            </label>
          )}
          <button className={`btn-flat dropdown-trigger`} data-target={menuId + 'drop'}>
            <i className="material-icons">more_horiz</i>
          </button>
          <ul id={menuId + 'drop'} className="dropdown-content">
            <li>
              <a href="#" className="dropdown-title">
                {t('menuView.editMenuItem.dropdownHideTitle')}
              </a>
            </li>
            {hidden ? (
              <li>
                <a href="#!" onClick={() => toggleHidden(0)}>
                  <i className="material-icons">visibility</i>
                  {t('menuView.editMenuItem.dropdownHideShow')}
                </a>
              </li>
            ) : (
              <>
                <li>
                  <a href="#!" onClick={() => toggleHidden(1)}>
                    <i className="material-icons">visibility_off</i>
                    {t('menuView.editMenuItem.dropdownHideOneHour')}
                  </a>
                </li>
                <li>
                  <a href="#!" onClick={() => toggleHidden(3)}>
                    <i className="material-icons">visibility_off</i>
                    {t('menuView.editMenuItem.dropdownHideThreeHour')}
                  </a>
                </li>
                <li>
                  <a href="#!" onClick={() => toggleHidden(countDownToTomorrow())}>
                    <i className="material-icons">visibility_off</i>
                    {t('menuView.editMenuItem.dropdownHideTomorrow')}
                  </a>
                </li>
                <li>
                  <a href="#!" onClick={() => toggleHidden(-1)}>
                    <i className="material-icons">visibility_off</i>
                    {t('menuView.editMenuItem.dropdownHideForever')}
                  </a>
                </li>
              </>
            )}

            <li className="divider" tabIndex={-1}></li>
            {(isOwner || isAdmin) && (
              <li>
                <a href="#!" onClick={() => duplicateElement(data.id)}>
                  <i className="material-icons">content_copy</i>
                  {t('menuView.editMenuItem.DuplicateItem')}
                </a>
              </li>
            )}
          </ul>
          {(isOwner || isAdmin) && (
            <>
              <Button size="small" onClick={onEdit} variant="contained">
                {t('general.EditButton')}
              </Button>
            <Button onClick={() => setConfirmDialogOpen(true)} variant="outlined" color="error">
              {t('general.Delete')}
            </Button>

              <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <DialogTitle>{t('general.aboutToDelete') + title}</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      onRemove(data.id)
                      setConfirmDialogOpen(false)
                    }}
                    variant="outlined"
                    color="error"
                  >
                    {t('general.Delete')}
                  </Button>
                  <Button onClick={() => setConfirmDialogOpen(false)} variant="outlined" color="primary">
                    {t('general.Cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListViewMenuItem
