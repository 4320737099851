import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getRestaurantInfoById, saveRestaurantInfoData } from '../../api/restaurantInfoApi'
import { Box, Button, CircularProgress, Grid, Link, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useAuthContext } from '../../contexts/AuthContext'
import LogoutIcon from '@mui/icons-material/Logout'

const steps = ['Setup Restaurant', 'Setup Devices', 'Add Members']

function Onboarding() {
  const { id } = useParams()
  const { loggedIn, logOut } = useAuthContext()
  const [restaurantData, setRestaurantData] = useState<any>(null)
  const [activeStep, setActiveStep] = useState(1)
  const [skipped, setSkipped] = useState(new Set<number>())
  const navigate = useNavigate()
  const location = useLocation()

  const onMoveForward = () => {
    if (activeStep !== steps.length) {
      setActiveStep(activeStep + 1)
      navigate(`/onboarding/${id}/step${activeStep + 1}`)
    } else {
      if (restaurantData) saveRestaurantInfoData({ id: restaurantData.id, ...restaurantData, cmsOnboarded: true })
      navigate(`/`)
    }
  }

  const onMoveBack = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1)
      navigate(`/onboarding/${id}/step${activeStep - 1}`)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      console.log('onboarding restaurant id', id)
      getRestaurantInfoById(id).then(({ data }) => {
        console.log('onboarding restaurant data', data)

        setRestaurantData(data)
      })
    }
  }, [id, loggedIn])

  useEffect(() => {
    console.log('new restaurant data', restaurantData)
  }, [restaurantData])

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  useEffect(() => {
    const parts = location.pathname.split('/')
    if (parts[parts.length - 1] === id) {
      console.log('redirecting to step1', parts)
      navigate(`/onboarding/${id}/step1`, { replace: true })
    } else {
      const stepName = parts[parts.length - 1]
      const step = stepName[stepName.length - 1]
      setActiveStep(Number(step))
    }
  }, [location])

  return (
    <Grid
      container
      position="relative"
      height="100vh"
      sx={{
        overflow: {
          xs: 'scroll',
          md: 'hidden',
        },
      }}
    >
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            objectFit: 'cover',
            width: '100%',
            objectPosition: {
              xs: 'center top',
              sm: 'center',
            },
            height: {
              xs: '40vh',
              sm: '100vh',
            },
          }}
          component="img"
          src="/img/GirlEating.png"
        ></Box>
      </Grid>
      <Grid item xs={12} sm={8} p={4} height="100%" overflow="scroll">
        <Stack direction="column" alignItems="center">
          <Stack spacing={2} alignItems="center" width="100%" height="100%" justifyContent="space-between">
            <Stack alignItems="center" spacing={2} width="100%">
              <Stack direction="row" alignItems="center" spacing={4}>
                <Typography variant="h3" textAlign="center">
                  Welcome to Mynt
                </Typography>
              </Stack>
              <Box width="80%">
                <Stepper activeStep={activeStep - 1} sx={{ width: '100%' }}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: {
                      optional?: React.ReactNode
                    } = {}
                    if (isStepSkipped(index)) {
                      stepProps.completed = false
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              </Box>
            </Stack>
            {restaurantData ? (
              <Outlet context={{ restaurantData, setRestaurantData }} />
            ) : (
              <Box p={4}>
                <CircularProgress />
              </Box>
            )}

            <Stack width="80%" direction="row" justifyContent="space-between">
              <Button startIcon={<ArrowBackIosIcon />} variant="contained" onClick={onMoveBack}>
                Back
              </Button>

              <Button endIcon={<ArrowForwardIosIcon />} color="success" variant="contained" onClick={onMoveForward}>
                {activeStep === steps.length ? 'Finish' : 'Next'}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Box
          component="img"
          sx={{ position: { sm: 'absolute', xs: 'static' }, top: '0', left: '0' }}
          src="/img/MyntLogo.png"
        />
        <Button
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          color="error"
          variant="outlined"
          endIcon={<LogoutIcon />}
          onClick={() => logOut()}
        >
          Log out
        </Button>
      </Grid>
    </Grid>
  )
}

export default Onboarding
