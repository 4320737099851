import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useState } from 'react'
import OrderScreenEditV1 from './v1/OrderScreenEdit'
import OrderScreenEditV2 from './v2/OrderScreenEdit'
import { useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../../../../atoms/SelectedRestaurantAtom'

function OrderScreenEditLayout() {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [version, setVersion] = useState(selectedRestaurant?.monitorSetting?.uiVersion || '1')

  const handleVersionChange = (e: any, newValue: string) => {
    setVersion(e.target.value)
  }

  console.log('version', version)

  return (
    <Stack spacing={2} minHeight="100%" justifyContent="space-between">
      <ToggleButtonGroup exclusive color="primary" value={'1'} onChange={(e, newValue) => {}}>
        <ToggleButton value="1" selected={version === '1'} onClick={handleVersionChange}>
          v1
        </ToggleButton>
        <ToggleButton value="2" selected={version === '2'} onClick={handleVersionChange}>
          v2
        </ToggleButton>
      </ToggleButtonGroup>
      {version === '1' && <OrderScreenEditV1 />}
      {version === '2' && <OrderScreenEditV2 />}
    </Stack>
  )
}

export default OrderScreenEditLayout
