enum PrintingMethods {
  SUNMI = 'sunmi',
  BITMAP = 'bitmap',
  SUNMI_V2 = 'sunmi_v2',
  BITMAP_V2 = 'bitmap_v2',
  SUNMI_V3 = 'sunmi_v3',
  BITMAP_V3 = 'bitmap_v3',
  TPCL = 'tpcl',
  TEC_MOBILE_HSP100 = 'tec_mobile_hsp100',
  TEC_MOBILE_KOP3X = 'tec_mobile_kop3x',
}

type Printer = {
  id: string
  restaurantId: string
  serialNumber: string
  model?: string
  name: string
  type: 'printer'

  menuIds: string[]
  showOtherSubmenuItems?: boolean

  externalId?: string
  staffPinCode?: string
  skipDeliveredWhenMarkedReady?: boolean
  shouldAutoPrintOrders?: boolean
  autoAcceptOrderInSeconds?: number
  printerPaperWidth?: '58mm' | '80mm'
  printingMethod?: PrintingMethods
  isAggressiveNotiEnabled?: boolean
  disableCustomerReceipt?: boolean
  appVersion?: string
  osVersion?: string
  buildNumber?: string
  vendorSerialNumber?: string
  lastActivityAt?: string | null
  ip?: string
  port?: string
  isCashierPartnerOnly?: boolean
  receiptsLanguage: string
}

export default Printer
export { PrintingMethods }
