import {
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { default as MultiSelect } from 'react-select'
import { useForm, useFieldArray, Controller, useFormContext, useWatch } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import selectedRestaurantMenuState from '../../selectors/selectedRestaurantMenu'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
import getLocalizedString from '../../utils/getLocalizedString'
import LoopIcon from '@mui/icons-material/Loop'
import { useEffect, useState } from 'react'
import { generateCouponCode } from '../../api/adminApi'

const animatedComponents = makeAnimated()

function CouponForm() {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const selectedRestaurantMenu = useRecoilValue(selectedRestaurantMenuState)
  const {
    register,
    control,
    setValue,
    formState: { errors, isValid },
  } = useFormContext()
  const values = useWatch({ control })
  const [isFetchingCode, setFetchingCode] = useState(false)

  const handleCodeFetch = async () => {
    setFetchingCode(true)
    try {
      const { data } = await generateCouponCode()
      setValue('code', data.code)
    } catch (err) {
      console.log(err)
    }
    setFetchingCode(false)
  }

  // Formatting so that the date is displayed correctly in the input
  useEffect(() => {
    const newFrom = moment(values?.from).isValid() ? moment(values?.from).format('YYYY-MM-DD') : null
    const newTo = moment(values?.to).isValid() ? moment(values?.to).format('YYYY-MM-DD') : null
    setValue('from', newFrom)
    setValue('to', newTo)
  }, [values?.from, values?.to])

  // Formatting so that the code is always in uppercase
  useEffect(() => {
    setValue('code', values?.code?.toUpperCase())
  }, [values?.code])

  return (
    <Stack>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="subtitle1">Promo Code:</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              {...register('code', { required: true, minLength: 4 })}
              inputProps={{ className: 'browser-default' }}
              fullWidth
              disabled={isFetchingCode}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleCodeFetch} disabled={isFetchingCode} color="primary">
                    <LoopIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
        </Stack>
        {errors['code'] && errors['code'].type === 'required' && (
          <span className="error-message">Promo Code is required.</span>
        )}
        {errors['code'] && errors['code'].type === 'minLength' && (
          <span className="error-message">Promo Code must be at least 4 characters long.</span>
        )}

        <Stack>
          <Typography variant="subtitle1">Description:</Typography>
          <TextField {...register('description')} inputProps={{ className: 'browser-default' }} />
        </Stack>

        <Stack>
          <Typography variant="subtitle1">Promotion Type:</Typography>
          <Controller
            name="type"
            control={control}
            render={({ field, fieldState, formState }) => (
              <Select
                MenuProps={{ sx: { maxHeight: '50vh' } }}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={String(field.value)}
              >
                <MenuItem value="cash_discount">Cash discount - Enter the coupon to receive cash discount</MenuItem>
                <MenuItem value="automated">
                  Automated Coupon - If you buy one product, another item gets discounted
                </MenuItem>
              </Select>
            )}
          />
        </Stack>

        <Collapse in={values?.type === 'automated'} unmountOnExit>
          <Stack>
            <Typography variant="subtitle1">Trigger Items:</Typography>
            <Controller
              name="comesFromItems"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  options={selectedRestaurantMenu
                    ?.filter((item: any) => item?.type === 'menuItem')
                    ?.map((item: any) => ({
                      value: item.id,
                      label: getLocalizedString(item, 'title'),
                    }))}
                  onChange={inputValue => field.onChange(inputValue?.map(({ value }: any) => value))}
                  value={field?.value
                    ?.map((itemId: string) => selectedRestaurantMenu?.find(({ id }: any) => itemId === id))
                    ?.map((item: any) => ({ value: item?.id, label: getLocalizedString(item, 'title') }))}
                />
              )}
            />
          </Stack>
        </Collapse>

        <Stack>
          <Typography variant="subtitle1">Select items that the discount applies to</Typography>
          <Controller
            name="itemIds"
            control={control}
            render={({ field }) => (
              <MultiSelect
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={selectedRestaurantMenu
                  ?.filter((item: any) => item?.type === 'menuItem')
                  ?.map((item: any) => ({
                    value: item.id,
                    label: getLocalizedString(item, 'title'),
                  }))}
                onChange={inputValue => {
                  console.log(inputValue)
                  return field.onChange(inputValue?.map(({ value }: any) => value))
                }}
                value={field?.value
                  ?.map((itemId: string) => selectedRestaurantMenu?.find(({ id }: any) => itemId === id))
                  ?.map((item: any) => ({ value: item?.id, label: getLocalizedString(item, 'title') }))}
              />
            )}
          />
        </Stack>

        <Stack>
          <Typography variant="subtitle1">Discount Amount:</Typography>
          <TextField
            {...register('rewardAmount', { required: true })}
            inputProps={{ className: 'browser-default' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{selectedRestaurant?.config?.currency?.symbol || '€'}</InputAdornment>
              ),
            }}
          />
        </Stack>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Stack px={1}>
              <Typography variant="subtitle1">Start date for this promotion</Typography>
              <Controller
                name="from"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <input
                    type="date"
                    id="startDate"
                    {...field}
                    // value={moment(field.value).format('YYYY-MM-DD')}
                    required
                  />
                )}
              />
              {errors.startDate && <span className="error-message">Start Date is required.</span>}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack px={1}>
              <Typography variant="subtitle1">End Date</Typography>
              <Controller
                name="to"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <input
                    type="date"
                    id="endDate"
                    {...field}
                    // value={moment(field.value).format('YYYY-MM-DD')}
                    required
                  />
                )}
              />

              {errors.endDate && <span className="error-message">End Date is required.</span>}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default CouponForm
