import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import { useForm } from 'react-hook-form'
import M from 'materialize-css'

/**
 *
 */
function AdminCouponTable({ domElement, ...props }) {
  const { t, i18n } = useTranslation()

  const [coupons, setCoupons] = useState([])

  useEffect(() => {
    setCoupons(props.coupons)
  }, [props.coupons])

  useEffect(() => {
    var modals = document.querySelectorAll('.modal')
    M.Modal.init(modals, {})
  })

  return (
    <>
      <tbody>
        {coupons.map((e, index) => (
          <tr key={e.id}>
            <td>{e.code}</td>
            <td>{e?.coin?.symbol}</td>
            <td>
              {e.scanCount} | {e.scanLimit} ({(e.scanCount / e.scanLimit).toFixed(3) * 100}%)
            </td>
            <td>
              <a
                className="btn-small"
                href={`https://ordering.myntapp.io/redeem/${e.code}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Url
              </a>
            </td>
            <td>
              <button
                data-target="AdminCouponEditModal"
                onClick={() => props.setEditIndex(index)}
                className="btn-small btn modal-trigger"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  )
}

export default AdminCouponTable
