import {
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import Localized from '../../../../../../../ui/Localized/Localized'
import getLocalizedString from '../../../../../../../utils/getLocalizedString'
import { useEffect, useMemo, useState } from 'react'
import theme from '../../../../../../../theme'
import usePriceFormat from '../../../../../../../hooks/usePriceFormat'
import { useFieldArray, useForm } from 'react-hook-form'
import { selectedRestaurantState } from '../../../../../../../atoms/SelectedRestaurantAtom'
import selectedRestaurantMenuState from '../../../../../../../selectors/selectedRestaurantMenu'

type CopyExtrasDialogProps = {
  open?: boolean
  onClose?: () => void
  onExtrasCopy: (extras: any) => void
}

function CopyExtrasDialog({ open = false, onClose = () => null, onExtrasCopy }: CopyExtrasDialogProps) {
  const [menuList] = useRecoilState(selectedRestaurantMenuState)
  const restaurant = useRecoilValue(selectedRestaurantState)
  const [selectedItemIndex, setSelectedItemIndex] = useState<any>(0)
  const { withCurrencySymbol } = usePriceFormat()

  const methods = useForm<any>({
    // shouldUnregister: true,
    mode: 'all',
    defaultValues: {},
  })
  const {
    register,
    formState: { errors },
    control,
    watch,
    unregister,
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = methods
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'extras',
    keyName: 'key',
  })

  const alphabeticallySortedMenuList: any = useMemo(
    () =>
      menuList
        .slice()
        .filter((item: any) => Boolean(item?.extras?.length))
        .sort((a: any, b: any) => getLocalizedString(a, 'title').localeCompare(getLocalizedString(b, 'title'))),
    [menuList]
  )

  const selectedItem = alphabeticallySortedMenuList[selectedItemIndex]
  useEffect(() => {
    setValue('extras', selectedItem?.extras)
    // Depricated values
    getValues().extras?.forEach((extra: any, index: number) => {
      if (extra.type === 'extraItem' || extra.type === undefined) {
        if (!extra?.translations?.name?.en && extra?.name) {
          setValue(`extras.${index}.translations.name.en`, extra.name)
        }
        if (!extra?.translations?.name?.fi && extra?.nameFI) {
          setValue(`extras.${index}.translations.name.fi`, extra.nameFI)
        }
        if (!extra?.translations?.name?.sv && extra?.nameSE) {
          setValue(`extras.${index}.translations.name.sv`, extra.nameSE)
        }
      }
    })
  }, [selectedItem])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ minHeight: '40vh', width: '60vw', maxWidth: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h4">Copy Extras from:</Typography>
          <Select onChange={e => setSelectedItemIndex(e.target.value)} value={selectedItemIndex} fullWidth>
            {alphabeticallySortedMenuList?.map((item: any, index: number) => (
              <MenuItem value={index} key={index}>
                <Stack>
                  <Typography variant="subtitle1">
                    <Localized target={item} translationsKey="title" />
                  </Typography>
                  <Typography variant="subtitle2">{item?.extras?.length} extras</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <Divider sx={{ borderColor: theme.palette.mynt.main, borderStyle: 'dashed' }} />
          {fields?.map((extra: any, index: number) => {
            const isRegularExtra = extra?.type === 'extraItem'
            let otherExtraItem: any = null
            if (!isRegularExtra) otherExtraItem = menuList.find((item: any) => item.id == extra.id)

            return (
              <Paper elevation={4} sx={{ width: '100%' }} key={index}>
                <Stack padding={2} spacing={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack>
                      <Typography variant="h5">
                        {isRegularExtra ? (
                          <Localized target={extra} translationsKey="name" />
                        ) : (
                          <Localized target={otherExtraItem} translationsKey="title" />
                        )}
                      </Typography>
                      <Typography variant="caption" sx={{ color: theme.palette.grey[700] }}>
                        {isRegularExtra ? 'Regular extra' : 'Item as extra'}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography variant="subtitle1">
                        {withCurrencySymbol(isRegularExtra ? extra.price || 0 : otherExtraItem?.price)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!Boolean(fields?.length)} onClick={() => onExtrasCopy(fields)}>
          Copy {fields?.length} extras
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CopyExtrasDialog
