import { Box, Stack, Tab, Tabs, Button, useMediaQuery } from '@mui/material'
import Localisation from './components/Localisation'
import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import BrandingIdentityEdit from './components/cards/BrandingIdentityEdit'
import KioskEditCard from './components/cards/KioskEdit/KioskEdit'
import OrderScreenEdit from './components/cards/OrderScreenEdit/v1/OrderScreenEdit'
import ScreensaverEdit from './components/cards/ScreensaverEdit'
import CheckoutInfoEdit from './components/cards/CheckoutInfoEdit'
import theme from '../../theme'
import General from './components/General'
import { FormProvider, useForm } from 'react-hook-form'
import IdentitySkeleton from './components/IdentitySkeleton/IdentitySkeleton'
import OrderScreenEditLayout from './components/cards/OrderScreenEdit/OrderScreenEditLayout'

function Identity() {
  const [restaurant, setRestaurant] = useState<any>({})
  const [selectedRestaurant, setSelectedRestaurant] = useRecoilState(selectedRestaurantState)
  const [activeTab, setActiveTab] = useState(0)
  const methods = useForm<any>({
    defaultValues: selectedRestaurant,
  })
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const handleSave = (data: any) => {
    console.log(data)
  }

  useEffect(() => {
    if (!selectedRestaurant) return
    reset(selectedRestaurant)
  }, [selectedRestaurant])

  function ViewRenderer({ activeTab }: { activeTab: number }) {
    const getCard = () => {
      switch (activeTab) {
        case 0:
          return <General />
        case 1:
          return <Localisation />
        case 2:
          return <BrandingIdentityEdit />
        case 3:
          return <KioskEditCard />
        case 4:
          return <ScreensaverEdit />
        case 5:
          return <OrderScreenEditLayout />
        case 6:
          return <CheckoutInfoEdit />
        default:
          return null
      }
    }

    return (
      <Box px={3} pb={5}>
        {getCard()}
      </Box>
    )
  }
  console.log(theme.breakpoints.down('sm'))
  return (
    <Stack spacing={2} height="100%">
      <Tabs
        variant={isDesktop ? 'fullWidth' : 'scrollable'}
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="General" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Localisation" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Receipt" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Kiosk" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Screensaver" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Order Screen" sx={{ backgroundColor: 'transparent !important' }} />
        <Tab label="Checkout Info" sx={{ backgroundColor: 'transparent !important' }} />
      </Tabs>

      {selectedRestaurant ? (
        <FormProvider {...methods}>
          <ViewRenderer activeTab={activeTab} />
        </FormProvider>
      ) : (
        <IdentitySkeleton />
      )}
    </Stack>
  )
}

export default Identity
