import { selector } from 'recoil'
import { selectedRestaurantState } from '../atoms/SelectedRestaurantAtom'

const selectedRestaurantMenuState = selector<any>({
  key: 'selectedRestaurantMenu',
  get: ({ get }) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const menuList = selectedRestaurant?.menu
    return menuList
  },
  set: ({ get, set }, newMenu) => {
    const selectedRestaurant = get(selectedRestaurantState)
    const newValue = {
      ...selectedRestaurant,
      menu: [...newMenu],
    }
    set(selectedRestaurantState, newValue)
  },
})

export default selectedRestaurantMenuState
