import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'
import './tooltip.scss'
import { Box } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

function Tooltip({ children, text, ...props }) {
  const id = uuidv4()

  return (
    <>
      <Box data-tooltip-id={id} className="mynt-tooltip" sx={{ position: 'relative', width: 'fit-content' }}>
        {children}
        <QuestionMarkIcon
          color="mynt"
          sx={{
            height: '12px',
            width: 'auto',
            position: 'absolute',
            top: '-2px',
            right: '-10px',
            border: 'none !important',
          }}
        />
      </Box>
      <ReactTooltip id={id} place="top" content={text} style={{ zIndex: 1000 }}></ReactTooltip>
    </>
  )
}

export default Tooltip
