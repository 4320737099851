import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditMenuItem from './EditMenuItem/EditMenuItem'
import { Button, Chip, Dialog, DialogActions, DialogTitle, IconButton, Stack } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import Modal from '../../../ui/Modal/Modal'
import getLocalizedString from '../../../utils/getLocalizedString'

type GridViewMenuItemProps = {
  image: string
  title: string
  description: string
  price: number
  specialAvailability: any
  itemInList: any
  isAlcohol: boolean
  data: any
  onSave: any
  onRemove: any
  index: number
  isOwner: boolean
  isAdmin: boolean
  duplicateElement: any
  toggleHidden: any
  countDownToTomorrow: any
  menuId: string
  eanCode: string
  hidden: boolean
  addOrRemove: any
  onEdit?: any
}

function GridViewMenuItem({
  image,
  title,
  description,
  price,
  specialAvailability,
  itemInList,
  isAlcohol,
  data,
  onSave,
  onRemove,
  index,
  isOwner,
  isAdmin,
  duplicateElement,
  toggleHidden,
  countDownToTomorrow,
  menuId,
  eanCode,
  hidden,
  addOrRemove,
  onEdit,
}: any) {
  const { t, i18n } = useTranslation()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  return (
    <div className={'menu-item should-animate' + (itemInList(menuId) ? ' selected' : '') + (hidden ? ' hidden' : '')}>
      <div className="item-row">
        <div className="item-info">
          <h5>
            {title} {isAlcohol && <span className="alcohol">(a)</span>}{' '}
            {specialAvailability && Object.keys(specialAvailability).length !== 0 && (
              <span className="special-override">⏳</span>
            )}
          </h5>
          {eanCode ? <div className="eanCode">{eanCode}</div> : ''}
          {data?.isHidden && <p className="hidden-item">Hidden from Kiosk/QR</p>}
          <Stack direction="row" flexWrap="wrap">
            {data?.tags?.map((tag: any, index: number) => (
              <Chip
                key={index}
                size="small"
                style={{ backgroundColor: tag?.color, color: '#f5f5f5', marginBottom: '5px', marginRight: '5px' }}
                label={getLocalizedString(tag, 'name')}
              />
            ))}
          </Stack>

          <div className="description">
            {data.extras?.length > 0 && <div className="extras">{data.extras?.length} extras</div>}
            {data.options?.length > 0 && <div className="extras">{data.options?.length} options</div>}
          </div>
          <p className="price">{price}€</p>
        </div>
        <div className="item-image" style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className="item-row-buttons">
        {(isOwner || isAdmin) && (
          <label>
            <input
              type="checkbox"
              className="filled-in checkbox-mynt"
              // style={{ display: "none" }}
              checked={itemInList(menuId)}
              onChange={() => addOrRemove(menuId, index)}
            />
            <span />
          </label>
        )}
        <button className={`btn-flat dropdown-trigger`} data-target={menuId + 'drop'}>
          <i className="material-icons">more_horiz</i>
        </button>
        <ul id={menuId + 'drop'} className="dropdown-content">
          <li>
            <a href="#" className="dropdown-title">
              {t('menuView.editMenuItem.dropdownHideTitle')}
            </a>
          </li>
          {hidden ? (
            <li>
              <a href="#!" onClick={() => toggleHidden(0)}>
                <i className="material-icons">visibility</i>
                {t('menuView.editMenuItem.dropdownHideShow')}
              </a>
            </li>
          ) : (
            <>
              <li>
                <a href="#!" onClick={() => toggleHidden(1)}>
                  <i className="material-icons">visibility_off</i>
                  {t('menuView.editMenuItem.dropdownHideOneHour')}
                </a>
              </li>
              <li>
                <a href="#!" onClick={() => toggleHidden(3)}>
                  <i className="material-icons">visibility_off</i>
                  {t('menuView.editMenuItem.dropdownHideThreeHour')}
                </a>
              </li>
              <li>
                <a href="#!" onClick={() => toggleHidden(countDownToTomorrow())}>
                  <i className="material-icons">visibility_off</i>
                  {t('menuView.editMenuItem.dropdownHideTomorrow')}
                </a>
              </li>
              <li>
                <a href="#!" onClick={() => toggleHidden(-1)}>
                  <i className="material-icons">visibility_off</i>
                  {t('menuView.editMenuItem.dropdownHideForever')}
                </a>
              </li>
            </>
          )}

          <li className="divider" tabIndex={-1}></li>
          {(isOwner || isAdmin) && (
            <li>
              <a href="#!" onClick={() => duplicateElement(data.id)}>
                <i className="material-icons">content_copy</i>
                {t('menuView.editMenuItem.DuplicateItem')}
              </a>
            </li>
          )}
        </ul>
        {(isOwner || isAdmin) && (
          <>
            <Button onClick={onEdit} variant="contained">
              {t('general.EditButton')}
            </Button>
            <Button onClick={() => setConfirmDialogOpen(true)} variant="outlined" color="error">
              {t('general.Delete')}
            </Button>
            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
              <DialogTitle>{t('general.aboutToDelete') + title}</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    onRemove(data.id)
                    setConfirmDialogOpen(false)
                  }}
                  variant="outlined"
                  color="error"
                >
                  {t('general.Delete')}
                </Button>
                <Button onClick={() => setConfirmDialogOpen(false)} variant="outlined" color="primary">
                  {t('general.Cancel')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </div>
  )
}

export default GridViewMenuItem
