import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth'
import { useAuthContext } from '../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { requestToLogin } from '../../../api/me'
import * as cache from '../../../utils/cache'
import { Alert, Box, Button, Collapse, Link, Stack, TextField, Typography } from '@mui/material'

/**
 *
 */
function LoginForm() {
  const { t, i18n } = useTranslation()
  const [email, setEmail] = useState('')
  const [isPending, setPending] = useState(false)
  const [isEmailSent, setEmailSent] = useState(false)
  const { setLoggedIn } = useAuthContext()
  const navigate = useNavigate()

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${window.location.origin}/welcome`,
    // This must be true.
    handleCodeInApp: true,
    // I removed stuff to make this work, docs here https://firebase.google.com/docs/auth/web/email-link-auth?authuser=2&hl=en
  }

  const auth = getAuth()
  const handleSignIn = () => {
    if (!email) return
    setEmailSent(false)
    setPending(true)

    if (process.env.REACT_APP_MOCK_LOGIN) {
      cache.set('store_auth_token', 'IiuPPBbX5QaFQkDixyOajMrvXG62')
      setLoggedIn(true)
      navigate('/', { replace: true })
      return
    }

    requestToLogin({ email, url: `${window.location.origin}/welcome` })
      // sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(resp => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email)
        setEmailSent(true)
        setPending(false)
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error! ', error)
        setEmailSent(false)
        setPending(false)
      })
  }

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      // if (!email) {
      //   // User opened the link on a different device. To prevent session fixation
      //   // attacks, ask the user to provide the associated email again. For example:
      //   email = window.prompt("Please provide your email for confirmation");
      // }
      // The client SDK will parse the code from the link for you.
      cache.clear()
      setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithEmailLink(auth, email as any, window.location.href)
          .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            console.log('Logged in! ', result)
            setLoggedIn(true)
            navigate('/', { replace: true })
          })
          .catch(error => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            if (error.code == 'auth/email-already-in-use') {
              alert('The email address is already in use')
            } else if (error.code == 'auth/invalid-email') {
              alert('The email address is not valid.')
            } else if (error.code == 'auth/operation-not-allowed') {
              alert('Operation not allowed.')
            } else if (error.code == 'auth/weak-password') {
              alert('The password is too weak.')
            }
            console.log('Error! ', error)
          })
      })
    }
  }, [auth, setLoggedIn])

  if (isSignInWithEmailLink(auth, window.location.href)) {
    return <>Logging in..</>
  }

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      <Stack spacing={2} width="100%">
        <TextField
          id="email"
          type="email"
          placeholder={t('authView.EnterEmail')}
          value={email}
          onChange={e => setEmail(e.target.value)}
          inputProps={{
            className: 'browser-default',
          }}
          className="browser-default"
          disabled={isPending}
        />
        <Button variant="contained" onClick={handleSignIn} disabled={isPending}>
          {t('authView.Login')}
        </Button>
        <Collapse in={isEmailSent}>
          <Alert color="success">
            {/* An email has been sent to your email <Typography variant="h5">{email}</Typography> containing a link to
            login with */}
            <Typography component="div">
              An email has been sent to your email{' '}
              <Box fontWeight="900" display="inline">
                {email}
              </Box>{' '}
              containing a link to login with.
            </Typography>
          </Alert>
        </Collapse>
      </Stack>
      <Link href="https://myntapp.io/enquire-about-mynt/" target="_blank" textAlign="center">
        {t('authView.DontHaveAccount')}
      </Link>
    </Stack>
  )
}

export default LoginForm
