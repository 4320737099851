import React, { useState } from 'react'
import AddKioskForm from './AddKioskForm'
import AddPrinterForm from './AddPrinterForm'
import AddMonitorForm from './AddMonitorForm'
import AddQRCode from './AddQRCode'
import { useMenuContext } from '../../contexts/MenuContext'
import { Box, Dialog, DialogContent, Modal, Paper, Stack } from '@mui/material'
import AddDeviceButton from './components/AddDeviceButton'
import theme from '../../theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedRestaurantState } from '../../atoms/SelectedRestaurantAtom'
import { devicesListState } from '../../atoms/DevicesAtoms'
import AddQRCodeForm from './AddQRCodeForm'

const SelectDeviceForm = (props: any) => {
  const selectedRestaurant = useRecoilValue(selectedRestaurantState)
  const [form, setForm] = useState<any>(null)
  const selectedRestaurantId = selectedRestaurant?.id

  const [deviceList, setDeviceList] = useRecoilState(devicesListState)

  return (
    <div className="select-device-form-wrapper">
      <Stack direction="row" spacing={2}>
        <AddDeviceButton text="Add Kiosk" img="/img/devices/kiosk.png" onClick={() => setForm('kiosk')} />
        <AddDeviceButton text="Add Printer" img="/img/devices/printer.png" onClick={() => setForm('printer')} />
        <AddDeviceButton text="Add QR Code" img="/img/devices/qr-code.png" onClick={() => setForm('qrcode')} />
        <AddDeviceButton text="Add Monitor" img="/img/devices/television.png" onClick={() => setForm('monitor')} />
      </Stack>
      <Dialog
        open={Boolean(form)}
        onClose={() => setForm(null)}
        sx={{
          /* Weird scroll behavior without */
          '& > .MuiDialog-container > .MuiPaper-root': {
            overflowY: 'initial',
            width: '100vw',
            border: Object.keys({}).length ? `2px solid ${theme.palette.error.main}` : 'none',
          },
          '& > .MuiDialog-container': { alignItems: 'flex-start' },
        }}
      >
        <DialogContent sx={{ overflowY: 'scroll' /* Weird scroll behavior without */ }}>
          {form === 'kiosk' && (
            <AddKioskForm
              restaurantId={selectedRestaurantId}
              onCancel={() => setForm(null)}
              onSuccess={newKiosk => {
                setForm(null)
                setDeviceList([...deviceList, newKiosk])
              }}
            />
          )}
          {form === 'printer' && (
            <AddPrinterForm
              restaurantId={selectedRestaurantId}
              restaurant={selectedRestaurant}
              onCancel={() => setForm(null)}
              onSuccess={newPrinter => {
                setForm(null)
                setDeviceList([...deviceList, newPrinter])
              }}
            />
          )}
          {form === 'qrcode' && (
            <AddQRCodeForm
              restaurantId={selectedRestaurantId}
              onCancel={() => setForm(null)}
              onSuccess={newQR => {
                setForm(null)
                setDeviceList([...deviceList, newQR])
              }}
            />
          )}
          {form === 'monitor' && (
            <AddMonitorForm
              handleCancel={() => setForm(null)}
              refresh={() => {
                props.refresh()
                setForm(null)
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SelectDeviceForm
